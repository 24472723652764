import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import LoginControl from "./files/login";
import ForgotPassword from "./files/forgotPassword";
import Validation from "./files/validation";
import ChangePassword from "./files/changePassword";

function PodRouter(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/login" element={<LoginControl adminCode={props.adminCode} />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/validation/:token" element={<Validation />} />
                        <Route path="/newPassword" element={<ChangePassword new={true} />} />
                        <Route path="/changePassword" element={<ChangePassword new={false} />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
