import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Dropdown, ListGroup, Modal } from "react-bootstrap";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import * as HelpDeskStyles from "../styles/helpDesk";

function NewTicket() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    const [categories, setCategories] = useState({
        items: []
    });

    const subjectCounterMax = 50;
    const descriptionCounterMax = 800;

    const [formData, setFormData] = useState({
        values: {
            subject: "",
            description: "",
            typeOfIssue: "Type"
        },
        valids: {
            subject: false,
            typeOfIssue: false,
            description: false
        },
        touched: {
            subject: false,
            typeOfIssue: false,
            description: false
        },
        wholeForm: false,
        counters: {
            subject: subjectCounterMax,
            description: descriptionCounterMax
        },
        max: {
            subject: subjectCounterMax,
            description: descriptionCounterMax
        },
        filesViewer: false
    });

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });
    const [completed, setCompleted] = useState(false);

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [files, setFiles] = useState(null);

    const [failedFilesModal, setFailedFilesModal] = useState({
        files: [],
        open: false
    });

    function handleCloseFailedFilesModal() {
        setFailedFilesModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        getCategories();
    }, []);

    function categoriesOnSelect(item) {
        const values = formData.values;
        const valids = formData.valids;

        let valid = false;

        if (item != "Type") {
            valid = true;
        }

        values["typeOfIssue"] = item;
        valids["typeOfIssue"] = valid;

        let wholeForm = false;

        if (valids.subject && valids.typeOfIssue && valids.description) {
            wholeForm = true;
        }

        setFormData((prevState) => {
            return { ...prevState, values: values, valids: valids, wholeForm: wholeForm };
        });
    }

    function getCategories() {
        Axios.post("/pods/helpdesk/tickets/getAllCategories")
            .then((res) => {
                const data = res.data;
                setCategories((prevState) => {
                    return { ...prevState, items: data.categories };
                });
            })
            .catch((err) => console.log(err));
    }

    function handleOnInputChange(event) {
        const { value, name } = event.target;

        const valids = formData.valids;
        const values = formData.values;
        const counters = formData.counters;
        const max = formData.max;

        counters[name] = max[name] - value.length;
        values[name] = value;

        let valid = false;
        if (value.length > 0) {
            valid = true;
        }

        valids[name] = valid;

        let wholeForm = false;

        if (valids.subject && valids.typeOfIssue && valids.description) {
            wholeForm = true;
        }

        setFormData((prevState) => {
            return { ...prevState, values: values, wholeForm: wholeForm, counters: counters };
        });
    }

    function handleFileInputChange(event) {
        setFiles(event.target.files);

        let filesViewer = false;
        if (event.target.files.length > 1) {
            filesViewer = true;
        }

        setFormData((prevState) => {
            return { ...prevState, filesViewer: filesViewer };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));
        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleUpdateTouch(event) {
        const { name } = event.target;

        const updatedTouched = {
            ...formData.touched,
            [name]: true
        };

        setFormData((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!formData.wholeForm) {
            switchAllTouches();
        } else {
            if (files == null || files.length == 0) {
                const data = {
                    authorID: user.id,
                    subject: formData.values.subject,
                    category: formData.values.typeOfIssue,
                    description: formData.values.description
                };

                Axios.post("/pods/helpdesk/tickets/submitTicketWithoutFiles", data)
                    .then((res) => {
                        const data = res.data;

                        if (data.error == "Yes") {
                            setModal({ header: "New Ticket", message: data.message, open: true });
                        } else {
                            setCompleted(true);
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                let localFiles = [];
                for (const [key, file] of Object.entries(files)) {
                    localFiles.push({
                        id: key,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    });
                }

                const checkData = { files: localFiles };

                Axios.post("/pods/helpdesk/tickets/checkFiles", checkData)
                    .then((res) => {
                        const firstData = res.data;
                        console.log(firstData);
                        if (firstData.error == "Yes") {
                            setModal({ header: "New Ticket", message: firstData.message, open: true });
                        } else if (firstData.limitError == "Yes") {
                            setModal({
                                error: true,
                                header: "File Limit Error",
                                message: "You can only upload 2 files at a time!",
                                open: true
                            });
                        } else if (firstData.failed) {
                            setFailedFilesModal({ files: firstData.files, open: true });
                        } else {
                            const uploadFormData = new FormData();
                            uploadFormData.append("uuid", firstData.uuid);
                            uploadFormData.append("authorID", user.id);
                            uploadFormData.append("subject", formData.values.subject);
                            uploadFormData.append("category", formData.values.typeOfIssue);
                            uploadFormData.append("description", formData.values.description);

                            const uploadConfig = {
                                headers: {
                                    "content-type": "multipart/form-data"
                                }
                            };
                            localFiles.forEach((lFile) => {
                                const file = firstData.files.find((file) => file.id === lFile.id);
                                if (file == null) {
                                    uploadFormData.append("files", files[lFile.id]);
                                }
                            });
                            Axios.post("/pods/helpdesk/tickets/submitTicketWithFiles", uploadFormData, uploadConfig)
                                .then((res) => {
                                    const secondData = res.data;
                                    if (secondData.error == "null") {
                                        setCompleted(true);
                                    }
                                })
                                .catch((err) => console.log(err));
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }

    function goBack() {
        navigate(-1);
    }

    return (
        <div style={HelpDeskStyles.body}>
            <Row>
                <Col style={HelpDeskStyles.newTicketTitle}>
                    <h1>Create a New Ticket</h1>
                </Col>
            </Row>
            {completed ? (
                <div>
                    <Row>
                        <Col style={HelpDeskStyles.newTicketTitle}>
                            <br />
                            <h2>Your ticket has been successfully submitted to High-View Studios</h2>
                            <h3>Your response will take 24 to 48 hours</h3>
                            <br />
                            <Button onClick={goBack}>Go Back</Button>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Subject:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.subject && !formData.valids.subject ? true : false}
                                            type="text"
                                            name="subject"
                                            placeholder="Please describle your issue in as few words as possible"
                                            maxLength={subjectCounterMax}
                                            value={formData.values.subject}
                                            onChange={handleOnInputChange}
                                            onBlur={handleUpdateTouch}
                                        />
                                        <Row>
                                            <Col
                                                style={
                                                    formData.touched.subject && !formData.valids.subject
                                                        ? HelpDeskStyles.errorText
                                                        : HelpDeskStyles.errorTextHidden
                                                }
                                            >
                                                Please fill in the subject
                                            </Col>
                                            <Col style={HelpDeskStyles.subjectCounter}>{formData.counters.subject}</Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <Form.Label>Type of Issue:</Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant={formData.touched.typeOfIssue && !formData.valids.typeOfIssue ? "danger" : "primary"}
                                            name="typeOfIssue"
                                        >
                                            {formData.values.typeOfIssue}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {categories.items.map((category, index) => {
                                                return (
                                                    <Dropdown.Item key={index} onClick={categoriesOnSelect.bind(this, category.name)}>
                                                        {category.name}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Row>
                                    <Col
                                        style={
                                            formData.touched.typeOfIssue && !formData.valids.typeOfIssue
                                                ? HelpDeskStyles.errorText
                                                : HelpDeskStyles.errorTextHidden
                                        }
                                    >
                                        <p>Please pick a Type of Issue</p>
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                <Col style={HelpDeskStyles.newTicketTypeSection}>
                                    <Form.Group>
                                        <Form.Label>Description:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.description && !formData.valids.description ? true : false}
                                            as="textarea"
                                            rows={4}
                                            name="description"
                                            placeholder="Please describle your issue in detail"
                                            maxLength={descriptionCounterMax}
                                            value={formData.values.description}
                                            onChange={handleOnInputChange}
                                            onBlur={handleUpdateTouch}
                                        />

                                        <Row>
                                            <Col
                                                style={
                                                    formData.touched.description && !formData.valids.description
                                                        ? HelpDeskStyles.errorText
                                                        : HelpDeskStyles.errorTextHidden
                                                }
                                            >
                                                Please fill in the description
                                            </Col>
                                            <Col style={HelpDeskStyles.subjectCounter}>{formData.counters.description}</Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>

                        {/* MAIN COLUMN 2 */}
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Files / Screenshots:</Form.Label>
                                        <Form.Control type="file" name="files/screenshots" onChange={handleFileInputChange} multiple />
                                        <div style={HelpDeskStyles.uploadDescription}>2 Files Max. 4MB Max per file</div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {formData.filesViewer ? (
                                    <div>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Files:</Form.Label>
                                            </Form.Group>
                                            <div style={HelpDeskStyles.multipleFilesSection}>
                                                <ListGroup>
                                                    {Object.keys(files).map((key, index) => {
                                                        return <ListGroup.Item key={index}>{files[key].name}</ListGroup.Item>;
                                                    })}
                                                </ListGroup>
                                            </div>
                                        </Col>
                                    </div>
                                ) : null}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={HelpDeskStyles.submitButtom}>
                            <Button onClick={handleSubmit}>Submit Ticket</Button>
                        </Col>
                    </Row>
                </div>
            )}
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={failedFilesModal.open} onHide={handleCloseFailedFilesModal}>
                <Modal.Header closeButton style={HelpDeskStyles.filesTableheading}>
                    <Modal.Title>New Ticket: Failed Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The following files can&#39;t be uploaded with this ticket, as to the reasons given. These need to be corrected before the
                        ticket can be submitted
                        <br />
                        <table border="1px" width="100%">
                            <thead>
                                <tr>
                                    <th style={HelpDeskStyles.filesTableRow}>
                                        <strong>File</strong>
                                    </th>
                                    <th style={HelpDeskStyles.filesTableRow}>
                                        <strong>Reason</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedFilesModal.files.map((file, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={HelpDeskStyles.filesTableRow}>{file.file}</td>
                                            <td style={HelpDeskStyles.filesTableRow}>{file.message}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <br />
                        <strong>Spaces in file names will be replaced by a &#39;_&#39; (underscore)</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFailedFilesModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewTicket;
