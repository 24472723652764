import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Table, Button, Modal, Form } from "react-bootstrap";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";
import * as UploadStyles from "../../../../../administration/pods/media/styles/upload"; //CROSS-OVER POD (from pod ref. A5)

function QuoteDetail() {
    const params = useParams();
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        quoteDetails: {
            minQuote: 0,
            maxQuote: 0
        },
        valids: {
            minQuote: false,
            maxQuote: false
        },
        touched: {
            minQuote: false,
            maxQuote: false
        },
        wholeForm: false
    });
    const [usersDetails, setUsersDetails] = useState({
        name: "",
        email: ""
    });

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("App Request Quote - Details"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("App Request Quote"));

        const data = { uuid: params.uuid };
        Axios.post("/pods/appRequestQuote/controller/getQuoteData", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    setDetails((prevState) => {
                        return {
                            ...prevState,
                            quoteDetails: { ...details.quoteDetails, ...data.quote }
                        };
                    });

                    setUsersDetails({
                        name: data.quote.user.fullName,
                        email: data.quote.user.email
                    });
                    validateQuoteNumbers(data.quote.minQuote, data.quote.maxQuote);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function validateQuoteNumbers(minQuote, maxQuote) {
        const valids = details.valids;

        if (minQuote.length > 0 && !isNaN(minQuote)) {
            valids["minQuote"] = true;
        } else {
            valids["minQuote"] = false;
        }

        if (maxQuote.length > 0 && !isNaN(maxQuote)) {
            valids["maxQuote"] = true;
        } else {
            valids["maxQuote"] = false;
        }

        let wholeForm = false;

        if (valids.minQuote && valids.maxQuote) {
            wholeForm = true;
        }

        setDetails((prevState) => {
            return { ...prevState, valids: valids, wholeForm: wholeForm };
        });
    }

    function handleQuoteOnChange(event) {
        const { name, value } = event.target;

        const quoteDetails = details.quoteDetails;
        const valids = details.valids;

        quoteDetails[name] = value;

        let valid = false;
        if (value.length > 0 && !isNaN(value)) {
            valid = true;
        }

        valids[name] = valid;

        let wholeForm = false;

        if (valids.minQuote && valids.maxQuote) {
            wholeForm = true;
        }

        setDetails((prevState) => {
            return {
                ...prevState,
                quoteDetails: quoteDetails,
                valids: valids,
                wholeForm: wholeForm
            };
        });
    }

    function handleUpdateTouched(event) {
        const { name } = event.target;

        const updatedTouches = { ...details.touched, [name]: true };

        setDetails((prevState) => {
            return { ...prevState, touched: updatedTouches };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(details.touched).map((k) => ({ [k]: true })));
        setDetails((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleUpdateQuote() {
        if (!details.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                uuid: params.uuid,
                name: usersDetails.name,
                email: usersDetails.email,
                minQuote: details.quoteDetails.minQuote,
                maxQuote: details.quoteDetails.maxQuote
            };
            Axios.post("/pods/appRequestQuote/controller/updateQuote", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setDetails((prevState) => {
                            return {
                                ...prevState,
                                quoteDetails: { ...details.quoteDetails, ...data.quote }
                            };
                        });

                        setUsersDetails({
                            name: data.quote.user.fullName,
                            email: data.quote.user.email
                        });
                        validateQuoteNumbers(data.quote.minQuote, data.quote.maxQuote);

                        setModal({
                            header: "App Request Quote Details",
                            message: "The status of this quote has been updated!",
                            open: true
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col md={8}>
                    <div>
                        <Table style={ControllerStyles.itemsTable}>
                            <tbody>
                                <tr>
                                    <td style={ControllerStyles.tableHeadings}>Brief Description</td>
                                    <td style={ControllerStyles.tableCells}>{details.quoteDetails.briefDescription}</td>
                                </tr>
                                <tr>
                                    <td style={ControllerStyles.tableHeadings}>Features</td>
                                    <td style={ControllerStyles.tableCells}>{details.quoteDetails.features}</td>
                                </tr>
                                <tr>
                                    <td style={ControllerStyles.tableHeadings}>Platform</td>
                                    <td style={ControllerStyles.tableCells}>{details.quoteDetails.platform}</td>
                                </tr>
                                <tr>
                                    <td style={ControllerStyles.tableHeadings}>Additional Information</td>
                                    <td style={ControllerStyles.tableCells}>{details.quoteDetails.addInfo}</td>
                                </tr>
                                <tr>
                                    <td style={ControllerStyles.tableHeadings}>Status</td>
                                    <td style={ControllerStyles.tableCells}>{details.quoteDetails.status}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        <Row>
                            <Col>
                                <Table style={ControllerStyles.itemsTable}>
                                    <tbody>
                                        <tr>
                                            <td style={ControllerStyles.tableCells}>Name</td>
                                            <td style={ControllerStyles.tableCells}>{usersDetails.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={ControllerStyles.tableCells}>Email</td>
                                            <td style={ControllerStyles.tableCells}>{usersDetails.email}</td>
                                        </tr>
                                        <tr>
                                            <td style={ControllerStyles.tableCells}>Company</td>
                                            <td style={ControllerStyles.tableCells}>{details.quoteDetails.company}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col style={ControllerStyles.minMaxSection}>
                                <Form.Group>
                                    <Row>
                                        <Col style={ControllerStyles.quotePoundSign} sm={1}>
                                            £
                                        </Col>
                                        <Col style={ControllerStyles.quoteBox} sm={11}>
                                            <Form.Control
                                                isInvalid={details.touched.minQuote && !details.valids.minQuote ? true : false}
                                                type="text"
                                                name="minQuote"
                                                value={details.quoteDetails.minQuote}
                                                placeholder="Min Quote"
                                                onChange={handleQuoteOnChange}
                                                onBlur={handleUpdateTouched}
                                            />
                                            <Form.Control.Feedback type="invalid">Enter a valid minimum quote</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Row>
                                        <Col style={ControllerStyles.quotePoundSign} sm={1}>
                                            £
                                        </Col>
                                        <Col style={ControllerStyles.quoteBox} sm={11}>
                                            <Form.Control
                                                isInvalid={details.touched.maxQuote && !details.valids.maxQuote ? true : false}
                                                type="text"
                                                name="maxQuote"
                                                value={details.quoteDetails.maxQuote}
                                                placeholder="Max Quote"
                                                onChange={handleQuoteOnChange}
                                                onBlur={handleUpdateTouched}
                                            />
                                        </Col>
                                    </Row>
                                    <Form.Control.Feedback type="invalid">Enter a valid maximum quote</Form.Control.Feedback>
                                </Form.Group>
                                <br />
                                <Button onClick={handleUpdateQuote}>Update</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={4}></Col>
            </Row>

            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default QuoteDetail;
