import React, { useEffect } from "react";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

//STYLES
import * as CancelPaymentStyles from "../../styles/checkout/cancelPayment";

function CancelPayment() {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const data = { orderUUID: params.id };
        Axios.post("/pods/3dPrintingShop/checkout/cancelPayment", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    navigate("../basket");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={CancelPaymentStyles.body}>
            <h1>Cancelling Payment</h1>
        </div>
    );
}

export default CancelPayment;
