import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as WebsiteCustomersStyles from "../../styles/packages/websiteCustomers";

function WebsiteCustomers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(`Website Customers`));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));
    }, []);

    function handleNewCustomerClick() {
        navigate("./newCustomer");
    }

    return (
        <div style={WebsiteCustomersStyles.body}>
            <Row>
                <Col style={WebsiteCustomersStyles.columnRight}>
                    <Button onClick={handleNewCustomerClick}>New Customer</Button>
                </Col>
            </Row>
            A <br />
            A <br />
            A <br />
            A <br />
            A <br />
            A <br />
            A <br />
            A <br />
            A <br />
        </div>
    );
}

export default WebsiteCustomers;
