//THIS SCRIPT CONTAINS BROWSER AND MOBILE VIEW
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Card } from "react-bootstrap";
import CenterContainer from "../../../special/wholeSite/centerContainer";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";

//STYLES
import * as mainMenuStyles from "../styles/mainMenu";

function MainMenu() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [showPlans, setShowPlans] = useState(false);

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        const data = { userID: user.id };
        Axios.post("/pods/plannerTasks/getUserPlans", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    if (data.plans.length > 0) {
                        setShowPlans(true);
                    } else {
                        setShowPlans(false);
                    }
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleOnlinePaymentsClick() {
        navigate("./onlinePayments");
    }

    function handleHelpDeskClick() {
        if (user.type == "seniorAdmin") {
            setModal({ header: "ViewHelp Online Helpdesk", message: "A Senior admin can't use the fornt end of the helpdesk", open: true });
        } else {
            navigate("./helpDesk");
        }
    }

    function handlePlannerClick() {
        navigate("./planner");
    }

    function handleCallOutClick() {
        navigate("./callOut");
    }

    const browserView = (
        <div>
            {showPlans ? (
                <div>
                    <Row>
                        <Col>
                            <CenterContainer>
                                <div style={mainMenuStyles.topButtons} onClick={handleOnlinePaymentsClick}>
                                    <span style={mainMenuStyles.topButtonsText}>
                                        Online
                                        <br />
                                        Payments
                                    </span>
                                </div>
                            </CenterContainer>
                        </Col>
                        <Col>
                            <CenterContainer>
                                <div style={mainMenuStyles.topButtons} onClick={handleHelpDeskClick}>
                                    <span style={mainMenuStyles.topButtonsText}>
                                        VeiwHelp
                                        <br />
                                        Online Helpdesk
                                    </span>
                                </div>
                            </CenterContainer>
                        </Col>
                        <Col>
                            <CenterContainer>
                                <div style={mainMenuStyles.topButtons} onClick={handlePlannerClick}>
                                    <span style={mainMenuStyles.topButtonsText}>
                                        Your
                                        <br />
                                        Plans
                                    </span>
                                </div>
                            </CenterContainer>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <Row>
                        <Col>
                            <CenterContainer>
                                <div style={mainMenuStyles.topButtons} onClick={handleOnlinePaymentsClick}>
                                    <span style={mainMenuStyles.topButtonsText}>
                                        Online
                                        <br />
                                        Payments
                                    </span>
                                </div>
                            </CenterContainer>
                        </Col>
                        <Col>
                            <CenterContainer>
                                <div style={mainMenuStyles.topButtons} onClick={handleHelpDeskClick}>
                                    <span style={mainMenuStyles.topButtonsText}>
                                        VeiwHelp
                                        <br />
                                        Online Helpdesk
                                    </span>
                                </div>
                            </CenterContainer>
                        </Col>
                    </Row>
                </div>
            )}
            <Row>
                <Col>
                    <CenterContainer ends={4} middle={4}>
                        <div style={mainMenuStyles.callOutButton} onClick={handleCallOutClick}>
                            Call Out High-View Studios
                        </div>
                    </CenterContainer>
                </Col>
            </Row>
        </div>
    );

    const mobileView = (
        <div style={mainMenuStyles.mobile_body}>
            <Row>
                <Col>
                    <Card style={mainMenuStyles.mobile_cardStyle} onClick={handleOnlinePaymentsClick}>
                        <Card.Body>Online Payments</Card.Body>
                    </Card>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={mainMenuStyles.mobile_cardStyle} onClick={handleHelpDeskClick}>
                        <Card.Body>
                            ViewHelp <br /> Online Helpdesk
                        </Card.Body>
                    </Card>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={mainMenuStyles.mobile_cardStyle} onClick={handlePlannerClick}>
                        <Card.Body>Your Plans</Card.Body>
                    </Card>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={mainMenuStyles.mobile_cardStyle} onClick={handleCallOutClick}>
                        <Card.Body>
                            Call Out <br /> High-View Studios
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );

    return (
        <div>
            {isBrowser ? browserView : mobileView}
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MainMenu;
