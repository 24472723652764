import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//STYLES
import * as ControllerStyles from "../styles/controller";

//COMPONENTS
import Product from "./product";

function Controller() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/pods/3dPrintingShop/frontEnd/getProducts")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setProducts(data.products);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleClick(code) {
        navigate(`./product/${code}`);
    }

    return (
        <div>
            <Row>
                <Col style={ControllerStyles.itemContainer}>
                    <Row>
                        {products.length == 0 ? (
                            <div style={ControllerStyles.shopClosedSign}>The shop is Closed</div>
                        ) : (
                            <>
                                {products.map((product, index) => {
                                    return <Product key={index} product={product} click={handleClick} />;
                                })}
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Controller;
