import React from "react";

//STYLES
import * as OrderConfirmationStyles from "../../styles/checkout/processOrder";

function OrderConfirmation() {
    return (
        <div style={OrderConfirmationStyles.body}>
            <h1>Order Confirmation</h1>
            <p>Thank you for your order</p>
        </div>
    );
}

export default OrderConfirmation;
