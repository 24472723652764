import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

//STYLES
import * as WebsiteCustomersStyles from "../../styles/packages/websiteCustomers";

function WebPackageItem(props) {
    return (
        <div>
            <Row>
                <Col md="auto" style={WebsiteCustomersStyles.vCenter}>
                    <Form.Check label="" />
                </Col>
                <Col style={WebsiteCustomersStyles.vCenter}>{props.item.name}</Col>
                <Col style={WebsiteCustomersStyles.vCenter}>£{props.item.price}</Col>
                <Col style={WebsiteCustomersStyles.centering}>
                    <Button>-</Button> 0 <Button>+</Button>
                </Col>
                <Col style={WebsiteCustomersStyles.vCenter}>
                    <Form.Control type="text" />
                </Col>
                <Col style={WebsiteCustomersStyles.vCenter}>£0.00</Col>
            </Row>
        </div>
    );
}

export default WebPackageItem;
