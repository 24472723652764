import React, { useState } from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as PhotoBoxStyles from "../styles/photoBox";

//COMPONENTS
import SubImageContainer from "./subImageContainer";

function PhotoBox(props) {
    const [selected, setSelected] = useState(0);

    function handleUpdateSelected(index) {
        setSelected(index);
    }

    return (
        <div>
            <div style={PhotoBoxStyles.container}>
                {props.images.length > 0 && (
                    <Image
                        style={PhotoBoxStyles.currentImage}
                        src={`/content/3dPrintingShop/products/${props.uuid.toString()}/${props.images[selected]}`}
                    />
                )}
            </div>
            <div style={PhotoBoxStyles.subContainerOuter}>
                {props.images.length > 0 && (
                    <div style={PhotoBoxStyles.subContainerInner}>
                        {props.images.map((image, index) => {
                            if (index == selected) {
                                return (
                                    <SubImageContainer
                                        key={index}
                                        selected={true}
                                        path={`/content/3dPrintingShop/products/${props.uuid.toString()}/${image}`}
                                        onClick={handleUpdateSelected.bind(this, index)}
                                    />
                                );
                            } else {
                                return (
                                    <SubImageContainer
                                        key={index}
                                        selected={false}
                                        path={`/content/3dPrintingShop/products/${props.uuid.toString()}/${image}`}
                                        onClick={handleUpdateSelected.bind(this, index)}
                                    />
                                );
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default PhotoBox;
