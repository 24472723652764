import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, Card } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as ViewControllerStyles from "../styles/viewController";

function CategoriesView(props) {
    const [categories, setCategories] = useState({
        items: [],
        selected: 0
    });

    const [editModal, setEditModal] = useState({
        open: false,
        title: "New Category",
        text: "",
        valid: false,
        touched: false
    });

    function handleCloseEditModal() {
        setEditModal((prevState) => {
            return { ...prevState, open: false, touched: false, title: "New Category" };
        });
    }

    const [modal, setModal] = useState({
        title: "",
        message: "",
        show: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, show: false };
        });
    }

    useEffect(() => {
        getCategories();
    }, []);

    function getCategories() {
        Axios.post("/pods/helpdesk/categoryView/getAllCategories")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCategories((prevState) => {
                        return { ...prevState, items: data.categories, selected: 0 };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNewBtn() {
        setEditModal((prevState) => {
            return { ...prevState, open: true, touched: false, valid: false, title: "New Category", text: "" };
        });
    }

    function handleEditBtn() {
        setEditModal((prevState) => {
            return { ...prevState, open: true, title: "Edit Category" };
        });
    }

    function handleOnTouched() {
        setEditModal((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function onInputChange(event) {
        const { value } = event.target;

        let valid = false;

        if (value.length > 0) {
            valid = true;
        }

        setEditModal((prevState) => {
            return { ...prevState, text: value, valid: valid };
        });
    }

    function handleEditOnSave() {
        if (editModal.valid) {
            const data = { title: editModal.text };

            Axios.post("/pods/helpdesk/categoryView/addCategory", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "Yes") {
                        setModal({ title: "Add Category", message: data.message, show: true });
                    } else {
                        setCategories((prevState) => {
                            return { ...prevState, items: data.categories };
                        });

                        handleCloseEditModal();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleEditOnUpdate() {
        if (editModal.valid) {
            const data = { uuid: categories.selected, title: editModal.text };

            Axios.post("/pods/helpdesk/categoryView/editCategory", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "Yes") {
                        setModal({ title: "Edit Category", message: data.message, show: true });
                    } else {
                        setCategories((prevState) => {
                            return { ...prevState, items: data.categories };
                        });

                        handleCloseEditModal();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnDelete() {
        const data = { uuid: categories.selected };

        Axios.post("/pods/helpdesk/categoryView/deleteCategory", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCategories((prevState) => {
                        return { ...prevState, items: data.categories, selected: 0 };
                    });

                    handleCloseEditModal();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnSelect(uuid, title) {
        setCategories((prevState) => {
            return { ...prevState, selected: uuid };
        });

        let valid = false;

        if (title.length > 0) {
            valid = true;
        }

        setEditModal((prevState) => {
            return { ...prevState, text: title, valid: valid };
        });
    }

    function handleBackNewBtn() {
        setCategories((prevState) => {
            return { ...prevState, selected: 0 };
        });

        setEditModal((prevState) => {
            return { ...prevState, touched: false, valid: false, text: "" };
        });
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Categories</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={ViewControllerStyles.scrollable250}>
                    {categories.items.map((category, index) => {
                        return (
                            <div key={index}>
                                <Card
                                    style={
                                        category.uuid == categories.selected ? ViewControllerStyles.cardSelectedStyle : ViewControllerStyles.cardStyle
                                    }
                                    onClick={handleOnSelect.bind(this, category.uuid, category.name)}
                                >
                                    <Card.Body>{category.name}</Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mr-auto">
                    {categories.selected == 0 ? (
                        <div>
                            <Button className="btn btn-primary" onClick={handleNewBtn}>
                                New
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button className="btn btn-primary" onClick={handleBackNewBtn}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </Button>
                            <Button className="btn btn-primary" onClick={handleEditBtn}>
                                Edit
                            </Button>
                            <Button className="btn btn-primary" onClick={handleOnDelete}>
                                Remove
                            </Button>
                        </div>
                    )}
                </div>

                <Button className="btn btn-primary" onClick={props.close}>
                    Close
                </Button>
            </Modal.Footer>

            <Modal show={editModal.open} onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Name: <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={editModal.touched && !editModal.valid ? true : false}
                            type="text"
                            onChange={onInputChange}
                            value={editModal.text}
                            onBlur={handleOnTouched}
                            autoFocus
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {categories.selected == 0 ? (
                        <div>
                            <Button onClick={handleEditOnSave}>Save</Button>
                        </div>
                    ) : (
                        <div>
                            <Button onClick={handleEditOnUpdate}>Update</Button>
                        </div>
                    )}

                    <Button onClick={handleCloseEditModal}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal.show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CategoriesView;
