import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import Axios from "axios";

//COMPONENTS
import YourPlans from "./files/yourPlans";
import Home from "./files/home";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!user.auth) {
            navigate("/pod/membership/login");
        } else {
            if (!location.pathname.includes("/plan")) {
                const data = { userID: user.id };
                Axios.post("/pods/plannerTasks/getUserPlans", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "null") {
                            if (data.plans.length != 1) {
                                navigate("./");
                            } else {
                                navigate(`./plan/${data.plans[0].planUUID}/home`);
                            }
                            setIsLoaded(true);
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                setIsLoaded(true);
            }
        }
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path={"/"} element={<YourPlans />} />
                        <Route path={"/plan/:id/home"} element={<Home />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
