import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

//STYLES
import * as CheckoutStyles from "../../styles/basket/checkout";

function BasketSideBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const printingShop = useSelector((state) => state.printingShop);

    function handleUpdatBasket() {
        dispatch(PrintingShopActions.UpdateBasketOnServer());
    }

    function handleGoToCheckout() {
        navigate("../checkout");
    }

    return (
        <div style={CheckoutStyles.body}>
            {printingShop.items.length > 0 && (
                <div>
                    <strong>Sub Total: £{(parseInt(printingShop.basketTotal) / 100).toFixed(2)}</strong> <br /> <br />
                    <div style={CheckoutStyles.checkoutBtn}>
                        <div>
                            {printingShop.updateBtn ? (
                                <Button variant="success" style={CheckoutStyles.checkoutButton} onClick={handleUpdatBasket}>
                                    Update
                                </Button>
                            ) : (
                                <Button variant="success" style={CheckoutStyles.checkoutButton} onClick={handleGoToCheckout}>
                                    Go To Checkout
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BasketSideBar;
