import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const removeCardStyle = {
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    textAlign: "center"
};

export const mainContactButton = {
    textAlign: "center"
};

export const mainContactLabels = {
    margin: "auto"
};

export const mainContactSwitchText = {
    fontSize: "1.5rem"
};

export const otherContactsAddBtn = {
    textAlign: "right"
};

export const editorialLabel = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};
