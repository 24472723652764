import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Form, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ColoursStyles from "../styles/colours";

function Colours() {
    const dispatch = useDispatch();
    const [colours, setColours] = useState([]);

    const colourModalDefaultValues = {
        open: false,
        values: {
            name: "",
            available: false
        },
        isValid: false,
        triedToSubmit: false,
        edit: false,
        editableID: ""
    };
    const [colourModal, setColorModal] = useState(colourModalDefaultValues);

    function handleCloseColourModal() {
        setColorModal(colourModalDefaultValues);
    }

    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleModalClose() {
        setModal({ header: "", message: "", open: false });
    }

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        deleteID: ""
    });

    function handleDeleteModalClose() {
        setDeleteModal({ open: false, deleteID: "" });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Colours"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadColours();
    }, []);

    function loadColours() {
        Axios.post("/pods/3dPrintingShop/getColours").then((res) => {
            const data = res.data;
            if (data.error == "null") {
                setColours(data.colours);
            }
        });
    }

    function handleNewColourClick() {
        setColorModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleAvailbleCheckChange(event) {
        const { checked } = event.target;

        const values = colourModal.values;

        values.available = checked;

        setColorModal((prevState) => {
            return { ...prevState, values };
        });
    }

    function handleNameChange(event) {
        const { value } = event.target;

        const values = colourModal.values;
        values.name = value;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        setColorModal((prevState) => {
            return { ...prevState, values, isValid };
        });
    }

    function handleOnSave() {
        if (colourModal.values.name == "") {
            setColorModal((prevState) => {
                return { ...prevState, triedToSubmit: true };
            });
        } else {
            const data = { name: colourModal.values.name, available: colourModal.values.available.toString() };
            Axios.post("/pods/3dPrintingShop/addColour", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setColorModal(colourModalDefaultValues);
                        loadColours();
                    } else {
                        setModal({ header: "Add Colour", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleEditClick(uuid) {
        const values = {
            name: colours[uuid].name,
            available: colours[uuid].available == "true"
        };

        setColorModal((prevState) => {
            return { ...prevState, values, edit: true, editableID: colours[uuid].uuid, open: true };
        });
    }

    function handleOnUpdate() {
        if (colourModal.values.name == "") {
            setColorModal((prevState) => {
                return { ...prevState, triedToSubmit: true };
            });
        } else {
            const data = { uuid: colourModal.editableID, name: colourModal.values.name, available: colourModal.values.available.toString() };
            Axios.post("/pods/3dPrintingShop/updateColour", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setColorModal(colourModalDefaultValues);
                        loadColours();
                    } else {
                        setModal({ header: "Add Colour", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDeleteYNOpen(uuid) {
        setDeleteModal({ deleteID: colours[uuid].uuid, open: true });
    }

    function handleDeleteYesClick() {
        const data = { uuid: deleteModal.deleteID };

        Axios.post("/pods/3dPrintingShop/deleteColour", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDeleteModal({ open: false, deleteID: "" });
                    loadColours();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ColoursStyles.body}>
            <Row>
                <Col style={ColoursStyles.newColourBtn}>
                    <Button onClick={handleNewColourClick}>New Colour</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Card style={ColoursStyles.cardHeader}>
                        <Card.Body>
                            <Row>
                                <Col sm={4}>Name</Col>
                                <Col sm={4}>Available</Col>
                                <Col sm={2}></Col>
                                <Col sm={2}></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {colours.map((colour, index) => {
                        return (
                            <div key={index}>
                                <Card style={ColoursStyles.cardStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={4} style={ColoursStyles.columnStyle}>
                                                {colour.name}
                                            </Col>
                                            <Col sm={4} style={ColoursStyles.columnStyle}>
                                                {colour.available}
                                            </Col>
                                            <Col sm={2} style={ColoursStyles.buttonColStyle}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                    <Button variant="warning" onClick={handleEditClick.bind(this, index)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm={2} style={ColoursStyles.buttonColStyle}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                    <Button variant="danger" onClick={handleDeleteYNOpen.bind(this, index)}>
                                                        <i className="fa-solid fa-trash-can"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <Modal show={colourModal.open} onHide={handleCloseColourModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Colour</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={colourModal.triedToSubmit && !colourModal.isValid}
                            type="text"
                            value={colourModal.values.name}
                            onChange={handleNameChange}
                            maxLength={50}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Check label="Available" checked={colourModal.values.available} onChange={handleAvailbleCheckChange} />
                </Modal.Body>
                <Modal.Footer>
                    {colourModal.edit ? <Button onClick={handleOnUpdate}>Update</Button> : <Button onClick={handleOnSave}>Save</Button>}
                    <Button onClick={handleCloseColourModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteModal.open} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Colour?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this colour?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDeleteYesClick}>Yes</Button>
                    <Button onClick={handleDeleteModalClose}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Colours;
