import React from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as SubImageContainerStyles from "../styles/subImageContainer";

function SubImageContainer(props) {
    return (
        <div>
            <Image
                style={props.selected ? SubImageContainerStyles.imageSelected : SubImageContainerStyles.image}
                src={props.path}
                onClick={props.onClick}
            />
        </div>
    );
}

export default SubImageContainer;
