export const body = {
    paddingTop: "10px"
};

export const errorText = {
    fontSize: "1rem",
    color: "red"
};

export const errorTextHidden = {
    fontSize: "1rem",
    color: "red",
    visibility: "hidden"
};

export const descriptionCounter = {
    fontSize: "1rem",
    color: "red",
    textAlign: "right"
};

export const uploadDescription = {
    fontSize: "1rem",
    fontWeight: "bold"
};

export const multipleFilesSection = {
    padding: "10px",
    fontSize: "1rem"
};

export const fileSection = {
    border: "solid 2px red",
    height: "150px",
    overflowY: "auto"
};

export const fileSectionHidden = {
    border: "solid 2px red",
    height: "150px",
    overflowY: "auto",
    visibility: "hidden"
};

export const buttonsSection = {
    textAlign: "right"
};

export const filesTableRow = {
    padding: "5px"
};

export const filesTableheading = {
    color: "red"
};
