import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import Login from "./files/loginForm";
import Register from "./files/register";
import ForgotPassword from "./files/forgottenPassword";
import EmailVerified from "./files/emailVerified";
import ChangePassword from "./files/changePassword";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/emailverified/:id/:email" element={<EmailVerified />} />
                        <Route path="/changePassword/:redirectBack" element={<ChangePassword />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
