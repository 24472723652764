import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useSelector } from "react-redux";

//STYLES
import * as HelpDeskStyles from "../styles/helpDesk";

function HelpDesk() {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        const data = { userID: user.id };
        Axios.post("/pods/helpdesk/tickets/getUsersTickets", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setTickets(data.tickets);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNewTicketButton() {
        navigate("./newTicket");
    }

    function handleTicketOnClick(uuid) {
        navigate(`./ticket/${uuid}`);
    }

    return (
        <div style={HelpDeskStyles.body}>
            <Row>
                <Col style={HelpDeskStyles.newTicketButtonRow}>
                    <Button onClick={handleNewTicketButton}>New Ticket</Button>
                </Col>
            </Row>
            {tickets.length == 0 ? (
                <div>
                    <Row>
                        <Col sm={2}></Col>
                        <Col style={HelpDeskStyles.noTicketsNotice}>
                            <p>You have no tickets to your account, click &#39;New Ticket&#39; to open a new ticket with High-View Studios</p>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <Row>
                        <Col sm={2}></Col>
                        <Col>
                            {tickets.map((ticket, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={HelpDeskStyles.cardStyle} onClick={handleTicketOnClick.bind(this, ticket.uuid)}>
                                            <Card.Body>
                                                <Row>
                                                    <Col>Stubject: {ticket.subject}</Col>
                                                    <Col>Date: {ticket.date}</Col>
                                                    <Col>Status: {ticket.status}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default HelpDesk;
