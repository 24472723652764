import React from "react";
import { useParams } from "react-router-dom";

//FILES
import TicketViewer from "../../mixedComponents/files/ticketViewer";

function TicketHolder() {
    const params = useParams();

    return (
        <div>
            <TicketViewer type="admin" uuid={params.uuid} />
        </div>
    );
}

export default TicketHolder;
