import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//COMPONENTS
import Controller from "./files/controller";
import ProductDetails from "./files/productDetails";
import AccessModalInterface from "../../accessModal/files/frontInterface";
import Basket from "./files/basket/basket";
import BasketSideBar from "./files/basket/basketSideBar";
import Checkout from "./files/checkout/checkout";
import CheckoutSideBar from "./files/checkout/checkoutSideBar";

import CancelPayment from "./files/checkout/cancelPayment";
import ProcessOrder from "./files/checkout/proccessOrder";
import OrderConfirmation from "./files/checkout/orderConfirmation";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.requestedPassword) {
            dispatch(AccessModalActions.UpdateScreen("changePassword"));
        }
        setIsLoaded(true);
    });

    return (
        <div>
            {isLoaded && (
                <div>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <Routes>
                                <Route path={"/"} element={<Controller />} />
                                <Route path={"/product/:code"} element={<ProductDetails />} />
                                <Route path={"/basket"} element={<Basket />} />
                                <Route path={"/checkout"} element={<Checkout />} />
                                <Route path={"/cancelPayment/:id"} element={<CancelPayment />} />
                                <Route path={"/processOrder/:id"} element={<ProcessOrder />} />
                                <Route path={"/orderConfirmation"} element={<OrderConfirmation />} />
                            </Routes>
                        </Col>
                        <Col sm={2}>
                            <AccessModalInterface />
                            <Routes>
                                <Route path={"/basket"} element={<BasketSideBar />} />
                                <Route path={"/checkout"} element={<CheckoutSideBar />} />
                            </Routes>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
