import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap";
import Axios from "axios";
import moment from "moment";

//STYLES
import * as ImagesAndModelsStyles from "../../styles/products/imagesAndModels";

//COMPONENTS
import ImageContainer from "./imageContainer";

function NewProduct_ImagesAndModels(props) {
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        console.log(props.data.values.models);
        if (props.data.values.models.length > 0) {
            addUpTimeTotal([...props.data.values.models]);
            addUpWeightTotal([...props.data.values.models]);
        }
    }, [props.data.values.models]);

    const [imagesControls, setImageControls] = useState({
        view: false,
        uuid: ""
    });

    const [tableLoaded, setTableLoaded] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(true);

    const modelInputDefaultValues = {
        open: false,
        values: {
            name: "",
            timeH: "00",
            timeM: "00",
            weight: ""
        },
        valids: {
            name: false,
            weight: false,
            time: false
        },
        touched: {
            name: false,
            weight: false
        },
        errorOnForm: false,
        wholeFormValid: false,
        edit: false,
        selectedID: -1
    };
    const [modelInput, setModelInput] = useState(modelInputDefaultValues);

    function handleCloseModelInput() {
        setModelInput(modelInputDefaultValues);

        setTableLoaded(false);

        setTimeout(() => {
            setTableLoaded(true);
        }, 1);
    }

    const [totalTime, setTotalTime] = useState("00:00");
    const [totalWeight, setTotalWeight] = useState("0kg");

    function handleOpenFileInput() {
        setImageControls((prevState) => {
            return { ...prevState, view: false };
        });
        hiddenFileInput.current.click();
    }

    function handleFilesSelected(event) {
        const files = event.target.files;

        const uploadFormData = new FormData();

        for (const file of files) {
            uploadFormData.append("photos", file);
        }
        const uploadConfig = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
        Axios.post("/pods/3dPrintingShop/uploadImages", uploadFormData, uploadConfig)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const newImages = [];
                    for (const image of data.images) {
                        const slot = { name: "n/a", file: image, status: "new" };
                        newImages.push(slot);
                    }

                    const images = [...props.data.values.images, ...newImages];

                    const currentData = props.data.values;
                    currentData.images = images;

                    const prevImages = [...props.data.previousImages];

                    prevImages.push({ name: "n/a", file: "n/a", status: "new" });

                    props.setData((prevState) => {
                        return { ...prevState, values: currentData, previousImages: prevImages };
                    });

                    const valids = props.data.valids;
                    let isValid = false;

                    if (data.images.length > 0) {
                        isValid = true;
                    }

                    valids.images = isValid;

                    const allTrue = Object.keys(props.data.valids).every(function (k) {
                        return props.data.valids[k] === true;
                    });

                    props.setData((prevState) => {
                        return { ...prevState, valids: valids, sectionValid: allTrue };
                    });

                    props.validateMainErrorMessage(null, allTrue, null);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleViewImageControls(uuid) {
        setImageControls((prevState) => {
            return { ...prevState, view: true, uuid: uuid };
        });

        setImagesLoaded(false);

        setTimeout(() => {
            setImagesLoaded(true);
        }, 1);
    }

    function handleMakeMainPicture() {
        const tempImages = [...props.data.values.images];

        const newImages = [];
        newImages.push(tempImages[imagesControls.uuid]);

        tempImages.splice(imagesControls.uuid, 1);

        newImages.push(...tempImages);

        setImagesLoaded(false);

        const newValues = { ...props.data.values, images: newImages };
        props.setData((prevState) => {
            return { ...prevState, values: newValues };
        });

        setImageControls((prevState) => {
            return { ...prevState, view: false, uuid: "" };
        });

        setTimeout(() => {
            setImagesLoaded(true);
        }, 1);
    }

    function handleDeletePhoto() {
        const newImages = [...props.data.values.images];
        newImages[imagesControls.uuid].status = "delete";

        setImagesLoaded(false);

        const newValues = { ...props.data.values, images: newImages };

        props.setData((prevState) => {
            return { ...prevState, values: newValues };
        });

        setTimeout(() => {
            setImagesLoaded(true);
        }, 1);
    }

    //MODEL INPUT

    function handleOpenModelInput() {
        setModelInput((prevState) => {
            return { ...prevState, selectedID: -1, open: true };
        });

        setTableLoaded(false);

        setTimeout(() => {
            setTableLoaded(true);
        }, 1);
    }

    function modelInput_handleChange(event) {
        const { name, value } = event.target;

        const values = modelInput.values;
        const valids = modelInput.valids;

        values[name] = value;

        if (name != "timeH" && name != "timeM") {
            let isValid = false;

            if (value.length > 0) {
                isValid = true;
            }
            valids[name] = isValid;
        }

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setModelInput((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });

        props.validateMainErrorMessage(null, allTrue, null);
    }

    function modelInput_handleTouched(event) {
        const { name } = event.target;

        const touched = modelInput.touched;

        touched[name] = true;

        setModelInput((prevState) => {
            return { ...prevState, touched };
        });
    }

    function numbersKeyPress(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function modelInput_handleSubmit() {
        if (!modelInput.wholeFormValid) {
            modelInput_switchAllTouches();
            setModelInput((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            const model = {
                name: modelInput.values.name,
                time: `${modelInput.values.timeH}:${modelInput.values.timeM}`,
                weight: modelInput.values.weight,
                status: "new"
            };

            const newModels = [...props.data.values.models, model];

            const newValues = {
                images: props.data.values.images,
                models: newModels
            };

            const newValids = props.data.valids;
            newValids.models = true;

            const allTrue = Object.keys(newValids).every(function (k) {
                return newValids[k] === true;
            });

            props.setData((prevState) => {
                return { ...prevState, values: newValues, valids: newValids, sectionValid: allTrue };
            });

            props.validateMainErrorMessage(null, allTrue, null);

            setModelInput(modelInputDefaultValues);
        }
    }

    function modelInput_handleSelectedClick(id) {
        setModelInput((prevState) => {
            return { ...prevState, selectedID: id };
        });
        setTableLoaded(false);

        setTimeout(() => {
            setTableLoaded(true);
        }, 1);
    }

    function modelInput_handleEditModel() {
        const timeSplit = props.data.values.models[modelInput.selectedID].time.split(":");

        const values = {
            name: props.data.values.models[modelInput.selectedID].name,
            timeH: timeSplit[0],
            timeM: timeSplit[1],
            weight: props.data.values.models[modelInput.selectedID].weight
        };

        const valids = {
            name: true,
            time: true,
            weight: true
        };

        setModelInput((prevState) => {
            return { ...prevState, edit: true, open: true, values, valids, wholeFormValid: true };
        });
    }

    function modelInput_handleUpdateModel() {
        if (!modelInput.wholeFormValid) {
            modelInput_switchAllTouches();
            setModelInput((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            const newModels = [...props.data.values.models];

            newModels[modelInput.selectedID].name = modelInput.values.name;
            newModels[modelInput.selectedID].time = `${modelInput.values.timeH}:${modelInput.values.timeM}`;
            newModels[modelInput.selectedID].weight = modelInput.values.weight;

            if (newModels[modelInput.selectedID].status == "current") {
                newModels[modelInput.selectedID].status = "update";
            }

            const newValues = {
                images: props.data.values.images,
                models: newModels
            };

            props.setData((prevState) => {
                return { ...prevState, values: newValues };
            });

            // addUpTimeTotal([...newModels]);
            // addUpWeightTotal([...newModels]);

            setModelInput(modelInputDefaultValues);

            setTableLoaded(false);

            setTimeout(() => {
                setTableLoaded(true);
            }, 1);
        }
    }

    function modelInput_handleDelete() {
        const tempModels = [...props.data.values.models];

        if (tempModels[modelInput.selectedID].status != "new") {
            tempModels[modelInput.selectedID].status = "delete";
        } else {
            tempModels.splice(modelInput.selectedID, 1);
        }

        const valids = props.data.valids;

        const validModels = [];
        for (const model of tempModels) {
            if (model.status != "delete") {
                validModels.push(model);
            }
        }

        let isValid = false;
        if (validModels.length > 0) {
            isValid = true;
        }
        valids.models = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        const newValues = {
            images: props.data.values.images,
            models: [...tempModels]
        };

        props.setData((prevState) => {
            return { ...prevState, values: newValues, valids: valids, sectionValid: allTrue };
        });

        props.validateMainErrorMessage(null, allTrue, null);

        setModelInput((prevState) => {
            return { ...prevState, selectedID: -1 };
        });

        setTableLoaded(false);

        setTimeout(() => {
            setTableLoaded(true);
        }, 1);
    }

    function addUpTimeTotal(models) {
        let totalTime = moment("00:00", "hh:mm");

        for (const [index, model] of models.entries()) {
            if (model.status != "delete") {
                const timeSplit = model.time.split(":");
                totalTime.add({ hours: timeSplit[0], minutes: timeSplit[1] });
            }
        }
        setTotalTime(totalTime.format("HH:mm"));
    }

    function addUpWeightTotal(models) {
        let weight = 0;

        for (const model of models) {
            if (model.status != "delete") {
                weight = weight + parseFloat(model.weight);
            }
        }

        let totalWeight = weight / 1000;

        setTotalWeight(`${totalWeight.toFixed(2)}kg`);
    }

    function modelInput_handleTimeLostTouched(event) {
        const { name, value } = event.target;

        const values = modelInput.values;
        const valids = modelInput.valids;

        if (value == "") {
            values[name] = "00";
        }
        if (value.length == 1) {
            values[name] = `0${value}`;
        }
        if (name == "timeM") {
            if (parseInt(value) > 59) {
                values[name] = "59";
            }
        }

        let isValid = false;

        if (`${values.timeH}:${values.timeM}` != "00:00") {
            isValid = true;
        }
        valids.time = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        setModelInput((prevState) => {
            return { ...prevState, values, valids, wholeFormValid: allTrue };
        });

        props.validateMainErrorMessage(null, allTrue, null);
    }

    function modelInput_switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(modelInput.touched).map((k) => ({ [k]: true })));
        setModelInput((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function numbersDecKeyPress(event) {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <strong>Images</strong>
                        </Form.Label>
                        <Form.Control style={{ display: "none" }} type="file" ref={hiddenFileInput} onChange={handleFilesSelected} multiple />
                    </Form.Group>
                    <Button onClick={handleOpenFileInput}>Upload</Button>
                    <div style={props.triedToSubmit && !props.data.valids.images ? ImagesAndModelsStyles.errorShow : ImagesAndModelsStyles.errorHide}>
                        Please upload at least one image!
                    </div>
                </Col>
                <Col style={imagesControls.view && imagesControls.uuid != 0 ? ImagesAndModelsStyles.show : ImagesAndModelsStyles.hidden}>
                    <br />
                    <Button onClick={handleMakeMainPicture}>Make Main Photo</Button>
                    <Button onClick={handleDeletePhoto}>Delete</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={ImagesAndModelsStyles.picturesContainerOuter}>
                        {imagesLoaded && (
                            <div style={ImagesAndModelsStyles.picturesContainerInner}>
                                {props.data.values.images.map((image, index) => {
                                    if (image.status != "delete") {
                                        if (index == 0) {
                                            return (
                                                <ImageContainer
                                                    key={index}
                                                    uuid={index}
                                                    imagePath={image.file}
                                                    main={true}
                                                    controlsShow={handleViewImageControls}
                                                />
                                            );
                                        } else {
                                            if (imagesControls.uuid == index) {
                                                console.log("selected");
                                                return (
                                                    <ImageContainer
                                                        key={index}
                                                        uuid={index}
                                                        imagePath={image.file}
                                                        main={false}
                                                        controlsShow={handleViewImageControls}
                                                        selected={true}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <ImageContainer
                                                        key={index}
                                                        uuid={index}
                                                        imagePath={image.file}
                                                        main={false}
                                                        controlsShow={handleViewImageControls}
                                                        selected={false}
                                                    />
                                                );
                                            }
                                        }
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <br />
            <strong>Models:</strong>
            <div style={props.triedToSubmit && !props.data.valids.models ? ImagesAndModelsStyles.errorShow : ImagesAndModelsStyles.errorHide}>
                Insert at least one modal
            </div>
            <Row>
                <Col style={ImagesAndModelsStyles.tableSection}>
                    {tableLoaded && (
                        <div>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>Modal</th>
                                        <th>Time</th>
                                        <th>Weight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.values.models.map((model, index) => {
                                        if (model.status != "delete") {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={ImagesAndModelsStyles.tableRow}
                                                    onClick={modelInput_handleSelectedClick.bind(this, index)}
                                                >
                                                    <td
                                                        style={
                                                            modelInput.selectedID == index
                                                                ? ImagesAndModelsStyles.tableCellSelected
                                                                : ImagesAndModelsStyles.tableCell
                                                        }
                                                    >
                                                        {model.name}
                                                    </td>
                                                    <td
                                                        style={
                                                            modelInput.selectedID == index
                                                                ? ImagesAndModelsStyles.tableCellSelected
                                                                : ImagesAndModelsStyles.tableCell
                                                        }
                                                    >
                                                        {model.time}
                                                    </td>
                                                    <td
                                                        style={
                                                            modelInput.selectedID == index
                                                                ? ImagesAndModelsStyles.tableCellSelected
                                                                : ImagesAndModelsStyles.tableCell
                                                        }
                                                    >
                                                        {model.weight}g
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Col>
                <Col>
                    <Button onClick={handleOpenModelInput}>New Model</Button>
                    {modelInput.selectedID != -1 && (
                        <>
                            <Button onClick={modelInput_handleEditModel}>Edit</Button>
                            <Button onClick={modelInput_handleDelete}>Delete</Button>{" "}
                        </>
                    )}
                    <br /> <br />
                    Total Time: {totalTime} (hours, minutes)
                    <br />
                    Total Weight: {totalWeight}
                </Col>
            </Row>

            <Modal show={modelInput.open} onHide={handleCloseModelInput}>
                <Modal.Header closeButton>
                    <Modal.Title>{modelInput.edit ? "Edit Model" : "Add Model"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    isInvalid={modelInput.touched.name && !modelInput.valids.name}
                                    type="text"
                                    name="name"
                                    value={modelInput.values.name}
                                    onChange={modelInput_handleChange}
                                    onBlur={modelInput_handleTouched}
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Time:</Form.Label>
                                <div style={ImagesAndModelsStyles.bottomSections}>
                                    <Form.Control
                                        style={ImagesAndModelsStyles.modelInput_textBoxWidth}
                                        type="text"
                                        maxLength={2}
                                        name="timeH"
                                        value={modelInput.values.timeH}
                                        onChange={modelInput_handleChange}
                                        onBlur={modelInput_handleTimeLostTouched}
                                    />
                                    <div style={ImagesAndModelsStyles.timeCenter}> : </div>
                                    <Form.Control
                                        style={ImagesAndModelsStyles.modelInput_textBoxWidth}
                                        onKeyPress={numbersKeyPress}
                                        type="text"
                                        maxLength={2}
                                        name="timeM"
                                        value={modelInput.values.timeM}
                                        onChange={modelInput_handleChange}
                                        onBlur={modelInput_handleTimeLostTouched}
                                    />
                                </div>
                                <div
                                    style={
                                        modelInput.errorOnForm && !modelInput.valids.time
                                            ? ImagesAndModelsStyles.errorShow
                                            : ImagesAndModelsStyles.errorHide
                                    }
                                >
                                    Please put a time!
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Weight (g):</Form.Label>
                                <Form.Control
                                    style={ImagesAndModelsStyles.modelInput_weightTextBoxWidth}
                                    isInvalid={modelInput.touched.weight && !modelInput.valids.weight}
                                    onKeyPress={numbersDecKeyPress}
                                    type="text"
                                    name="weight"
                                    value={modelInput.values.weight}
                                    onChange={modelInput_handleChange}
                                    onBlur={modelInput_handleTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a weight!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {modelInput.edit ? (
                        <Button onClick={modelInput_handleUpdateModel}>Update</Button>
                    ) : (
                        <Button onClick={modelInput_handleSubmit}>Submit</Button>
                    )}
                    <Button onClick={handleCloseModelInput}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewProduct_ImagesAndModels;
