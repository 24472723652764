export const counter = {
    color: "red"
};

export const sideLine = {
    borderRight: "solid 1px grey"
};

export const addCommentButton = {
    textAlign: "center"
};

export const commentCounter = {
    textAlign: "right",
    color: "red"
};

export const headerButton = {
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "10px",
    marginRight: "10px"
};
