import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Axios from "axios";

import * as MediaActions from "../../../store/actions/media";

import Media_Directory from "./directory";

//STYLES
import * as MoveModalStyles from "./styles/moveModal";
import * as GalleryStyles from "./styles/gallery";

function Media_MoveModal() {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const media = useSelector((state) => state.media);
    let file = 0;

    useEffect(() => {
        dispatch(MediaActions.Update_Move_Width(ref.current.offsetWidth));
        getDirectories();

        const handleResize = () => {
            dispatch(MediaActions.Update_Move_WidthRowCardTotals(ref.current.offsetWidth));
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function getDirectories() {
        const data = { directory: media.move_filePath };

        Axios.post("/adminPods/fileSystem/getDirectories", data)
            .then((res) => {
                const data = res.data;
                dispatch(MediaActions.Update_Move_Directories(data.directories));
                dispatch(MediaActions.Update_Move_CardAndRowTotals(data.directories.length));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div ref={ref}>
            File name: {media.move_file} <br />
            New File Location: {media.move_filePath.replace("media/", "")}{" "}
            {media.move_filePath === media.currentURI ? <strong>(Current Directory)</strong> : null}
            <br />
            {media.move_numberOfRows.map((row, index) => {
                return (
                    <Row key={index}>
                        {media.move_cardsPerRow.map((card, index) => {
                            const id = file;
                            file++;

                            let show = false;
                            if (id < media.move_directories.length) {
                                return (
                                    <Col key={card} style={GalleryStyles.borders}>
                                        <Media_Directory
                                            show={true}
                                            up={media.move_directories[id].type == "GoUp"}
                                            directory={true}
                                            text={media.move_directories[id].name}
                                        />
                                    </Col>
                                );
                            } else {
                                return (
                                    <Col key={card} style={GalleryStyles.borders}>
                                        <Media_Directory show={false} />
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                );
            })}
        </div>
    );
}

export default Media_MoveModal;
