import * as Colors from "../../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "30px"
};

export const topButtons = {
    textAlign: "right",
    paddingBottom: "20px"
};

export const tabText = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    cursor: "pointer"
};

export const tabSelectedText = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    borderBottom: "4px solid"
};

export const cardHeadingsStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const normalCardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const noMethodCardStyle = {
    backgroundColor: "red",
    color: "white",
    cursor: "pointer"
};

export const tobeBankedMethodCardStyle = {
    backgroundColor: "green",
    color: "white",
    cursor: "pointer"
};

export const statementBody = {
    paddingTop: "30px",
    height: "550px",
    overlow: "auto",
    overflowX: "hidden"
};

export const headings = {
    paddingTop: "30px"
};

export const previewTopDetails = {
    textAlign: "right"
};

export const topEmailMessage = {
    textAlign: "center"
};

export const previewItemHeaderBordersStart = {
    border: "1px solid black"
};

export const previewItemHeaderBorders = {
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    textAlign: "center"
};

export const previewItemBordersStart = {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black"
};

export const previewItemBorders = {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    textAlign: "center"
};

export const previewTotal = {
    textAlign: "right"
};

export const paymentMethod = {
    textAlign: "right",
    cursor: "pointer"
};

export const pdfLodingSign = {
    fontWeight: "bold",
    fontSize: "1.5rem"
};

export const rightBtns = {
    textAlign: "right"
};

export const paymentMethodsTitle = {
    margin: "auto"
};

export const paymentMethodsError = {
    color: "red"
};

export const redText = {
    backgroundColor: "red",
    color: "white"
};

export const greenText = {
    backgroundColor: "green",
    color: "white"
};

export const blueText = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const yellowText = {
    backgroundColor: "yellow"
};

export const receiptKey = {
    backgroundColor: Colors.background,
    textAlign: "center",
    padding: "30px"
};

export const hidden = {
    visibility: "hidden"
};

export const visible = {
    visibility: "visible"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};
