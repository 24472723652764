import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//STYLES
import * as CheckoutStyles from "../../styles/basket/checkout";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

function PlaceOrder() {
    const user = useSelector((state) => state.user);
    const printingShop = useSelector((state) => state.printingShop);

    const dispatch = useDispatch();

    function switchAllCheckoutTouches() {
        const newBDTouches = Object.assign(...Object.keys(printingShop.checkoutFormData.touched).map((k) => ({ [k]: true })));

        dispatch(PrintingShopActions.UpdateCheckoutFormData({ ...printingShop.checkoutFormData, touched: newBDTouches }));
    }

    function handleCheckoutClick() {
        if (!printingShop.checkoutFormData.wholeForm) {
            switchAllCheckoutTouches();
        } else {
            const items = [];
            for (const item of printingShop.items) {
                items.push({ basketUUID: item.uuid, itemUUID: item.productDetails.uuid, quantity: item.quantity });
            }

            console.log(printingShop.items);

            const data = {
                userID: user.id,
                userDetails: printingShop.checkoutFormData.values,
                postageUUID: printingShop.selectedPostage,
                items: items
            };

            Axios.post("/pods/3dprintingShop/checkout/processCheckout", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        window.location = data.url;
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleTestCheckout() {
        // const items = [];
        // for (const item of printingShop.items) {
        //     items.push({ uuid: item.productDetails.uuid, quantity: item.quantity });
        // }
        // const data = { items: items };
        // Axios.post("/pods/3dPrintingShop/frontEnd/processCheckout", data)
        //     .then((res) => {
        //         const data = res.data;
        //         if (data.error == "null") {
        //             window.location = data.url;
        //         }
        //     })
        //     .catch((err) => console.log(err));
    }

    return (
        <div style={CheckoutStyles.body}>
            {printingShop.items.length > 0 && (
                <div>
                    <Row>
                        <Col>
                            <strong>Sub Total: </strong>
                        </Col>
                        <Col>£{(parseInt(printingShop.basketTotal) / 100).toFixed(2)}</Col>
                    </Row>
                    {printingShop.postageOptions.length > 0 && (
                        <div>
                            <Row>
                                <Col>
                                    <strong>Postage</strong>
                                </Col>
                                <Col>
                                    £{(printingShop.postageOptions.find((post) => post.uuid == printingShop.selectedPostage).price / 100).toFixed(2)}
                                </Col>
                            </Row>
                        </div>
                    )}
                    <br />
                    <Row>
                        <Col>
                            <strong>Total:</strong>
                        </Col>
                        <Col>£{(printingShop.checkoutTotal / 100).toFixed(2)}</Col>
                    </Row>

                    <br />
                    <Button variant="success" style={CheckoutStyles.checkoutButton} onClick={handleCheckoutClick}>
                        Buy now
                    </Button>
                    <Button onClick={handleTestCheckout}>Test</Button>
                </div>
            )}
        </div>
    );
}

export default PlaceOrder;
