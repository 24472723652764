import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Row, Col, Form } from "react-bootstrap";
import Axios from "axios";
import Switch from "react-switch";

//STYLES
import * as StatementStyles from "../styles/produceAStatement";

function ItemsViewer(props) {
    const [allItems, setAllItems] = useState(null);
    const [currentMenu, setCurrentMenu] = useState({
        name: "",
        menus: [],
        items: []
    });
    const [menuHistory, setMenuHistory] = useState([]);

    const addNewItemDefaults = {
        open: false,
        menu: false,
        colors: false,
        inputs: {
            name: "",
            unitPrice: ""
        },
        valids: {
            name: false,
            unitPrice: false
        },
        wholeForm: false,
        errorOnForm: false
    };

    const [addNewItem, setAddNewItem] = useState(addNewItemDefaults);

    const coloredItemDefaults = {
        name: "",
        unitPrice: ""
    };

    const [coloredItem, setColoredItem] = useState(coloredItemDefaults);

    useEffect(() => {
        if (props.open) {
            Axios.post("/pods/onlinePayments/getSystemItems")
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setAllItems(data.items);

                        setCurrentMenu({
                            name: "main",
                            menus: data.items.filter((item) => item.menu == "main" && item.type == "menu"),
                            items: data.items.filter((item) => item.menu == "main" && item.type == "item")
                        });
                        setMenuHistory(["main"]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [props.open]);

    function changeMenu(name, setHistory) {
        setCurrentMenu({
            name: name,
            menus: allItems.filter((item) => item.menu == name && item.type == "menu"),
            items: allItems.filter((item) => item.menu == name && item.type == "item")
        });
        if (setHistory) {
            setMenuHistory((prevState) => {
                return [...prevState, name];
            });
        }
    }

    function menuBack() {
        changeMenu(menuHistory[menuHistory.length - 2], false);
        menuHistory.pop();
    }

    function handleAddNewItemClose() {
        setAddNewItem(addNewItemDefaults);
    }

    function handle_OpenAddNewItem() {
        if (currentMenu.name == "Colors") {
            const inputs = addNewItem.inputs;
            const valids = addNewItem.valids;

            inputs["unitPrice"] = "null";

            let isValid = false;
            if (inputs["unitPrice"] != "") {
                isValid = true;
            }
            valids["unitPrice"] = isValid;

            let isFormValid = false;
            if (valids.name && valids.unitPrice) {
                isFormValid = true;
            }
            setAddNewItem((prevState) => {
                return { ...prevState, inputs: inputs, valids: valids, wholeForm: isFormValid, open: true };
            });
        } else {
            setAddNewItem((prevState) => {
                return { ...prevState, open: true };
            });
        }
    }

    function handleColorSwitchChange(checked) {
        setAddNewItem((prevState) => {
            return { ...prevState, colors: checked };
        });
    }

    function handleMenuSwitchChange(checked) {
        const inputs = addNewItem.inputs;
        const valids = addNewItem.valids;

        if (checked) {
            inputs["unitPrice"] = "null";
        } else {
            inputs["unitPrice"] = "";
        }

        let isValid = false;
        if (inputs["unitPrice"] != "") {
            isValid = true;
        }
        valids["unitPrice"] = isValid;

        let isFormValid = false;
        if (valids.name && valids.unitPrice) {
            isFormValid = true;
        }

        setAddNewItem((prevState) => {
            return { ...prevState, menu: checked, inputs: inputs, valids: valids, wholeForm: isFormValid };
        });
    }

    function unitPriceKeyPress(event) {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function handleNewItemAdd() {
        if (!addNewItem.wholeForm) {
            setAddNewItem((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            const data = {
                name: addNewItem.inputs.name,
                currentMenu: currentMenu.name,
                itemMenu: addNewItem.menu,
                unitPrice: addNewItem.inputs.unitPrice,
                colors: addNewItem.colors
            };
            Axios.post("/pods/onlinePayments/addNewSystemItem", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setAllItems(data.items);

                        setCurrentMenu((prevState) => {
                            return {
                                ...prevState,
                                menus: data.items.filter((item) => item.menu == prevState.name && item.type == "menu"),
                                items: data.items.filter((item) => item.menu == prevState.name && item.type == "item")
                            };
                        });

                        setAddNewItem(addNewItemDefaults);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleNewItemTextChange(event) {
        const { name, value } = event.target;

        const inputs = addNewItem.inputs;
        const valids = addNewItem.valids;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;
        inputs[name] = value;

        let isFormValid = false;
        if (valids.name && valids.unitPrice) {
            isFormValid = true;
        }

        setAddNewItem((prevState) => {
            return { ...prevState, inputs: inputs, valids: valids, wholeForm: isFormValid };
        });
    }

    function handleItemClicked(item) {
        if (item.colors === "true") {
            setColoredItem({ name: item.name, unitPrice: item.unitPrice });
            changeMenu("Colors", true);
        } else {
            props.addItem(item.name, item.unitPrice);
        }
    }

    function handleColoredItemClicked(item) {
        const name = `${coloredItem.name} - ${item.name}`;

        props.addItem(name, coloredItem.unitPrice);
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Items: {currentMenu.name == "main" ? "Main Menu" : currentMenu.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {currentMenu.name == "main" ? (
                            <div>
                                <Card style={StatementStyles.itemBtnCard} onClick={handle_OpenAddNewItem}>
                                    <Card.Body>
                                        <strong>New Item</strong>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        ) : (
                            <div>
                                <Row>
                                    <Col>
                                        <Card style={StatementStyles.itemBtnCard}>
                                            <Card.Body onClick={menuBack}>
                                                <strong>Back</strong>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card style={StatementStyles.itemBtnCard} onClick={handle_OpenAddNewItem}>
                                            <Card.Body>
                                                <strong>New Item</strong>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        )}
                        <div style={StatementStyles.itemsBody}>
                            {currentMenu.menus.map((menu, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={StatementStyles.itemCard} onClick={changeMenu.bind(this, menu.name, true)}>
                                            <Card.Body>
                                                <Row>
                                                    <Col sm={11}>
                                                        <strong>{menu.name}</strong>
                                                    </Col>
                                                    <Col sm={1}>
                                                        <i className="fa-solid fa-chevron-right"></i>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                            {currentMenu.name == "Colors" ? (
                                <div>
                                    {currentMenu.items.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Card style={StatementStyles.itemCard} onClick={handleColoredItemClicked.bind(this, item)}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>{item.name}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>
                                    {currentMenu.items.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Card style={StatementStyles.itemCard} onClick={handleItemClicked.bind(this, item)}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col sm={10}>{item.name}</Col>
                                                            <Col sm={2}>£{item.unitPrice}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={addNewItem.open} onHide={handleAddNewItemClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Item to {currentMenu.name == "main" ? "Main Menu" : currentMenu.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={addNewItem.errorOnForm && !addNewItem.valids.name ? true : false}
                            type="text"
                            value={addNewItem.inputs.name}
                            name="name"
                            onChange={handleNewItemTextChange}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    {currentMenu.name != "Colors" ? (
                        <div>
                            <div style={StatementStyles.switchBtns}>
                                Menu: &emsp;
                                <Switch
                                    className="customCSS-Content-New-Item-Switch"
                                    onChange={handleMenuSwitchChange}
                                    checked={addNewItem.menu}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                            </div>
                            {!addNewItem.menu ? (
                                <div>
                                    <br />
                                    <Form.Group>
                                        <Form.Label>Unit Price:</Form.Label>
                                        <Form.Control
                                            isInvalid={addNewItem.errorOnForm && !addNewItem.valids.unitPrice ? true : false}
                                            type="text"
                                            onKeyPress={unitPriceKeyPress}
                                            value={addNewItem.inputs.unitPrice}
                                            name="unitPrice"
                                            onChange={handleNewItemTextChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter an unit price</Form.Control.Feedback>
                                    </Form.Group>
                                    <br />
                                    <div style={StatementStyles.switchBtns}>
                                        This item comes in different colours: &emsp;
                                        <Switch
                                            className="customCSS-Content-New-Item-Switch"
                                            onChange={handleColorSwitchChange}
                                            checked={addNewItem.colors}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleAddNewItemClose}>Close</Button>
                    <Button onClick={handleNewItemAdd}>Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ItemsViewer;
