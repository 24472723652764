import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Axios from "axios";
import DatePicker from "react-datepicker";
import Moment from "moment";
import Switch from "react-switch";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as StatementsViewerStyles from "../styles/statementsViewer";

function StatementsViewer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [type, setType] = useState("invoice");
    const [statements, setStatments] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Online Payments"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));

        getStatements(type);
    }, []);

    const [detailsModal, setDetailsModal] = useState({
        open: false,
        statement: {},
        items: [],
        view: "text",
        fileName: "",
        loading: false,
        producing: false,
        showBtns: true
    });

    function handleCloseDetailsModal() {
        setDetailsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [dateModal, setDateModal] = useState({
        open: false,
        date: new Date(),
        selectedtMethod: { uuid: 0, method: "Select", bankedOp: "false" },
        methodIsValid: false,
        confirmClicked: false
    });

    const [paymentMethods, setPaymentMethods] = useState([]);

    function handleDateModalClose() {
        setDateModal((prevState) => {
            return { ...prevState, open: false };
        });

        setDetailsModal((prevState) => {
            return { ...prevState, producing: false, showBtns: true };
        });
    }

    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleCloseModal() {
        setModal({ open: false, header: "", message: "" });
    }

    const [keyModal, setKeyModal] = useState({
        open: false
    });

    function handleCloseKeyModal() {
        setKeyModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const defaultPaymentMethodModalDetails = {
        open: false,
        selectedMethod: { uuid: 0, method: "Select", bankedOp: "false" },
        bankedDate: new Date(),
        isValid: false,
        banked: false,
        confirmClicked: false
    };

    const [paymentMethodModal, setPaymentMethodModal] = useState(defaultPaymentMethodModalDetails);

    function handlePaymentMethodClose() {
        setPaymentMethodModal(defaultPaymentMethodModalDetails);
    }

    function changeTab(tab) {
        setType(tab);
        getStatements(tab);
    }

    function getStatements(type) {
        const data = { type: type };
        Axios.post("/pods/onlinePayments/getStatements", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setStatments(data.statements);
                    setPaymentMethods(data.paymentMethods);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleProduceAnInvoice() {
        navigate("./produceInvoice");
    }

    function handleProduceAReceipt() {
        if (paymentMethods.length == 0) {
            setModal({
                header: "Receipts - No Payments Methods",
                message: "You cannot produce a receipt without no payment method options in the system",
                open: true
            });
        } else {
            navigate("./produceReceipt");
        }
    }

    function handleMore() {
        navigate("./moreSettings");
    }

    function handleOpenDetailsModal(statement) {
        const data = { ref: statement.ref };
        Axios.post("/pods/onlinePayments/getStatementItems", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    for (const item of data.items) {
                        item.total = item.unitPrice * item.quantity;
                    }

                    setDetailsModal((prevState) => {
                        return {
                            ...prevState,
                            view: "text",
                            open: true,
                            statement: statement,
                            items: data.items
                        };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleViewAsPDF(ref) {
        setDetailsModal((prevState) => {
            return { ...prevState, loading: true, showBtns: false };
        });

        const data = { ref: ref, type: type };
        Axios.post("/pods/onlinepayments/makeStatmentPDF", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setDetailsModal((prevState) => {
                        return {
                            ...prevState,
                            fileName: data.filename,
                            view: "pdf",
                            loading: false,
                            showBtns: true
                        };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleViewAsText() {
        setDetailsModal((prevState) => {
            return { ...prevState, view: "text" };
        });
    }

    function pdfLoaded(filename) {
        const data = { filename: filename };

        Axios.post("/pods/onlinePayments/deleteTempPDF", data)
            .then((res) => {
                const data = res.data;
            })
            .catch((err) => console.log(err));
    }

    function handleOpenDateModal() {
        if (paymentMethods.length == 0) {
            setModal({
                header: "Receipts - No Payments Methods",
                message: "You cannot produce a receipt without no payment method options in the system",
                open: true
            });
        } else {
            setDetailsModal((prevState) => {
                return { ...prevState, producing: true, showBtns: false };
            });
            setDateModal((prevState) => {
                return { ...prevState, date: new Date(), open: true };
            });
        }
    }

    function handleProduceAReceiptFromInvoice() {
        if (!dateModal.methodIsValid) {
            setDateModal((prevState) => {
                return { ...prevState, confirmClicked: true };
            });
        } else {
            setDateModal((prevState) => {
                return { ...prevState, open: false };
            });

            const data = {
                invRef: detailsModal.statement.ref,
                date: Moment(dateModal.date).format("DD/MM/YYYY"),
                paymentMethod: dateModal.selectedtMethod
            };

            Axios.post("/pods/onlinePayments/produceReceiptFromInvoice", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null") {
                        const timer = setTimeout(() => {
                            const secondData = { filename: data.filename };

                            Axios.post("/pods/onlinePayments/deleteTempPDF", secondData)
                                .then((res) => {
                                    const secondData = res.data;
                                    const statement = detailsModal.statement;
                                    statement.receiptRef = data.statement.receiptRef;

                                    setDetailsModal((prevState) => {
                                        return {
                                            ...prevState,
                                            producing: false,
                                            showBtns: true,
                                            statement: statement
                                        };
                                    });
                                    setModal({
                                        header: "Receipt Created!",
                                        message: data.message,
                                        open: true
                                    });
                                })
                                .catch((err) => console.log(err));
                        }, 2000);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleDateChange(date) {
        setDateModal((prevState) => {
            return { ...prevState, date: date };
        });
    }

    function handleDMPaymentMethodChange(item) {
        let isValid = false;
        if (item.method != "Select") {
            isValid = true;
        }
        setDateModal((prevState) => {
            return { ...prevState, selectedtMethod: item, methodIsValid: isValid };
        });
    }

    function receiptPaymentMethodStyle(statement) {
        if (statement.paymentMethod == "") {
            return StatementsViewerStyles.noMethodCardStyle;
        } else if (statement.banked == "no") {
            return StatementsViewerStyles.tobeBankedMethodCardStyle;
        } else {
            return StatementsViewerStyles.normalCardStyle;
        }
    }

    function handleOpenKey() {
        setKeyModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handlePaymentMethodModalOpen() {
        if (paymentMethods.length == 0) {
            setModal({
                header: "Receipts - No Payments Methods",
                message: "You cannot change a receipt's payment method without no payment method options in the system",
                open: true
            });
        } else {
            if (detailsModal.statement.paymentMethod == "") {
                const selectedMethod = { uuid: 0, method: "Select", bankedOp: "false" };

                setPaymentMethodModal((prevState) => {
                    return { ...prevState, selectedMethod: selectedMethod, open: true };
                });
            } else {
                const method = paymentMethods.find((meth) => meth.method == detailsModal.statement.paymentMethod);

                let onOpenBanked;
                let onOpenBankedDate;

                if (method.bankedOp == "true") {
                    onOpenBanked = detailsModal.statement.banked == "yes";
                    if (detailsModal.statement.banked == "yes") {
                        const segDate = detailsModal.statement.bankedDate.split("/");
                        onOpenBankedDate = new Date(`${segDate[1]}/${segDate[0]}/${segDate[2]}`);
                    } else {
                        onOpenBankedDate = new Date();
                    }
                } else {
                    onOpenBanked = false;
                    onOpenBankedDate = new Date();
                }

                setPaymentMethodModal((prevState) => {
                    return {
                        ...prevState,
                        selectedMethod: method,
                        banked: onOpenBanked,
                        bankedDate: onOpenBankedDate,
                        open: true
                    };
                });
            }
        }
    }

    function handlePMModalChangeMethod(item) {
        let isValid = false;

        if (item != "Select") {
            isValid = true;
        }

        let banked = false;
        let date = new Date();

        if (item.bankedOp == "true") {
            date = paymentMethodModal.bankedDate;
            banked = paymentMethodModal.banked;
        } else {
            date = new Date();
        }

        setPaymentMethodModal((prevState) => {
            return { ...prevState, selectedMethod: item, isValid: isValid, banked: banked, bankedDate: date };
        });
    }

    function handlePMModalChangeBankSwitch(checked) {
        setPaymentMethodModal((prevState) => {
            return { ...prevState, banked: checked };
        });
    }

    function handlePMModalChangeBankedDate(date) {
        setPaymentMethodModal((prevState) => {
            return { ...prevState, bankedDate: date };
        });
    }

    function handlePaymentMethodSubmit() {
        if (!paymentMethodModal.isValid) {
            setPaymentMethodModal((prevState) => {
                return { ...prevState, confirmClicked: true };
            });
        } else {
            const data = {
                id: detailsModal.statement.id,
                paymentMethod: paymentMethodModal.selectedMethod,
                banked: paymentMethodModal.banked,
                bankedDate: Moment(paymentMethodModal.bankedDate).format("DD/MM/YYYY")
            };
            Axios.post("/pods/onlinePayments/updatePaymentMethod", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        const statement = detailsModal.statement;
                        statement.paymentMethod = data.statement.paymentMethod;
                        statement.banked = data.statement.banked;
                        statement.bankedDate = data.statement.bankedDate;

                        setDetailsModal((prevState) => {
                            return {
                                ...prevState,
                                statement: statement
                            };
                        });

                        setPaymentMethodModal(defaultPaymentMethodModalDetails);
                        getStatements(type);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div style={StatementsViewerStyles.body}>
            <Row>
                <Col>
                    <Button onClick={handleProduceAnInvoice}>Produce an Invoice</Button>
                    <Button onClick={handleProduceAReceipt}>Produce a Receipt</Button>
                </Col>
                <Col style={StatementsViewerStyles.topButtons}>
                    {type == "receipt" && <Button onClick={handleOpenKey}>Key</Button>}
                    <Button onClick={handleMore}>More...</Button>
                </Col>
            </Row>
            <Row>
                <Col
                    style={type == "invoice" ? StatementsViewerStyles.tabSelectedText : StatementsViewerStyles.tabText}
                    onClick={changeTab.bind(this, "invoice")}
                >
                    Invoices
                </Col>
                <Col
                    style={type == "receipt" ? StatementsViewerStyles.tabSelectedText : StatementsViewerStyles.tabText}
                    onClick={changeTab.bind(this, "receipt")}
                >
                    Receipts
                </Col>
            </Row>
            <Row>
                <Col style={StatementsViewerStyles.headings}>
                    <Card style={StatementsViewerStyles.cardHeadingsStyle}>
                        <Card.Body>
                            <Row>
                                <Col>Ref.</Col>
                                <Col>Name</Col>
                                <Col>Email</Col>
                                <Col>Total</Col>
                                <Col>Date</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col style={StatementsViewerStyles.statementBody}>
                    {statements.length > 0 ? (
                        <div>
                            {type == "invoice" ? (
                                <div>
                                    {statements.map((statement, index) => {
                                        return (
                                            <div key={index}>
                                                <Card
                                                    style={StatementsViewerStyles.normalCardStyle}
                                                    onClick={handleOpenDetailsModal.bind(this, statement)}
                                                >
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>{statement.ref}</Col>
                                                            <Col>{statement.customer}</Col>
                                                            <Col>{statement.email}</Col>
                                                            <Col>£{statement.total}</Col>
                                                            <Col>{statement.date}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                //RECEIPTS
                                <div>
                                    {statements.map((statement, index) => {
                                        const receiptStyle = receiptPaymentMethodStyle(statement);
                                        return (
                                            <div key={index}>
                                                <Card style={receiptStyle} onClick={handleOpenDetailsModal.bind(this, statement)}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>{statement.ref}</Col>
                                                            <Col>{statement.customer}</Col>
                                                            <Col>{statement.email}</Col>
                                                            <Col>£{statement.total}</Col>
                                                            <Col>{statement.date}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>You have no statements</div>
                    )}
                </Col>
            </Row>
            <Modal size={"lg"} show={detailsModal.open} onHide={handleCloseDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{type.charAt(0).toUpperCase() + type.slice(1)} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={StatementsViewerStyles.topEmailMessage}>
                        This {type} was sent to {detailsModal.statement.email}
                        {type == "receipt" && detailsModal.view == "pdf" && (
                            <div>
                                <strong>Payment Method:</strong> {detailsModal.statement.paymentMethod} (This will not show on the {type})
                            </div>
                        )}
                        <hr />
                    </div>
                    {detailsModal.view == "text" ? (
                        <div>
                            <div style={StatementsViewerStyles.previewTopDetails}>
                                <strong>Ref:</strong> {detailsModal.statement.ref} <br />
                                <strong>Date:</strong> {detailsModal.statement.date}
                            </div>
                            <div>
                                <strong>Name:</strong> {detailsModal.statement.customer} <br /> <br />
                                <strong>Items:</strong>
                            </div>
                            <div>
                                <Row>
                                    <Col sm={4} style={StatementsViewerStyles.previewItemHeaderBordersStart}>
                                        <strong>Item</strong>
                                    </Col>
                                    <Col style={StatementsViewerStyles.previewItemHeaderBorders}>
                                        <strong>Unit Price</strong>
                                    </Col>
                                    <Col style={StatementsViewerStyles.previewItemHeaderBorders}>
                                        <strong>Quantity</strong>
                                    </Col>
                                    <Col style={StatementsViewerStyles.previewItemHeaderBorders}>
                                        <strong>Total</strong>
                                    </Col>
                                </Row>
                                {detailsModal.items.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Row>
                                                <Col sm={4} style={StatementsViewerStyles.previewItemBordersStart}>
                                                    {item.item}
                                                </Col>
                                                <Col style={StatementsViewerStyles.previewItemBorders}>£{parseFloat(item.unitPrice).toFixed(2)}</Col>
                                                <Col style={StatementsViewerStyles.previewItemBorders}>{item.quantity}</Col>
                                                <Col style={StatementsViewerStyles.previewItemBorders}>£{parseFloat(item.total).toFixed(2)}</Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </div>
                            <br />
                            {type == "receipt" && (
                                <div style={StatementsViewerStyles.paymentMethod} onClick={handlePaymentMethodModalOpen}>
                                    <strong>Payment method:</strong>{" "}
                                    {detailsModal.statement.paymentMethod == "" ? "No Payment Method" : detailsModal.statement.paymentMethod} <br />
                                    {detailsModal.statement.banked != "n/a" && (
                                        <div>
                                            <strong>Banked: </strong>
                                            {detailsModal.statement.bankedDate == "" ? "To be banked" : detailsModal.statement.bankedDate}
                                        </div>
                                    )}
                                </div>
                            )}
                            <br />
                            <div style={StatementsViewerStyles.previewTotal}>
                                <strong>Total: £{detailsModal.statement.total}</strong>
                            </div>
                        </div>
                    ) : null}
                    {detailsModal.view == "pdf" ? (
                        <div>
                            <iframe
                                id="iframepdf"
                                width="100%"
                                height="500"
                                src={`${window.location.origin}/content/temp/${detailsModal.fileName}`}
                                onLoad={pdfLoaded.bind(this, detailsModal.fileName)}
                            ></iframe>
                        </div>
                    ) : null}
                    <hr />
                    <Row>
                        <Col>
                            {type == "invoice" && (
                                <div>
                                    {detailsModal.statement.receiptRef != "" ? (
                                        <div>Receipt Ref: {detailsModal.statement.receiptRef}</div>
                                    ) : (
                                        <div>
                                            {detailsModal.producing ? (
                                                <div style={StatementsViewerStyles.pdfLodingSign}>Producing...</div>
                                            ) : (
                                                <div>
                                                    {detailsModal.showBtns && (
                                                        <div>
                                                            <Button onClick={handleOpenDateModal}>Produce Receipt</Button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                        <Col style={StatementsViewerStyles.rightBtns}>
                            {detailsModal.loading ? (
                                <div style={StatementsViewerStyles.pdfLodingSign}>Loading...</div>
                            ) : (
                                <div>
                                    {detailsModal.view == "text" && detailsModal.showBtns && (
                                        <Button variant="primary" onClick={handleViewAsPDF.bind(this, detailsModal.statement.ref)}>
                                            View As PDF
                                        </Button>
                                    )}
                                    {detailsModal.view == "pdf" && detailsModal.showBtns && (
                                        <Button variant="primary" onClick={handleViewAsText}>
                                            View As Text
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={dateModal.open} onHide={handleDateModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reciept Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>Select a date for the receipt: </Col>
                        <Col>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={dateModal.date} onChange={(date: Date) => handleDateChange(date)} />
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col style={StatementsViewerStyles.paymentMethodsTitle}>
                            <div>
                                Payment Method:
                                {dateModal.confirmClicked && !dateModal.methodIsValid && (
                                    <p style={StatementsViewerStyles.paymentMethodsError}>Select a payment method!</p>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant={dateModal.confirmClicked && !dateModal.methodIsValid ? "danger" : "primary"}>
                                    {dateModal.selectedtMethod.method}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {paymentMethods.map((item, index) => {
                                        return (
                                            <Dropdown.Item key={index} onClick={handleDMPaymentMethodChange.bind(this, item)}>
                                                {item.method}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleProduceAReceiptFromInvoice}>Produce Reciept</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={keyModal.open} onHide={handleCloseKeyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Receipts Key</Modal.Title>
                </Modal.Header>
                <Modal.Body style={StatementsViewerStyles.receiptKey}>
                    <br />
                    <Row>
                        <Col style={StatementsViewerStyles.redText}>Red: No payment method</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={StatementsViewerStyles.greenText}>Green: Needs to be banked</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={StatementsViewerStyles.blueText}>Blue: Completed (in the bank)</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={StatementsViewerStyles.yellowText}>Yellow: Test transaction</Col>
                    </Row>
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseKeyModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={paymentMethodModal.open} onHide={handlePaymentMethodClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <div>
                                Payment Method:
                                {paymentMethodModal.confirmClicked && !paymentMethodModal.isValid && (
                                    <p style={StatementsViewerStyles.paymentMethodsError}>Select a payment method!</p>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant={paymentMethodModal.confirmClicked && !paymentMethodModal.isValid ? "danger" : "primary"}>
                                    {paymentMethodModal.selectedMethod.method}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {paymentMethods.map((item, index) => {
                                        return (
                                            <Dropdown.Item key={index} onClick={handlePMModalChangeMethod.bind(this, item)}>
                                                {item.method}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <br />
                    <Row
                        style={paymentMethodModal.selectedMethod.bankedOp == "true" ? StatementsViewerStyles.visible : StatementsViewerStyles.hidden}
                    >
                        <Col>Banked:</Col>
                        <Col>
                            <Switch
                                className="customCSS-Content-New-Item-Switch"
                                onChange={handlePMModalChangeBankSwitch}
                                checked={paymentMethodModal.banked}
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row style={paymentMethodModal.banked ? StatementsViewerStyles.visible : StatementsViewerStyles.hidden}>
                        <Col>Banked Date:</Col>
                        <Col>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={paymentMethodModal.bankedDate}
                                onChange={(date: Date) => handlePMModalChangeBankedDate(date)}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handlePaymentMethodSubmit}>Update</Button>
                    <Button onClick={handlePaymentMethodClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default StatementsViewer;
