import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as VerisonsStyles from "../styles/versions";

function Verisons() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [versions, setVersions] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("App Service - Versions"));

        Axios.post("/pods/appService/environments/versionsLoad")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setVersions(data.versions);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function handleTextChange(event) {
        const { name, value } = event.target;
        console.log(name);
        const temp = [...versions];

        temp[name].version = value;

        setVersions(temp);
    }

    function handleUpdate() {
        const data = { versions: versions };
        Axios.post("/pods/appService/environments/updateVersions", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    navigate(-1);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={VerisonsStyles.body}>
            {versions.map((version, index) => {
                return (
                    <div key={index}>
                        <Card style={VerisonsStyles.cardStyle}>
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        {version.environment == "Core" ? version.environment : `${version.environment} (${version.adminCode})`}
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name={version.uuid - 1}
                                            style={VerisonsStyles.textBox}
                                            value={version.version}
                                            onChange={handleTextChange}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}
            <Row>
                <Col style={VerisonsStyles.columnRight}>
                    <Button onClick={handleUpdate}>Update</Button>
                </Col>
            </Row>
        </div>
    );
}

export default Verisons;
