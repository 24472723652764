import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

//COMPONENTS
import ViewController from "./files/viewController";
import TicketHolder from "./files/admin_ticketHolder";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<ViewController />} />
                        <Route path="/ticket/:uuid" element={<TicketHolder />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
