export const body = {};

export const counterStyle = {
    textAlign: "right",
    color: "red"
};

export const errorHide = {
    visibility: "hidden",
    color: "red"
};

export const errorShow = {
    visibility: "visible",
    color: "red"
};
