import React, { useState, useEffect } from "react";
import { Row, Col, Button, Dropdown, Table, Modal, Form } from "react-bootstrap";
import Axios from "axios";
import { isBrowser } from "react-device-detect";

//STYLES
import * as QuoteSystemStyles from "../styles/quoteSystem";
import * as UploadStyles from "../../../../../administration/pods/media/styles/upload"; //CROSS-OVER POD (from pod ref. A5)

function QuoteSystem() {
    const [tabMenu, setTabMenu] = useState({
        rows: {
            1: {
                items: ["The Process", "Updating Service"]
            },
            2: {
                items: ["The Cost", "Support"]
            }
        },
        topRow: "2",
        bottomRow: "1",
        selectedTab: "The Process"
    });

    const defaultData = {
        values: {
            name: "",
            email: "",
            company: "",
            breifDes: "",
            features: "",
            platform: "Platform",
            addInfo: ""
        },
        valids: {
            name: false,
            email: false,
            breifDes: false,
            features: false,
            platform: false
        },
        touched: {
            name: false,
            email: false,
            breifDes: false,
            features: false,
            platform: false
        },
        counterMax: {
            name: 50,
            email: 50,
            company: 50,
            breifDes: 200,
            features: 200,
            addInfo: 200
        },
        counter: {
            breifDes: 200,
            features: 200,
            addInfo: 200
        },
        wholeForm: false
    };

    const [formData, setFormData] = useState(defaultData);

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        onStart();
    }, []);

    function onStart() {}

    function handleChangeTab(tab) {
        setTabMenu((prevState) => {
            return { ...prevState, selectedTab: tab };
        });
    }

    function handleChangeTopTab(tab) {
        let topRow = "";
        let bottomRow: "";

        if (tabMenu.topRow == "1") {
            topRow = "2";
            bottomRow = "1";
        } else {
            topRow = "1";
            bottomRow = "2";
        }

        setTabMenu((prevState) => {
            return { ...prevState, selectedTab: tab, topRow: topRow, bottomRow: bottomRow };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));
        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleUpdateTouch(event) {
        const { name } = event.target;

        const updatedTouched = { ...formData.touched, [name]: true };

        setFormData((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function handleInputChange(event) {
        const { name, value } = event.target;

        const values = formData.values;
        const counters = formData.counter;

        if (name in formData.counter) {
            counters[name] = formData.counterMax[name] - value.length;
        }

        values[name] = value;

        setFormData((prevState) => {
            return { ...prevState, values: values, counter: counters };
        });
    }

    function handleInputChangeWithValidation(event) {
        const { name, value } = event.target;

        const values = formData.values;
        const valids = formData.valids;
        const counters = formData.counter;

        let valid = true;

        if (value.length == 0) {
            valid = false;
        }

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            valid = false;
        }

        values[name] = value;
        valids[name] = valid;

        if (name in formData.counter) {
            counters[name] = formData.counterMax[name] - value.length;
        }

        const areTrue = Object.values(valids).every((value) => value == true);

        setFormData((prevState) => {
            return { ...prevState, values: values, valids: valids, wholeForm: areTrue, counter: counters };
        });
    }

    function handleDropDownBoxClick(item) {
        const values = formData.values;
        const valids = formData.valids;

        let valid = true;
        if (item == "Platform") {
            valid = false;
        }

        values["platform"] = item;
        valids["platform"] = valid;

        const areTrue = Object.values(valids).every((value) => value == true);

        setFormData((prevState) => {
            return { ...prevState, values: values, valids: valids, wholeForm: areTrue };
        });
    }

    function handleOnSubmit() {
        if (!formData.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                name: formData.values.name,
                email: formData.values.email,
                company: formData.values.company,
                briefDescription: formData.values.breifDes,
                features: formData.values.features,
                platform: formData.values.platform,
                addInfo: formData.values.addInfo
            };
            Axios.post("/pods/appRequestQuote/quoteSystem/requestQuote", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setFormData(defaultData);
                        setModal({
                            header: "App Request Quote",
                            message: "Quote request successfully sent to High-View Studios, please wait 24 to 48 hours for a response",
                            open: true
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const browserTabSystem = (
        <div>
            <Row style={QuoteSystemStyles.tabRow}>
                <Col>
                    <div>
                        <Row>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.topRow].items[0]
                                        ? QuoteSystemStyles.selectedTab
                                        : QuoteSystemStyles.tab
                                }
                                onClick={handleChangeTopTab.bind(this, tabMenu.rows[tabMenu.topRow].items[0])}
                            >
                                {tabMenu.rows[tabMenu.topRow].items[0]}
                            </Col>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.topRow].items[1]
                                        ? QuoteSystemStyles.selectedTab
                                        : QuoteSystemStyles.tab
                                }
                                onClick={handleChangeTopTab.bind(this, tabMenu.rows[tabMenu.topRow].items[1])}
                            >
                                {tabMenu.rows[tabMenu.topRow].items[1]}
                            </Col>
                        </Row>
                        <Row style={QuoteSystemStyles.rowBackground}>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.bottomRow].items[0]
                                        ? QuoteSystemStyles.selectedTab
                                        : QuoteSystemStyles.tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.bottomRow].items[0])}
                            >
                                {tabMenu.rows[tabMenu.bottomRow].items[0]}
                            </Col>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.bottomRow].items[1]
                                        ? QuoteSystemStyles.selectedTab
                                        : QuoteSystemStyles.tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.bottomRow].items[1])}
                            >
                                {tabMenu.rows[tabMenu.bottomRow].items[1]}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={QuoteSystemStyles.tabContent}>
                                {tabMenu.selectedTab == "The Process" && (
                                    <div>
                                        <p>
                                            The development process will be slightly different for every customer, depending on their needs. The
                                            process can last for 6 months to 2 years, this will include an initial meeting to the project and a
                                            meeting at every stage of the development. Sometimes research will need to be carried out before the
                                            development of the project can start, or before a feature of the software can start to be developed. After
                                            every research period there will be a meeting for the customer to see the solution of the feature before
                                            the development starts.
                                            <br /> <br /> There will be a testing period of every project and this will involve the customer having a
                                            prototype of the app for them to start using the software in the company. This will allow any un-seen
                                            development errors to pop up and for the user to have the chance for suggesting new features for the app.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "Updating Service" && (
                                    <div>
                                        <p>
                                            As part of the development process there is a update service with every app to make it much simpler to
                                            update your app for further development. This service is counted into the development cost. <br /> <br />{" "}
                                            If you are buying a product that the development process is still active, you can have the update at extra
                                            cost, £50 for 2 year subscription.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "The Cost" && (
                                    <div>
                                        <p>
                                            All app development will be priced on a individually basis. Most of the development will be charged on a
                                            hourly rate but some feature will cost more than the hourly rate. If you are on a tight budget, you can
                                            perosnalise the project to suit you and you can also pause the development until you have another budget.
                                            High-View Studios will work around you. <br /> <br /> There are products that are already been developed
                                            that are on sale which the public can buy, but these products can only be further developed on by the
                                            original customer.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "Support" && (
                                    <div>
                                        <p>
                                            Support is available on all apps that have been developed by High-View Studios. When the project is in the
                                            development and testing stages, you will have full support for how ever long your app needs it. Some
                                            larger projects will have a support and a error feature built into them for quicker respond time. For any
                                            app problems please use the ViewHelp system.
                                        </p>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );

    const mobileTabSystem = (
        <div>
            <Row style={QuoteSystemStyles.tabRow}>
                <Col>
                    <div>
                        <Row>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.topRow].items[0]
                                        ? QuoteSystemStyles.m_selectedTab
                                        : QuoteSystemStyles.m_tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.topRow].items[0])}
                            >
                                {tabMenu.rows[tabMenu.topRow].items[0]}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.topRow].items[1]
                                        ? QuoteSystemStyles.m_selectedTab
                                        : QuoteSystemStyles.m_tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.topRow].items[1])}
                            >
                                {tabMenu.rows[tabMenu.topRow].items[1]}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.bottomRow].items[0]
                                        ? QuoteSystemStyles.m_selectedTab
                                        : QuoteSystemStyles.m_tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.bottomRow].items[0])}
                            >
                                {tabMenu.rows[tabMenu.bottomRow].items[0]}
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={
                                    tabMenu.selectedTab == tabMenu.rows[tabMenu.bottomRow].items[1]
                                        ? QuoteSystemStyles.m_selectedTab
                                        : QuoteSystemStyles.m_tab
                                }
                                onClick={handleChangeTab.bind(this, tabMenu.rows[tabMenu.bottomRow].items[1])}
                            >
                                {tabMenu.rows[tabMenu.bottomRow].items[1]}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={QuoteSystemStyles.m_tabContent}>
                                {tabMenu.selectedTab == "The Process" && (
                                    <div>
                                        <p>
                                            The development process will be slightly different for every customer, depending on their needs. The
                                            process can last for 6 months to 2 years, this will include an initial meeting to the project and a
                                            meeting at every stage of the development. Sometimes research will need to be carried out before the
                                            development of the project can start, or before a feature of the software can start to be developed. After
                                            every research period there will be a meeting for the customer to see the solution of the feature before
                                            the development starts.
                                            <br /> <br /> There will be a testing period of every project and this will involve the customer having a
                                            prototype of the app for them to start using the software in the company. This will allow any un-seen
                                            development errors to pop up and for the user to have the chance for suggesting new features for the app.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "Updating Service" && (
                                    <div>
                                        <p>
                                            As part of the development process there is a update service with every app to make it much simpler to
                                            update your app for further development. This service is counted into the development cost. <br /> <br />{" "}
                                            If you are buying a product that the development process is still active, you can have the update at extra
                                            cost, £50 for 2 year subscription.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "The Cost" && (
                                    <div>
                                        <p>
                                            All app development will be priced on a individually basis. Most of the development will be charged on a
                                            hourly rate but some feature will cost more than the hourly rate. If you are on a tight budget, you can
                                            perosnalise the project to suit you and you can also pause the development until you have another budget.
                                            High-View Studios will work around you. <br /> <br /> There are products that are already been developed
                                            that are on sale which the public can buy, but these products can only be further developed on by the
                                            original customer.
                                        </p>
                                    </div>
                                )}
                                {tabMenu.selectedTab == "Support" && (
                                    <div>
                                        <p>
                                            Support is available on all apps that have been developed by High-View Studios. When the project is in the
                                            development and testing stages, you will have full support for how ever long your app needs it. Some
                                            larger projects will have a support and a error feature built into them for quicker respond time. For any
                                            app problems please use the ViewHelp system.
                                        </p>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <div style={QuoteSystemStyles.body}>
            <Row>
                <Col>
                    <div style={{ ...QuoteSystemStyles.container, ...QuoteSystemStyles.topContainer }}>
                        <h2>App Development</h2>
                        <p>
                            High-View Studios are branching out into App Development, it can provide you with an easy-to-use solution for you company
                            or organisation. If you have a website with High-View Studios, there will certainly an App solution for mobiles to work
                            with your website, so you don’t have to go on the website every time to enter some data. High-View Studios also provides
                            tailored apps that suits the customers’ needs. From the initial meeting, High-View Studios will be committed to get to
                            know your company or organisation so you can get the best out of your app.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col> {isBrowser ? browserTabSystem : mobileTabSystem}</Col>
                <Col>
                    <Row style={QuoteSystemStyles.tabRow}>
                        <Col>
                            <div style={{ ...QuoteSystemStyles.container, ...QuoteSystemStyles.topContainer }}>
                                <h2>Get A Quote</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row style={QuoteSystemStyles.tabRow_Second}>
                        <Col>
                            <div style={QuoteSystemStyles.container}>
                                <Row>
                                    <Col>Name:</Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                isInvalid={formData.touched.name && !formData.valids.name ? true : false}
                                                name="name"
                                                type="text"
                                                maxLength={formData.counterMax.name}
                                                value={formData.values.name}
                                                onChange={handleInputChangeWithValidation}
                                                onBlur={handleUpdateTouch}
                                            />
                                            <Form.Control.Feedback style={QuoteSystemStyles.feedbackText} type="invalid">
                                                Please enter a name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Email:</Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                isInvalid={formData.touched.email && !formData.valids.email ? true : false}
                                                name="email"
                                                type="text"
                                                maxLength={formData.counterMax.email}
                                                value={formData.values.email}
                                                onChange={handleInputChangeWithValidation}
                                                onBlur={handleUpdateTouch}
                                            />
                                            <Form.Control.Feedback style={QuoteSystemStyles.feedbackText} type="invalid">
                                                Please enter a valid email
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Company (if applicable):</Col>
                                    <Col>
                                        <Form.Control
                                            name="company"
                                            type="text"
                                            maxLength={formData.counterMax.company}
                                            value={formData.values.company}
                                            onChange={handleInputChange}
                                        />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Breif Description of the app idea:</Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                isInvalid={formData.touched.breifDes && !formData.valids.breifDes ? true : false}
                                                name="breifDes"
                                                as="textarea"
                                                maxLength={formData.counterMax.breifDes}
                                                value={formData.values.breifDes}
                                                onChange={handleInputChangeWithValidation}
                                                onBlur={handleUpdateTouch}
                                                rows={2}
                                            />
                                            <Row>
                                                <Col></Col>
                                                <Col style={QuoteSystemStyles.counter}>{formData.counter.breifDes}</Col>
                                            </Row>
                                            <Form.Control.Feedback style={QuoteSystemStyles.feedbackText} type="invalid">
                                                Please enter a breif description
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Features:</Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                isInvalid={formData.touched.features && !formData.valids.features ? true : false}
                                                name="features"
                                                as="textarea"
                                                maxLength={formData.counterMax.features}
                                                value={formData.values.features}
                                                onChange={handleInputChangeWithValidation}
                                                onBlur={handleUpdateTouch}
                                                rows={2}
                                            />
                                            <Row>
                                                <Col>
                                                    <Form.Control.Feedback style={QuoteSystemStyles.feedbackText} type="invalid">
                                                        Please enter some feature the app may have
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col style={QuoteSystemStyles.counter}>{formData.counter.features}</Col>
                                            </Row>
                                        </Form.Group>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Platflorm:</Col>
                                    <Col>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant={formData.touched.platform && !formData.valids.platform ? "danger" : "warning"}
                                                style={
                                                    formData.touched.platform && !formData.valids.platform
                                                        ? QuoteSystemStyles.dropdownTextError
                                                        : QuoteSystemStyles.dropdownText
                                                }
                                                name="platform"
                                            >
                                                {formData.values.platform}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleDropDownBoxClick.bind(this, "Mobile App")}>Mobile App</Dropdown.Item>
                                                <Dropdown.Item onClick={handleDropDownBoxClick.bind(this, "Web App")}>Web App</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col
                                        style={
                                            formData.touched.platform && !formData.valids.platform
                                                ? QuoteSystemStyles.errorText
                                                : QuoteSystemStyles.errorTextHidden
                                        }
                                    >
                                        <p>Please pick a Platform</p>
                                    </Col>
                                    <br />
                                </Row>
                                <Row>
                                    <Col>Additional Information:</Col>
                                    <Col>
                                        <Form.Control
                                            name="addInfo"
                                            as="textarea"
                                            maxLength={formData.counterMax.addInfo}
                                            value={formData.values.addInfo}
                                            onChange={handleInputChange}
                                            rows={2}
                                        />
                                        <Row>
                                            <Col style={QuoteSystemStyles.counter}>{formData.counter.addInfo}</Col>
                                        </Row>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={QuoteSystemStyles.submitBtnCol}>
                                        <Button variant="warning" style={QuoteSystemStyles.dropdownText} onClick={handleOnSubmit}>
                                            Submit to High-View Studios
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default QuoteSystem;
