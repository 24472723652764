import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};
