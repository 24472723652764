import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

//COMPONENTS
import MainMenu from "./files/mainMenu";

import StatementsViewer from "./files/statementsViewer";
import ProduceAStatement from "./files/produceAStatement";
import MorePayments from "./files/moreSettings";
import WebPackages from "./files/packages/webPackages";
import WebsiteCustomers from "./files/packages/websiteCustomers";
import WebPackageViewer from "./files/packages/packageViewer";
import CustomerViewer from "./files/packages/customerViewer";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<MainMenu />} />
                        <Route path="/statements" element={<StatementsViewer />} />
                        <Route path="/statements/produceReceipt" element={<ProduceAStatement type="receipt" />} />
                        <Route path="/statements/produceInvoice" element={<ProduceAStatement type="invoice" />} />
                        <Route path="/statements/moreSettings" element={<MorePayments />} />
                        <Route path="/websitePackages" element={<WebPackages />} />
                        <Route path="/websitePackages/addPackage" element={<WebPackageViewer modify={false} />} />
                        <Route path="/websitePackages/modifyPackage/:id" element={<WebPackageViewer modify={true} />} />
                        <Route path="/websiteCustomers/" element={<WebsiteCustomers />} />
                        <Route path="/websiteCustomers/newCustomer" element={<CustomerViewer modify={false} />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
