import * as Colors from "../../../../../globalStyles/environmentColors";

export const body = {
    paddingRight: "30px",
    paddingLeft: "30px",
    paddingTop: "10px"
};

export const tab = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    cursor: "pointer"
};

export const tabSelected = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    borderBottom: "4px solid"
};

export const mainBody = {
    height: "500px",
    paddingTop: "30px"
};

export const cardHeadingsStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const statementBody = {
    paddingTop: "30px",
    height: "550px",
    overlow: "auto",
    overflowX: "hidden"
};

export const previewTopDetails = {
    textAlign: "right"
};

export const topEmailMessage = {
    textAlign: "center"
};

export const previewItemHeaderBordersStart = {
    border: "1px solid black"
};

export const previewItemHeaderBorders = {
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    textAlign: "center"
};

export const previewItemBordersStart = {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    borderLeft: "1px solid black"
};

export const previewItemBorders = {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    textAlign: "center"
};

export const previewTotal = {
    textAlign: "right"
};

export const pdfLodingSign = {
    fontWeight: "bold",
    fontSize: "1.5rem"
};

export const rightBtns = {
    textAlign: "right"
};
