import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//STYLES
import * as CheckoutStyles from "../../styles/checkout/checkout";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

function Checkout() {
    const user = useSelector((state) => state.user);
    const printingShop = useSelector((state) => state.printingShop);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (printingShop.items.length == 0) {
            navigate("../basket");
        } else {
            loadData();
        }
    }, []);

    function loadData() {
        const data = { userID: user.id };
        Axios.post("/pods/3dPrintingShop/checkout/getUserAddressAndPostageData", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    const values = { ...data.userDetails[0] };
                    const valids = {};
                    for (const [key, value] of Object.entries(values)) {
                        if (value != "") {
                            valids[key] = true;
                        } else {
                            valids[key] = false;
                        }
                    }

                    const allTrue = Object.keys(valids).every(function (k) {
                        return valids[k] === true;
                    });

                    dispatch(PrintingShopActions.UpdateCheckoutFormData({ ...printingShop.checkoutFormData, values, valids, wholeForm: allTrue }));
                    dispatch(PrintingShopActions.UpdatePostageOptions(data.postage, data.postage[0].uuid));
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnTouched(event) {
        const { name } = event.target;

        const touched = { ...printingShop.checkoutFormData.touched };

        touched[name] = true;

        dispatch(PrintingShopActions.UpdateCheckoutFormData({ ...printingShop.checkoutFormData, touched }));
    }

    function handleOnChange(event) {
        const { name, value } = event.target;

        const values = { ...printingShop.checkoutFormData.values };
        const valids = { ...printingShop.checkoutFormData.valids };

        values[name] = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;

        const allTrue = Object.keys(valids).every(function (k) {
            return valids[k] === true;
        });

        dispatch(PrintingShopActions.UpdateCheckoutFormData({ ...printingShop.checkoutFormData, values, valids, wholeForm: allTrue }));
    }

    function numbersDecKeyPress(event) {
        if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function handleCheckOnChange(uuid) {
        dispatch(PrintingShopActions.UpdatePostageSelected(uuid));
    }

    return (
        <div style={CheckoutStyles.body}>
            <h1 style={CheckoutStyles.title}>Checkout</h1>
            <strong>Address:</strong> <br /> <br />
            <Row>
                <Col>
                    Address Line 1:
                    <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={
                                printingShop.checkoutFormData.touched.addressLine1 && !printingShop.checkoutFormData.valids.addressLine1
                                    ? true
                                    : false
                            }
                            type="text"
                            name="addressLine1"
                            value={printingShop.checkoutFormData.values.addressLine1}
                            onChange={handleOnChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please complete Address Line 1</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    Address Line 2:
                    <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={
                                printingShop.checkoutFormData.touched.addressLine2 && !printingShop.checkoutFormData.valids.addressLine2
                                    ? true
                                    : false
                            }
                            type="text"
                            name="addressLine2"
                            value={printingShop.checkoutFormData.values.addressLine2}
                            onChange={handleOnChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please complete Address Line 2</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    Town:
                    <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={printingShop.checkoutFormData.touched.town && !printingShop.checkoutFormData.valids.town ? true : false}
                            type="text"
                            name="town"
                            value={printingShop.checkoutFormData.values.town}
                            onChange={handleOnChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a town</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    Postcode:
                    <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={
                                printingShop.checkoutFormData.touched.postcode && !printingShop.checkoutFormData.valids.postcode ? true : false
                            }
                            type="text"
                            name="postcode"
                            value={printingShop.checkoutFormData.values.postcode}
                            onChange={handleOnChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a post code</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    Telephone Number:
                    <br />
                    <Form.Group>
                        <Form.Control
                            isInvalid={printingShop.checkoutFormData.touched.tel && !printingShop.checkoutFormData.valids.tel ? true : false}
                            type="text"
                            name="tel"
                            value={printingShop.checkoutFormData.values.tel}
                            onKeyPress={numbersDecKeyPress}
                            onChange={handleOnChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a telephone number</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <Row>
                <Col md="auto">
                    <strong>Postage:</strong> <br /> <br />
                </Col>
                <Col>
                    <br />
                    {printingShop.postageOptions.map((postage, index) => {
                        return (
                            <Form.Check
                                key={index}
                                type="radio"
                                checked={postage.uuid == printingShop.selectedPostage && true}
                                label={`${postage.name} (£${(postage.price / 100).toFixed(2)})`}
                                onChange={handleCheckOnChange.bind(this, postage.uuid)}
                            />
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Checkout;
