import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const newItemBtn = {
    textAlign: "right"
};

export const cardHeader = {
    backgroundColor: Colors.primary,
    color: "white",
    fontWeight: "bold"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardStyleNotActive = {
    backgroundColor: "red",
    color: "white",
    cursor: "pointer"
};

export const scrollView = {
    height: "500px",
    overflow: "auto"
    // overflowY: "hidden"
};
