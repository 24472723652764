import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

//STYLES
import * as FrontInterfaceStyles from "../styles/frontInterface";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";
import * as UserActions from "../../../../store/actions/user";

function ChangePassword() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const [data, setData] = useState({
        valids: {
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        },
        touched: {
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        },
        errorOnForm: false
    });

    const [message, setMessage] = useState("text");

    const [completedRequest, setCompletedRequest] = useState(false);

    function handleOnChange(event) {
        const { id, value } = event.target;

        const valids = data.valids;

        let isValid = false;
        if (id == "confirmPassword") {
            const newPassword = document.getElementById("newPassword").value;
            if (newPassword == value) {
                isValid = true;
            }
        } else {
            if (value != "") {
                isValid = true;
            }
        }

        valids[id] = isValid;

        const allTrue = Object.keys(data.valids).every(function (k) {
            return data.valids[k] === true;
        });

        setData((prevState) => {
            return { ...prevState, valids, errorOnForm: allTrue };
        });
    }

    function handleOnTouch(event) {
        const { id } = event.target;

        const touched = data.touched;
        touched[id] = true;

        setData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function switchAllTouches() {
        const touches = Object.assign(...Object.keys(data.touched).map((k) => ({ [k]: true })));

        setData((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function handleUpdatePassword() {
        if (!data.errorOnForm) {
            switchAllTouches();
        } else {
            const currentPassword = document.getElementById("currentPassword").value;
            const newPassword = document.getElementById("newPassword").value;
            const confirmPassword = document.getElementById("confirmPassword").value;

            const data = { userID: user.id, oldPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword };

            Axios.post("/pods/accessModal/changePassword", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "null" && data.userError == "Yes") {
                        setMessage(data.message);
                    } else {
                        dispatch(UserActions.UpdateRequestedPassword(false));
                        setCompletedRequest(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnClose() {
        dispatch(AccessModalActions.UpdateScreen("")); //**
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {completedRequest ? (
                    <div style={FrontInterfaceStyles.passwordRequestedText}>Your password has been changed</div>
                ) : (
                    <div>
                        <Form.Group>
                            <Form.Label>Current Password:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.currentPassword && !data.valids.currentPassword ? true : false}
                                type="password"
                                id="currentPassword"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter youur current password!</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>New Password:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.newPassword && !data.valids.newPassword ? true : false}
                                type="password"
                                id="newPassword"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a new password!</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Confirm Password:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.confirmPassword && !data.valids.confirmPassword ? true : false}
                                type="password"
                                id="confirmPassword"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Passwords do not match!</Form.Control.Feedback>
                        </Form.Group>
                        <div style={message != "text" ? FrontInterfaceStyles.errorTextShow : FrontInterfaceStyles.errorTextHide}>{message}</div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {completedRequest ? <Button onClick={handleOnClose}>Close</Button> : <Button onClick={handleUpdatePassword}>Update</Button>}
            </Modal.Footer>
        </div>
    );
}

export default ChangePassword;
