import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";

//STYLES
import * as FrontInterfaceStyles from "../styles/frontInterface";

function Register() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        valids: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false
        },
        touched: {
            name: false,
            email: false,
            password: false,
            confirmPassword: false
        },
        errorOnForm: false
    });
    const [message, setMessage] = useState("text");
    const [completedRegistration, setCompletedRegistration] = useState({
        completed: false,
        message: ""
    });

    function handleBackBtn() {
        dispatch(AccessModalActions.UpdateScreen("signIn"));
    }

    function handleOnChange(event) {
        const { id, value } = event.target;

        const valids = data.valids;

        let isValid = false;
        if (id == "email") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
                isValid = true;
            }
        } else if (id == "confirmPassword") {
            const password = document.getElementById("password").value;
            if (password == value) {
                isValid = true;
            }
        } else {
            if (value != "") {
                isValid = true;
            }
        }

        valids[id] = isValid;

        const allTrue = Object.keys(data.valids).every(function (k) {
            return data.valids[k] === true;
        });

        setData((prevState) => {
            return { ...prevState, valids, errorOnForm: allTrue };
        });
    }

    function handleOnTouch(event) {
        const { id } = event.target;

        const touched = data.touched;
        touched[id] = true;

        setData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function switchAllTouches() {
        const touches = Object.assign(...Object.keys(data.touched).map((k) => ({ [k]: true })));

        setData((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function handleOnRegister() {
        if (!data.errorOnForm) {
            switchAllTouches();
        } else {
            const name = document.getElementById("name").value;
            const email = document.getElementById("email").value;
            const password = document.getElementById("password").value;
            const confirmPassword = document.getElementById("confirmPassword").value;

            const data = {
                fullName: name,
                email: email,
                password: password,
                confirmPassword: confirmPassword
            };

            Axios.post("/pods/accessModal/register", data)
                .then((res) => {
                    const data = res.data;
                    if (data.userError === "Yes") {
                        setMessage(data.message);
                    } else {
                        let output = "";
                        if (data.alreadyAnAccount) {
                            output =
                                "Your account has been linked with the account that High-View Studios holds for you, as you have made payments to High-View Studios in the past. \n\r Please verify your email to use the ViewHelp helpdesk and to make any payment on this site";
                        } else {
                            output =
                                "Thank you for registering with High-View Studios! Please verify your email to use the ViewHelp helpdesk and to make any payment on this site";
                        }

                        setCompletedRegistration({ completed: true, message: output });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {completedRegistration.completed ? (
                    <div style={FrontInterfaceStyles.passwordRequestedText}>{completedRegistration.message}</div>
                ) : (
                    <div>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.name && !data.valids.name ? true : false}
                                type="text"
                                id="name"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a name!</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.email && !data.valids.email ? true : false}
                                type="text"
                                id="email"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid email!</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.password && !data.valids.password ? true : false}
                                type="password"
                                id="password"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a password!</Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Confirm Password:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.confirmPassword && !data.valids.confirmPassword ? true : false}
                                type="password"
                                id="confirmPassword"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Passwords do not match!</Form.Control.Feedback>
                        </Form.Group>
                        <div style={message != "text" ? FrontInterfaceStyles.errorTextShow : FrontInterfaceStyles.errorTextHide}>{message}</div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {completedRegistration.completed ? (
                    <Button onClick={handleBackBtn}>Sign In</Button>
                ) : (
                    <div>
                        <Button className="mr-auto" onClick={handleBackBtn}>
                            Back
                        </Button>
                        <Button onClick={handleOnRegister}>Register</Button>
                    </div>
                )}
            </Modal.Footer>
        </div>
    );
}

export default Register;
