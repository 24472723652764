import React, { useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import useWindowSize from "../../../pods/wholeSite/windowSize";
import CenterContainer from "../../../pods/wholeSite/centerContainer";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import * as adminLoginStyles from "../styles/login";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

//ACTIONS
import * as UserActions from "../../../../store/actions/user";

function ChangePassword(props) {
    const [useWindowHeight] = useWindowSize();
    const [message, setmessage] = useState("");
    const [passwordHelp, setPasswordHelp] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false,
        closeFunction: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleChangePassword(event) {
        event.preventDefault();

        const oldPassword = document.getElementById("txtCurrentPassword").value;
        const newPassword = document.getElementById("txtNewPassword").value;
        const confirmPassword = document.getElementById("txtConfirmPassword").value;

        if (oldPassword == "" && newPassword == "" && confirmPassword == "") {
            setModal({
                header: "Change Password",
                error: true,
                message: "Please fill out all the fields!",
                closeFunction: handleCloseModal,
                open: true
            });
        } else {
            const data = { userId: user.id, oldPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword };

            Axios.post("/adminPods/security/changePassword", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.userError == "Yes") {
                        if (data.message == "Your password is not strong enough") {
                            setmessage(data.message);
                            setPasswordHelp(true);
                        } else {
                            setPasswordHelp(false);
                            setmessage(data.message);
                        }
                    } else {
                        dispatch(UserActions.UpdateNew(data.user.new == "true"));
                        dispatch(UserActions.UpdateRequestedPassword(data.user.requestedPassword == "true"));

                        navigate(`/admin-${data.adminCode}/overview`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div>
            <Row style={{ ...adminLoginStyles.body, height: useWindowHeight - 10 }} className="align-items-center">
                <Col>
                    <Row>
                        <Col className="text-center">
                            <CenterContainer>
                                <CenterContainer style={adminLoginStyles.innerContainer}>
                                    {props.new ? <h1>Create A New Password</h1> : <h1>Change Password</h1>}
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col sm={3} style={adminLoginStyles.changePasswordTitles}>
                                                    Current Password:
                                                </Col>
                                                <Col sm={9}>
                                                    <Form.Control type="password" id="txtCurrentPassword" />
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col sm={3} style={adminLoginStyles.changePasswordTitles}>
                                                    New Password:
                                                </Col>
                                                <Col sm={9}>
                                                    <Form.Control type="password" id="txtNewPassword" />
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col sm={3} style={adminLoginStyles.changePasswordTitles}>
                                                    Comfirm New Password:
                                                </Col>
                                                <Col sm={9}>
                                                    <Form.Control type="password" id="txtConfirmPassword" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <br />
                                    <p>{message}</p>
                                    {passwordHelp ? (
                                        <div style={adminLoginStyles.passwordHelp}>
                                            Your password must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character. It also
                                            requires to be a minimum 8 characters in length
                                        </div>
                                    ) : null}
                                    <br />
                                    <Button variant="warning" style={adminLoginStyles.loginBtns} onClick={handleChangePassword}>
                                        Submit
                                    </Button>
                                </CenterContainer>
                            </CenterContainer>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={modal.open} onHide={modal.closeFunction}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={modal.closeFunction}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ChangePassword;
