import Axios from "axios";

//OTHER ACTION FILES
import * as PrintingShopActions from "./3dPrintingShop";

export const ACCESSMODAL_UPDATE_SCREEN = "ACCESSMODAL_UPDATE_SCREEN";
export const UPDATE_ACTION_AND_OPEN_SIGNIN = "UPDATE_ACTION_AND_OPEN_SIGNIN";

export const LogActionsAndSignIn = (loginActions) => {
    return {
        type: UPDATE_ACTION_AND_OPEN_SIGNIN,
        value: loginActions
    };
};

export const UpdateScreen = (val) => {
    return {
        type: ACCESSMODAL_UPDATE_SCREEN,
        value: val
    };
};

export const SignInMethod = () => {
    return (dispatch, getState) => {
        if (getState().accessModal.loginActions.action == "getBasket") {
            dispatch(PrintingShopActions.FetchBasketDeatils());
        }
        if (getState().accessModal.loginActions.action == "addItemToBasket") {
            const data = getState().accessModal.loginActions.data;
            data.userID = getState().user.id;
            Axios.post("/pods/3dPrintingShop/frontEnd/addProductToBasket", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        dispatch(PrintingShopActions.UpdateBasketItemTotal());

                        dispatch(PrintingShopActions.ToggleBasketSuccess(true));

                        setTimeout(() => {
                            dispatch(PrintingShopActions.ToggleBasketSuccess(false));
                        }, 2000);
                    }
                })
                .catch((err) => console.log(err));
        }

        dispatch({
            type: ACCESSMODAL_UPDATE_SCREEN,
            value: ""
        });
    };
};

export const SignOutMethod = (action) => {
    return (dispatch, getState) => {
        if (action == "clearBasketInferface") {
            dispatch({
                type: PrintingShopActions.CLEAR_BASKET_INTERFACE
            });
        }
    };
};
