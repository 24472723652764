export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const scrollableBox = {
    height: "350px",
    overlow: "auto",
    overflowX: "hidden"
};

export const errorTextShow = {
    color: "red",
    visibility: "visible"
};

export const errorTextHidden = {
    color: "red",
    visibility: "hidden"
};
