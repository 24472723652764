import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

//ACTIONS
import * as UserActions from "../../store/actions/user";
import * as GS_navSettingsActions from "../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as AdminHomeStyles from "./styles/admin_home";

//COMPONENTS
import AdminNavBar from "../pods/navBar/navBar";

import AdminNoneItems from "../pods/navBar/noneItems";

import AdminOverview from "../pods/overview/overview";
import AdminUsers from "../pods/users/podRouter";
import AdminEditorials from "../pods/editorials/podRouter";
import AdminMedia from "../pods/media/media";
import AdminContacts from "../pods/contacts/files/contactsControl";
import AdminPages from "../pods/pages/podRouter";
import AdminFeatures from "../pods/features/podRouter";
import AdminStyleConfig from "../pods/styleConfig/styleArea";
import AdminHelp from "../pods/help/files/controller";

function AdminHome(props) {
    const GS_navSettings = useSelector((state) => state.GS_navSettings);
    const user = useSelector((state) => state.user);
    const [overlay, setOverlay] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [mainMenuItems, setMenuItems] = useState([]);

    useEffect(() => {
        onOpen();
        // eslint-disable-next-line
    }, []);

    function onOpen() {
        if (!user.auth) {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else if (user.type === "registered") {
            navigate(`/admin-${props.adminCode}/security/login`);
        } else {
            dispatch(GS_navSettingsActions.GetAdminMenu());
        }
    }

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //MENU CHANGE METHODS
    function subMenuSelect(subMenuType, title) {
        if (subMenuType == "none") {
            navigate(`./none/${title}`);
        }
        if (subMenuType == "component") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}`);
        }
        if (subMenuType == "sideMenu") {
            dispatch(GS_navSettingsActions.UpdateSelectedToPrev());
            dispatch(GS_navSettingsActions.UpdateSelected(CapsFirstLetter(title)));
            setOverlay(true);
        }
        if (subMenuType == "display") {
            if (overlay) {
                setOverlay(false);
                dispatch(GS_navSettingsActions.ResetPrevSelected());
            }
            navigate(`./${title}/list`);
        }
    }

    function changeSection(title) {
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        navigate(`./${title}`);
    }

    function changeSection_CamelCase(title) {
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        const words = title.split(" ");
        console.log(words);
        navigate(`./${words[0]}`);
    }

    function openSubMenuItem(menu, code, floater) {
        if (overlay) {
            setOverlay(false);
            dispatch(GS_navSettingsActions.ResetPrevSelected());
        }
        if (menu == "pages") {
            navigate(`./pages/settings/${code}`);
        } else if (menu == "features" || floater) {
            navigate(`./features/pod/${code}`);
        } else {
            const link = `${menu}/${code.replace(" ", "")}`;
            navigate(`./${link}`);
        }
    }

    //MAIN MENU ACTIONS
    function handleLogOut() {
        Axios.post("/userLogin/admin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "Admin logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));
                    navigate(`/admin-${data.adminCode}/security/login`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleTurnOffOverlay() {
        dispatch(GS_navSettingsActions.UpdatePrevToSelected());
        setOverlay(false);
    }

    return (
        <div style={AdminHomeStyles.body}>
            {GS_navSettings.adminMenuIsLoaded && (
                <div>
                    <Row>
                        <Col md={GS_navSettings.size}>
                            <Row>
                                <Col style={AdminHomeStyles.title}>{GS_navSettings.size == 3 ? "Administration" : "Admin"}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AdminNavBar
                                        changeSection={changeSection}
                                        subMenuSelect={subMenuSelect}
                                        openSubItem={openSubMenuItem}
                                        logOutFunction={handleLogOut}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12 - GS_navSettings.size} style={AdminHomeStyles.bodyColumn}>
                            <div style={AdminHomeStyles.mainBodyContainer}>
                                <div style={AdminHomeStyles.mainBody}>
                                    <Row>
                                        <Col style={AdminHomeStyles.sectionTitle}>
                                            {GS_navSettings.static ? GS_navSettings.staticText : GS_navSettings.title}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Routes>
                                                <Route path="/overview" element={<AdminOverview />} />
                                                <Route path="/users/*" exact element={<AdminUsers />} />
                                                <Route path="/editorials/*" element={<AdminEditorials />} />
                                                <Route path="/media" element={<AdminMedia />} />
                                                <Route path="/contacts" element={<AdminContacts />} />
                                                <Route path="/none/:menu" element={<AdminNoneItems />} />
                                                <Route
                                                    path="/pages/*"
                                                    element={
                                                        <AdminPages
                                                            items={GS_navSettings.adminMenu.find((section) => section.title == "Pages").subMenuItems}
                                                            SPODSystems={
                                                                GS_navSettings.adminMenu.find((section) => section.title == "Pages").SPODSystems
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/features/*"
                                                    element={
                                                        <AdminFeatures
                                                            items={
                                                                GS_navSettings.adminMenu.find((section) => section.title == "Features").subMenuItems
                                                            }
                                                            SPODSystems={
                                                                GS_navSettings.adminMenu.find((section) => section.title == "Features").SPODSystems
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route path="styleConfiguration/*" element={<AdminStyleConfig />} />
                                                <Route path="/help" element={<AdminHelp />} />
                                            </Routes>
                                        </Col>
                                    </Row>
                                </div>
                                {overlay && (
                                    <div style={AdminHomeStyles.overlay} onClick={handleTurnOffOverlay}>
                                        <span style={AdminHomeStyles.overlayText}>Click to go back to this section</span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default AdminHome;
