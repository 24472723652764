import React, { useState } from "react";
import Axios from "axios";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../../../store/actions/user";
import moment from "moment";

//styles
import * as loginFormStyles from "./styles/loginForm";
import * as containerStyles from "../../special/wholeSite/styles/container"; //CROSS-OVER POD LINK

function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const [message, setMessage] = useState("");
    const [formState, setFormState] = useState({
        valids: {
            username: false,
            password: false
        },
        touched: {
            username: false,
            password: false
        },
        wholeForm: false
    });

    function goToRegister() {
        navigate("/membership/register");
    }

    function goToForgottenPassword() {
        navigate("/membership/forgotPassword");
    }

    function logIn(event) {
        event.preventDefault();
        if (!formState.wholeForm) {
            switchAllTouches();
        } else {
            const email = document.getElementById("username").value;
            const password = document.getElementById("password").value;

            const data = { email: email, password: password };
            Axios.post("/userLogin/auth/local/user", data)
                .then((res) => {
                    const data = res.data;
                    if (data.message === "Logged in successful") {
                        dispatch(UserActions.UpdateAuth(true));
                        dispatch(UserActions.UpdateID(data.user.id));
                        dispatch(UserActions.UpdateName(data.user.fullName));
                        dispatch(UserActions.UpdateEmail(data.user.email));
                        dispatch(UserActions.UpdateType(data.user.type));
                        dispatch(UserActions.UpdateRequestedPassword(data.user.requestedPassword == "true"));
                        setMessage("");
                        if (data.user.requestedPassword == "true") {
                            navigate("/membership/changePassword/pod");
                        }
                    } else {
                        setMessage(data.info);
                        if (data.info === "A Super Admin cannot sign into the front end") {
                            document.getElementById("username").value = "";
                            document.getElementById("password").value = "";
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function logOut() {
        Axios.post("/userLogin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "User logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function generateGreeting() {
        const currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 17) {
            return "Good Afternoon";
        } else if (currentHour >= 17 && currentHour < 21) {
            return "Good Evening";
        } else if (currentHour >= 21 && currentHour < 3) {
            return "Good Night";
        } else {
            return "Hello";
        }
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            logIn(event);
        }
    }

    function handleOnTouched(event) {
        const { id } = event.target;

        const updatedTouched = { ...formState.touched, [id]: true };

        setFormState((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function onInputChange(event) {
        const { id, value } = event.target;

        const valids = formState.valids;

        let valid = false;
        if (value.length > 0) {
            valid = true;
        }

        valids[id] = valid;

        let wholeForm = false;

        if (valids.username && valids.password) {
            wholeForm = true;
        }

        setFormState((prevState) => {
            return { ...prevState, valids: valids, wholeForm: wholeForm };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formState.touched).map((k) => ({ [k]: true })));
        setFormState((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    return (
        <div>
            {user.isLoaded ? (
                <div style={containerStyles.sideContainer}>
                    {user.auth ? (
                        <div>
                            {generateGreeting()} <strong>{user.fullName}</strong> <br />
                            <Button variant="warning" style={loginFormStyles.loginBtns} onClick={logOut}>
                                Log Out
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <strong>Sign In or Sign Up</strong> <br /> <br />
                            <Form>
                                <Form.Group>
                                    <Form.Label>Username:</Form.Label>
                                    <Form.Control
                                        isInvalid={formState.touched.username && !formState.valids.username ? true : false}
                                        type="text"
                                        id="username"
                                        onBlur={handleOnTouched}
                                        onChange={onInputChange}
                                    />
                                    <Form.Control.Feedback style={loginFormStyles.FP_InputError} type="invalid">
                                        Enter an username
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control
                                        isInvalid={formState.touched.password && !formState.valids.password ? true : false}
                                        type="password"
                                        id="password"
                                        onKeyPress={handleKeyPress}
                                        onBlur={handleOnTouched}
                                        onChange={onInputChange}
                                    />
                                    <Form.Control.Feedback style={loginFormStyles.FP_InputError} type="invalid">
                                        Enter a password
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <p>{message}</p>
                                <br />
                                <Button style={loginFormStyles.loginBtns} variant="warning" onClick={logIn}>
                                    Login
                                </Button>
                                <Button style={loginFormStyles.loginBtns} variant="warning" onClick={goToRegister}>
                                    Register
                                </Button>
                                <Button style={loginFormStyles.loginBtns} variant="warning" onClick={goToForgottenPassword}>
                                    Forgot Password?
                                </Button>
                            </Form>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default LoginForm;
