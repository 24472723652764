export const body = {
    padding: "10px",
    textAlign: "center"
};

export const innerBody = {
    backgroundColor: "white",
    border: "1px solid black",
    padding: "5px",
    cursor: "pointer"
};

export const title = {
    fontWeight: "bold"
};

export const image = {
    height: "150px",
    width: "150px",
    border: "1px solid black"
};

export const price = {
    paddingTop: "10px",
    textAlign: "right"
};
