export const image = {
    width: "70px",
    height: "70px",
    border: "1px solid black",
    margin: "5px",
    cursor: "pointer"
};

export const imageSelected = {
    width: "70px",
    height: "70px",
    border: "2px solid red",
    margin: "5px",
    cursor: "pointer"
};
