import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//STYLES
import * as FrontInterfaceStyles from "../styles/frontInterface";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";
import * as UserActions from "../../../../store/actions/user";
import * as PrintingShopActions from "../../../envStore/actions/3dPrintingShop";

//COMPONENTS
import SignInModal from "./signIn";
import ForgotPasswordModal from "./forgotPassword";
import RegisterModal from "./register";
import ChangePassword from "./changePassword";

function FrontInterface() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const accessModal = useSelector((state) => state.accessModal);
    const printingShop = useSelector((state) => state.printingShop);

    useEffect(() => {
        dispatch(PrintingShopActions.UpdateBasketItemTotal());
    }, []);

    function handleModalOnHide() {
        dispatch(AccessModalActions.UpdateScreen(""));
    }

    function handleSignInBtn() {
        const actions = { action: "getBasket", data: {} };
        dispatch(AccessModalActions.LogActionsAndSignIn(actions));
    }

    function handleSignOut() {
        Axios.post("/userLogin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "User logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));

                    dispatch(AccessModalActions.SignOutMethod("clearBasketInferface"));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleCartOnClick() {
        navigate("./basket");
    }

    return (
        <div style={FrontInterfaceStyles.body}>
            {user.auth ? (
                <>
                    <strong>Welcome Back!</strong>
                    <br />
                    {user.fullName}
                    <br />
                    <br />
                    <div style={FrontInterfaceStyles.basket}>
                        <i style={FrontInterfaceStyles.cart} onClick={handleCartOnClick} className="fa-solid fa-cart-shopping"></i>
                        {"   "}
                        {printingShop.basketItemTotal}
                    </div>
                    <br />
                    <br />
                    <Button onClick={handleSignOut}>Sign Out</Button>
                </>
            ) : (
                <>
                    <strong>Welcome!</strong>
                    <br />
                    <br />
                    <div style={FrontInterfaceStyles.signInBtn}>
                        <Button onClick={handleSignInBtn}>Sign In</Button>
                    </div>
                </>
            )}
            <Modal show={accessModal.screen != ""} onHide={handleModalOnHide}>
                {/* SCREENS */}
                {accessModal.screen == "signIn" && <SignInModal />}
                {accessModal.screen == "forgotPassword" && <ForgotPasswordModal />}
                {accessModal.screen == "register" && <RegisterModal />}
                {accessModal.screen == "changePassword" && <ChangePassword />}
            </Modal>
        </div>
    );
}

export default FrontInterface;
