import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";

//STYLES
import * as PersonalisationStyles from "../../styles/products/personalisation";

function NewProduct_Personlisation(props) {
    const fieldModalDefaultValues = {
        open: false,
        values: {
            field: "",
            length: ""
        },
        valids: {
            field: false,
            length: false
        },
        touched: {
            field: false,
            length: false
        },
        edit: false
    };
    const [fieldModal, setFieldModal] = useState(fieldModalDefaultValues);

    const [fieldControls, setFieldControls] = useState({
        view: false,
        selectedUUID: -1
    });

    function handleCloseTextModal() {
        setFieldModal(fieldModalDefaultValues);
    }

    function handleCheckChange(event) {
        const { checked, name } = event.target;

        let newColours = [];
        if (checked) {
            newColours = [...props.data.values.coloursAvailableIn];

            newColours.push(`[${name}]`);
        } else {
            console.log(props.data.values.coloursAvailableIn);
            for (const colour of props.data.values.coloursAvailableIn) {
                console.log(colour);
                if (colour != `[${name}]`) {
                    newColours.push(colour);
                }
            }
        }
        const colourStock = getColourStock(newColours);

        const newValues = {
            coloursAvailableIn: newColours,
            coloursInStock: colourStock,
            customFields: props.data.values.customFields
        };

        let isValid = false;
        if (newColours != "") {
            isValid = true;
        }

        const valids = props.data.valids;
        valids.coloursAvailableIn = isValid;

        const allTrue = Object.keys(props.data.valids).every(function (k) {
            return props.data.valids[k] === true;
        });

        props.setData((prevState) => {
            return { ...prevState, values: newValues, valids, sectionValid: allTrue };
        });

        props.validateMainErrorMessage(null, null, allTrue);
    }

    function getColourCheck(uuid) {
        let check = false;

        if (props.data.values.coloursAvailableIn.includes(`[${uuid.toString()}]`)) {
            check = true;
        }
        return check;
    }

    function getColourStock(colours) {
        let colourStock = [];

        for (const colour of colours) {
            if (props.data.colours.find((color) => color.uuid == colour[1]).available == "true") {
                colourStock.push(colour);
            }
        }

        if (colourStock.length == 0) {
            const basicDetailsValues = props.basicDetails.values;

            basicDetailsValues.active = false;

            props.setBasicDetails((prevState) => {
                return { ...prevState, values: basicDetailsValues };
            });
        }

        return colourStock;
    }

    function handleOpenTextModal() {
        setFieldControls((prevState) => {
            return { ...prevState, selectedUUID: -1, view: false };
        });

        setFieldModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function numbersKeyPress(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function fieldModal_handleTextChange(event) {
        const { value, name } = event.target;

        const values = fieldModal.values;
        const valids = fieldModal.valids;

        values[name] = value;

        let isValid = false;
        if (name == "length") {
            const number = parseInt(value);
            if (number <= 50) {
                isValid = true;
            }
        } else {
            if (value != "") {
                isValid = true;
            }
        }
        valids[name] = isValid;

        setFieldModal((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    function fieldModal_handleOnTouched(event) {
        const { name } = event.target;

        const touched = fieldModal.touched;
        touched[name] = true;

        setFieldModal((prevState) => {
            return { ...prevState, touched };
        });
    }

    function switchAllTouches() {
        const touches = Object.assign(...Object.keys(fieldModal.touched).map((k) => ({ [k]: true })));

        setFieldModal((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function fieldModal_handleOnSubmit() {
        if (!fieldModal.valids.field || !fieldModal.valids.length) {
            switchAllTouches();
        } else {
            const field = {
                fieldName: fieldModal.values.field,
                length: fieldModal.values.length,
                status: "new"
            };

            const custFields = props.data.values.customFields;
            custFields.push(field);

            const personalisationValues = {
                coloursAvailableIn: props.data.values.coloursAvailableIn,
                coloursInStock: props.data.values.coloursInStock,
                customFields: custFields
            };

            props.setData((prevState) => {
                return { ...prevState, values: personalisationValues };
            });

            setFieldModal(fieldModalDefaultValues);
        }
    }

    function fieldModal_handleOnUpdate() {
        if (!fieldModal.valids.field || !fieldModal.valids.length) {
            switchAllTouches();
        } else {
            const fields = [...props.data.values.customFields];

            fields[fieldControls.selectedUUID].fieldName = fieldModal.values.field;
            fields[fieldControls.selectedUUID].length = fieldModal.values.length;

            if (fields[fieldControls.selectedUUID].status == "current") {
                fields[fieldControls.selectedUUID].status = "update";
            }

            const values = {
                coloursAvailableIn: props.data.values.coloursAvailableIn,
                coloursInStock: props.data.values.coloursInStock,
                customFields: fields
            };

            props.setData((prevState) => {
                return { ...prevState, values };
            });

            setFieldModal(fieldModalDefaultValues);
        }
    }

    function fieldModal_handleOnDelete() {
        const fields = [...props.data.values.customFields];

        fields[fieldControls.selectedUUID].status = "delete";

        const values = {
            coloursAvailableIn: props.data.values.coloursAvailableIn,
            coloursInStock: props.data.values.coloursInStock,
            customFields: fields
        };

        props.setData((prevState) => {
            return { ...prevState, values };
        });

        setFieldModal(fieldModalDefaultValues);

        setFieldControls((prevState) => {
            return { ...prevState, selectedUUID: -1, view: false };
        });
    }

    function handleSelectCustomField(uuid) {
        setFieldControls((prevState) => {
            return { ...prevState, selectedUUID: uuid, view: true };
        });
    }

    function fieldModal_handleOpenSelectedInEdit() {
        const values = {
            field: props.data.values.customFields[fieldControls.selectedUUID].fieldName,
            length: props.data.values.customFields[fieldControls.selectedUUID].length
        };

        const valids = {
            field: values.field != "",
            length: values.length != ""
        };

        setFieldModal((prevState) => {
            return { ...prevState, values, valids, edit: true, open: true };
        });
    }

    return (
        <div>
            <Row>
                <Col>
                    <strong>Colours Available In:</strong>
                    <br />
                    <br />
                    <div
                        style={
                            props.triedToSubmit && !props.data.valids.coloursAvailableIn
                                ? PersonalisationStyles.errorShow
                                : PersonalisationStyles.errorHide
                        }
                    >
                        At least one colour is required
                    </div>
                </Col>
                <Col>
                    <strong>Custom Fields:</strong>
                    <Row>
                        <Col>
                            {fieldControls.view && (
                                <div>
                                    <Button onClick={fieldModal_handleOpenSelectedInEdit}>Edit</Button>
                                    <Button onClick={fieldModal_handleOnDelete}>Delete</Button>
                                </div>
                            )}
                        </Col>
                        <Col style={PersonalisationStyles.addTextBtn}>
                            <Button onClick={handleOpenTextModal}>Add Text</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Card style={PersonalisationStyles.cardHeader}>
                                <Card.Body>
                                    <Row>
                                        <Col>Colour</Col>
                                        <Col>Stock</Col>
                                        <Col>Available</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div>
                                {props.data.colours.map((colour, index) => {
                                    return (
                                        <div key={index}>
                                            <Card style={PersonalisationStyles.cardStyle}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col>{colour.name}</Col>
                                                        <Col>{colour.available == "true" ? "In Stock" : "Out of Stock"}</Col>
                                                        <Col>
                                                            <Form.Check
                                                                label=""
                                                                name={colour.uuid}
                                                                checked={getColourCheck(colour.uuid)}
                                                                onChange={handleCheckChange}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card style={PersonalisationStyles.cardHeader}>
                                <Card.Body>
                                    <Row>
                                        <Col>Field</Col>
                                        <Col>Length</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div>
                                {/* {console.log(props.data.values.customFields)} */}
                                {props.data.values.customFields.map((field, index) => {
                                    if (field.status != "delete") {
                                        return (
                                            <div key={index}>
                                                <Card
                                                    style={
                                                        fieldControls.selectedUUID == index
                                                            ? PersonalisationStyles.fieldCardStyleSelected
                                                            : PersonalisationStyles.fieldCardStyle
                                                    }
                                                    onClick={handleSelectCustomField.bind(this, index)}
                                                >
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>{field.fieldName}</Col>
                                                            <Col>{field.length}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={fieldModal.open} onHide={handleCloseTextModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{fieldModal.edit ? "Edit Custom Field" : "Add Custom Field"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={7}>
                            <Form.Group>
                                <Form.Label>Field Name:</Form.Label>
                                <Form.Control
                                    isInvalid={fieldModal.touched.field && !fieldModal.valids.field ? true : false}
                                    type="text"
                                    name="field"
                                    value={fieldModal.values.field}
                                    onChange={fieldModal_handleTextChange}
                                    onBlur={fieldModal_handleOnTouched}
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a field name!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={5}>
                            <Form.Group>
                                <Form.Label>Length:</Form.Label>
                                <Form.Control
                                    isInvalid={fieldModal.touched.length && !fieldModal.valids.length ? true : false}
                                    type="text"
                                    name="length"
                                    onKeyPress={numbersKeyPress}
                                    value={fieldModal.values.length}
                                    onChange={fieldModal_handleTextChange}
                                    onBlur={fieldModal_handleOnTouched}
                                    maxLength={3}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a length less than 51!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {fieldModal.edit ? (
                        <Button onClick={fieldModal_handleOnUpdate}>Update</Button>
                    ) : (
                        <Button onClick={fieldModal_handleOnSubmit}>Add</Button>
                    )}
                    <Button onClick={handleCloseTextModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewProduct_Personlisation;
