import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as ControllerStyles from "../styles/controller";

function NewEnvironmentModal(props) {
    const [formData, setFormData] = useState({
        inputs: {
            environment: "",
            domain: "",
            adminCode: "",
            adminStamp: ""
        },
        length: {
            environment: 50,
            domain: 100,
            adminCode: 4,
            adminStamp: 50
        },
        valids: {
            environment: false,
            domain: false,
            adminCode: false,
            adminStamp: false
        },
        touched: {
            environment: false,
            domain: false,
            adminCode: false,
            adminStamp: false
        },
        wholeFormValid: false
    });
    const [copyStatus, setCopyStatus] = useState("Copy Code");

    const [modal, setModal] = useState({
        open: false,
        message: ""
    });

    function handleModalClose() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        if (props.show) {
            if (props.edit) {
                const data = { uuid: props.uuid };
                Axios.post("/pods/appService/environments/getEnvironmentData", data)
                    .then((res) => {
                        const data = res.data;
                        console.log(data);
                        validateInput("environment", data.environment.environment, true);
                        validateInput("domain", data.environment.domain, true);
                        validateInput("adminCode", data.environment.adminCode, true);
                        validateInput("adminStamp", data.environment.adminStamp, true);
                    })
                    .catch((err) => console.log(err));
            }
        }
    }, [props.show]);

    function handleAdminCodeGenerate() {
        const data = {
            length: 4,
            letters: false
        };
        Axios.post("/pods/appService/environments/generateCode", data)
            .then((res) => {
                const data = res.data;
                console.log(data.code);
                handleUpdateCodeControl("adminCode", data.code);
            })
            .catch((err) => console.log(err));
    }

    function handleAdminStampGenerate() {
        const data = {
            length: 50,
            letters: true
        };
        Axios.post("/pods/appService/environments/generateCode", data)
            .then((res) => {
                const data = res.data;
                handleUpdateCodeControl("adminStamp", data.code);
            })
            .catch((err) => console.log(err));
    }

    function handleControlTouched(event) {
        const { name } = event.target;

        const updatedTouches = formData.touched;

        updatedTouches[name] = true;

        setFormData((prevState) => {
            return { ...prevState, touched: updatedTouches };
        });
    }

    function handleUpdateControlText(event) {
        const { name, value, validity } = event.target;
        validateInput(name, value, validity.valid);
    }

    function validateInput(name, value, valid) {
        if (valid) {
            const inputs = formData.inputs;
            const valids = formData.valids;

            inputs[name] = value;

            let controlValid = false;

            if (value.length > 0) {
                controlValid = true;
            }

            valids[name] = controlValid;

            let wholeFormValid = false;

            if (valids["environment"] && valids["domain"] && valids["adminCode"] && valids["adminStamp"]) {
                wholeFormValid = true;
            }

            setFormData((prevState) => {
                return { ...prevState, inputs: inputs, valids: valids, wholeFormValid };
            });
        }
    }

    function handleUpdateCodeControl(name, value) {
        const inputs = formData.inputs;
        const valids = formData.valids;

        inputs[name] = value;

        let controlValid = false;

        if (value.length > 0) {
            controlValid = true;
        }

        valids[name] = controlValid;

        let wholeFormValid = false;

        if (valids["environment"] && valids["domain"] && valids["adminCode"] && valids["adminStamp"]) {
            wholeFormValid = true;
        }

        setFormData((prevState) => {
            return { ...prevState, inputs: inputs, valids: valids, wholeFormValid };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));

        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleCreate() {
        if (!formData.wholeFormValid) {
            switchAllTouches();
        } else {
            const data = {
                environment: formData.inputs.environment,
                domain: formData.inputs.domain,
                adminCode: formData.inputs.adminCode,
                adminStamp: formData.inputs.adminStamp
            };

            Axios.post("/pods/appService/environments/createEnvironmentHolder", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        if (data.userError == "Yes") {
                            setModal({ message: data.message, open: true });
                        } else {
                            props.reload();
                            props.onClose();
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleUpdate() {
        if (!formData.wholeFormValid) {
            switchAllTouches();
        } else {
            const data = {
                uuid: props.uuid,
                environment: formData.inputs.environment,
                domain: formData.inputs.domain,
                adminCode: formData.inputs.adminCode,
                adminStamp: formData.inputs.adminStamp
            };
            Axios.post("/pods/appService/environments/updateEnvironmentHolder", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        if (data.userError == "Yes") {
                            setModal({ message: data.message, open: true });
                        } else {
                            props.reload();
                            props.onClose();
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function copyToClipboard(field) {
        navigator.clipboard.writeText(formData.inputs[field]);
        setCopyStatus("Code Copied!");

        setTimeout(() => {
            setCopyStatus("Copy Code");
        }, 1000);
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? "Edit Environment" : "New Environment"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            Environment:
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.environment && !formData.valids.environment ? true : false}
                                    type="text"
                                    name="environment"
                                    maxLength={formData.length.environment}
                                    value={formData.inputs.environment}
                                    onChange={handleUpdateControlText}
                                    onBlur={handleControlTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter an environment!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            Domain:
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.domain && !formData.valids.domain ? true : false}
                                    type="text"
                                    name="domain"
                                    maxLength={formData.length.domain}
                                    value={formData.inputs.domain}
                                    onChange={handleUpdateControlText}
                                    onBlur={handleControlTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter a domain!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Admin Code: </Col>
                    </Row>
                    <Row>
                        <Col sm={8} style={ControllerStyles.randomBtn}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.adminCode && !formData.valids.adminCode ? true : false}
                                    type="text"
                                    pattern="[0-9]*"
                                    name="adminCode"
                                    maxLength={formData.length.adminCode}
                                    value={formData.inputs.adminCode}
                                    onChange={handleUpdateControlText}
                                    onBlur={handleControlTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter an admin code!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Button onClick={handleAdminCodeGenerate}>
                                <i className="fa-solid fa-shuffle"></i>
                            </Button>
                            {formData.inputs.adminCode.length == 4 && (
                                <OverlayTrigger placement="right" overlay={<Tooltip>{copyStatus}</Tooltip>}>
                                    <Button onClick={copyToClipboard.bind(this, "adminCode")}>
                                        <i className="fa-regular fa-clipboard"></i>
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Admin Stamp: </Col>
                    </Row>
                    <Row>
                        <Col sm={8} style={ControllerStyles.randomBtn}>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={formData.touched.adminStamp && !formData.valids.adminStamp ? true : false}
                                    type="text"
                                    name="adminStamp"
                                    maxLength={formData.length.adminStamp}
                                    value={formData.inputs.adminStamp}
                                    onChange={handleUpdateControlText}
                                    onBlur={handleControlTouched}
                                />
                                <Form.Control.Feedback type="invalid">Please enter an admin stamp!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Button onClick={handleAdminStampGenerate}>
                                <i className="fa-solid fa-shuffle"></i>
                            </Button>
                            {formData.inputs.adminStamp.length == 50 && (
                                <OverlayTrigger placement="right" overlay={<Tooltip>{copyStatus}</Tooltip>}>
                                    <Button onClick={copyToClipboard.bind(this, "adminStamp")}>
                                        <i className="fa-regular fa-clipboard"></i>
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onClose}>Close</Button>
                    {props.edit ? <Button onClick={handleUpdate}>Update</Button> : <Button onClick={handleCreate}>Create</Button>}
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Code Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewEnvironmentModal;
