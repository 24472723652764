import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Form, Card } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as CategoriesStyles from "../styles/categories";

function Categories() {
    const dispatch = useDispatch();

    const defaultValues = {
        open: false,
        title: "",
        isValid: false,
        triedToSubmit: false
    };

    const [newCategoryModal, setNewCategoryModal] = useState(defaultValues);
    const [categories, setCategories] = useState([]);

    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false, header: "", message: "" };
        });
    }

    const [YNmodal, setYNModal] = useState({
        open: false,
        header: "",
        message: "",
        code: ""
    });

    function handleCloseYNModal() {
        setYNModal((prevState) => {
            return { ...prevState, open: false, header: "", message: "" };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Categories"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadCategories();
    }, []);

    function loadCategories() {
        Axios.post("/pods/3dPrintingShop/getCategories")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCategories(data.categories);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleCloseNewCategoryModal() {
        setNewCategoryModal(defaultValues);
    }

    function handleOpenNewCategoryModal() {
        setNewCategoryModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleChangeNewCategoryTtitle(event) {
        const { value } = event.target;

        let isValid = false;

        if (value != "") {
            isValid = true;
        }

        setNewCategoryModal((prevState) => {
            return { ...prevState, title: value, isValid };
        });
    }

    function handleOnSubmit() {
        if (!newCategoryModal.isValid) {
            setNewCategoryModal((prevState) => {
                return { ...prevState, triedToSubmit: true };
            });
        } else {
            const data = { title: newCategoryModal.title };
            Axios.post("/pods/3dPrintingShop/addCategory", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setCategories(data.categories);

                        setNewCategoryModal(defaultValues);
                    } else {
                        if (data.dialog == "Yes") {
                            setYNModal({ header: "New Category", message: data.message, code: data.code, open: true });
                        } else {
                            setModal({ header: "New Category", message: data.message, open: true });
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnClickConfirmed() {
        setYNModal((prevState) => {
            return { ...prevState, open: false, header: "", message: "" };
        });

        const data = { title: newCategoryModal.title, code: YNmodal.code };
        Axios.post("/pods/3dPrintingShop/addCategoryConfirmed", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCategories(data.categories);
                    setNewCategoryModal(defaultValues);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={CategoriesStyles.body}>
            <Row>
                <Col style={CategoriesStyles.newCateBtn}>
                    <Button onClick={handleOpenNewCategoryModal}>New Category</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Card style={CategoriesStyles.cardHeader}>
                        <Card.Body>
                            <Row>
                                <Col>Name</Col>
                                <Col>Code</Col>
                                <Col>Items</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {categories.map((item, index) => {
                        return (
                            <div key={index}>
                                <Card style={CategoriesStyles.cardStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col>{item.name}</Col>
                                            <Col>{item.code}</Col>
                                            <Col>{item.noOfItems}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <Modal show={newCategoryModal.open} onHide={handleCloseNewCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        Title: <br />
                        <Form.Control
                            isInvalid={newCategoryModal.triedToSubmit && !newCategoryModal.isValid}
                            type="text"
                            value={newCategoryModal.title}
                            onChange={handleChangeNewCategoryTtitle}
                        />
                        <Form.Control.Feedback type="invalid">Enter a title!</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleOnSubmit}>Add</Button>
                    <Button onClick={handleCloseNewCategoryModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={YNmodal.open} onHide={handleCloseYNModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{YNmodal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{YNmodal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleOnClickConfirmed}>Yes</Button>
                    <Button onClick={handleCloseYNModal}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Categories;
