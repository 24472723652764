import * as Colors from "../../../../../globalStyles/administratorColors";

export const cardHeader = {
    backgroundColor: Colors.primary,
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    textAlign: "center"
};

export const fieldCardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    textAlign: "center",
    cursor: "pointer"
};

export const fieldCardStyleSelected = {
    backgroundColor: Colors.primary,
    color: "yellow",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer"
};

export const errorHide = {
    visibility: "hidden",
    color: "red"
};

export const errorShow = {
    visibility: "visible",
    color: "red"
};

export const addTextBtn = {
    textAlign: "right"
};
