import React from "react";
import { Col, Row, Card, Dropdown, Form, Button } from "react-bootstrap";

//STYLES
import * as WebPackageItemControlStyles from "../../styles/packages/webPackageItemControl";

function WebPackageItemControl(props) {
    function handleDropdownChange(field, value) {
        props.changeDropdownText(props.id, field, value);
    }

    function handleTextChangeAndValidate(event) {
        const { name, value } = event.target;
        props.textChangeAndValidate(props.id, name, value);
    }

    function handleTextChange(event) {
        const { name, value } = event.target;
        props.textChange(props.id, name, value);
    }

    function handleCheckChanged(event) {
        const { name, checked } = event.target;
        props.checkChange(props.id, name, checked);
    }

    function handleChangeTouched(event) {
        const { name } = event.target;
        props.changeTouched(props.id, name);
    }

    function handleItemDelete() {
        props.deleteItem(props.id);
    }

    return (
        <div>
            <Card className="shadow-none" style={WebPackageItemControlStyles.cardStyle}>
                <Card.Body>
                    <Row>
                        <Col>
                            Type:
                            <Dropdown>
                                <Dropdown.Toggle>{props.item.values.type}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleDropdownChange.bind(this, "type", "Essential")}>Essential</Dropdown.Item>
                                    <Dropdown.Item onClick={handleDropdownChange.bind(this, "type", "Additional")}>Additional</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    isInvalid={props.item.touched.name && !props.item.valids.name ? true : false}
                                    type="text"
                                    name="name"
                                    value={props.item.values.name}
                                    onChange={handleTextChangeAndValidate}
                                    onBlur={handleChangeTouched}
                                />
                                <Form.Control.Feedback type="invalid">Invalid</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Price:</Form.Label>
                                <Form.Control
                                    isInvalid={props.item.touched.price && !props.item.valids.price ? true : false}
                                    type="text"
                                    name="price"
                                    value={props.item.values.price}
                                    onChange={handleTextChangeAndValidate}
                                    onBlur={handleChangeTouched}
                                />
                                <Form.Control.Feedback type="invalid">Invalid</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            Frequently:
                            <Dropdown>
                                <Dropdown.Toggle>{props.item.values.frequently}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleDropdownChange.bind(this, "frequently", "Monthly")}>Monthly</Dropdown.Item>
                                    <Dropdown.Item onClick={handleDropdownChange.bind(this, "frequently", "Annually")}>Annually</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col>
                            Default Quantity:
                            <Button>-</Button> 0 <Button>+</Button>
                            {/* <Form.Control type="text" name="defaultValue" value={props.item.values.defaultValue} onChange={handleTextChange} /> */}
                        </Col>
                        <Col>
                            <br />
                            <Form.Check
                                label="Additional Info"
                                name="additionalInfo"
                                checked={props.item.values.additionalInfo}
                                onChange={handleCheckChanged}
                            />
                        </Col>
                        <Col sm="auto">
                            <Button onClick={handleItemDelete}>
                                <i className="fa-solid fa-trash-can"></i>
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
        </div>
    );
}

export default WebPackageItemControl;
