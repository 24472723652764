import React from "react";
import { useParams } from "react-router-dom";

function PrintingProgress() {
    const params = useParams();

    return (
        <div>
            <h1>Printing Progress</h1>
            {params.id}
        </div>
    );
}

export default PrintingProgress;
