import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";

import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";
import * as GS_pageSettingsActions from "../../../../store/actions/globalSettings/GS_pageSettings";

//STYLES
import * as PageSettingsStyles from "../styles/pageSettings";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

import Pages_LayoutSlot from "./layoutSlot";
import Pages_PreLoadedTemplates from "./preLoadedTemplates";

import helpIcon from "../../../../public/images/help.png";

function Admin_Page_Settings() {
    const params = useParams();
    const dispatch = useDispatch();

    const [bootstrapModal, setBootstrapModal] = useState({
        open: false
    });

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [templateModal, setTemplateModal] = useState({
        open: false
    });

    function handleTemplateModalClose() {
        setTemplateModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const GS_pageSettings = useSelector((state) => state.GS_pageSettings);

    const [settings, setSettings] = useState({
        title: ""
    });

    function handleCloseBootstrapModal() {
        setBootstrapModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        getPageDetails();
    }, [params.id]);

    function getPageDetails() {
        const data = { pod: params.pod, id: params.id };

        Axios.post("/adminPods/pages/getPage", data)
            .then((res) => {
                setSettings((prevState) => {
                    return { ...prevState, title: res.data.page.title };
                });
                dispatch(GS_navSettingsActions.UpdateTitle(`Pages - ${res.data.page.title}`));
                dispatch(GS_navSettingsActions.UpdateSelected("Pages"));
                dispatch(GS_navSettingsActions.UpdateSubSelected(res.data.page.title));
                dispatch(GS_pageSettingsActions.AddLayoutFromDatabase(res.data.layout));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleOpenRowTemplates() {
        setTemplateModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleInsertNewRow() {
        dispatch(GS_pageSettingsActions.InsertNewLayoutRow());
    }

    function handleOpenBootstrapHelp() {
        setBootstrapModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    function handleSaveLayout() {
        const data = {
            pageID: params.id,
            pod: params.pod,
            layout: GS_pageSettings.layout,
            deletedColumns: GS_pageSettings.deletedColumns
        };

        Axios.post("/adminPods/pages/saveLayout", data)
            .then((res) => {
                const data = res.data;
                console.log(res.data);
                if (data.message == "Layout successfully saved") {
                    setModal({
                        error: false,
                        header: "Layout",
                        message: data.message,
                        open: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div style={PageSettingsStyles.body}>
            <div style={PageSettingsStyles.topText}>
                Each layout works on a gird system where you can add rows and columns to have you desired layout. Use the ‘Insert New Row’ to get
                started on your first row, or choose a pre-built row from the ‘Pre-Loaded Row Templates’ button. <br />
                On this website, all columns in a row will add up to the value of 12, as bootstrap gird system allows 12 columns across a page.
                <Image src={helpIcon} style={PageSettingsStyles.hepImage} onClick={handleOpenBootstrapHelp} />
            </div>
            <Row>
                <Col>
                    <Button onClick={handleInsertNewRow}>Insert New Row</Button>
                </Col>
                <Col style={PageSettingsStyles.preLoadedButton}>
                    <Button onClick={handleOpenRowTemplates}>Pre-Loaded Row Templates</Button>
                </Col>
            </Row>
            <Row style={PageSettingsStyles.templateContainer}>
                <Col>
                    {GS_pageSettings.layout.map((row, index) => {
                        return <Pages_LayoutSlot key={index} rowID={row.id} columns={row.columns} />;
                    })}
                </Col>
            </Row>
            <Row>
                <Col style={PageSettingsStyles.preLoadedButton}>
                    <Button onClick={handleSaveLayout}>Save</Button>
                </Col>
            </Row>

            {/* MODALS */}
            <Modal size="lg" show={templateModal.open} onHide={handleTemplateModalClose}>
                <Pages_PreLoadedTemplates closeBtn={handleTemplateModalClose} />
            </Modal>

            <Modal show={bootstrapModal.open} onHide={handleCloseBootstrapModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Bootstrap</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bootstrap is the most popular CSS Framework for developing responsive and mobile-first websites. <br /> <br />
                    Your website use this framework to get the best styling! <br />
                    <br />
                    <a href="https://www.w3schools.com/whatis/whatis_bootstrap.asp" target="_blank" rel="noreferrer">
                        More Information
                    </a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseBootstrapModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admin_Page_Settings;
