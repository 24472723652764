export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const columnRight = {
    textAlign: "right"
};

export const buttons = {
    textAlign: "center"
};
