import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";

//STYLES
import * as ImageContainerStyles from "../../styles/products/imageContainer";

function ImageContainer(props) {
    const [imageStyle, setImageStyle] = useState(ImageContainerStyles.body);

    useEffect(() => {
        if (props.main) {
            setImageStyle(ImageContainerStyles.main);
        }
        if (props.selected) {
            setImageStyle(ImageContainerStyles.selected);
        }
    }, []);

    return (
        <div style={imageStyle}>
            <Image style={ImageContainerStyles.image} src={`/content/${props.imagePath}`} onClick={props.controlsShow.bind(this, props.uuid)} />
        </div>
    );
}

export default ImageContainer;
