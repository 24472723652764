import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Dropdown, Button, Table, Modal } from "react-bootstrap";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

//COMPONENTS
import BasicDetails from "./basicDetails";
import ImagesAndModels from "./imagesAndModels";
import Personalisation from "./personalisation";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as NewProductStyles from "../../styles/products/newProduct";

function NewProduct(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [tab, setTab] = useState("Basic Details");
    const [triedToSubmit, setTriedToSubmit] = useState(false);

    const [basicDetails, setBasicDetails] = useState({
        values: {
            title: "",
            description: "",
            price: "",
            category: "Select Category",
            productCode: "",
            availableFrom: new Date(),
            active: false
        },
        previousProductCode: "",
        valids: {
            title: false,
            description: false,
            price: false,
            category: false
        },
        touched: {
            title: false,
            description: false,
            price: false
        },
        countersMax: {
            title: 50,
            description: 1000
        },
        sectionValid: false,
        categories: []
    });

    const [imagesAndModels, setImagesAndModels] = useState({
        values: {
            images: [],
            models: []
        },
        previousImages: [],
        valids: {
            images: false,
            models: false
        },
        sectionValid: false
    });

    const [personalisation, setPersonalisation] = useState({
        values: {
            coloursAvailableIn: [],
            coloursInStock: [],
            customFields: []
        },
        valids: {
            coloursAvailableIn: false
        },
        sectionValid: false,
        colours: []
    });

    const [formErrorMessage, setFormErrorMessage] = useState("error");

    const [goBackModal, setGoBackModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleCloseGoBackModal() {
        setGoBackModal((prevState) => {
            return { ...prevState, open: false };
        });
        navigate(-1);
    }

    useEffect(() => {
        if (props.edit) {
            dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Edit Product"));
        } else {
            dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - New Product"));
        }
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        if (props.edit) {
            loadCategoriesAndProduct();
        } else {
            loadCategories();
        }
    }, []);

    function fetchErrorMessage(basDet, imaAnMod, personal) {
        let errorMessage = "";
        const values = {
            basicDetails: basDet == null ? basicDetails.sectionValid : basDet,
            imagesAndModels: imaAnMod == null ? imagesAndModels.sectionValid : imaAnMod,
            personalisation: personal == null ? personalisation.sectionValid : personal
        };
        if (!values.basicDetails) {
            errorMessage = `Basic Details`;
        }
        if (!values.imagesAndModels) {
            if (errorMessage == null) {
                errorMessage = "Images and Modals";
            } else {
                errorMessage = `${errorMessage}, Images and Modals`;
            }
        }
        if (!values.personalisation) {
            if (errorMessage == null) {
                errorMessage = "Personalisation";
            } else {
                errorMessage = `${errorMessage}, Personalisation`;
            }
        }

        setFormErrorMessage(`There are errors on the following sections: ${errorMessage}`);
    }

    function loadCategories() {
        Axios.post("/pods/3dPrintingShop/getCategories")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setBasicDetails((prevState) => {
                        return { ...prevState, categories: data.categories };
                    });
                    setPersonalisation((prevState) => {
                        return { ...prevState, colours: data.colours };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function loadCategoriesAndProduct() {
        const data = { uuid: params.id };
        Axios.post("/pods/3dPrintingShop/getCategoriesAndOneProduct", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    //Basic Details
                    const categoryCode = data.product.productCode.split("-")[0];
                    const categoryName = data.categories.find((category) => category.code == categoryCode).name;

                    const availableFrom = data.product.availableFrom.split("/");

                    const basicDetailsValues = {
                        title: data.product.title,
                        description: data.product.description,
                        price: (parseInt(data.product.price) / 100).toFixed(2),
                        category: categoryName,
                        productCode: data.product.productCode,
                        availableFrom: Date.parse(`${availableFrom[1]}/${availableFrom[0]}/${availableFrom[2]}`),
                        active: data.product.active == "true"
                    };

                    let titleIsValid = false;
                    let descriptionIsValid = false;
                    let priceIsValid = false;
                    let categoryIsValid = false;

                    if (basicDetailsValues.title != "") {
                        titleIsValid = true;
                    }
                    if (basicDetailsValues.description != "") {
                        descriptionIsValid = true;
                    }
                    if (basicDetailsValues.price != "") {
                        priceIsValid = true;
                    }
                    if (basicDetailsValues.category != "Select Category") {
                        categoryIsValid = true;
                    }

                    const basicDetailsValids = {
                        title: titleIsValid,
                        description: descriptionIsValid,
                        price: priceIsValid,
                        category: categoryIsValid
                    };

                    const basicDetailsAllTrue = Object.keys(basicDetailsValids).every(function (k) {
                        return basicDetailsValids[k] === true;
                    });

                    setBasicDetails((prevState) => {
                        return {
                            ...prevState,
                            values: basicDetailsValues,
                            valids: basicDetailsValids,
                            categories: data.categories,
                            previousProductCode: basicDetailsValues.productCode,
                            sectionValid: basicDetailsAllTrue
                        };
                    });

                    //Images and Models
                    const imagesAndModelsValues = {
                        images: data.images,
                        models: data.models
                    };

                    let imagesIsValid = false;
                    let modelsIsValid = false;

                    if (data.images.length > 0) {
                        imagesIsValid = true;
                    }

                    if (data.models.length > 0) {
                        modelsIsValid = true;
                    }

                    const imagesAndModelsValids = {
                        images: imagesIsValid,
                        models: modelsIsValid
                    };

                    const imagesAndModelsAllTrue = Object.keys(imagesAndModelsValids).every(function (k) {
                        return imagesAndModelsValids[k] === true;
                    });

                    setImagesAndModels((prevState) => {
                        return {
                            ...prevState,
                            values: imagesAndModelsValues,
                            previousImages: data.images,
                            valids: imagesAndModelsValids,
                            sectionValid: imagesAndModelsAllTrue
                        };
                    });

                    //Personalisation

                    const personalisationValues = {
                        coloursAvailableIn: data.product.coloursAvailableIn.split(","),
                        coloursInStock: data.product.coloursInStock.split(","),
                        customFields: data.customFields
                    };

                    let coloursIsValid = false;

                    if (data.product.coloursAvailableIn != "") {
                        coloursIsValid = true;
                    }

                    const personalisationValids = {
                        coloursAvailableIn: coloursIsValid
                    };

                    const personalisationAllTrue = Object.keys(personalisationValids).every(function (k) {
                        return personalisationValids[k] === true;
                    });

                    setPersonalisation((prevState) => {
                        return {
                            ...prevState,
                            values: personalisationValues,
                            valids: personalisationValids,
                            sectionValid: personalisationAllTrue,
                            colours: data.colours
                        };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function switchAllTouches() {
        const newBDTouches = Object.assign(...Object.keys(basicDetails.touched).map((k) => ({ [k]: true })));

        setBasicDetails((prevState) => {
            return { ...prevState, touched: newBDTouches };
        });
    }

    function handleOnSave() {
        if (!basicDetails.sectionValid || !imagesAndModels.sectionValid || !personalisation.sectionValid) {
            switchAllTouches();
            fetchErrorMessage(null, null, null);
            setTriedToSubmit(true);
        } else {
            const data = {
                title: basicDetails.values.title,
                description: basicDetails.values.description,
                price: parseInt(parseFloat(basicDetails.values.price) * 100),
                productCode: basicDetails.values.productCode,
                availableFrom: moment(basicDetails.values.availableFrom).format("DD/MM/YYYY"),
                active: basicDetails.values.active.toString(),
                images: imagesAndModels.values.images,
                models: imagesAndModels.values.models,
                coloursInStock: personalisation.values.coloursInStock.toString(),
                coloursAvailableIn: personalisation.values.coloursAvailableIn.toString(),
                customFields: personalisation.values.customFields
            };
            Axios.post("/pods/3dPrintingShop/insertProduct", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setGoBackModal({ header: "Insert Product", message: "Product inserted successfully!", open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnUpdate() {
        if (!basicDetails.sectionValid || !imagesAndModels.sectionValid || !personalisation.sectionValid) {
            switchAllTouches();
            setTriedToSubmit(true);
        } else {
            const previousValues = {
                productCode: basicDetails.previousProductCode,
                images: imagesAndModels.previousImages
            };

            const data = {
                uuid: params.id,
                title: basicDetails.values.title,
                description: basicDetails.values.description,
                price: parseInt(parseFloat(basicDetails.values.price) * 100),
                productCode: basicDetails.values.productCode,
                availableFrom: moment(basicDetails.values.availableFrom).format("DD/MM/YYYY"),
                active: basicDetails.values.active.toString(),
                images: imagesAndModels.values.images,
                models: imagesAndModels.values.models,
                coloursAvailableIn: personalisation.values.coloursAvailableIn.toString(),
                coloursInStock: personalisation.values.coloursInStock.toString(),
                customFields: personalisation.values.customFields,
                previousValues
            };
            console.log();
            Axios.post("/pods/3dPrintingShop/updateProduct", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setBasicDetails((prevState) => {
                            return { ...prevState, previousProductCode: basicDetails.values.productCode };
                        });

                        const imagesAndModelsValues = {
                            images: data.images,
                            models: data.models
                        };
                        setImagesAndModels((prevState) => {
                            return { ...prevState, values: imagesAndModelsValues, previousImages: data.images };
                        });

                        setGoBackModal({ header: "Update Product", message: "Product updated successfully!", open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function selecteTab(tab) {
        setTab(tab);
    }

    return (
        <div style={NewProductStyles.body}>
            <Row>
                <Col
                    style={tab == "Basic Details" ? NewProductStyles.selected : NewProductStyles.tab}
                    onClick={selecteTab.bind(this, "Basic Details")}
                >
                    <div style={triedToSubmit && !basicDetails.sectionValid ? NewProductStyles.tabErrorText : NewProductStyles.tabText}>
                        Basic Details
                    </div>
                </Col>
                <Col
                    style={tab == "Images and Models" ? NewProductStyles.selected : NewProductStyles.tab}
                    onClick={selecteTab.bind(this, "Images and Models")}
                >
                    <div style={triedToSubmit && !imagesAndModels.sectionValid ? NewProductStyles.tabErrorText : NewProductStyles.tabText}>
                        Images and Models
                    </div>
                </Col>
                <Col
                    style={tab == "Personalisation" ? NewProductStyles.selected : NewProductStyles.tab}
                    onClick={selecteTab.bind(this, "Personalisation")}
                >
                    <div style={triedToSubmit && !personalisation.sectionValid ? NewProductStyles.tabErrorText : NewProductStyles.tabText}>
                        Personalisation
                    </div>
                </Col>
            </Row>
            <br />
            <div style={basicDetails.values.active ? NewProductStyles.hidden : NewProductStyles.activeStatus}>
                <h5>This product is not active</h5>
            </div>

            {tab == "Basic Details" && (
                <BasicDetails
                    data={basicDetails}
                    setData={setBasicDetails}
                    triedToSubmit={triedToSubmit}
                    edit={props.edit}
                    personalisation={personalisation}
                    validateMainErrorMessage={fetchErrorMessage}
                />
            )}
            {tab == "Images and Models" && (
                <ImagesAndModels
                    data={imagesAndModels}
                    setData={setImagesAndModels}
                    triedToSubmit={triedToSubmit}
                    validateMainErrorMessage={fetchErrorMessage}
                />
            )}
            {tab == "Personalisation" && (
                <Personalisation
                    data={personalisation}
                    setData={setPersonalisation}
                    triedToSubmit={triedToSubmit}
                    basicDetails={basicDetails}
                    setBasicDetails={setBasicDetails}
                    validateMainErrorMessage={fetchErrorMessage}
                />
            )}
            <br />
            <Row>
                <Col>
                    <div style={NewProductStyles.saveBtn}>
                        {props.edit ? <Button onClick={handleOnUpdate}>Update</Button> : <Button onClick={handleOnSave}>Save</Button>}
                    </div>
                    <div style={NewProductStyles.errorText}>
                        {triedToSubmit && (
                            <div>
                                {!basicDetails.sectionValid || !imagesAndModels.sectionValid || !personalisation.sectionValid ? (
                                    <div>{formErrorMessage}</div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </Col>
                {/* <Col style={NewProductStyles.topBorder}></Col>
                <Col style={NewProductStyles.topBorder}>
                    <Row>
                        <Col style={NewProductStyles.errorText} sm={9}>
                            {formErrorMessage}
                        </Col>
                        <Col style={NewProductStyles.saveBtn}>
                            
                        </Col>
                    </Row>
                </Col> */}
            </Row>
            <Modal show={goBackModal.open} onHide={handleCloseGoBackModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{goBackModal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{goBackModal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseGoBackModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewProduct;
