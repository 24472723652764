import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Row, Col, Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as PostageShippingStyles from "../styles/postageShipping";

function PostageShipping() {
    const dispatch = useDispatch();
    const [postage, setPostage] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Postage & Shipping"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadPostage();
    }, []);

    const postageModalDefaultValues = {
        open: false,
        values: {
            name: "",
            price: "",
            defaultValue: false,
            defaultOverride: false,
            active: true
        },
        valids: {
            name: false,
            price: false
        },
        touched: {
            name: false,
            price: false
        },
        wholeForm: false,
        edit: false,
        editableID: ""
    };

    const modalDefaultValues = {
        open: "",
        header: "",
        message: "",
        showChange: false
    };

    const [postageModal, setPostageModal] = useState(postageModalDefaultValues);

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        deleteID: ""
    });

    function handleDeleteModalClose() {
        setDeleteModal({ open: false, deleteID: "" });
    }

    function handleClosePostageModal() {
        setPostageModal(postageModalDefaultValues);
    }

    const [modal, setModal] = useState(postageModalDefaultValues);

    function handleCloseModal() {
        setModal(modalDefaultValues);
    }

    function handleOpenPostageModal() {
        const values = { ...postageModal.values };

        if (postage.length == 0) {
            values.defaultValue = true;
        }
        setPostageModal((prevState) => {
            return { ...prevState, open: true, values };
        });
    }

    function loadPostage() {
        Axios.post("/pods/3dPrintingShop/getAllPostage")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPostage(data.postage);
                }
            })
            .catch((err) => console.log(err));
    }

    function numbersDecKeyPress(event) {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function handleControlTouched(event) {
        const { name } = event.target;

        const touched = { ...postageModal.touched };

        touched[name] = true;

        setPostageModal((prevState) => {
            return { ...prevState, touched };
        });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = { ...postageModal.values };
        const valids = { ...postageModal.valids };

        values[name] = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;

        let wholeForm = false;
        if (valids.name && valids.price) {
            wholeForm = true;
        }

        setPostageModal((prevState) => {
            return { ...prevState, values, valids, wholeForm };
        });
    }

    function handleRadioOnChange() {
        if (postage.length == 0) {
            setModal({ header: "Default", message: "There needs to be at least one postage that is a default", open: true });
        } else {
            let hitDefault = false;
            for (const post of postage) {
                if (post.defaultValue) {
                    hitDefault = true;
                    break;
                }
            }

            if (hitDefault) {
                setModal({
                    header: "Default",
                    message: "There is already a default postage, do you want to change the default to this postage?",
                    showChange: true,
                    open: true
                });
            }
        }
    }

    function handleCheckOnChange(event) {
        console.log(postageModal);
        if (postageModal.values.defaultValue) {
            setModal({
                header: "Active",
                message: "You can't de-active this postage as it's the default, please select another postage as the default first",
                open: true
            });
        } else {
            const { checked } = event.target;

            const values = { ...postageModal.values };

            values.active = checked;

            setPostageModal((prevState) => {
                return { ...prevState, values };
            });
        }
    }

    function handleChangeDefault() {
        const values = { ...postageModal.values };

        values.defaultValue = true;
        values.defaultOverride = true;
        values.active = true;

        setPostageModal((prevState) => {
            return { ...prevState, values };
        });

        setModal(modalDefaultValues);
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(postageModal.valids).map((k) => ({ [k]: true })));
        setPostageModal((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleModalOnSubmit() {
        if (!postageModal.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                name: postageModal.values.name,
                price: postageModal.values.price * 100,
                defaultValue: postageModal.values.defaultValue,
                defaultOverride: postageModal.values.defaultOverride,
                active: postageModal.values.active
            };

            Axios.post("/pods/3dPrintingShop/addPostage", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        loadPostage();
                        setPostageModal(postageModalDefaultValues);
                    } else {
                        setModal({ header: "Error!", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleModalOnUpdate() {
        if (!postageModal.wholeForm) {
            switchAllTouches();
        } else {
            const data = {
                uuid: postageModal.editableID,
                name: postageModal.values.name,
                price: postageModal.values.price * 100,
                defaultValue: postageModal.values.defaultValue,
                defaultOverride: postageModal.values.defaultOverride,
                active: postageModal.values.active
            };
            Axios.post("/pods/3dPrintingShop/updatePostage", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        loadPostage();
                        setPostageModal(postageModalDefaultValues);
                    } else {
                        setModal({ header: "Error!", message: data.message, open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleEditClick(index) {
        const values = { ...postageModal.values };

        values.name = postage[index].name;
        values.price = (postage[index].price / 100).toFixed(2);
        values.defaultValue = postage[index].defaultValue;
        values.active = postage[index].active;

        const valids = {
            name: true,
            price: true
        };

        setPostageModal((prevState) => {
            return { ...prevState, open: true, edit: true, editableID: postage[index].uuid, values, valids, wholeForm: true };
        });
    }

    function handleDeleteYNOpen(index) {
        if (postage[index].defaultValue) {
            setModal({
                header: "Default",
                message: "You can't delete the default postage, please select another postage to be default first",
                open: true
            });
        } else {
            setDeleteModal({ deleteID: postage[index].uuid, open: true });
        }
    }

    function handleDeleteYesClick() {
        const data = { uuid: deleteModal.deleteID };

        Axios.post("/pods/3dPrintingShop/deletePostage", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDeleteModal({ open: false, deleteID: "" });
                    loadPostage();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={PostageShippingStyles.body}>
            <Row>
                <Col style={PostageShippingStyles.newPostageButton}>
                    <Button onClick={handleOpenPostageModal}>New Postage</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Card style={PostageShippingStyles.cardHeader}>
                        <Card.Body>
                            <Row>
                                <Col sm={4}>Postage</Col>
                                <Col sm={2}>Price</Col>
                                <Col sm={2}>Default</Col>
                                <Col sm={2}></Col>
                                <Col sm={2}></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    {postage.map((post, index) => {
                        return (
                            <div key={index}>
                                <Card style={post.active ? PostageShippingStyles.cardStyle : PostageShippingStyles.cardNotActiveStyle}>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={4} style={PostageShippingStyles.columnStyle}>
                                                {post.name}
                                            </Col>
                                            <Col sm={2} style={PostageShippingStyles.columnStyle}>
                                                £{(post.price / 100).toFixed(2)}
                                            </Col>
                                            <Col sm={2}>{post.defaultValue && "Yes"}</Col>
                                            <Col sm={2} style={PostageShippingStyles.buttonColStyle}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                    <Button variant="warning" onClick={handleEditClick.bind(this, index)}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm={2} style={PostageShippingStyles.buttonColStyle}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                    <Button variant="danger" onClick={handleDeleteYNOpen.bind(this, index)}>
                                                        <i className="fa-solid fa-trash-can"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <Modal show={postageModal.open} onHide={handleClosePostageModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{postageModal.edit ? "Modify Postage" : "Add Postage"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={postageModal.touched.name && !postageModal.valids.name ? true : false}
                            type="text"
                            name="name"
                            value={postageModal.values.name}
                            onChange={handleTextChange}
                            onBlur={handleControlTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Form.Label>Amount:</Form.Label>
                        <Form.Control
                            isInvalid={postageModal.touched.price && !postageModal.valids.price ? true : false}
                            type="text"
                            name="price"
                            value={postageModal.values.price}
                            onChange={handleTextChange}
                            onKeyPress={numbersDecKeyPress}
                            onBlur={handleControlTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter an amount</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Check label="Default" type="radio" checked={postageModal.values.defaultValue} onChange={handleRadioOnChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Check label="Active" checked={postageModal.values.active} onChange={handleCheckOnChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {postageModal.edit ? <Button onClick={handleModalOnUpdate}>Update</Button> : <Button onClick={handleModalOnSubmit}>Add</Button>}
                    <Button onClick={handleClosePostageModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    {modal.showChange && <Button onClick={handleChangeDefault}>Change</Button>}
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteModal.open} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Colour?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this colour?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDeleteYesClick}>Yes</Button>
                    <Button onClick={handleDeleteModalClose}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PostageShipping;
