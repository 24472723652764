import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

function Controller() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("App Request Quote"));
    dispatch(GS_navSettingsActions.UpdateSelected("Features"));
    dispatch(GS_navSettingsActions.UpdateSubSelected("App Request Quote"));

    Axios.post("/pods/appRequestQuote/controller/getRequests")
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (data.error == "null") {
          setQuotes(data.quotes);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleEditItemsClick() {
    navigate("./editItems");
  }

  function handleQuoteDoubleClick(uuid) {
    console.log(uuid);
    navigate(`./quote/${uuid}`);
  }

  return (
    <div style={ControllerStyles.body}>
      <Card style={ControllerStyles.cardStyleHeadings}>
        <Card.Body>
          <Row>
            <Col sm={3}>Name</Col>
            <Col sm={3}>Email</Col>
            <Col sm={2}>Status</Col>
            <Col sm={2}>Min Quote</Col>
            <Col sm={2}>Max Quote</Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div style={ControllerStyles.scrollableBox}>
        {quotes.map((quote, index) => {
          return (
            <div key={index}>
              <Card
                style={ControllerStyles.cardStyle}
                onDoubleClick={handleQuoteDoubleClick.bind(this, quote.uuid)}
              >
                <Card.Body>
                  <Row>
                    <Col sm={3}>{quote.user.fullName}</Col>
                    <Col sm={3}>{quote.user.email}</Col>
                    <Col sm={2}>{quote.status}</Col>
                    <Col sm={2}>
                      {quote.minQuote != "" ? `£${quote.minQuote}` : ""}
                    </Col>
                    <Col sm={2}>
                      {quote.maxQuote != "" ? `£${quote.maxQuote}` : ""}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Controller;
