import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { Row, Col, Button } from "react-bootstrap";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as OrderDetailsStyles from "../../styles/order/orderDetails";

//COMPONENTS
import OrderDetailsItem from "./orderDetailsItem";

function OrderDetails() {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [details, setDetails] = useState({});
    const [items, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Order Details"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadData();
    }, []);

    function loadData() {
        const data = { id: params.id };
        Axios.post("/pods/3dPrintingShop/orders/getOrderDetails", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setDetails(data.details);
                    setItems(data.items);
                    setLoaded(true);
                }
            })
            .catch((err) => console.log(err));
    }

    function handlePrintingProgressClick() {
        navigate("./printingProgress");
    }

    return (
        <div style={OrderDetailsStyles.body}>
            {loaded && (
                <div>
                    <Row>
                        <Col>
                            <Row>
                                <Col style={OrderDetailsStyles.columnRight}>
                                    <strong>Order ID:</strong> {details.id}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Customer:</strong> {details.userDetails.fullName} <br />
                                    <strong>Email: </strong> {details.userDetails.email} <br />
                                    <strong>Telephone: </strong> {details.userDetails.tel} <br /> <br />
                                    <strong>Address: </strong> <br />
                                    {details.userDetails.addressLine1} <br />
                                    {details.userDetails.addressLine2} <br />
                                    {details.userDetails.town} <br />
                                    {details.userDetails.postcode} <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Row>
                                        <Col style={OrderDetailsStyles.columnRight}>
                                            <strong>Postage:</strong>
                                        </Col>
                                        <Col>
                                            {details.postageName} (£{(details.postageWhenOrdered / 100).toFixed(2)})
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={OrderDetailsStyles.columnRight}>
                                            <strong>Total:</strong>
                                        </Col>
                                        <Col>£{(details.total / 100).toFixed(2)}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Items:</strong>
                                    <br /> <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {items.map((item, index) => {
                                        return <OrderDetailsItem key={index} item={item} />;
                                    })}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} style={OrderDetailsStyles.buttons}>
                            <Button onClick={handlePrintingProgressClick}>Printing Progress</Button>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default OrderDetails;
