import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ProductsStyles from "../styles/products";

function Products() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Products"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadProducts();
    }, []);

    function loadProducts() {
        Axios.post("/pods/3dPrintingShop/getProducts")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setProducts(data.products);
                }
            })
            .catch((err) => console.log(err));
    }

    function navigateNewItem() {
        navigate("./newProduct");
    }

    function navigateEditItem(id) {
        navigate(`./editProduct/${id}`);
    }

    return (
        <div style={ProductsStyles.body}>
            <Row>
                <Col style={ProductsStyles.newItemBtn}>
                    <Button onClick={navigateNewItem}>New Product</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Card style={ProductsStyles.cardHeader}>
                        <Card.Body>
                            <Row>
                                <Col>Item</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col style={ProductsStyles.scrollView}>
                    {products.map((product, index) => {
                        return (
                            <div key={index}>
                                <Card
                                    style={product.active == "true" ? ProductsStyles.cardStyle : ProductsStyles.cardStyleNotActive}
                                    onClick={navigateEditItem.bind(this, product.uuid)}
                                >
                                    <Card.Body>
                                        <Row>
                                            <Col>{product.title}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
}

export default Products;
