import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

//STYLES
import * as NewTaskStyles from "../styles/newTask";

function NewTask(props) {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        setFormData(defaultValues);
    }, [props.open]);

    const defaultValues = {
        title: "",
        description: "",
        titleMax: 100,
        titleLength: 100,
        descriptionMax: 200,
        descriptionLength: 200,
        titleError: false
    };

    const [formData, setFormData] = useState(defaultValues);

    function handleTextChange(event) {
        const { name, value } = event.target;

        let newLength = formData[`${name}Max`] - value.length;

        setFormData((prevState) => {
            return { ...prevState, [name]: value, [`${name}Length`]: newLength };
        });
    }

    function handleCreateNewTask() {
        if (formData.title == "") {
            setFormData((prevState) => {
                return { ...prevState, titleError: true };
            });
        } else {
            const data = { planUUID: props.planUUID, title: formData.title, description: formData.description, author: user.id };
            Axios.post("/pods/plannerTasks/createTask", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        props.loadPlan();
                        props.close();
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                            isInvalid={formData.titleError}
                            type="text"
                            name="title"
                            maxLength={formData.titleMax}
                            value={formData.title}
                            onChange={handleTextChange}
                        />
                        <div style={NewTaskStyles.descriptionCount}>
                            {formData.titleLength}/{formData.titleMax}
                        </div>
                        <Form.Control.Feedback type="invalid">Please enter a title!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            maxLength={formData.descriptionMax}
                            value={formData.description}
                            onChange={handleTextChange}
                        />
                        <div style={NewTaskStyles.descriptionCount}>
                            {formData.descriptionLength}/{formData.descriptionMax}
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCreateNewTask}>Create</Button>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewTask;
