import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

//COMPONENTS
import EnivronmentModal from "./environmentModal";

function Controller() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [envModal, setEnvModal] = useState({
        open: false,
        edit: false,
        uuid: 0
    });
    const [environments, setEnvironments] = useState([]);

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        uuid: 0
    });

    function handleDeleteModalClose() {
        setDeleteModal((prevState) => {
            return { ...prevState, open: false, uuid: 0 };
        });
    }

    function handleEnvironmentModalOpen() {
        setEnvModal({ open: true, edit: false, uuid: 0 });
    }

    function handleEnvironmentModalClose() {
        setEnvModal({ open: false, edit: false, uuid: 0 });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("App Service"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("App Service"));

        loadEnvironments();
    }, []);

    function loadEnvironments() {
        Axios.post("/pods/appService/environments/getAllEnvironments")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setEnvironments(data.environments);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleEnvironmentEditClick(uuid) {
        setEnvModal({ open: true, edit: true, uuid: uuid });
    }

    function handleDeleteClick(uuid) {
        setDeleteModal((prevState) => {
            return { ...prevState, open: true, uuid: uuid };
        });
    }

    function acceptDeleteClick() {
        const data = { uuid: deleteModal.uuid };
        Axios.post("/pods/appService/environments/deleteEnvironmentHolder", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadEnvironments();
                    setDeleteModal((prevState) => {
                        return { ...prevState, open: false, uuid: 0 };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleVersionsClick() {
        navigate("./versions");
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col>
                    <Button onClick={handleEnvironmentModalOpen}>New Environment</Button>
                </Col>
                <Col style={ControllerStyles.newEnvironmentBtn}>
                    <Button onClick={handleVersionsClick}>Versions</Button>
                </Col>
            </Row>
            <br />
            {environments.map((environment, index) => {
                return (
                    <div key={index}>
                        <Card style={ControllerStyles.cardStyle}>
                            <Card.Body>
                                <Row>
                                    <Col sm={5} style={ControllerStyles.columnStyle}>
                                        <strong>Environment:</strong> {environment.environment}
                                    </Col>
                                    <Col sm={5} style={ControllerStyles.columnStyle}>
                                        <strong>Admin Code:</strong> {environment.adminCode}
                                    </Col>
                                    <Col sm={1}>
                                        <Button variant="warning" onClick={handleEnvironmentEditClick.bind(this, environment.uuid)}>
                                            <i className="fa-solid fa-pen-to-square"></i>
                                        </Button>
                                    </Col>
                                    <Col sm={1}>
                                        <Button variant="danger" onClick={handleDeleteClick.bind(this, environment.uuid)}>
                                            <i className="fa-solid fa-trash-can"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}
            <EnivronmentModal
                show={envModal.open}
                edit={envModal.edit}
                uuid={envModal.uuid}
                onClose={handleEnvironmentModalClose}
                reload={loadEnvironments}
            />
            <Modal show={deleteModal.open} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Environment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this environment?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={acceptDeleteClick}>Yes</Button>
                    <Button onClick={handleDeleteModalClose}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Controller;
