import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

//COMPONETS
import MainMenu from "./files//mainMenu";
import Categories from "./files/categories";
import Colours from "./files/colours";
import Products from "./files/products";
import NewProduct from "./files/products/newProduct";
import PostageShipping from "./files/postageShipping";
import Orders from "./files/orders";
import OrderDetails from "./files/order/orderDetails";
import PrintingProgress from "./files/order/printingProgress";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        console.log("hit");
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path={"/"} element={<MainMenu />} />
                        <Route path={"/categories"} element={<Categories />} />
                        <Route path={"/colours"} element={<Colours />} />
                        <Route path={"/post_ship"} element={<PostageShipping />} />
                        <Route path={"/products"} element={<Products />} />
                        <Route path={"/products/newProduct"} element={<NewProduct edit={false} />} />
                        <Route path={"/products/editProduct/:id"} element={<NewProduct edit={true} />} />
                        <Route path={"/orders"} element={<Orders />} />
                        <Route path={"/orderDetails/:id"} element={<OrderDetails />} />
                        <Route path={"/orderDetails/:id/printingProgress"} element={<PrintingProgress />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
