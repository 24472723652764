import * as Colors from "../../../../../environment/globalStyles/environmentColors";

export const loginBtns = {
    color: Colors.accent,
    fontWeight: "bold"
};

export const FP_InputError = {
    color: "yellow",
    textAlign: "right",
    fontWeight: "bold"
};
