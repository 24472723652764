import * as Colors from "../../../../globalStyles/environmentColors";

export const topButtons = {
    height: "40vh",
    lineHeight: "40vh",
    color: "yellow",
    fontWeight: "bold",
    fontSize: "1.5rem",
    cursor: "pointer"
};

export const topButtonsText = {
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "30px" /* <-- adjust this */
};

export const callOutButton = {
    color: "yellow",
    fontWeight: "bold",
    fontSize: "1.5rem",
    cursor: "pointer"
};

export const mobile_body = {
    padding: "10px"
};

export const mobile_cardStyle = {
    backgroundColor: Colors.primary,
    color: "yellow",
    fontWeight: "bold",
    borderRadius: "25px",
    margin: "auto",
    textAlign: "center"
};
