//MOBILE VIEW NEEDED

import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import CenterContainer from "../../../special/wholeSite/centerContainer";
import Axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import * as CallOutStyles from "../styles/callOut";

function CallOut() {
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    const defaultValues = {
        values: {
            fullName: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            postcode: "",
            tel: "",
            issue: ""
        },
        valids: {
            fullName: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false,
            issue: false
        },
        touched: {
            fullName: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false,
            issue: false
        },
        changed: false,
        wholeForm: false
    };

    const [formData, setFormData] = useState(defaultValues);

    const [person, setPerson] = useState("yourself");
    const [completed, setCompleted] = useState(false);

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalAction, setModalAction] = useState({
        header: "",
        open: false,
        message: "",
        acceptName: "",
        acceptFunction: ""
    });

    function handleCloseModalAction() {
        setModalAction((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelFunction: "",
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        handleYourselfButton();
    }, []);

    function handleUpdateTouched(event) {
        const { name } = event.target;

        const updatedTouches = { ...formData.touched, [name]: true };

        setFormData((prevState) => {
            return { ...prevState, touched: updatedTouches };
        });
    }

    function handleUpdateTouched_Email(event) {
        const { name, value } = event.target;

        const updatedTouches = { ...formData.touched, [name]: true };

        setFormData((prevState) => {
            return { ...prevState, touched: updatedTouches };
        });

        if (value == user.email) {
            setModal({
                header: "Request A Call Out",
                message: "You have entered the email associaed with this account, you have been swtiched back to 'For Yourself' tab",
                open: true
            });
            handleYourselfButton();
        }
    }

    function handleOnChange(event) {
        const { name, value } = event.target;

        const values = formData.values;
        const valids = formData.valids;

        let isValid = true;

        if (value.length === 0) {
            isValid = false;
        }

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            isValid = false;
        }

        if (name === "tel" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(value)) {
            isValid = false;
        }

        values[name] = value;
        valids[name] = isValid;

        const areTrue = Object.values(valids).every((value) => value == true);

        setFormData((prevState) => {
            return { ...prevState, values: values, valids: valids, wholeForm: areTrue };
        });
    }

    function handleOnChange_Yourself(event) {
        const { name, value } = event.target;

        const values = formData.values;
        const valids = formData.valids;

        let isValid = true;

        if (value.length === 0) {
            isValid = false;
        }

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            isValid = false;
        }

        if (name === "tel" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(value)) {
            isValid = false;
        }

        values[name] = value;
        valids[name] = isValid;

        const areTrue = Object.values(valids).every((value) => value == true);

        setFormData((prevState) => {
            return { ...prevState, values: values, valids: valids, wholeForm: areTrue, changed: true };
        });
    }

    function handleYourselfButton() {
        setPerson("yourself");

        if (user.auth) {
            const data = { userID: user.id };
            Axios.post("/pods/viewHelp/callOut/getUserInfo", data)
                .then((res) => {
                    const data = res.data;
                    const newValues = { ...formData.values, ...data.userDetails };

                    validateData(newValues);

                    setFormData((prevState) => {
                        return { ...prevState, values: newValues };
                    });
                })
                .catch((err) => console.log(err));
        }
    }

    function handleSomeoneElseButton() {
        setPerson("someoneElse");
        setFormData(defaultValues);
    }

    function validateData(newValues) {
        const fields = Object.keys(newValues);

        const valids = formData.valids;

        for (const field of fields) {
            let isValid = true;

            if (newValues[field].length == 0) {
                isValid = false;
            }

            if (field === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(newValues[field])) {
                isValid = false;
            }

            if (field === "tel" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(newValues[field])) {
                isValid = false;
            }

            valids[field] = isValid;
        }
        console.log(valids);
        const areTrue = Object.values(valids).every((value) => value == true);

        setFormData((prevState) => {
            return { ...prevState, valids: valids, wholeForm: areTrue };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formData.touched).map((k) => ({ [k]: true })));
        setFormData((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleCallOutButton() {
        if (!formData.wholeForm) {
            switchAllTouches();
        } else {
            if (person == "yourself") {
                if (formData.changed) {
                    setModalYN({
                        open: true,
                        heading: "Request A Call Out",
                        message: "You have changed some details, do you want to update your profile with the new details?",
                        acceptFunction: handleSubmitCallOut_Yourself.bind(this, "Yes"),
                        acceptName: "Yes",
                        showAccept: true,
                        cancelFunction: handleSubmitCallOut_Yourself.bind(this, "No"),
                        cancelName: "No",
                        showCancel: true
                    });
                } else {
                    handleSubmitCallOut_Yourself("No");
                }
            } else {
                const data = {
                    name: formData.values.fullName,
                    email: formData.values.email,
                    addressLine1: formData.values.addressLine1,
                    addressLine2: formData.values.addressLine2,
                    town: formData.values.town,
                    postcode: formData.values.postcode,
                    tel: formData.values.tel,
                    issue: formData.values.issue
                };

                Axios.post("/pods/viewHelp/callOut/submitCallOut/checkEmail", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "null") {
                            if (data.userError == "Yes") {
                                setModalAction({
                                    header: "Request A Call Out",
                                    open: true,
                                    message:
                                        "The email is associated to another account, the details on this form cannot be updated on the associated account.",
                                    acceptName: "Ok",
                                    acceptFunction: modelActionAccept
                                });
                            } else {
                                setCompleted(true);
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }

    function handleSubmitCallOut_Yourself(updateDetails) {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });

        const data = {
            userID: user.id,
            addressLine1: formData.values.addressLine1,
            addressLine2: formData.values.addressLine2,
            town: formData.values.town,
            postcode: formData.values.postcode,
            tel: formData.values.tel,
            issue: formData.values.issue,
            update: updateDetails
        };

        Axios.post("/pods/viewHelp/callOut/submitCallOut/Yourself", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCompleted(true);
                }
            })
            .catch((err) => console.log(err));
    }

    function modelActionAccept() {
        setModalAction((prevState) => {
            return { ...prevState, open: false };
        });

        const data = {
            name: formData.values.fullName,
            email: formData.values.email,
            addressLine1: formData.values.addressLine1,
            addressLine2: formData.values.addressLine2,
            town: formData.values.town,
            postcode: formData.values.postcode,
            tel: formData.values.tel,
            issue: formData.values.issue
        };

        Axios.post("/pods/viewHelp/callOut/submitCallOut/someoneElse", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setCompleted(true);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleGoBack() {
        navigate(-1);
    }

    return (
        <div>
            <CenterContainer>
                <h1>Request A Call Out</h1> <br />
                <h2>If you have an issue with your IT, you can call High-View Studios out and High-View Studios will come to you</h2>
                <br />
                {completed ? (
                    <div>
                        <h3>Your call out request has been sent.</h3>
                        <h3>Your response could take up to 24 hours</h3>
                        <Button variant="warning" style={CallOutStyles.buttons} onClick={handleGoBack}>
                            Go Back
                        </Button>
                    </div>
                ) : (
                    <div>
                        <p style={CallOutStyles.topText}>Call Outs are only available in North-East Wales and Chester at this present time!</p>
                        <p style={CallOutStyles.topText}>
                            Call Outs are not available until 1.30pm on week days, please wait until then for a response
                        </p>
                        <Button
                            style={person == "yourself" ? CallOutStyles.selectedButtons : CallOutStyles.buttons}
                            variant={person == "yourself" ? "danger" : "warning"}
                            onClick={handleYourselfButton}
                        >
                            For yourself
                        </Button>
                        <Button
                            style={person == "someoneElse" ? CallOutStyles.selectedButtons : CallOutStyles.buttons}
                            variant={person == "someoneElse" ? "danger" : "warning"}
                            onClick={handleSomeoneElseButton}
                        >
                            For someone else
                        </Button>
                        <br /> <br />
                        <Form.Group style={CallOutStyles.formLabel}>
                            <Form.Label>Name:</Form.Label>
                            {person == "yourself" ? (
                                <div>
                                    <Form.Label>{formData.values.fullName}</Form.Label>
                                </div>
                            ) : (
                                <div>
                                    <Form.Control
                                        isInvalid={formData.touched.fullName && !formData.valids.fullName}
                                        type="text"
                                        name="fullName"
                                        value={formData.values.fullName}
                                        onChange={handleOnChange}
                                        onBlur={handleUpdateTouched}
                                    />
                                </div>
                            )}

                            <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                Please enter a name
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group style={CallOutStyles.formLabel}>
                            <Form.Label>Email:</Form.Label>
                            {person == "yourself" ? (
                                <div>
                                    <Form.Label>{formData.values.email}</Form.Label>
                                </div>
                            ) : (
                                <div>
                                    <Form.Control
                                        isInvalid={formData.touched.email && !formData.valids.email}
                                        type="text"
                                        name="email"
                                        value={formData.values.email}
                                        onChange={handleOnChange}
                                        onBlur={handleUpdateTouched_Email}
                                    />
                                </div>
                            )}

                            <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                Please enter a valid email
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group style={CallOutStyles.formLabel}>
                            <Form.Label>Address:</Form.Label> <br />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Address Line 1:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.addressLine1 && !formData.valids.addressLine1}
                                            type="text"
                                            name="addressLine1"
                                            value={formData.values.addressLine1}
                                            onChange={person == "yourself" ? handleOnChange_Yourself : handleOnChange}
                                            onBlur={handleUpdateTouched}
                                        />
                                        <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                            Please enter a value in Address Line 1
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Address Line 2:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.addressLine2 && !formData.valids.addressLine2}
                                            type="text"
                                            name="addressLine2"
                                            value={formData.values.addressLine2}
                                            onChange={person == "yourself" ? handleOnChange_Yourself : handleOnChange}
                                            onBlur={handleUpdateTouched}
                                        />
                                        <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                            Please enter a value in Address Line 2
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Town:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.town && !formData.valids.town}
                                            type="text"
                                            name="town"
                                            value={formData.values.town}
                                            onChange={person == "yourself" ? handleOnChange_Yourself : handleOnChange}
                                            onBlur={handleUpdateTouched}
                                        />
                                        <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                            Please enter a town
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Postcode:</Form.Label>
                                        <Form.Control
                                            isInvalid={formData.touched.postcode && !formData.valids.postcode}
                                            type="text"
                                            name="postcode"
                                            value={formData.values.postcode}
                                            onChange={person == "yourself" ? handleOnChange_Yourself : handleOnChange}
                                            onBlur={handleUpdateTouched}
                                        />
                                        <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                            Please enter a postcode
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                        <br />
                        <Form.Group style={CallOutStyles.formLabel}>
                            <Form.Label>Telephone:</Form.Label>
                            <Form.Control
                                isInvalid={formData.touched.tel && !formData.valids.tel}
                                type="text"
                                name="tel"
                                value={formData.values.tel}
                                onChange={person == "yourself" ? handleOnChange_Yourself : handleOnChange}
                                onBlur={handleUpdateTouched}
                            />
                            <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                Please enter a valid telephone
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group style={CallOutStyles.formLabel}>
                            <Form.Label>Your Issue:</Form.Label>
                            <Form.Control
                                isInvalid={formData.touched.issue && !formData.valids.issue}
                                as="textarea"
                                rows={3}
                                name="issue"
                                value={formData.values.issue}
                                onChange={handleOnChange}
                                onBlur={handleUpdateTouched}
                            />
                            <Form.Control.Feedback style={CallOutStyles.feedbackText} type="invalid">
                                Please enter your issue
                            </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Button variant="warning" style={CallOutStyles.buttons} onClick={handleCallOutButton}>
                            Call Out High-View Studios
                        </Button>
                    </div>
                )}
            </CenterContainer>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalAction.open} onHide={handleCloseModalAction}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalAction.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalAction.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={modalAction.acceptFunction}>
                        {modalAction.acceptName}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.cancelFunction}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CallOut;
