import Axios from "axios";

export const GS_UPDATE_NAV_SIZING = "GS_UPDATE_NAV_SIZING";
export const GS_UPDATE_NAV_TITLE = "GS_UPDATE_NAV_TITLE";
export const GS_UPDATE_NAV_SELECTEDITEM = "GS_UPDATE_NAV_SELECTEDITEM";
export const GS_UPDATE_NAV_SELECTEDSUBITEM = "GS_UPDATE_NAV_SELECTEDSUBITEM";
export const GS_UPDATE_NAV_SELECTEDTOPREV = "GS_UPDATE_NAV_SELECTEDTOPREV";
export const GS_UPDATE_NAV_PREVTOSELECTED = "GS_UPDATE_NAV_PREVTOSELECTED";
export const GS_UPDATE_NAV_RESETPREVSELECTED = "GS_UPDATE_NAV_RESETPREVSELECTED";
export const GS_UPDATE_NAV_STATIC = "GS_UPDATE_NAV_STATIC";
export const GS_UPDATE_NAV_STATICTEXT = "GS_UPDATE_NAV_STATICTEXT";
export const GS_UPDATE_MENU_ITEMS = "GS_UPDATE_MENU_ITEMS";

export const UpdateSizing = (val) => {
    return {
        type: GS_UPDATE_NAV_SIZING,
        value: val
    };
};

export const UpdateTitle = (val) => {
    return {
        type: GS_UPDATE_NAV_TITLE,
        value: val
    };
};

export const UpdateSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDITEM,
        value: val
    };
};

export const UpdateSubSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDSUBITEM,
        value: val
    };
};

export const UpdateSelectedToPrev = (val) => {
    return {
        type: GS_UPDATE_NAV_SELECTEDTOPREV
    };
};

export const UpdatePrevToSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_PREVTOSELECTED
    };
};

export const ResetPrevSelected = (val) => {
    return {
        type: GS_UPDATE_NAV_RESETPREVSELECTED
    };
};

export const UpdateStatic = (val) => {
    return {
        type: GS_UPDATE_NAV_STATIC,
        value: val
    };
};

export const UpdateStaticText = (val) => {
    return {
        type: GS_UPDATE_NAV_STATICTEXT,
        value: val
    };
};

export const GetAdminMenu = () => {
    return (dispatch, getState) => {
        Axios.post("/adminPods/admin/getPagesAndFeatures")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error === "null") {
                    let pagesItem = { ...getState().GS_navSettings.menuItems["pages"] };
                    let featuresItem = { ...getState().GS_navSettings.menuItems["features"] };

                    //PAGES
                    if (data.pages.error != "no pages") {
                        if (data.pages.type == "sideMenu") {
                            const subMenu = [];
                            data.pages.items.map((page) => {
                                const subItem = {
                                    id: page.uuid,
                                    title: page.title,
                                    methodId: page.uuid,
                                    floater: false
                                };
                                subMenu.push(subItem);
                            });

                            pagesItem.subMenuItems = subMenu;
                            pagesItem.subMenuType = "sideMenu";
                        } else {
                            pagesItem.subMenuItems = data.pages.items;
                            pagesItem.SPODSystems = data.pages.SPODSystems;
                            pagesItem.subMenuType = "display";
                        }
                    } else {
                        pagesItem.subMenuType = "none";
                    }

                    //FEATURES
                    if (data.features.error != "no features") {
                        if (data.features.type == "sideMenu") {
                            const subMenu = [];
                            data.features.items.map((feature) => {
                                const subItem = {
                                    id: feature.uuid,
                                    title: feature.title,
                                    methodId: feature.adminCode,
                                    floater: false
                                };
                                subMenu.push(subItem);
                            });

                            featuresItem.subMenuItems = subMenu;
                            featuresItem.subMenuType = "sideMenu";
                        } else {
                            featuresItem.subMenuItems = data.features.items;
                            featuresItem.SPODSystems = data.features.SPODSystems;
                            featuresItem.subMenuType = "display";
                        }
                    } else {
                        featuresItem.subMenuType = "none";
                    }

                    //OTHER MENUS
                    const otherMenus = [];
                    for (const [key, menu] of Object.entries(getState().GS_navSettings.menuItems)) {
                        const items = data.otherMenuItems.filter((men) => men.topMenu == menu.title.toLowerCase());
                        if (items.length > 0) {
                            const modifyMenu = getState().GS_navSettings.menuItems[menu.title.toLowerCase()];
                            const subMenu = [];
                            for (const item of items) {
                                const subItem = {
                                    id: item.uuid,
                                    title: item.title,
                                    methodId: item.adminCode,
                                    floater: true
                                };
                                subMenu.push(subItem);
                            }
                            modifyMenu.subMenuItems = subMenu;
                            modifyMenu.subMenuType = "component";
                            modifyMenu.subMenu = true;

                            otherMenus.push({
                                title: menu.title.toLowerCase(),
                                menuItem: modifyMenu
                            });
                        }
                    }

                    let styleConfig = false;
                    if (data.styleConfig == "true") {
                        styleConfig = true;
                    }

                    dispatch(BuildMenu(pagesItem, featuresItem, otherMenus, styleConfig));
                }
            })
            .catch((err) => console.log(err));
    };
};

const BuildMenu = (pagesItem, featuresItem, otherMenus, styleConfig) => {
    return (dispatch, getState) => {
        let items = [];
        if (getState().user.type == "seniorAdmin" || getState().user.type == "admin") {
            if (styleConfig) {
                items = ["overview", "users", "pages", "editorials", "media", "contacts", "features", "style", "help", "logOut"];
            } else {
                items = ["overview", "users", "pages", "editorials", "media", "contacts", "features", "help", "logOut"];
            }
        }
        if (getState().user.type == "modifier") {
            items = ["overview", "pages", "editorials", "media", "help", "logOut"];
        }

        const tempMenu = [];

        items.forEach((item) => {
            if (item === "pages") {
                tempMenu.push(pagesItem);
            } else if (item === "features") {
                tempMenu.push(featuresItem);
            } else {
                const menu = otherMenus.find((i) => i.title == item);
                if (menu != null) {
                    tempMenu.push(menu.menuItem);
                } else {
                    tempMenu.push(getState().GS_navSettings.menuItems[item]);
                }
            }
        });

        dispatch({
            type: GS_UPDATE_MENU_ITEMS,
            value: tempMenu
        });
    };
};
