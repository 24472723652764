import * as Colors from "../../../../../environment/globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const catergoriesBtn = {
    textAlign: "right"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const cardSelectedStyle = {
    backgroundColor: "#F97A02",
    cursor: "pointer"
};

export const scrollable250 = {
    height: "250px",
    overflow: "auto",
    overflowX: "hidden"
};
