import * as ReducerActions from "../actions/3dPrintingShop";

const initialState = {
    basketItemTotal: 0,
    updateBtn: false,
    items: [],
    serverQuanities: {},
    basketQuantities: {},
    basketTotal: 0,
    basketSuccess: false,
    checkoutFormData: {
        values: {
            addressLine1: "",
            addressLine2: "",
            town: "",
            postcode: "",
            tel: ""
        },
        valids: {
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false
        },
        touched: {
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false
        },
        wholeForm: false
    },
    postageOptions: [],
    selectedPostage: 0,
    checkoutTotal: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_BASKET_ITEM_TOTAL:
            return { ...state, basketItemTotal: action.value };
        case ReducerActions.UPDATE_SETUPDATE:
            return { ...state, updateBtn: action.value };
        case ReducerActions.UPDATE_BASKET:
            return {
                ...state,
                items: action.items,
                serverQuanities: action.serverQuanities,
                basketQuantities: action.basketQuantities,
                basketTotal: action.basketTotal,
                basketItemTotal: action.itemTotal
            };
        case ReducerActions.UPDATE_BASKET_QUANITIES:
            return { ...state, basketQuantities: action.value };
        case ReducerActions.TOGGLE_BASKET_SUCCUESS:
            return { ...state, basketSuccess: action.value };
        case ReducerActions.CHECKOUT_DATA_UPDATE:
            return { ...state, checkoutFormData: action.value };
        case ReducerActions.CLEAR_BASKET_INTERFACE:
            return { ...state, items: [], serverQuanities: {}, basketQuantities: {}, basketTotal: 0, basketItemTotal: 0 };
        case ReducerActions.UPDATE_POSTAGE_OPTIONS:
            return { ...state, postageOptions: action.postage, selectedPostage: action.selected, checkoutTotal: action.checkoutTotal };
        case ReducerActions.UPDATE_SELECTED_POSTAGE:
            return { ...state, selectedPostage: action.value, checkoutTotal: action.checkoutTotal };
        default:
            return state;
    }
};

export default reducer;
