import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

//STYLES
import * as BasketStyles from "../../styles/basket/basket";

//COMPONENTS
import BasketItem from "./basketItem";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

function Basket() {
    const dispatch = useDispatch();
    const printingShop = useSelector((state) => state.printingShop);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(PrintingShopActions.FetchBasketDeatils());
    }, []);

    return (
        <div style={BasketStyles.body}>
            <h1 style={BasketStyles.topHeading}>Your Basket</h1>
            {user.auth ? (
                <div>
                    {printingShop.items.length > 0 ? (
                        <div>
                            {printingShop.items.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <BasketItem
                                            item={item}
                                            serverQuantity={printingShop.serverQuanities[item.uuid]}
                                            basketQuantity={printingShop.basketQuantities[item.uuid]}
                                        />
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div style={BasketStyles.topHeading}>There are no items in your basket</div>
                    )}
                </div>
            ) : (
                <div style={BasketStyles.topHeading}>Sign in to view your basket</div>
            )}
        </div>
    );
}

export default Basket;
