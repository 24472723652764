import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

import * as ControllerStyles from "../styles/controller";

function Controller() {
    const [tab, setTab] = useState("");
    const [statements, setStatments] = useState([]);
    const user = useSelector((state) => state.user);

    const [detailsModal, setDetailsModal] = useState({
        open: false,
        statement: {},
        items: [],
        view: "text",
        fileName: "",
        loading: false,
        producing: false,
        showBtns: true
    });

    function handleCloseDetailsModal() {
        setDetailsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        handleSetTab("invoice");
    }, []);

    function handleSetTab(tab) {
        setTab(tab);

        const data = { type: tab, userID: user.id };
        Axios.post("/pods/onlinePayments/getUserStatements", data)
            .then((res) => {
                const data = res.data;

                if (data.error == "null") {
                    setStatments(data.statements);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleViewAsPDF(ref) {
        setDetailsModal((prevState) => {
            return { ...prevState, loading: true, showBtns: false };
        });

        const data = { ref: ref, type: tab };
        Axios.post("/pods/onlinepayments/makeStatmentPDF", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setDetailsModal((prevState) => {
                        return { ...prevState, fileName: data.filename, view: "pdf", loading: false, showBtns: true };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleViewAsText() {
        setDetailsModal((prevState) => {
            return { ...prevState, view: "text" };
        });
    }

    function pdfLoaded(filename) {
        const data = { filename: filename };

        Axios.post("/pods/onlinePayments/deleteTempPDF", data)
            .then((res) => {
                const data = res.data;
            })
            .catch((err) => console.log(err));
    }

    function handleOpenDetailsModal(statement) {
        const data = { ref: statement.ref };
        Axios.post("/pods/onlinePayments/getStatementItems", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    for (const item of data.items) {
                        item.total = item.unitPrice * item.quantity;
                    }

                    setDetailsModal((prevState) => {
                        return { ...prevState, view: "text", open: true, statement: statement, items: data.items };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col sm={2}></Col>
                <Col>
                    <Row>
                        <Col
                            style={tab == "invoice" ? ControllerStyles.tabSelected : ControllerStyles.tab}
                            onClick={handleSetTab.bind(this, "invoice")}
                        >
                            Invoices
                        </Col>
                        <Col
                            style={tab == "receipt" ? ControllerStyles.tabSelected : ControllerStyles.tab}
                            onClick={handleSetTab.bind(this, "receipt")}
                        >
                            Receipts
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}></Col>
            </Row>
            <Row>
                <Col sm={2}></Col>
                <Col style={ControllerStyles.mainBody}>
                    <Row>
                        <Col>
                            <Card style={ControllerStyles.cardHeadingsStyle}>
                                <Card.Body>
                                    <Row>
                                        <Col>Ref.</Col>
                                        <Col>Total</Col>
                                        <Col>Date</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={ControllerStyles.statementBody}>
                            {statements.length > 0 ? (
                                <div>
                                    {statements.map((statement, index) => {
                                        return (
                                            <div key={index}>
                                                <Card style={ControllerStyles.cardStyle} onClick={handleOpenDetailsModal.bind(this, statement)}>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>{statement.ref}</Col>
                                                            <Col>£{statement.total}</Col>
                                                            <Col>{statement.date}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>You have no {tab}s</div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}></Col>
            </Row>
            <Modal size={"lg"} show={detailsModal.open} onHide={handleCloseDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{tab.charAt(0).toUpperCase() + tab.slice(1)} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailsModal.view == "text" ? (
                        <div>
                            <div style={ControllerStyles.previewTopDetails}>
                                <strong>Ref:</strong> {detailsModal.statement.ref} <br />
                                <strong>Date:</strong> {detailsModal.statement.date}
                            </div>
                            <div>
                                <strong>Name:</strong> {detailsModal.statement.customer} <br /> <br />
                                <strong>Items:</strong>
                            </div>
                            <div>
                                <Row>
                                    <Col sm={4} style={ControllerStyles.previewItemHeaderBordersStart}>
                                        <strong>Item</strong>
                                    </Col>
                                    <Col style={ControllerStyles.previewItemHeaderBorders}>
                                        <strong>Unit Price</strong>
                                    </Col>
                                    <Col style={ControllerStyles.previewItemHeaderBorders}>
                                        <strong>Quantity</strong>
                                    </Col>
                                    <Col style={ControllerStyles.previewItemHeaderBorders}>
                                        <strong>Total</strong>
                                    </Col>
                                </Row>
                                {detailsModal.items.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Row>
                                                <Col sm={4} style={ControllerStyles.previewItemBordersStart}>
                                                    {item.item}
                                                </Col>
                                                <Col style={ControllerStyles.previewItemBorders}>£{parseFloat(item.unitPrice).toFixed(2)}</Col>
                                                <Col style={ControllerStyles.previewItemBorders}>{item.quantity}</Col>
                                                <Col style={ControllerStyles.previewItemBorders}>£{parseFloat(item.total).toFixed(2)}</Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </div>
                            <br />
                            <div style={ControllerStyles.previewTotal}>
                                <strong>Total: £{detailsModal.statement.total}</strong>
                            </div>
                        </div>
                    ) : null}
                    {detailsModal.view == "pdf" ? (
                        <div>
                            <iframe
                                id="iframepdf"
                                width="100%"
                                height="500"
                                src={`${window.location.origin}/content/temp/${detailsModal.fileName}`}
                                onLoad={pdfLoaded.bind(this, detailsModal.fileName)}
                            ></iframe>
                        </div>
                    ) : null}
                    <hr />
                    <Row>
                        <Col>
                            {tab == "invoice" && (
                                <div>
                                    {detailsModal.statement.receiptRef != "" ? (
                                        <div>Receipt Ref: {detailsModal.statement.receiptRef}</div>
                                    ) : (
                                        <div>
                                            {detailsModal.producing ? (
                                                <div style={ControllerStyles.pdfLodingSign}>Producing...</div>
                                            ) : // <div>
                                            //     {detailsModal.showBtns && (
                                            //         <div>
                                            //             <Button>Request Bank Transfer Details</Button>
                                            //         </div>
                                            //     )}
                                            // </div>
                                            null}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                        <Col style={ControllerStyles.rightBtns}>
                            {detailsModal.loading ? (
                                <div style={ControllerStyles.pdfLodingSign}>Loading...</div>
                            ) : (
                                <div>
                                    {detailsModal.view == "text" && detailsModal.showBtns && (
                                        <Button variant="primary" onClick={handleViewAsPDF.bind(this, detailsModal.statement.ref)}>
                                            View As PDF
                                        </Button>
                                    )}
                                    {detailsModal.view == "pdf" && detailsModal.showBtns && (
                                        <Button variant="primary" onClick={handleViewAsText}>
                                            View As Text
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Controller;
