export const body = {
    margin: "20px",
    paddingRight: "20px",
    height: "100%"
};

export const container = {
    // height: "40%",
    // overflow: "auto",
    // padding: "20px"
};

export const borders = {
    border: "solid 1px"
};

export const createDirBtn = {
    textAlign: "right"
};

export const noContentMessage = {
    textAlign: "center"
};

export const urlHeading = {
    fontWeight: "bold",
    paddingTop: "10px"
};

export const errorMessage = {
    color: "red",
    textAlign: "center",
    fontWeight: "bold"
};
