import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//COMPONENTS
import ListDisplay from "../navBar/subMenuListDisplay";
import FeatureArea from "./files/featureArea";
import EnvironmentPodsMap from "../../../environment/pods/environmentPodsMap";

function PodRouter(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/list" element={<ListDisplay section="features" items={props.items} SPODSystems={props.SPODSystems} />} />
                        <Route path="/pod/:code/*" element={<FeatureArea />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
