import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { useParams } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as PackageViewerStyles from "../../styles/packages/packageViewer";

//COMPONENTS
import WebPackageItemControl from "./webPackageItemControl";

function PackageViewer(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const [packageName, setPackageName] = useState({
        value: "",
        valid: false,
        touched: false
    });
    const [items, setItems] = useState({
        valid: false,
        touched: false,
        slots: []
    });
    const [multiplePackage, setMultiplePackage] = useState(false);
    const [packageValid, setPackageValid] = useState(false);

    const defaultItem = {
        values: {
            type: "Essential",
            name: "",
            price: "",
            frequently: "Monthly",
            defaultValue: "",
            additionalInfo: false
        },
        valids: {
            name: false,
            price: false
        },
        touched: {
            name: false,
            price: false
        },
        itemValid: false,
        status: "new"
    };

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        let title = "";
        if (props.modify) {
            title = "Modify Package";
        } else {
            title = "Add Package";
        }
        dispatch(GS_navSettingsActions.UpdateTitle(`Website Packages - ${title}`));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));

        if (props.modify) {
            getOnePackage();
        }
    }, []);

    function getOnePackage() {
        const data = { uuid: params.id };
        Axios.post("/pods/onlinePayments/websitePackages/getOnePackage", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const packageNam = { ...packageName };
                    packageNam.value = data.package.name;
                    packageNam.valid = true;
                    setPackageName(packageNam);
                    setMultiplePackage(data.package.multiple == "true");

                    const slots = [];
                    for (const item of data.items) {
                        let newItem = {
                            values: {
                                uuid: item.uuid,
                                type: item.type,
                                name: item.name,
                                price: item.price,
                                frequently: item.frequently,
                                defaultValue: item.defaultValue,
                                additionalInfo: item.additionalInfo == "true"
                            },
                            valids: {
                                name: true,
                                price: true
                            },
                            touched: {
                                name: true,
                                price: true
                            },
                            itemValid: true,
                            status: "current"
                        };

                        slots.push(newItem);
                    }

                    const itemsValid = Object.keys(slots).every(function (k) {
                        return slots[k].itemValid === true;
                    });

                    setItems((prevState) => {
                        return { ...prevState, slots, valid: true };
                    });
                    console.log(itemsValid);
                    let isPackageValid = false;
                    if (itemsValid && packageNam.valid) {
                        isPackageValid = true;
                    }

                    setPackageValid(isPackageValid);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleAddItem() {
        const temp = { ...items };

        temp.slots = [...temp.slots, defaultItem];
        temp.valid = true;
        temp.touched = true;

        const itemsValid = Object.keys(temp.slots).every(function (k) {
            return temp.slots[k].itemValid === true;
        });

        setItems(temp);

        let isPackageValid = false;
        if (itemsValid && packageName.valid && temp.valid) {
            isPackageValid = true;
        }

        setPackageValid(isPackageValid);
    }

    function changeDropdownText(id, field, value) {
        const temp = [...items.slots];
        temp[id].values[field] = value;

        if (props.modify && temp[id].status == "current") {
            temp[id].status = "update";
        }

        setItems((prevState) => {
            return { ...prevState, slots: temp };
        });
    }

    function textChange(id, field, value) {
        const temp = [...items.slots];
        temp[id].values[field] = value;

        if (props.modify && temp[id].status == "current") {
            temp[id].status = "update";
        }

        setItems((prevState) => {
            return { ...prevState, slots: temp };
        });
    }

    function textChangeAndValidate(id, field, value) {
        const temp = [...items.slots];
        temp[id].values[field] = value;

        if (props.modify && temp[id].status == "current") {
            temp[id].status = "update";
        }

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        temp[id].valids[field] = isValid;

        temp[id].itemValid = Object.keys(temp[id].valids).every(function (k) {
            return temp[id].valids[k] === true;
        });

        const itemsValid = Object.keys(temp).every(function (k) {
            return temp[k].itemValid === true;
        });

        let isPackageValid = false;
        if (itemsValid && packageName.valid && items.valid) {
            isPackageValid = true;
        }

        setItems((prevState) => {
            return { ...prevState, slots: temp };
        });

        setPackageValid(isPackageValid);
    }

    function checkChange(id, field, value) {
        const temp = [...items.slots];
        temp[id].values[field] = value;

        if (props.modify && temp[id].status == "current") {
            temp[id].status = "update";
        }

        setItems((prevState) => {
            return { ...prevState, slots: temp };
        });
    }

    function changeTouched(id, field) {
        const temp = [...items.slots];
        temp[id].touched[field] = true;

        setItems((prevState) => {
            return { ...prevState, temp };
        });
    }

    function deleteItem(id) {
        const temp = [...items.slots];
        if (props.modify && temp[id].status != "new") {
            temp[id].status = "delete";
        } else {
            temp.splice(id, 1);
        }
        setItems((prevState) => {
            return { ...prevState, slots: temp };
        });
    }

    function handleMultiplePackageChanged(event) {
        const { checked } = event.target;
        setMultiplePackage(checked);
    }

    function handlePackageNameChange(event) {
        const { value } = event.target;

        const packageN = { ...packageName };

        packageN.value = value;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        packageN.valid = isValid;

        const temp = [...items.slots];
        const itemsValid = Object.keys(temp).every(function (k) {
            return temp[k].itemValid === true;
        });

        let isPackageValid = false;
        if (itemsValid && packageN.valid && items.valid) {
            isPackageValid = true;
        }

        setPackageName(packageN);
        setPackageValid(isPackageValid);
    }

    function handlePackageNameTouched() {
        const packageN = { ...packageName };

        packageN.touched = true;

        setPackageName(packageN);
    }

    function handleOnSave() {
        if (!packageValid) {
            switchAllTouches();
        } else {
            const data = { name: packageName.value, multiple: multiplePackage, items: items.slots };
            Axios.post("/pods/onlinePayments/websitePackages/addPackage", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                })
                .catch((err) => console.log(err));
        }
    }

    function handleOnUpdate() {
        if (!packageValid) {
            switchAllTouches();
        } else {
            const data = { uuid: params.id, name: packageName.value, multiple: multiplePackage, items: items.slots };
            Axios.post("/pods/onlinePayments/websitePackages/updatePackage", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        setModal({ header: "Package", message: "Package updated successfully", open: true });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function switchAllTouches() {
        const temp = [...items.slots];

        for (const item of temp) {
            item.touched = Object.assign(...Object.keys(item.touched).map((k) => ({ [k]: true })));
        }

        const packageN = { ...packageName };
        packageN.touched = true;

        setItems((prevState) => {
            return { ...prevState, slots: temp, touched: true };
        });
        setPackageName(packageN);
    }

    return (
        <div style={PackageViewerStyles.body}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Package Name: </Form.Label>
                        <Form.Control
                            isInvalid={packageName.touched && !packageName.valid}
                            type="text"
                            value={packageName.value}
                            onChange={handlePackageNameChange}
                            onBlur={handlePackageNameTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a package name!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Check
                        label="This package can contain more than 1 websites per contract"
                        checked={multiplePackage}
                        onChange={handleMultiplePackageChanged}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={PackageViewerStyles.columnRight}>
                    <Button onClick={handleAddItem}>Add Item</Button>
                </Col>
            </Row>
            <div style={items.touched && !items.valid ? PackageViewerStyles.errorTextShow : PackageViewerStyles.errorTextHidden}>
                There are no items in this package
            </div>
            <br />
            <div style={PackageViewerStyles.scrollableBox}>
                {items.slots.map((item, index) => {
                    if (item.status != "delete") {
                        return (
                            <WebPackageItemControl
                                key={index}
                                id={index}
                                item={item}
                                changeDropdownText={changeDropdownText}
                                textChange={textChange}
                                textChangeAndValidate={textChangeAndValidate}
                                checkChange={checkChange}
                                changeTouched={changeTouched}
                                deleteItem={deleteItem}
                            />
                        );
                    }
                })}
            </div>
            <Row>
                <Col style={PackageViewerStyles.columnRight}>
                    {props.modify ? <Button onClick={handleOnUpdate}>Update</Button> : <Button onClick={handleOnSave}>Save</Button>}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PackageViewer;
