import * as Colors from "../../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const tab = {
    textAlign: "center",
    fontSize: "1.5rem",
    cursor: "pointer"
};

export const selected = {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    backgroundColor: Colors.primary,
    color: "white",
    borderRadius: "25px 25px 0 0",
    cursor: "pointer"
};

export const tabText = {};

export const tabErrorText = {
    color: "red"
};

export const topBorder = {
    borderTop: "2px black solid"
};

export const saveBtn = {
    //  textAlign: "right"
    float: "right"
};

export const errorText = {
    float: "right",
    color: "red",
    paddingRight: "10px"
};

export const activeStatus = {
    textAlign: "center",
    color: "red",
    fontWeight: "bold"
};

export const hidden = {
    visibility: "hidden"
};
