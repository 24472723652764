export const container = {
    backgroundColor: "white",
    width: "350px",
    height: "350px",
    border: "1px solid black",
    padding: "30px",
    textAlign: "center"
};

export const currentImage = {
    backgroundColor: "red",
    width: "100%",
    height: "100%",
    border: "1px solid black"
};

export const subContainerInner = {
    display: "flex",
    flexDirection: "row",
    overflow: "scroll"
};

export const subContainerOuter = {
    backgroundColor: "white",
    border: "1px solid black",
    width: "350px",
    height: "80px",
    marginTop: "10px"
};

export const imageContainer = {
    width: "100%",
    height: "100%"
};
