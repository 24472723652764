import React, { useEffect, useState } from "react";
import useWindowSize from "../../../pods/wholeSite/windowSize";
import CenterContainer from "../../../pods/wholeSite/centerContainer";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import EnvironmentPodsMap from "../../../../environment/pods/environmentPodsMap";

//STYLES
import * as adminLoginStyles from "../styles/login";

function AdminLogin(props) {
    const [useWindowHeight] = useWindowSize();
    const [updateDatabase, setUpdateDatabase] = useState(false);
    const navigate = useNavigate();

    function forgotPassword() {
        navigate("../forgotPassword");
    }

    useEffect(() => {
        const data = { adminCode: props.adminCode };
        Axios.post("/adminPods/security/checkForVersionUpdates", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    if (data.coreUpdate || data.envUpdate) {
                        setUpdateDatabase(true);

                        setTimeout(() => {
                            updateDatabases(data.coreUpdate, data.envUpdate, data.coreVersions, data.envVersions);
                        }, 100);
                    }
                }
            })
            .catch((err) => console.log(err));
    }, []);

    function updateDatabases(coreUpdate, envUpdate, coreVersions, envVersions) {
        const coreData = { coreUpdate: coreUpdate, versions: coreVersions };
        console.log(coreData);
        Axios.post("/adminPods/adminUpdatePanel/runUpdates", coreData)
            .then((res) => {
                const adminRevcdData = res.data;
                if (adminRevcdData.error == "null") {
                    const environmentData = { envUpdate: envUpdate, versions: envVersions };
                    Axios.post("/adminPods/environmentUpdatePanel/runUpdates", environmentData)
                        .then((res) => {
                            const envRevcdData = res.data;
                            if (envRevcdData.error == "null") {
                                setUpdateDatabase(false);
                            }
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Row style={adminLoginStyles.body} className="align-items-center">
                <Col>
                    <Row>
                        <Col className="text-center">
                            <CenterContainer>
                                {updateDatabase ? (
                                    <div>
                                        <CenterContainer style={adminLoginStyles.innerContainer}>
                                            <h2>Updating Databases...</h2>
                                        </CenterContainer>
                                    </div>
                                ) : (
                                    <EnvironmentPodsMap podCode="loginForm" />
                                )}
                            </CenterContainer>
                        </Col>
                    </Row>
                </Col>
                <Col style={adminLoginStyles.forgotPassword}>
                    <i style={adminLoginStyles.helpIcon} className="fa-solid fa-circle-question" onClick={forgotPassword}></i>
                </Col>
            </Row>
        </div>
    );
}

export default AdminLogin;
