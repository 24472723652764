import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

//FILES
import TicketViewer from "../../mixedComponents/files/ticketViewer";

//STYLES
import * as HelpDeskStyles from "../styles/helpDesk";

function TicketHolder() {
    const params = useParams();

    return (
        <div style={HelpDeskStyles.ticketHolder_body}>
            <Row>
                <Col sm={2}></Col>
                <Col>
                    <TicketViewer type="user" uuid={params.uuid} />
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default TicketHolder;
