import * as Colors from "../../../../environment/globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "30px"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const container = {
    height: "650px",
    overflow: "auto",
    overflowX: "hidden"
};

export const columnRight = {
    textAlign: "right"
};
