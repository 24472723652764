import React from "react";

//STYLES
import * as TaskCommentStyles from "../styles/taskComment";

function TaskCooment(props) {
    return (
        <div>
            <hr />
            {props.comment} <br />
            <div style={TaskCommentStyles.date}>
                {props.author == "Planner Master" ? "Shaun Evans" : props.author} - {props.date}
            </div>
        </div>
    );
}

export default TaskCooment;
