import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as NoneItemsStyles from "./styles/noneItems";

function NoneItems() {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    function CapsFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(CapsFirstLetter(params.menu)));
        dispatch(GS_navSettingsActions.UpdateSelected(CapsFirstLetter(params.menu)));
    }, [params.menu, location]);

    return (
        <div style={NoneItemsStyles.body}>
            <h2>
                There are no {params.menu} on this site.
                <br /> <br />
                Please contact High-View Studios if you require {params.menu} to your site.
            </h2>
        </div>
    );
}

export default NoneItems;
