import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";

//STYLES
import * as FrontInterfaceStyles from "../styles/frontInterface";

function ForgotPassword() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        valids: {
            email: false
        },
        touched: {
            email: false
        },
        errorOnForm: false
    });
    const [message, setMessage] = useState("text");
    const [passwordRequested, setPasswordRequested] = useState(false);

    function handleBackBtn() {
        dispatch(AccessModalActions.UpdateScreen("signIn"));
    }

    function handleOnChange(event) {
        const { id, value } = event.target;

        const valids = data.valids;

        let isValid = false;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            isValid = true;
        }

        valids[id] = isValid;

        const allTrue = Object.keys(data.valids).every(function (k) {
            return data.valids[k] === true;
        });

        setData((prevState) => {
            return { ...prevState, valids, errorOnForm: allTrue };
        });
    }

    function handleOnTouch(event) {
        const { id } = event.target;

        const touched = data.touched;
        touched[id] = true;

        setData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function switchAllTouches() {
        const touches = Object.assign(...Object.keys(data.touched).map((k) => ({ [k]: true })));

        setData((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function handleOnSubmit() {
        if (!data.errorOnForm) {
            switchAllTouches();
        } else {
            const email = document.getElementById("email").value;

            const data = { email: email };
            Axios.post("/pods/accessModal/requestPassword", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error != "null") {
                        setMessage(data.message);
                    } else {
                        setPasswordRequested(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {passwordRequested ? (
                    <div style={FrontInterfaceStyles.passwordRequestedText}>
                        Password Requested <br /> Please check your email for a temporary password
                    </div>
                ) : (
                    <div>
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                isInvalid={data.touched.email && !data.valids.email ? true : false}
                                type="text"
                                id="email"
                                onChange={handleOnChange}
                                onBlur={handleOnTouch}
                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid email!</Form.Control.Feedback>
                        </Form.Group>
                        <div style={message != "text" ? FrontInterfaceStyles.errorTextShow : FrontInterfaceStyles.errorTextHide}>{message}</div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {passwordRequested ? (
                    <div>
                        <Button onClick={handleBackBtn}>Sign In</Button>
                    </div>
                ) : (
                    <div>
                        <Button className="mr-auto" onClick={handleBackBtn}>
                            Back
                        </Button>
                        <Button onClick={handleOnSubmit}>Submit</Button>
                    </div>
                )}
            </Modal.Footer>
        </div>
    );
}

export default ForgotPassword;
