import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as WebPackageStyles from "../../styles/packages/webPackages";

function WebPackages() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Website Packages"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));

        loadPackages();
    }, []);

    function loadPackages() {
        Axios.post("/pods/onlinePayments/websitePackages/getAllPackages")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPackages(data.packages);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleAddPackage() {
        navigate("./addPackage");
    }

    function handlePackageClick(uuid) {
        navigate(`./modifyPackage/${uuid}`);
    }

    return (
        <div style={WebPackageStyles.body}>
            <Row>
                <Col style={WebPackageStyles.columnRight}>
                    <Button onClick={handleAddPackage}>Add Package</Button>
                </Col>
            </Row>
            <br />
            {packages.map((packagee, index) => {
                return (
                    <div key={index}>
                        <Card style={WebPackageStyles.cardStyle} onClick={handlePackageClick.bind(this, packagee.uuid)}>
                            <Card.Body>
                                <Row>
                                    <Col>{packagee.name}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}
        </div>
    );
}

export default WebPackages;
