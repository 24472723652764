export const body = {
    paddingTop: "20px",
    paddingRight: "30px"
};

export const columnRight = {
    textAlign: "right"
};

export const scrollableBox = {
    height: "350px",
    overlow: "auto",
    overflowX: "hidden"
};

export const hideCheck = {
    visibility: "hidden"
};

export const itemsHeadings = {
    fontWeight: "bold"
    // textAlign: "center"
};

export const centering = {
    textAlign: "center"
};

export const vCenter = {
    margin: "auto"
};
