import React from "react";
import { Image, Row, Col } from "react-bootstrap";

//STYLES
import * as OrderDetailsItemStyles from "../../styles/order/orderDetailsItem";

function OrderDetailsItem(props) {
    return (
        <div>
            <div style={OrderDetailsItemStyles.body}>
                <Row>
                    <Col md="auto">
                        <Image
                            src={`/content/3dPrintingShop/products/${props.item.itemDetails.uuid.toString()}/${props.item.itemDetails.mainImage}`}
                            style={OrderDetailsItemStyles.image}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <strong>Name:</strong> {props.item.itemDetails.title} <br />
                                <strong>Quantity:</strong> {props.item.quantity}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={OrderDetailsItemStyles.columnRight}>
                                <strong>Price Per Item:</strong> £{(props.item.priceWhenOrdered / 100).toFixed(2)} <br />
                                <strong>Total:</strong> £{((props.item.priceWhenOrdered * props.item.quantity) / 100).toFixed(2)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <br />
        </div>
    );
}

export default OrderDetailsItem;
