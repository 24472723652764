export const body = {
    paddingRight: "30px"
};

export const ticketBorder = {
    borderBottom: "solid 4px black"
};

export const viewAllLink = {
    textAlign: "center",
    padding: "10px",
    color: "blue",
    cursor: "pointer"
};

export const frontEnd_ClosedNotice = {
    paddingTop: "20px",
    textAlign: "center",
    fontSize: "1.5rem"
};
