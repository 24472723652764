//THIS SCRIPT CONTAINS BROWSER AND MOBILE VIEW
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { isBrowser } from "react-device-detect";

//STYLES
import * as HomeStyles from "../styles/home";

//COMPONENTS
import NewTask from "./newTask";
import TaskCard from "./taskCard";
import TaskDetails from "./taskDetails";

import * as PlannerActions from "../../../../../envStore/actions/planner";

function Home() {
    const params = useParams();
    const bodyRef = useRef(null);
    const [bodyHeight, setBodyHeight] = useState("100vh");
    const [columnHeight, setColumnHeight] = useState("300px");

    const user = useSelector((state) => state.user);
    const planner = useSelector((state) => state.planner);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //MOBILEVIEW DATA
    const taskStatus = [
        { title: "Not Started", id: "notStarted" },
        { title: "In Progress", id: "inProgress" },
        { title: "To Be Tested", id: "toBeTested" },
        { title: "Completed", id: "completed" }
    ];
    const [currentStatus, setCurrentStatus] = useState(0);

    //TASKS
    const [tasks, setTasks] = useState({
        notStarted: [],
        inProgress: [],
        toBeTested: [],
        completed: []
    });

    const [planDetails, setPlanDetails] = useState({
        title: ""
    });

    const [newTaskModal, setNewTaskModal] = useState({
        open: false
    });

    function handleCloseNewTaskModal() {
        setNewTaskModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleOpenNewTaskModal() {
        setNewTaskModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    const [taskDeatilsModal, setTaskDetailsModal] = useState({
        open: false,
        uuid: ""
    });

    function handleOpenTaskDetailsModal(uuid) {
        setTaskDetailsModal((prevState) => {
            return { ...prevState, uuid: uuid, open: true };
        });
    }

    function handleCloseTaskDetailsModal() {
        setTaskDetailsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        loadPlan();

        setBodyHeight(bodyRef.current.offsetHeight - 60);
        setColumnHeight(bodyRef.current.offsetHeight - 210);
    }, []);

    function loadPlan() {
        const data = { uuid: params.id, userID: user.id };
        Axios.post("/pods/plannerTasks/getPlanDetails", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPlanDetails((prevState) => {
                        return { ...prevState, title: data.planDetails.title };
                    });
                    dispatch(PlannerActions.UpdateType(data.userDetails.type));

                    setTasks({
                        notStarted: data.tasks.filter((task) => task.state == "Not Started"),
                        inProgress: data.tasks.filter((task) => task.state.includes("In Progress")),
                        toBeTested: data.tasks.filter((task) => task.state == "To Be Tested"),
                        completed: data.tasks.filter((task) => task.state == "Completed")
                    });
                } else {
                    navigate("../");
                }
            })
            .catch((err) => console.log(err));
    }

    function mobile_handleGoForwards() {
        const nextStatus = currentStatus + 1;
        setCurrentStatus(nextStatus);
    }

    function mobile_handleGoBackwards() {
        const nextStatus = currentStatus - 1;
        setCurrentStatus(nextStatus);
    }

    const browserView = (
        <div>
            <div>
                <Row style={HomeStyles.taskBody}>
                    <Col sm={3} style={HomeStyles.statusTitles}>
                        Not Started
                    </Col>
                    <Col sm={3} style={HomeStyles.statusTitles}>
                        In Progress
                    </Col>
                    <Col sm={3} style={HomeStyles.statusTitles}>
                        To Be Tested
                    </Col>
                    <Col sm={3} style={HomeStyles.statusEndTitle}>
                        Completed
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm={3} style={{ ...HomeStyles.planColumns, height: columnHeight }}>
                        {tasks.notStarted.map((task, index) => {
                            return (
                                <div key={index}>
                                    <TaskCard task={task} onOpen={handleOpenTaskDetailsModal.bind(this, task.uuid)} />
                                    <br />
                                </div>
                            );
                        })}
                    </Col>
                    <Col sm={3} style={{ ...HomeStyles.planColumns, height: columnHeight }}>
                        {tasks.inProgress.map((task, index) => {
                            return (
                                <div key={index}>
                                    <TaskCard task={task} onOpen={handleOpenTaskDetailsModal.bind(this, task.uuid)} />
                                    <br />
                                </div>
                            );
                        })}
                    </Col>
                    <Col sm={3} style={{ ...HomeStyles.planColumns, height: columnHeight }}>
                        {tasks.toBeTested.map((task, index) => {
                            return (
                                <div key={index}>
                                    <TaskCard task={task} onOpen={handleOpenTaskDetailsModal.bind(this, task.uuid)} />
                                    <br />
                                </div>
                            );
                        })}
                    </Col>
                    <Col sm={3} style={{ ...HomeStyles.planColumns, height: columnHeight }}>
                        {tasks.completed.map((task, index) => {
                            return (
                                <div key={index}>
                                    <TaskCard task={task} onOpen={handleOpenTaskDetailsModal.bind(this, task.uuid)} />
                                    <br />
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </div>
        </div>
    );

    const mobileView = (
        <div>
            <Row>
                <Col style={currentStatus == 0 ? HomeStyles.hidden : HomeStyles.visible}>
                    <Button onClick={mobile_handleGoBackwards}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </Button>
                </Col>
                <Col style={HomeStyles.statusTitle}>{taskStatus[currentStatus].title}</Col>
                <Col
                    style={
                        currentStatus == 3
                            ? { ...HomeStyles.mobileRightArrow, ...HomeStyles.hidden }
                            : { ...HomeStyles.mobileRightArrow, ...HomeStyles.visible }
                    }
                >
                    <Button onClick={mobile_handleGoForwards}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col style={{ ...HomeStyles.mobilePlanColumns, height: columnHeight }}>
                    {tasks[taskStatus[currentStatus].id].map((task, index) => {
                        return (
                            <div key={index}>
                                <TaskCard task={task} onOpen={handleOpenTaskDetailsModal.bind(this, task.uuid)} />
                                <br />
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );

    return (
        <div ref={bodyRef} style={{ ...HomeStyles.body, height: bodyHeight }}>
            <div style={HomeStyles.heading}>
                <h1 style={planDetails.title != "" ? HomeStyles.titleVisible : HomeStyles.titleHidden}>
                    <u>Plan - {planDetails.title}</u>
                </h1>
            </div>
            {(planner.type == "admin" || planner.type == "contractor") && (
                <div style={HomeStyles.addNewTaskRow}>
                    <OverlayTrigger placement="right" overlay={<Tooltip>New Task</Tooltip>}>
                        <Button onClick={handleOpenNewTaskModal}>
                            <i className="fa-solid fa-plus"></i>
                        </Button>
                    </OverlayTrigger>
                </div>
            )}
            {isBrowser ? browserView : mobileView}
            <NewTask open={newTaskModal.open} close={handleCloseNewTaskModal} planUUID={params.id} loadPlan={loadPlan} />
            <TaskDetails open={taskDeatilsModal.open} close={handleCloseTaskDetailsModal} uuid={taskDeatilsModal.uuid} loadPlan={loadPlan} />
        </div>
    );
}

export default Home;
