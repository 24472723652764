import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";
import { EditorState, convertToRaw, Modifier, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import { Form, Row, Col, Button, Modal, ListGroup, Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import MediaCanvas from "../../media/canvas";

import * as MediaActions from "../../../../store/actions/media";
import * as GS_contentSettingsActions from "../../../../store/actions/globalSettings/GS_contentSettings";
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as NewEditoralItemStyles from "../styles/newEditorialItem";
import * as UploadStyles from "../../media/styles/upload"; //CROSS-OVER POD LINK

function NewEditoralItem(props) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [tab, setTab] = useState("Settings");
    const [previewText, setPreviewText] = useState("");
    const [settings, setSettings] = useState({
        tab: "Settings",
        title: "",
        titleShow: false,
        titlePosition: "left"
    });
    const [editItem, setEditItem] = useState(-1);
    const navigate = useNavigate();
    const params = useParams();

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: "",
        error: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const media = useSelector((state) => state.media);
    const GS_contentSettings = useSelector((state) => state.GS_contentSettings);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.modify) {
            dispatch(GS_navSettingsActions.UpdateTitle("Modify Editoral Item"));
            getEditorial();
        } else {
            dispatch(GS_contentSettingsActions.RefreashImages());
            dispatch(GS_navSettingsActions.UpdateTitle("New Editoral Item"));
        }
        dispatch(GS_navSettingsActions.UpdateSizing(1));
    }, []);

    function getEditorial() {
        const data = { id: params.id };
        Axios.post("/adminPods/editorials/getEditorial", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                const editorStateM = stringToDraftjs(data.editorial.content);
                setEditorState(editorStateM);

                setSettings((prevState) => {
                    return {
                        ...prevState,
                        title: data.editorial.title,
                        titleShow: data.editorial.titleShow == "true",
                        titlePosition: data.editorial.titlePosition
                    };
                });
                dispatch(GS_contentSettingsActions.LoadImagesFromDatabase(data.images));
                handlePreview(editorStateM, data.images);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function stringToDraftjs(text) {
        const blocksFromHTML = htmlToDraftjs(text);
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    function handleCloseMediaCanvas() {
        dispatch(MediaActions.UpdateCanvasOpen(false));
    }

    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        handlePreview(editorState, GS_contentSettings.images);
    };

    function handleTitleShowChange(checked) {
        setSettings((prevState) => {
            return { ...prevState, titleShow: checked };
        });
    }

    const [previewModal, setPreviewModal] = useState({
        open: false,
        html: ""
    });

    function handlePreviewModalClose() {
        setPreviewModal({ open: false });
    }

    function handleTitleChange(event) {
        const { value } = event.target;

        setSettings((prevState) => {
            return { ...prevState, title: value };
        });
    }

    function handleSaveBtn() {
        if (settings.title == "") {
            setModal({
                error: true,
                header: "Title",
                message: "Please enter a title",
                open: true
            });
        } else {
            let continu = true;
            for (const image of GS_contentSettings.images) {
                if (image.url == "") {
                    continu = false;
                    setModal({
                        error: true,
                        header: "Images",
                        message: "Please delete the images without a URL",
                        open: true
                    });
                }
            }
            if (continu) {
                if (props.modify) {
                    const data = {
                        uuid: params.id,
                        title: settings.title,
                        content: DOMPurify.sanitize(draftjsToHtml(convertToRaw(editorState.getCurrentContent()))),
                        images: GS_contentSettings.images,
                        deletedImages: GS_contentSettings.deleted,
                        titleShow: settings.titleShow.toString(),
                        titlePosition: settings.titlePosition
                    };
                    Axios.post("/adminPods/editorials/updateEditorial", data)
                        .then((res) => {
                            const data = res.data;
                            if (data.error == "null") {
                                dispatch(GS_navSettingsActions.UpdateSizing(3));
                                navigate("../");
                            } else {
                                console.log(data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    const data = {
                        title: settings.title,
                        content: DOMPurify.sanitize(draftjsToHtml(convertToRaw(editorState.getCurrentContent()))),
                        images: GS_contentSettings.images,
                        titleShow: settings.titleShow.toString(),
                        titlePosition: settings.titlePosition
                    };
                    Axios.post("/adminPods/editorials/insertNewEditorial", data)
                        .then((res) => {
                            const data = res.data;
                            if (data.error == "null") {
                                dispatch(GS_navSettingsActions.UpdateSizing(3));
                                navigate("../");
                            } else {
                                console.log(data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        }
    }

    //'<img src="/media/home/staff/My_Staff_Reviews.png" width=100 height=100/>'
    function handlePreview(editorState, images) {
        let editorText = DOMPurify.sanitize(draftjsToHtml(convertToRaw(editorState.getCurrentContent())));
        let continu = true;

        for (const image of images) {
            if (image.url == "") {
                continu = false;
                setModal({
                    error: true,
                    header: "Image not found!",
                    message: `${image.id} is missing an url`,
                    open: true
                });
                return;
            } else {
                editorText = editorText.replace(`{${image.id}}`, `<img src="/content/${image.url}" width=${image.width} height=${image.height}/>`);
            }
        }
        setPreviewText(editorText);
    }

    function sectionsButtonClick(event) {
        const { name } = event.target;
        setSettings((prevState) => {
            return { ...prevState, tab: name };
        });
    }

    function handleInsertImageButton() {
        let prevID = 0;
        if (GS_contentSettings.images.length > 0) {
            prevID = GS_contentSettings.images[GS_contentSettings.images.length - 1].id;
            prevID = Number(prevID.replace("image", ""));
        }

        const id = `image${prevID + 1}`;

        const image = {
            uuid: "null",
            id: id,
            url: "",
            width: 100,
            height: 100,
            status: "create"
        };

        dispatch(GS_contentSettingsActions.UpdateImages(image));
    }

    function handleOpenMediaCanvas(id) {
        dispatch(GS_contentSettingsActions.UpdateImageID(id));
        dispatch(MediaActions.UpdateCanvasOpen(true));
    }

    function truncateString(str, size) {
        return str.length > size ? str.substr(0, size - 1) + "..." : str;
    }

    function handleInsertImageIntoText(id) {
        const image = `{${id}}`;

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        let nextEditorState = EditorState.createEmpty();
        const nextContentState = Modifier.replaceText(contentState, selection, image);
        nextEditorState = EditorState.push(editorState, nextContentState, "insert-characters");
        handleEditorChange(nextEditorState);
    }

    function handleChangeTab(tab) {
        setSettings((prevState) => {
            return { ...prevState, tab: tab };
        });
    }

    function handleEditItem(id) {
        setEditItem(id);
    }

    function handleEditBack() {
        setEditItem(-1);
    }

    function handleDeleteImage(id) {
        dispatch(GS_contentSettingsActions.DeleteImage(id));
    }

    function handleImageSizeChange(event, measurement, id) {
        const { value } = event.target;

        dispatch(GS_contentSettingsActions.changeImageSize(id, measurement, value));

        handlePreview(editorState, GS_contentSettings.images);
    }

    function handleChangeTitlePosition(position) {
        setSettings((prevState) => {
            return { ...prevState, titlePosition: position };
        });
    }

    return (
        <div style={NewEditoralItemStyles.body}>
            <Row>
                <Col style={NewEditoralItemStyles.textSize}>
                    <div>
                        <Form.Group style={NewEditoralItemStyles.newItemTitle}>
                            <Form.Label style={NewEditoralItemStyles.newItemTitleText}>Title:</Form.Label>
                            <Form.Control type="text" value={settings.title} onChange={handleTitleChange} />
                        </Form.Group>
                    </div>
                </Col>
                <Col style={NewEditoralItemStyles.textSize}>
                    <Row>
                        <Col style={NewEditoralItemStyles.textSize}>
                            <strong>Preview: </strong>
                        </Col>
                        <Col style={NewEditoralItemStyles.saveButton}>
                            <Button onClick={handleSaveBtn}>Save</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Editor
                                editorState={editorState}
                                toolbar={{
                                    options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "link",
                                        "embedded",
                                        "emoji",
                                        "remove",
                                        "history"
                                    ]
                                }}
                                onEditorStateChange={handleEditorChange}
                                wrapperStyle={NewEditoralItemStyles.editorWrapper}
                                editorStyle={NewEditoralItemStyles.editorStyle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <br />
                        <br />
                        <br />
                        <Col>
                            <Row style={NewEditoralItemStyles.tabControlledArea}>
                                <Col>
                                    <Row>
                                        <Col
                                            style={settings.tab == "Settings" ? NewEditoralItemStyles.selectedTab : NewEditoralItemStyles.tab}
                                            onClick={handleChangeTab.bind(this, "Settings")}
                                        >
                                            Settings
                                        </Col>
                                        <Col
                                            style={settings.tab == "Images" ? NewEditoralItemStyles.selectedTab : NewEditoralItemStyles.tab}
                                            onClick={handleChangeTab.bind(this, "Images")}
                                        >
                                            Images
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={NewEditoralItemStyles.tabContent}>
                                            {settings.tab == "Settings" ? (
                                                <div>
                                                    Title Visible:
                                                    <Switch
                                                        className="customCSS-Content-New-Item-Switch"
                                                        onChange={handleTitleShowChange}
                                                        checked={settings.titleShow}
                                                        checkedIcon={false}
                                                        uncheckedIcon={false}
                                                    />
                                                    <div style={!settings.titleShow ? NewEditoralItemStyles.hidden : null}>
                                                        Position:
                                                        <Dropdown>
                                                            <Dropdown.Toggle>{settings.titlePosition}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={handleChangeTitlePosition.bind(this, "left")}>
                                                                    Left
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={handleChangeTitlePosition.bind(this, "center")}>
                                                                    Center
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={handleChangeTitlePosition.bind(this, "right")}>
                                                                    Right
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {settings.tab == "Images" ? (
                                                <div>
                                                    <Row>
                                                        <Col style={NewEditoralItemStyles.insertImageButton}>
                                                            <Button onClick={handleInsertImageButton}>Add Image</Button>
                                                        </Col>
                                                    </Row>
                                                    {GS_contentSettings.images.length > 0 ? (
                                                        <div>
                                                            <Row>
                                                                <Col>Image ID</Col>
                                                                <Col>Image URL</Col>
                                                                <Col></Col>
                                                                <Col></Col>
                                                            </Row>
                                                            <ListGroup style={NewEditoralItemStyles.scrollable250}>
                                                                {GS_contentSettings.images.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            {editItem == index ? (
                                                                                <div>
                                                                                    <ListGroup.Item>
                                                                                        <Row>
                                                                                            <Col sm={2}>{item.id}</Col>
                                                                                            <Col sm={4}>{truncateString(item.url, 30)}</Col>
                                                                                            <Col sm={2}>
                                                                                                Width <br />
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    value={item.width}
                                                                                                    onChange={(event) =>
                                                                                                        handleImageSizeChange(event, "width", index)
                                                                                                    }
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                Height
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    value={item.height}
                                                                                                    onChange={(event) =>
                                                                                                        handleImageSizeChange(event, "height", index)
                                                                                                    }
                                                                                                />
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Delete</Tooltip>}
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={handleDeleteImage.bind(this, index)}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-trash-can"></i>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Back</Tooltip>}
                                                                                                >
                                                                                                    <Button onClick={handleEditBack}>
                                                                                                        <i className="fa-solid fa-circle-left"></i>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </ListGroup.Item>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <ListGroup.Item key={index}>
                                                                                        <Row>
                                                                                            <Col sm={2}>{item.id}</Col>
                                                                                            <Col sm={4}>{truncateString(item.url, 30)}</Col>
                                                                                            <Col sm={2}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Find Image</Tooltip>}
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={handleOpenMediaCanvas.bind(
                                                                                                            this,
                                                                                                            index
                                                                                                        )}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-magnifying-glass"></i>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Insert Image</Tooltip>}
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={handleInsertImageIntoText.bind(
                                                                                                            this,
                                                                                                            item.id
                                                                                                        )}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-circle-arrow-right"></i>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                            <Col sm={2}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip>Edit</Tooltip>}
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={handleEditItem.bind(this, index)}
                                                                                                    >
                                                                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </ListGroup.Item>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </ListGroup>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col style={NewEditoralItemStyles.previewContainer}>{HTMLParse(DOMPurify.sanitize(previewText))}</Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton style={modal.error ? UploadStyles.errorModalColor : UploadStyles.successModalColor}>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={previewModal.open} onHide={handlePreviewModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{HTMLParse(DOMPurify.sanitize(previewModal.html))}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlePreviewModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Offcanvas style={NewEditoralItemStyles.mediaCanvas} show={media.canvas_open} onHide={handleCloseMediaCanvas} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Media - Choose an image </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <MediaCanvas />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default NewEditoralItem;
