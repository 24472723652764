import * as Colors from "../../../../globalStyles/environmentColors";

export const body = {
    paddingRight: "30px",
    paddingLeft: "30px",
    paddingTop: "10px"
};

export const newTicketButtonRow = {
    textAlign: "right"
};

export const newTicketTitle = {
    textAlign: "center"
};

export const newTicketTypeSection = {
    textAlign: "left"
};

export const subjectCounter = {
    fontSize: "1rem",
    color: "red",
    textAlign: "right"
};

export const errorText = {
    fontSize: "1rem",
    color: "red"
};

export const errorTextHidden = {
    fontSize: "1rem",
    color: "red",
    visibility: "hidden"
};

export const uploadDescription = {
    fontSize: "1rem",
    fontWeight: "bold"
};

export const multipleFilesSection = {
    border: "solid 2px red",
    padding: "10px",
    fontSize: "1rem",
    height: "150px",
    overflowY: "auto"
};

export const submitButtom = {
    textAlign: "right"
};

export const filesTableRow = {
    padding: "5px"
};

export const filesTableheading = {
    color: "red"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const ticketHolder_body = {
    paddingTop: "10px"
};

export const noTicketsNotice = {
    textAlign: "center",
    fontSize: "2rem"
};
