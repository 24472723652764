import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Switch from "react-switch";
import Axios from "axios";

function UserPermissions(props) {
    function handleSwitchContractorsChange(checked) {
        setChange("contractorAccess", checked);
    }

    function handleSwitchUsersChange(checked) {
        setChange("userAccess", checked);
    }

    function setChange(userType, permission) {
        const data = { taskUUID: props.taskUUID, userType, permission };

        Axios.post("/pods/plannerTasks/changePermissions", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    props.loadDetails();
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header>
                    <Modal.Title>User Permissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Users that can add comments to this task:
                    <br /> <br />
                    <Row>
                        <Col>Contractors</Col>
                        <Col>
                            <Switch
                                className="customCSS-Content-New-Item-Switch"
                                onChange={handleSwitchContractorsChange}
                                checked={props.contrators}
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>Users</Col>
                        <Col>
                            <Switch
                                className="customCSS-Content-New-Item-Switch"
                                onChange={handleSwitchUsersChange}
                                checked={props.users}
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserPermissions;
