import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import EnvironmentPodsMap from "../../../environment/pods/environmentPodsMap";

//STYLES
import * as GS_navSettingsActions from "../../../store/actions/globalSettings/GS_navSettings";

function StyleArea() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Style Configuration"));
        dispatch(GS_navSettingsActions.UpdateSelected("Style Configuration"));
    }, []);
    return <div>{<EnvironmentPodsMap podCode="STYLINGCONFIG" />}</div>;
}

export default StyleArea;
