import * as Colors from "../../../../../globalStyles/environmentColors";

export const body = {
    padding: "30px"
};

export const heading = {
    textAlign: "center"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    textAlign: "center",
    cursor: "pointer"
};

export const noPlansText = {
    textAlign: "center",
    paddingTop: "30px"
};
