import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import CenterContainer from "../../../../special/wholeSite/centerContainer";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { useSelector } from "react-redux";

//STYLES
import * as loginFormStyles from "../../../loginPod/styles/loginForm";
import * as registerStyles from "../styles/register";

function ChangePassword() {
    const navigate = useNavigate();
    const params = useParams();

    const user = useSelector((state) => state.user);
    const [formState, setFormState] = useState({
        valids: {
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        },
        touched: {
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        },
        wholeForm: false,
        passwordMessage: "",
        passwordNotice: false,
        confirmPasswordNotice: ""
    });

    const [modal, setModal] = useState({
        title: "",
        message: "",
        show: false
    });

    const [completedRequest, setCompletedRequest] = useState(false);

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, show: false };
        });
    }

    function handleInputChange(event) {
        const { value, name } = event.target;

        const valids = formState.valids;

        let valid = true;
        let passwordMessage = "";
        let passwordNotice = false;
        let confirmPasswordNotice = "";

        if (value.length != 0) {
            valid = true;
        }

        valids[name] = valids;

        let wholeForm = false;

        if (name == "newPassword") {
            let valid = true;

            if (value.length == 0) {
                valid = false;
                passwordMessage = "Please enter a new password";
            } else if (!ValidatePassword(value)) {
                valid = false;
                passwordMessage = "Password is too weak!";
                passwordNotice = true;
            }
            valids[name] = valid;
        }

        if (name == "confirmPassword") {
            let valid = true;

            if (value.length == 0) {
                valid = false;
                confirmPasswordNotice = "Please re-enter your new password";
            } else if (value != document.getElementById("newPass").value) {
                valid = false;
                confirmPasswordNotice = "Passwords do not match";
            }
            valids[name] = valid;
        }

        if (valids.currentPassword && valids.newPassword && valids.confirmPassword) {
            wholeForm = true;
        }
        setFormState((prevState) => {
            return {
                ...prevState,
                valids: valids,
                wholeForm: wholeForm,
                passwordMessage: passwordMessage,
                passwordNotice: passwordNotice,
                confirmPasswordNotice: confirmPasswordNotice
            };
        });
    }

    function handleTouched(event) {
        const { name } = event.target;

        const updatedTouched = { ...formState.touched, [name]: true };

        setFormState((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formState.touched).map((k) => ({ [k]: true })));
        setFormState((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function handleSubmit() {
        if (!formState.wholeForm) {
            switchAllTouches();
        } else {
            const currentPassword = document.getElementById("currentPass").value;
            const newPassword = document.getElementById("newPass").value;
            const confirmPassword = document.getElementById("confirmPass").value;

            const data = { userID: user.id, oldPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword };
            Axios.post("/pods/membership/changePassword", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    if (data.error == "Yes") {
                        setModal({ Header: "Change Password", message: data.message, open: true });
                    } else {
                        setCompletedRequest(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleGoBack() {
        if (params.redirectBack == "pod") {
            navigate(-1);
        }
        if (params.redirectBack == "main") {
            navigate(-2);
        }
    }

    function ValidatePassword(password) {
        let check = true;

        if (password.length <= 8) {
            check = false;
        } else if (!/[a-z]/.test(password)) {
            check = false;
        } else if (!/[A-Z]/.test(password)) {
            check = false;
        } else if (!/\d/.test(password)) {
            check = false;
        } else if (!password.match(/[!@#$£%^&*()]/)) {
            check = false;
        }

        return check;
    }

    return (
        <div>
            <CenterContainer>
                <h1>Change You Password</h1>
                <br />
                {completedRequest ? (
                    <div>
                        <p>Your password has now been changed!</p>
                        <p>Click the button below to go back</p>
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={handleGoBack}>
                            Go Back
                        </Button>
                    </div>
                ) : (
                    <div>
                        <p>You need to change your password after using a temporary password</p>
                        <Form>
                            <Form.Group>
                                <Form.Label>Current Password:</Form.Label>
                                <Form.Control
                                    style={registerStyles.FP_formTextBox}
                                    sm={3}
                                    isInvalid={formState.touched.currentPassword && !formState.valids.currentPassword ? true : false}
                                    type="password"
                                    name="currentPassword"
                                    id="currentPass"
                                    onChange={handleInputChange}
                                    onBlur={handleTouched}
                                />
                                <Form.Control.Feedback style={registerStyles.FP_InputError} type="invalid">
                                    Please enter your current password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <br />
                            <Form.Group>
                                <Form.Label>New Password:</Form.Label>
                                <Form.Control
                                    style={registerStyles.FP_formTextBox}
                                    sm={3}
                                    isInvalid={formState.touched.newPassword && !formState.valids.newPassword ? true : false}
                                    type="password"
                                    name="newPassword"
                                    id="newPass"
                                    onChange={handleInputChange}
                                    onBlur={handleTouched}
                                />
                                <Form.Control.Feedback style={registerStyles.FP_InputError} type="invalid">
                                    {formState.passwordMessage != "" ? formState.passwordMessage : "Please enter a new password"}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <br />
                            {formState.touched.newPassword && formState.passwordNotice ? (
                                <div>
                                    <p style={registerStyles.passwordNotice}>
                                        Your password must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character. It also requires
                                        to be a minimum 8 characters in length
                                    </p>
                                    <br />
                                </div>
                            ) : null}
                            <Form.Group>
                                <Form.Label>Confirm new Password:</Form.Label>
                                <Form.Control
                                    style={registerStyles.FP_formTextBox}
                                    sm={3}
                                    isInvalid={formState.touched.confirmPassword && !formState.valids.confirmPassword ? true : false}
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPass"
                                    onChange={handleInputChange}
                                    onBlur={handleTouched}
                                />
                                <Form.Control.Feedback style={registerStyles.FP_InputError} type="invalid">
                                    {formState.confirmPasswordNotice != "" ? formState.confirmPasswordNotice : "Please re-enter your new password"}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                        <br /> <br />
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                )}
            </CenterContainer>
            <Modal show={modal.show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ChangePassword;
