import * as Colors from "../../../../../globalStyles/environmentColors";

export const body = {
    padding: "30px"
};

export const itemsBtn = {
    textAlign: "right"
};

export const itemAddUpdateBtn = {
    textAlign: "right"
};

export const itemBackBtn = {
    textAlign: "left"
};

export const switchBtns = {
    textAlign: "right"
};

export const itemCard = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer"
};

export const itemsBody = {
    height: "500px",
    overlow: "auto",
    overflowX: "hidden"
};

export const itemBtnCard = {
    backgroundColor: Colors.primary,
    color: "yellow",
    cursor: "pointer",
    textAlign: "center"
};

export const tableHeading = {
    border: "1px solid black",
    textAlign: "center",
    fontWeight: "bold"
};

export const tableItemCell = {
    border: "1px solid black",
    cursor: "pointer"
};

export const tablePriceCell = {
    border: "1px solid black",
    textAlign: "center",
    cursor: "pointer"
};

export const itemScroll = {
    height: "200px",
    overlow: "auto",
    overflowX: "hidden"
};

export const statementTotal = {
    fontWeight: "bold",
    textAlign: "left"
};

export const statementPreviewButton = {
    textAlign: "right"
};

export const errorSubmitError = {
    color: "red"
};

export const pdfLodingSign = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5rem"
};

export const closeBtn = {
    textAlign: "right"
};

export const exCusBtn = {
    textAlign: "right"
};

export const footerStyle = {
    border: "1px bold solid",
    textAlign: "right"
};

export const exCusNoCus = {
    textAlign: "center"
};

export const logo = {
    width: "20px"
};

export const paymentMethodError = {
    color: "red"
};

export const paymentMethodsTitle = {
    margin: "auto",
    textAlign: "right"
};

export const paymentMethodsDropdown = {
    textAlign: "right"
};
