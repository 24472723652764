import React, { useState, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import Axios from "axios";

//STYLES
import * as FileViewerStyles from "../styles/fileViewer";

function FileViewer(props) {
    function handleOnClick(filename) {
        let filePath = "";
        if (props.original) {
            filePath = `/content/helpDesk/${props.ticketUUID}/original/${filename}`;
        } else {
            filePath = `/content/helpDesk/${props.ticketUUID}/${props.replyUUID}/${filename}`;
        }
        window.open(filePath, "_blank");
    }

    return (
        <div>
            <Modal show={props.open} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>File(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.files != null ? (
                        <div>
                            {props.files.map((file, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={FileViewerStyles.cardStyle} onClick={handleOnClick.bind(this, file)}>
                                            <Card.Body>{file}</Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FileViewer;
