import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import CenterContainer from "../../../../special/wholeSite/centerContainer";

//STYLES
import * as loginFormStyles from "../.././../loginPod/styles/loginForm";

function EmailVerified() {
    const params = useParams();
    const navigate = useNavigate();
    const [verified, setVerified] = useState("No");

    useEffect(() => {
        const data = { id: params.id, email: params.email };
        Axios.post("/pods/membership/emailVerified", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.message == "Email Verified") {
                    setVerified("Yes");
                } else {
                    setVerified("Error");
                }
            })
            .catch((err) => {
                console.log(err);
                setVerified("Error");
            });
    }, []);

    function goToHome() {
        navigate("/");
    }

    return (
        <div>
            <CenterContainer>
                {verified === "No" ? <h1>Verifing email...</h1> : null}
                {verified === "Yes" ? (
                    <div>
                        <h1>Email Verified!</h1>
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={goToHome}>
                            Go to Home
                        </Button>
                    </div>
                ) : null}
                {verified === "Error" ? (
                    <div>
                        <h1>There was a problem verifing your email</h1>
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={goToHome}>
                            Go to Home
                        </Button>
                    </div>
                ) : null}
            </CenterContainer>
        </div>
    );
}

export default EmailVerified;
