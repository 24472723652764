import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import HTMLParse from "html-react-parser";

//STYLES
import * as FrontEndMainContactStyles from "../../../../environment/globalStyles/frontEndMainContact";

const { REACT_APP_ReCAPTCHA_KEY } = process.env;

function FrontEndMainContact() {
    const reRef = useRef();
    const navigate = useNavigate();
    const [editorial, setEditorial] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [names, setNames] = useState({
        one: "1",
        two: "",
        three: "",
        four: ""
    });
    const [details, setDetails] = useState({
        name: "",
        email: "",
        address: "",
        telephone: "",
        showDetails: false
    });

    useEffect(() => {
        onOpen();
    }, []);

    function onOpen() {
        Axios.post("/adminPods/contacts/frontEnd/onLoad")
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    setNames({
                        one: data.security.one,
                        two: data.security.two,
                        three: data.security.three,
                        four: data.security.four
                    });
                    setDetails({
                        name: data.details.name,
                        email: data.details.email,
                        address: data.details.address,
                        telephone: data.details.telephone,
                        showDetails: data.details.name != "" || data.details.email != "" || data.details.address != "" || data.details.telephone != ""
                    });
                    if (data.editorial != "n/a") {
                        let editorial = data.editorial.editorial.content;

                        for (const image of data.editorial.images) {
                            editorial = editorial.replace(
                                `{${image.id}}`,
                                `<img style="max-width:100%; max-height:100%" src="/content/${image.url}" width=${image.width} height=${image.height}/>`
                            );
                        }

                        setEditorial(editorial);
                    } else {
                        setEditorial("n/a");
                    }
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSendForm() {
        setErrorMessage("");
        const one = document.getElementById(names.one).value;
        const two = document.getElementById(names.two).value;
        const three = document.getElementById(names.three).value;
        const four = document.getElementById(names.four).value;

        const token = reRef.current.getValue();

        if (one == "") {
            setErrorMessage("Please enter a name");
            return;
        }
        if (two == "") {
            setErrorMessage("Please enter an email");
            return;
        }
        if (three == "") {
            setErrorMessage("Please enter a subject");
            return;
        }
        if (four == "") {
            setErrorMessage("Please enter a message");
            return;
        }

        if (token == "") {
            setErrorMessage("Please check if you're a human");
        } else {
            const data = {
                one: one,
                two: two,
                three: three,
                four: four,
                token: token
            };
            Axios.post("/adminPods/contacts/frontEnd/submitMainContactForm", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "Yes") {
                        setErrorMessage(data.message);
                    } else {
                        setSubmitted(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const browserView = (
        <div>
            <h1>Contact Us</h1>
            {editorial != "n/a" && HTMLParse(DOMPurify.sanitize(editorial))}
            <br />
            {submitted && (
                <div>
                    <br />
                    <h2>Thank you for your query</h2>
                    <br />
                    <h2>Someone will get back in touch!</h2>
                </div>
            )}
            <div style={submitted ? FrontEndMainContactStyles.hidden : FrontEndMainContactStyles.unhidden}>
                {details.showDetails && (
                    <div>
                        Please use the contact details below or fill in the form
                        <br />
                        <br />
                        <Row>
                            <Col>
                                {details.name != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.detailsHeadings}>Name:</Col>
                                            <Col style={FrontEndMainContactStyles.details}>{details.name}</Col>
                                        </Row>
                                        <br />
                                    </div>
                                )}
                            </Col>
                            <Col>
                                {details.email != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.detailsHeadings}>Email:</Col>
                                            <Col style={FrontEndMainContactStyles.details}>{details.email}</Col>
                                        </Row>
                                        <br />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {details.address != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.detailsHeadings}>Address:</Col>
                                            <Col style={FrontEndMainContactStyles.details}>{details.address}</Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                            <Col>
                                {details.telephone != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.detailsHeadings}>Telephone:</Col>
                                            <Col style={FrontEndMainContactStyles.details}>{details.telephone}</Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <br />
                    </div>
                )}
                <Row>
                    <Col sm={3} style={FrontEndMainContactStyles.formLabels}>
                        <Row>
                            <Col>
                                <Form.Label className="formFieldText">Name:</Form.Label>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="formFieldText">Email:</Form.Label>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="formFieldText">Subject:</Form.Label>
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className="formFieldText">Message:</Form.Label>
                                <br />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col>
                                <Form.Control type="text" id={names.one} />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type="text" id={names.two} />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control type="text" id={names.three} />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control as="textarea" rows={3} id={names.four} />
                                <br />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={FrontEndMainContactStyles.reCAPTCHA}>
                    <ReCAPTCHA sitekey={REACT_APP_ReCAPTCHA_KEY} ref={reRef} />
                </div>
                <br />
                <p style={FrontEndMainContactStyles.errorMessage}>{errorMessage}</p>
                <Button variant={FrontEndMainContactStyles.buttonVariant} style={FrontEndMainContactStyles.button} onClick={handleSendForm}>
                    Send
                </Button>
            </div>
        </div>
    );

    const mobileView = (
        <div>
            <h1>Contact Us</h1>
            <br />
            {submitted && (
                <div>
                    <br />
                    <h2>Thank you for your query</h2>
                    <br />
                    <h2>Someone will get back in touch!</h2>
                </div>
            )}
            <div style={submitted ? FrontEndMainContactStyles.hidden : FrontEndMainContactStyles.unhidden}>
                {details.showDetails && (
                    <div>
                        Please use the contact details below or fill in the form
                        <br />
                        <br />
                        <Row>
                            <Col>
                                {details.name != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.m_detailsHeadings}>
                                                Name:
                                                <br />
                                                {details.name}
                                            </Col>
                                        </Row>
                                        <br />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {details.email != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.m_detailsHeadings}>
                                                Email:
                                                <br />
                                                {details.email}
                                            </Col>
                                        </Row>
                                        <br />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {details.address != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.m_detailsHeadings}>
                                                Address:
                                                <br />
                                                {details.address}
                                            </Col>
                                        </Row>
                                        <br />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {details.telephone != "" && (
                                    <div>
                                        <Row>
                                            <Col style={FrontEndMainContactStyles.m_detailsHeadings}>
                                                Telephone:
                                                <br />
                                                {details.telephone}
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <br />
                    </div>
                )}
                <Row>
                    <Col style={FrontEndMainContactStyles.m_formLabels}>
                        <Form.Label className="formFieldText">Name:</Form.Label>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" id={names.one} />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col style={FrontEndMainContactStyles.m_formLabels}>
                        <Form.Label className="formFieldText">Email:</Form.Label>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" id={names.two} />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col style={FrontEndMainContactStyles.m_formLabels}>
                        <Form.Label className="formFieldText">Subject:</Form.Label>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" id={names.three} />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col style={FrontEndMainContactStyles.m_formLabels}>
                        <Form.Label className="formFieldText">Message:</Form.Label>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control as="textarea" rows={3} id={names.four} />
                        <br />
                    </Col>
                </Row>

                <div style={FrontEndMainContactStyles.reCAPTCHA}>
                    <ReCAPTCHA sitekey={REACT_APP_ReCAPTCHA_KEY} ref={reRef} />
                </div>
                <br />
                <p style={FrontEndMainContactStyles.errorMessage}>{errorMessage}</p>
                <Button variant="warning" style={FrontEndMainContactStyles.button} onClick={handleSendForm}>
                    Send
                </Button>
            </div>
        </div>
    );

    return (
        <div>
            <Row>
                <Col sm={FrontEndMainContactStyles.ends == null ? 2 : FrontEndMainContactStyles.ends}></Col>
                <Col
                    sm={FrontEndMainContactStyles.middle == null ? 8 : FrontEndMainContactStyles.middle}
                    style={FrontEndMainContactStyles.centerContainer}
                >
                    {isBrowser ? browserView : mobileView}
                </Col>
                <Col sm={FrontEndMainContactStyles.ends == null ? 2 : FrontEndMainContactStyles.ends}></Col>
            </Row>
        </div>
    );
}

export default FrontEndMainContact;
