import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as OrdersStyles from "../styles/orders";

function Orders() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("3D Printing Service - Orders"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("3D Printing Service"));

        loadOrders();
    }, []);

    function loadOrders() {
        Axios.post("/pods/3dPrintingShop/orders/getOrders")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setOrders(data.orders);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOnClick(id) {
        navigate(`../orderDetails/${id}`);
    }

    return (
        <div style={OrdersStyles.body}>
            <Card style={OrdersStyles.cardHeader}>
                <Card.Body>
                    <Row>
                        <Col>Date</Col>
                        <Col>Customer</Col>
                        <Col>No. of Items</Col>
                        <Col>Total</Col>
                        <Col>Status</Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            {orders.map((order, index) => {
                return (
                    <div key={index}>
                        <Card style={OrdersStyles.cardStyle} onClick={handleOnClick.bind(this, order.id)}>
                            <Card.Body>
                                <Row>
                                    <Col>{order.dateCreated}</Col>
                                    <Col>{order.name}</Col>
                                    <Col>{order.noOfItems}</Col>
                                    <Col>{`£${(order.total / 100).toFixed(2)}`}</Col>
                                    <Col>{order.status}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                );
            })}
        </div>
    );
}

export default Orders;
