import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const textBox = {
    width: "100px"
};

export const columnRight = {
    textAlign: "right"
};
