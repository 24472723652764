export const body = {
    width: "100%",
    display: "table"
};

export const heading = {
    display: "table-row",
    height: "40px"
};

export const addNewTaskRow = {
    display: "table-row",
    height: "40px"
};

export const statusTitles = {
    textAlign: "center",
    fontWeight: "bold",
    borderRight: "solid 1px rgba(0, 0, 0, 0.3)"
};

export const statusEndTitle = {
    textAlign: "center",
    fontWeight: "bold"
};

export const statusTitlesRow = {
    display: "table-row",
    height: "50px"
};

export const titleVisible = {
    visibility: "visible",
    textAlign: "center"
};

export const titleHidden = {
    visibility: "hidden"
};

export const taskColumns = {
    display: "table-row"
};

export const planColumns = {
    textAlign: "center",
    borderRight: "solid 1px rgba(0, 0, 0, 0.3)",
    paddingLeft: "20px",
    overflow: "auto"
};

export const mobilePlanColumns = {
    textAlign: "center",
    paddingLeft: "20px",
    overflow: "auto"
};

export const planEndColumn = {
    textAlign: "center",
    height: "100%"
};

export const plansRowHeight = {
    height: "100%"
};

export const taskBody = {
    textAlign: "center"
};

export const mobileRightArrow = {
    textAlign: "right"
};

export const statusTitle = {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "bold"
};

export const visible = {
    visibility: "visible"
};

export const hidden = {
    visibility: "hidden"
};
