import React, { useState, useRef } from "react";
import { Row, Col, Form, ListGroup, Button, Modal } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";

//STYLES
import * as TicketReplyStyles from "../styles/ticketReply";

function TicketReply(props) {
    const user = useSelector((state) => state.user);
    const [files, setFiles] = useState(null);
    const fileInputRef = useRef(null);
    const descriptionCounterMax = 800;

    const [descriptionSettings, setDescriptionSettings] = useState({
        value: "",
        touched: false,
        valid: false,
        counter: descriptionCounterMax,
        maxCounter: descriptionCounterMax,
        filesViewer: false
    });

    function handleUpdateTouch(event) {
        setDescriptionSettings((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [failedFilesModal, setFailedFilesModal] = useState({
        files: [],
        open: false
    });

    function handleCloseFailedFilesModal() {
        setFailedFilesModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleOnInputChange(event) {
        const { value } = event.target;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        const counter = descriptionCounterMax - value.length;

        setDescriptionSettings((prevState) => {
            return { ...prevState, value: value, valid: isValid, counter: counter };
        });
    }

    function handleFileInputChange(event) {
        setFiles(event.target.files);

        let filesViewer = false;
        if (event.target.files.length > 1) {
            filesViewer = true;
        }

        setDescriptionSettings((prevState) => {
            return { ...prevState, filesViewer: filesViewer };
        });
    }

    function switchAllTouches() {
        setDescriptionSettings((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function onRefreash() {
        props.refresh();
        fileInputRef.current.value = null;
        setDescriptionSettings((prevState) => {
            return { ...prevState, value: "", touched: false, valid: false, fileViewer: false };
        });
        setFiles(null);
    }

    function handleReplySubmit(status, systemClose) {
        if (!systemClose && !descriptionSettings.valid) {
            switchAllTouches();
        } else {
            let author = "";
            if (props.type == "admin") {
                author = "HVS";
            } else {
                author = user.id;
            }

            let description = "";
            if (systemClose) {
                description = "High-View Studios has closed this ticket.";
            } else {
                description = descriptionSettings.value.replace(/\s+/g, " ").trim();
            }

            if (files == null || files.length == 0) {
                const data = {
                    ticketUUID: props.ticketUUID,
                    authorID: author,
                    description: description,
                    status: status
                };

                Axios.post("/pods/helpdesk/tickets/ticketReplyWithoutFiles", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "null") {
                            onRefreash();
                            if (status == "reopened") {
                                props.reOpenDisable();
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                let localFiles = [];
                for (const [key, file] of Object.entries(files)) {
                    localFiles.push({
                        id: key,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    });
                }

                const checkData = { files: localFiles };
                Axios.post("/pods/helpdesk/tickets/checkFiles", checkData)
                    .then((res) => {
                        const firstData = res.data;
                        if (firstData.error == "Yes") {
                            setModal({ header: "Reply", message: firstData.message, open: true });
                        } else if (firstData.limitError == "Yes") {
                            setModal({
                                error: true,
                                header: "File Limit Error",
                                message: "You can only upload 2 files at a time!",
                                open: true
                            });
                        } else if (firstData.failed) {
                            setFailedFilesModal({ files: firstData.files, open: true });
                        } else {
                            const uploadFormData = new FormData();
                            uploadFormData.append("uuid", firstData.uuid);
                            uploadFormData.append("ticketUUID", props.ticketUUID);
                            uploadFormData.append("authorID", author);
                            uploadFormData.append("status", status);
                            uploadFormData.append("description", description);

                            const uploadConfig = {
                                headers: {
                                    "content-type": "multipart/form-data"
                                }
                            };
                            localFiles.forEach((lFile) => {
                                const file = firstData.files.find((file) => file.id === lFile.id);
                                if (file == null) {
                                    uploadFormData.append("files", files[lFile.id]);
                                }
                            });
                            Axios.post("/pods/helpdesk/tickets/ticketReplyWithFiles", uploadFormData, uploadConfig)
                                .then((res) => {
                                    const secondData = res.data;
                                    console.log(secondData);
                                    if (secondData.error == "null") {
                                        onRefreash();
                                        if (status == "reopened") {
                                            props.reOpenDisable();
                                        }
                                    }
                                })
                                .catch((err) => console.log(err));
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }

    function handleTicketClose() {
        if (fileInputRef.current.value != null && files != null) {
            setModal({
                header: "Close Ticket",
                message: "You have got files to this reply, please add a description and click 'Submit and Close' ",
                open: true
            });
        } else {
            handleReplySubmit("closed", true);
        }
    }

    return (
        <div style={TicketReplyStyles.body}>
            <h3>Reply</h3>

            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    isInvalid={descriptionSettings.touched && !descriptionSettings.valid ? true : false}
                                    as="textarea"
                                    rows={4}
                                    name="description"
                                    placeholder="Please enter a reply"
                                    maxLength={descriptionCounterMax}
                                    value={descriptionSettings.value}
                                    onChange={handleOnInputChange}
                                    onBlur={handleUpdateTouch}
                                />

                                <Row>
                                    <Col
                                        style={
                                            descriptionSettings.touched && !descriptionSettings.valid
                                                ? TicketReplyStyles.errorText
                                                : TicketReplyStyles.errorTextHidden
                                        }
                                    >
                                        Please fill in the description
                                    </Col>
                                    <Col style={TicketReplyStyles.descriptionCounter}>{descriptionSettings.counter}</Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={TicketReplyStyles.buttonsSection}>
                            {props.reOpen ? (
                                <div>
                                    <Button onClick={handleReplySubmit.bind(this, "reopened", false)}>Re-open Ticket</Button>
                                </div>
                            ) : (
                                <div>
                                    <Button onClick={handleReplySubmit.bind(this, "", false)}>Submit</Button>
                                    {props.type == "admin" ? (
                                        <div>
                                            <Button onClick={handleTicketClose}>Close</Button>
                                            <Button onClick={handleReplySubmit.bind(this, "closed", false)}>Submit and Close</Button>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Form.Label>Files / Screenshots:</Form.Label>
                            <Form.Control ref={fileInputRef} type="file" name="files/screenshots" onChange={handleFileInputChange} multiple />
                            <div style={TicketReplyStyles.uploadDescription}>2 Files Max. 4MB Max per file</div>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <div style={descriptionSettings.filesViewer ? TicketReplyStyles.fileSection : TicketReplyStyles.fileSectionHidden}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Files:</Form.Label>
                                </Form.Group>
                                {descriptionSettings.filesViewer && files !== null ? (
                                    <div style={TicketReplyStyles.multipleFilesSection}>
                                        <ListGroup>
                                            {Object.keys(files).map((key, index) => {
                                                return <ListGroup.Item key={index}>{files[key].name}</ListGroup.Item>;
                                            })}
                                        </ListGroup>
                                    </div>
                                ) : null}
                            </Col>
                        </div>
                    </Row>
                </Col>
            </Row>

            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={failedFilesModal.open} onHide={handleCloseFailedFilesModal}>
                <Modal.Header closeButton style={TicketReplyStyles.filesTableheading}>
                    <Modal.Title>Reply: Failed Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        The following files can&#39;t be uploaded with this ticket, as to the reasons given. These need to be corrected before the
                        ticket can be submitted
                        <br />
                        <table border="1px" width="100%">
                            <thead>
                                <tr>
                                    <th style={TicketReplyStyles.filesTableRow}>
                                        <strong>File</strong>
                                    </th>
                                    <th style={TicketReplyStyles.filesTableRow}>
                                        <strong>Reason</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedFilesModal.files.map((file, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={TicketReplyStyles.filesTableRow}>{file.file}</td>
                                            <td style={TicketReplyStyles.filesTableRow}>{file.message}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <br />
                        <strong>Spaces in file names will be replaced by a &#39;_&#39; (underscore)</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFailedFilesModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TicketReply;
