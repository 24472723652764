import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//STYLES
import * as ProductDetailsStyles from "../styles/productDetails";

//COMPONENTS
import PhotoBox from "./photoBox";

//ACTIONS
import * as AccessModalActions from "../../../../envStore/actions/accessModal";
import * as PrintingShopActions from "../../../../envStore/actions/3dPrintingShop";

function ProductDetails(props) {
    const params = useParams();
    const dispatch = useDispatch();

    const [details, setDetails] = useState({});
    const [photos, setPhotos] = useState([]);
    const [quantity, setQuantity] = useState(1);

    const user = useSelector((state) => state.user);
    const printingShop = useSelector((state) => state.printingShop);

    const [customForm, setCustomForm] = useState({
        elements: {
            colours: [],
            fields: []
        },
        values: {},
        valids: {},
        active: false,
        errorOnForm: false
    });

    useEffect(() => {
        onLoad();
    }, []);

    function onLoad() {
        const data = { code: params.code };
        Axios.post("/pods/3dPrintingShop/frontEnd/getOneProduct", data)
            .then((res) => {
                const data = res.data;
                setDetails(data.product);
                setPhotos(data.photos);

                //customisation
                if (data.product.colours.length > 1 || data.product.customFields.length > 0) {
                    let elements = {
                        colours: [],
                        fields: []
                    };
                    let values = {};
                    let valids = {};
                    let active = false;

                    if (data.product.colours.length > 1) {
                        elements.colours = data.product.colours;
                        values.selectedColour = "Select Colour";
                        valids.colour = false;

                        active = true;
                    }

                    if (data.product.customFields.length > 0) {
                        elements.fields = data.product.customFields;

                        for (const [index, field] of data.product.customFields.entries()) {
                            values[`field_${index}`] = "";
                            valids[`field_${index}`] = false;
                        }

                        active = true;
                    }

                    setCustomForm((prevState) => {
                        return { ...prevState, elements, values, valids, active };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleSelectColour(color) {
        const values = customForm.values;
        const valids = customForm.valids;

        values.selectedColour = color;
        valids.colour = true;

        setCustomForm((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    function handleTextBoxOnChange(event) {
        const { name, value } = event.target;

        const values = customForm.values;
        const valids = customForm.valids;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        values[name] = value;
        valids[name] = isValid;

        setCustomForm((prevState) => {
            return { ...prevState, values, valids };
        });
    }

    function handleAddToCart() {
        if (customForm.active) {
            const customFormAllTrue = Object.keys(customForm.valids).every(function (k) {
                return customForm.valids[k] === true;
            });

            if (!customFormAllTrue) {
                setCustomForm((prevState) => {
                    return { ...prevState, errorOnForm: true };
                });
            } else {
                setCustomForm((prevState) => {
                    return { ...prevState, errorOnForm: false };
                });
                handleAddProductToCart();
            }
        } else {
            handleAddProductToCart();
        }
    }

    function handleAddProductToCart() {
        const data = { userID: user.id, productCode: details.productCode, quantity, customItem: customForm.active.toString() };
        const customData = [];

        if (customForm.active) {
            if (customForm.elements.colours.length > 0) {
                customData.push({
                    fieldName: "Colour",
                    value: customForm.values.selectedColour
                });
            }

            for (const [index, file] of Object.entries(customForm.elements.fields)) {
                customData.push({
                    fieldName: file.fieldName,
                    value: customForm.values[`field_${index}`]
                });
            }
        }
        data.customData = customData;

        if (user.auth) {
            Axios.post("/pods/3dPrintingShop/frontEnd/addProductToBasket", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "null") {
                        dispatch(PrintingShopActions.UpdateBasketItemTotal());

                        dispatch(PrintingShopActions.ToggleBasketSuccess(true));

                        setTimeout(() => {
                            dispatch(PrintingShopActions.ToggleBasketSuccess(false));
                        }, 2000);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            const actions = { action: "addItemToBasket", data: data };
            dispatch(AccessModalActions.LogActionsAndSignIn(actions));
        }
    }

    function handleIncreaseQuantity() {
        setQuantity(quantity + 1);
    }

    function handleDecreaseQuantity() {
        if (quantity != 1) {
            setQuantity(quantity - 1);
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <p style={ProductDetailsStyles.topProductCode}>Product Code: {details.productCode}</p>
                    <h1 style={ProductDetailsStyles.title}>{details.title}</h1>
                    <br />
                    <Row>
                        <Col>
                            <strong>Description:</strong> <br /> <br />
                            {details.description}
                            <br /> <br />
                            <strong>Specifications</strong>
                            <br /> <br />
                            Weight: {details.totalWeight} <br />
                            Time to build: {details.totalTime} (hours, mintues) <br /> <br />
                            {details.colours != null && details.colours.length == 1 && (
                                <div>
                                    <strong>Colour:</strong> <br />
                                    This product comes in {details.colours[0].name}
                                </div>
                            )}
                            <h2 style={ProductDetailsStyles.price}>£{(parseInt(details.price) / 100).toFixed(2)}</h2>
                            {customForm.active && (
                                <div>
                                    <strong>Customisation</strong> <br />
                                    <br />
                                    <Row>
                                        {customForm.elements.colours.length > 1 && (
                                            <Col>
                                                Colour:
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant={customForm.errorOnForm && !customForm.valids.colour ? "danger" : "primary"}
                                                    >
                                                        {customForm.values.selectedColour}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {customForm.elements.colours.map((colour, index) => {
                                                            return (
                                                                <Dropdown.Item key={index} onClick={handleSelectColour.bind(this, colour.name)}>
                                                                    {colour.name}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        )}
                                        {customForm.elements.fields.length > 0 && (
                                            <Col>
                                                {customForm.elements.fields.map((field, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {index != 0 && <br />}
                                                            <Form.Group>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Label>{field.fieldName}:</Form.Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <p style={ProductDetailsStyles.counter}>
                                                                            {field.length - customForm.values[`field_${index}`].length}/{field.length}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Control
                                                                            type="text"
                                                                            isInvalid={
                                                                                customForm.errorOnForm && !customForm.valids[`field_${index}`]
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            name={`field_${index}`}
                                                                            value={customForm.values[`field_${index}`]}
                                                                            maxLength={field.length}
                                                                            onChange={handleTextBoxOnChange}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            )}
                            <br />
                            <p style={customForm.errorOnForm ? ProductDetailsStyles.showError : ProductDetailsStyles.hideError}>
                                Please correct the errors in above customisation
                            </p>
                            <Row>
                                <Col style={ProductDetailsStyles.quantitySection}>
                                    Quantity: <br />
                                    <Button onClick={handleDecreaseQuantity}>-</Button>
                                    <Form.Label style={ProductDetailsStyles.quantityText}>{quantity}</Form.Label>
                                    <Button onClick={handleIncreaseQuantity}>+</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {printingShop.basketSuccess ? (
                                        <Button style={{ width: "100%" }} variant="success">
                                            Item Added To Basket
                                        </Button>
                                    ) : (
                                        <Button style={{ width: "100%" }} onClick={handleAddToCart}>
                                            Add To Basket
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col style={ProductDetailsStyles.photoContainer}>
                            <PhotoBox images={photos} uuid={details.uuid} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default ProductDetails;
