import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Axios from "axios";

import FileViewer from "./fileViewer";

//STYLES
import * as ReplyViewerStyles from "../styles/replyViewer";

function ReplyViewer(props) {
    const [fileViewerShow, setFileViewerShow] = useState({
        show: false,
        replyUUID: ""
    });

    function handleFilesViewerClose() {
        setFileViewerShow((prevState) => {
            return { ...prevState, show: false };
        });
    }

    function handleViewFileButton(replyUUID) {
        let data = { original: false, ticketUUID: props.info.ticketUUID, replyUUID: props.info.uuid };

        Axios.post("/pods/helpdesk/tickets/getTicketFiles", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    setFileViewerShow((prevState) => {
                        return { ...prevState, show: true, files: data.files };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={ReplyViewerStyles.ticketBorder}>
            <Row>
                <Col>
                    <Row>
                        <Col sm={3}>
                            <p>User:</p>
                        </Col>
                        <Col sm={9}>{props.info.user}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Reply:</p>
                            {props.info.status == "reopened" ? (
                                <div>
                                    <p>
                                        <strong>This ticket has been re-opened</strong>
                                    </p>
                                </div>
                            ) : null}
                            <p>{props.info.description}</p>
                            {props.info.status == "closed" ? (
                                <div>
                                    <p>
                                        <strong>This ticket has been closed</strong>
                                    </p>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col sm={3}>
                            <p>Date:</p>
                        </Col>
                        <Col sm={9}>{props.info.date}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <p>Files:</p>
                        </Col>
                        <Col>
                            {props.info.files == "true" ? (
                                <Button onClick={handleViewFileButton.bind(this, props.info.uuid)}>View File(s)</Button>
                            ) : (
                                "No file(s) attached"
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <FileViewer
                open={fileViewerShow.show}
                close={handleFilesViewerClose}
                files={fileViewerShow.files}
                original={false}
                ticketUUID={props.info.ticketUUID}
                replyUUID={props.info.uuid}
            />
            <br />
        </div>
    );
}

export default ReplyViewer;
