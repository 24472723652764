export const body = {
    paddingTop: "30px",
    paddingRight: "15px"
};

export const checkoutBtn = {
    textAlign: "center"
};

export const checkoutButton = {
    width: "100%"
};
