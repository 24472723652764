import React, { useEffect, useState, forwardRef } from "react";
import { Modal, Button, Row, Col, Form, Dropdown } from "react-bootstrap";
import Axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

//STYLES
import * as TaskDetailsStyles from "../styles/taskDetails";

//COMPONENTS
import TaskComment from "./taskComment";
import UserPermissions from "./userPermissions";

function TaskDetails(props) {
    const [details, setDetails] = useState({});
    const [comments, setComments] = useState([]);
    const [dueDate, setDueDate] = useState(new Date());
    const [dateVariant, setDateVariant] = useState("");
    const [editable, setEditable] = useState(false);
    const [addComments, setAddComments] = useState(false);

    const user = useSelector((state) => state.user);
    const planner = useSelector((state) => state.planner);

    const titleEditDefaults = {
        edit: false,
        title: "",
        titleCount: 100,
        titleMax: 100
    };
    const [titleEdit, setTitleEdit] = useState(titleEditDefaults);

    const descriptionEditDefaults = {
        edit: false,
        description: "",
        descriptionCount: 200,
        descriptionMax: 200
    };
    const [descriptionEdit, setDescriptionEdit] = useState(descriptionEditDefaults);

    const commentModalDefaults = {
        open: false,
        text: "",
        commentMax: 400,
        commentCount: 400
    };
    const [commentModal, setCommentModal] = useState(commentModalDefaults);

    function handleOpenComentModal() {
        setCommentModal((prevState) => {
            return { ...prevState, text: "", commentCount: 400, open: true };
        });
    }

    function handleCloseCommentModal() {
        setCommentModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const [userPermissionsModal, setUserPermissionsModal] = useState({
        open: false,
        contractors: false,
        users: true
    });

    function handleUserPermissionsClose() {
        setUserPermissionsModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleUserPermissionsOpen() {
        setUserPermissionsModal((prevState) => {
            return { ...prevState, open: true };
        });
    }

    const states = ["Not Started", "In Progress - Planning", "In Progress - Research", "In Progress - In Development", "To Be Tested", "Completed"];

    useEffect(() => {
        if (props.open) {
            setTitleEdit(titleEditDefaults);
            setDescriptionEdit(descriptionEditDefaults);
            setCommentModal(commentModalDefaults);
            loadDetails();
        }
    }, [props.open]);

    function loadDetails() {
        const data = { uuid: props.uuid };
        Axios.post("/pods/plannerTasks/getTaskDetails", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDetails(data.details);
                    const comments = data.comments;

                    comments.sort((a, b) => {
                        if (a.creationDate < b.creationDate) return 1;
                        if (a.creationDate > b.creationDate) return -1;
                        return 0;
                    });
                    setComments(comments);
                    if (data.details.dueDate != "") {
                        const dueDate = data.details.dueDate.split("/");
                        setDueDate(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`));

                        let btnVariant = "";

                        const green = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).subtract(7, "d");
                        const yellow = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).subtract(2, "d");
                        const red = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).add(1, "d");

                        if (moment().isBefore(green)) {
                            btnVariant = "primary";
                        } else {
                            if (moment().isSameOrAfter(green) && moment().isBefore(yellow)) {
                                btnVariant = "success";
                            } else {
                                if (moment().isSameOrAfter(yellow) && moment().isBefore(red)) {
                                    btnVariant = "warning";
                                } else {
                                    if (moment().isSameOrAfter(red)) {
                                        btnVariant = "danger";
                                    }
                                }
                            }
                        }

                        setDateVariant(btnVariant);
                    }
                    setUserPermissionsModal((prevState) => {
                        return { ...prevState, contractors: data.details.contractorAccess == "true", users: data.details.userAccess == "true" };
                    });

                    setUserPermissions(data.details.author, data.details.userAccess == "true", data.details.contractorAccess == "true");
                }
            })
            .catch((err) => console.log(err));
    }

    function setUserPermissions(author, userAccess, contractorAccess) {
        let addComments = false;

        if (planner.type == "admin") {
            setEditable(true);
            addComments = true;
        } else if (planner.type == "contractor") {
            if (author == user.id) {
                setEditable(true);
                addComments = true;
            } else {
                setEditable(false);
                if (contractorAccess) {
                    addComments = true;
                }
            }
        } else if (planner.type == "user") {
            setEditable(false);
            if (userAccess) {
                addComments = true;
            }
        }
        setAddComments(addComments);
    }

    function handleTitleTextChange(event) {
        const { value } = event.target;

        const newLength = titleEdit.titleMax - value.length;

        setTitleEdit((prevState) => {
            return { ...prevState, title: value, titleCount: newLength };
        });
    }

    function handleTitleEditClick() {
        setTitleEdit((prevState) => {
            return { ...prevState, title: details.title, edit: !prevState.edit, titleCount: titleEdit.titleMax - details.title.length };
        });
    }

    function handleTitleOnSave() {
        updateDetail("title", titleEdit.title);
    }

    function handleDescriptionEditClick() {
        setDescriptionEdit((prevState) => {
            return {
                ...prevState,
                description: details.description,
                edit: !prevState.edit,
                descriptionCount: descriptionEdit.descriptionMax - details.description.length
            };
        });
    }

    function handleDescriptionTextChange(event) {
        const { value } = event.target;

        const newLength = descriptionEdit.descriptionMax - value.length;

        setDescriptionEdit((prevState) => {
            return { ...prevState, description: value, descriptionCount: newLength };
        });
    }

    function handleDescriptionOnSave() {
        updateDetail("description", descriptionEdit.description);
    }

    function handleCommentTextChange(event) {
        const { value } = event.target;

        const newCount = commentModal.commentMax - value.length;

        setCommentModal((prevState) => {
            return { ...prevState, text: value, commentCount: newCount };
        });
    }

    function handleAddComment() {
        const data = { taskUUID: props.uuid, planUUID: details.planUUID, comment: commentModal.text, author: user.id };
        Axios.post("/pods/plannerTasks/insertComment", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadDetails();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleNextState() {
        let taskState = "";
        let check = true;
        states.forEach((state, index) => {
            if (state == details.state && check) {
                taskState = states[index + 1];
                check = false;
            }
        });
        updateState(taskState);
    }

    function updateState(state) {
        const data = { uuid: props.uuid, state: state };
        Axios.post("/pods/plannerTasks/updateState", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadDetails();
                    props.loadPlan();
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDateChange(date) {
        updateDetail("dueDate", moment(date).format("DD/MM/YYYY"));
    }

    //DATE BUTTON
    const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button variant={dateVariant} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    DateCustomInput.displayName = "Custom Date";

    function handleSetDateClick() {
        const date = moment().add(8, "d");

        updateDetail("dueDate", date.format("DD/MM/YYYY"));
    }

    function handleRemoveDateClick() {
        updateDetail("dueDate", "");
    }

    function updateDetail(detail, updatedValue) {
        const data = { uuid: props.uuid, detail, updatedValue };
        Axios.post("/pods/plannerTasks/updateDetail", data)
            .then((res) => {
                const data = res.data;
                console.log(data);
                if (data.error == "null") {
                    loadDetails();
                    props.loadPlan();

                    setDescriptionEdit((prevState) => {
                        return { ...prevState, edit: false };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div>
            <Modal show={props.open} size={"lg"} onHide={props.close}>
                <Modal.Header closeButton={!editable}>
                    <Modal.Title>Details</Modal.Title>
                    {editable && (
                        <div className="ml-auto">
                            <Dropdown>
                                <Dropdown.Toggle bsPrefix="p-0" variant="light">
                                    <div style={TaskDetailsStyles.headerButton}>
                                        <i className="fa-solid fa-ellipsis"></i>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleUserPermissionsOpen}>User Permissions</Dropdown.Item>
                                    <Dropdown.Item onClick={props.close}>Close</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <strong>Task:</strong> <br />
                            {titleEdit.edit ? (
                                <div>
                                    <Form.Control
                                        type="text"
                                        maxLength={titleEdit.titleMax}
                                        value={titleEdit.title}
                                        onChange={handleTitleTextChange}
                                    />
                                </div>
                            ) : (
                                <div>{details.title}</div>
                            )}
                        </Col>
                        {titleEdit.edit && (
                            <Col sm={1}>
                                <Button onClick={handleTitleOnSave}>
                                    <i className="fa-solid fa-floppy-disk"></i>
                                </Button>
                                <div style={TaskDetailsStyles.counter}>
                                    {titleEdit.titleCount}/{titleEdit.titleMax}
                                </div>
                            </Col>
                        )}
                        <Col sm={1}>
                            {titleEdit.edit ? (
                                <div>
                                    <Button onClick={handleTitleEditClick}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    {details.state != "Completed" && (
                                        <div>
                                            {editable && (
                                                <Button onClick={handleTitleEditClick}>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <strong>Description:</strong> <br />
                            {descriptionEdit.edit ? (
                                <div>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        maxLength={descriptionEdit.descriptionMax}
                                        value={descriptionEdit.description}
                                        onChange={handleDescriptionTextChange}
                                    />
                                </div>
                            ) : (
                                <div>{details.description}</div>
                            )}
                        </Col>
                        {descriptionEdit.edit && (
                            <Col sm={1}>
                                <Button onClick={handleDescriptionOnSave}>
                                    <i className="fa-solid fa-floppy-disk"></i>
                                </Button>
                                <div style={TaskDetailsStyles.counter}>
                                    {descriptionEdit.descriptionCount}/{descriptionEdit.descriptionMax}
                                </div>
                            </Col>
                        )}
                        <Col sm={1}>
                            {details.state != "Completed" && (
                                <div>
                                    {editable && (
                                        <Button onClick={handleDescriptionEditClick}>
                                            {descriptionEdit.edit ? (
                                                <i className="fa-solid fa-xmark"></i>
                                            ) : (
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            )}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col style={TaskDetailsStyles.sideLine}>
                            {editable ? (
                                <div>
                                    <Row>
                                        <Col>
                                            <strong>Status:</strong> <br /> {details.state}
                                        </Col>
                                        <Col sm={2}>
                                            <Dropdown>
                                                <Dropdown.Toggle></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {states.map((state, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} onClick={updateState.bind(this, state)}>
                                                                {state}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                        <Col sm={2}>
                                            {details.state != "Completed" && (
                                                <div>
                                                    <Button onClick={handleNextState}>
                                                        <i className="fa-solid fa-caret-right"></i>
                                                    </Button>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div>
                                    <Row>
                                        <Col>
                                            <strong>Status:</strong> <br /> {details.state}
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                        <Col>
                            {details.state != "Completed" && (
                                <div>
                                    {editable ? (
                                        <div>
                                            <div>
                                                {details.dueDate == "" ? (
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <strong>Due Date:</strong> <br /> No Due Date
                                                            </Col>
                                                            <Col>
                                                                <Button onClick={handleSetDateClick}>Set Date</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <strong>Due Date:</strong> <br />
                                                                <DatePicker
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={dueDate}
                                                                    onChange={(date: Date) => handleDateChange(date)}
                                                                    customInput={<DateCustomInput />}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Button onClick={handleRemoveDateClick}>Remove Date</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {details.dueDate == "" ? (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <strong>Due Date:</strong> <br /> No Due Date
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <strong>Due Date:</strong> <br /> {details.dueDate}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                    {addComments && (
                        <div>
                            <hr />
                            <Row>
                                <Col style={TaskDetailsStyles.addCommentButton}>
                                    <Button onClick={handleOpenComentModal}>Add Comment</Button>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <Row>
                        <Col>
                            {comments.map((comment, index) => {
                                return <TaskComment key={index} comment={comment.comment} date={comment.creationDate} author={comment.author} />;
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={commentModal.open} onHide={handleCloseCommentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        maxLength={commentModal.commentMax}
                        value={commentModal.text}
                        onChange={handleCommentTextChange}
                    />
                    <div style={TaskDetailsStyles.commentCounter}>
                        {commentModal.commentCount}/{commentModal.commentMax}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleAddComment}>Submit</Button>
                    <Button onClick={handleCloseCommentModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <UserPermissions
                open={userPermissionsModal.open}
                taskUUID={props.uuid}
                close={handleUserPermissionsClose}
                contrators={userPermissionsModal.contractors}
                users={userPermissionsModal.users}
                loadDetails={loadDetails}
            />
        </div>
    );
}

export default TaskDetails;
