import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Dropdown, Card, Form } from "react-bootstrap";
import Axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import DOMPurify from "dompurify";
import DatePicker from "react-datepicker";
import Moment from "moment";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as MoreSettingsStyles from "../styles/moreSettings";

function MoreSettings() {
    const dispatch = useDispatch();
    const [tab, setTab] = useState();
    const [invoiceMsgState, setInvoiceMsgState] = useState(EditorState.createEmpty());
    const [receiptMsgState, setReceiptMsgState] = useState(EditorState.createEmpty());
    const [dates, setDates] = useState({
        selected: "New",
        items: []
    });
    const [newDate, setNewDate] = useState(new Date());
    const [updateID, setUpdateID] = useState(0);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(0);

    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    const paymentModalDefaultValues = {
        open: false,
        title: "",
        name: "",
        isValid: false,
        bankedOption: false,
        btnPressed: false
    };
    const [paymentModal, setPaymentModal] = useState(paymentModalDefaultValues);

    function closePaymentModal() {
        setPaymentModal(paymentModalDefaultValues);
    }

    const [modalYN, setModalYN] = useState({
        open: false,
        heading: "",
        message: "",
        acceptFunction: "",
        acceptName: "",
        showAccept: false,
        cancelName: "",
        showCancel: false
    });

    function handleModalYNClose() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Online Payments - More Settings"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));
        handleTabClick("customMessages");
    }, []);

    function handleTabClick(tab) {
        setTab(tab);
        if (tab == "customMessages") {
            loadStaementCustomMessages();
        }
        if (tab == "websitePayments") {
            loadWebsitePayments();
        }
    }

    function loadStaementCustomMessages() {
        Axios.post("/pods/onlinePayments/settings/getCustomStatementMessages")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDates((prevState) => {
                        return { ...prevState, items: data.statements };
                    });

                    handleStatementMsgs(data.statements, data.statements[0].id);
                }
            })
            .catch((err) => console.log(err));
    }

    function loadWebsitePayments() {
        Axios.post("/pods/onlinePayments/settings/getWebsitePayments")
            .then((res) => {
                const data = res.data;
                setPaymentMethods(data.paymentMethods);
            })
            .catch((err) => console.log(err));
    }

    function stringToDraftjs(text) {
        const blocksFromHTML = htmlToDraftjs(text);
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    function handleInvoiceMsgChange(editorState) {
        setInvoiceMsgState(editorState);
    }

    function handleReceiptMsgChange(editorState) {
        setReceiptMsgState(editorState);
    }

    function handleCustomMessagesSave() {
        const invoiceMsg = DOMPurify.sanitize(draftjsToHtml(convertToRaw(invoiceMsgState.getCurrentContent())));
        const receiptMsg = DOMPurify.sanitize(draftjsToHtml(convertToRaw(receiptMsgState.getCurrentContent())));

        const data = {
            date: Moment(newDate).format("DD/MM/YYYY"),
            invoiceMsg: invoiceMsg,
            receiptMsg: receiptMsg
        };

        Axios.post("/pods/onlinePayments/settings/saveNewCustomStatementMessages", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDates((prevState) => {
                        return { ...prevState, items: data.statements };
                    });
                    handleStatementMsgs(data.statements, data.newStatementID);
                    setModal({
                        header: "Custom Messages",
                        message: data.message,
                        open: true
                    });
                } else {
                    setModal({ header: "Error", message: data.message, open: true });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDateChange(date) {
        setNewDate(date);
    }

    function handleNewMsg() {
        setDates((prevState) => {
            return { ...prevState, selected: "New" };
        });

        setInvoiceMsgState(EditorState.createEmpty());
        setReceiptMsgState(EditorState.createEmpty());
    }

    function handleStatementMsgs(items, id) {
        setUpdateID(id);
        console.log(items);
        const statements = items.find((item) => item.id == id);

        setDates((prevState) => {
            return { ...prevState, selected: statements.fromDate };
        });

        setInvoiceMsgState(stringToDraftjs(statements.invoiceMsg));
        setReceiptMsgState(stringToDraftjs(statements.receiptMsg));
    }

    function handleUpdateStatements() {
        const invoiceMsg = DOMPurify.sanitize(draftjsToHtml(convertToRaw(invoiceMsgState.getCurrentContent())));
        const receiptMsg = DOMPurify.sanitize(draftjsToHtml(convertToRaw(receiptMsgState.getCurrentContent())));

        const data = {
            id: updateID,
            invoiceMsg: invoiceMsg,
            receiptMsg: receiptMsg
        };

        Axios.post("/pods/onlinePayments/settings/updateCustomStatementMessages", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setDates((prevState) => {
                        return { ...prevState, items: data.statements };
                    });

                    setModal({
                        header: "Custom Messages",
                        message: data.message,
                        open: true
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleAddPaymentModalOpen() {
        setSelectedMethod(0);
        setPaymentModal((prevState) => {
            return { ...prevState, title: "Add", open: true };
        });
    }

    function handlePMChangeCheck() {
        setPaymentModal((prevState) => {
            return { ...prevState, bankedOption: !prevState.bankedOption };
        });
    }

    function handlePMTextChange(event) {
        const { value } = event.target;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        setPaymentModal((prevState) => {
            return { ...prevState, name: value, isValid: isValid };
        });
    }

    function handlePMSubmit() {
        if (!paymentModal.isValid) {
            setPaymentModal((prevState) => {
                return { ...prevState, btnPressed: true };
            });
        } else {
            if (paymentModal.title == "Add") {
                const data = { name: paymentModal.name, bankedOp: paymentModal.bankedOption.toString() };
                Axios.post("/pods/onlinePayments/settings/addPaymentMethod", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "null") {
                            setPaymentModal(paymentModalDefaultValues);
                            loadWebsitePayments();
                        } else {
                            setModal({ header: "Add Payment Method", message: data.message, open: true });
                        }
                    })
                    .catch((err) => console.log(err));
            }
            if (paymentModal.title == "Edit") {
                const data = { uuid: selectedMethod, name: paymentModal.name, bankedOp: paymentModal.bankedOption.toString() };
                Axios.post("/pods/onlinePayments/settings/updatePaymentMethod", data)
                    .then((res) => {
                        const data = res.data;
                        if (data.error == "null") {
                            setPaymentModal(paymentModalDefaultValues);
                            loadWebsitePayments();
                        } else {
                            setModal({ header: "Edit Payment Method", message: data.message, open: true });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }

    function handleSelectPaymentMethod(id) {
        setSelectedMethod(id);
    }

    function handleEditMethod() {
        const method = paymentMethods.find((meth) => meth.uuid == selectedMethod);
        setPaymentModal((prevState) => {
            return {
                ...prevState,
                title: "Edit",
                name: method.method,
                bankedOption: method.bankedOp == "true",
                btnPressed: true,
                isValid: true,
                open: true
            };
        });
    }

    function handleRemoveMethod() {
        setModalYN({
            open: true,
            heading: "Delete Payment Method?",
            message: "Are you sure you want to delete this payment method?",
            acceptFunction: removeAccept,
            acceptName: "Yes",
            showAccept: true,
            cancelName: "No",
            showCancel: true
        });
    }

    function removeAccept() {
        setModalYN((prevState) => {
            return { ...prevState, open: false };
        });
        const data = { uuid: selectedMethod };
        Axios.post("/pods/onlinePayments/settings/removePaymentMethod", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    loadWebsitePayments();
                    setSelectedMethod(0);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={MoreSettingsStyles.body}>
            <Row>
                <Col
                    style={tab == "customMessages" ? MoreSettingsStyles.tabSelectedText : MoreSettingsStyles.tabText}
                    onClick={handleTabClick.bind(this, "customMessages")}
                >
                    Statements Custom Messages
                </Col>
                <Col
                    style={tab == "websitePayments" ? MoreSettingsStyles.tabSelectedText : MoreSettingsStyles.tabText}
                    onClick={handleTabClick.bind(this, "websitePayments")}
                >
                    Website Payments
                </Col>
            </Row>
            <Row>
                <Col style={MoreSettingsStyles.tabBody}>
                    {tab == "customMessages" && (
                        <div>
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={3} style={MoreSettingsStyles.dateLabel}>
                                            From Date:
                                        </Col>
                                        <Col sm={9}>
                                            <Dropdown>
                                                <Dropdown.Toggle>{dates.selected}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {dates.items.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} onClick={handleStatementMsgs.bind(this, dates.items, item.id)}>
                                                                {item.fromDate}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                    <Dropdown.Item onClick={handleNewMsg}>New</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={3} style={MoreSettingsStyles.newDateLabel}>
                                            {dates.selected == "New" && <strong>From Date:</strong>}
                                        </Col>
                                        <Col sm={5} style={MoreSettingsStyles.dateLabel}>
                                            {dates.selected == "New" && (
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={newDate}
                                                    onChange={(date: Date) => handleDateChange(date)}
                                                />
                                            )}
                                        </Col>
                                        <Col sm={4} style={MoreSettingsStyles.saveBtn}>
                                            {dates.selected !== "New" ? (
                                                <Button onClick={handleUpdateStatements}>Update</Button>
                                            ) : (
                                                <Button onClick={handleCustomMessagesSave}>Save</Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <strong>Invoice:</strong> <br /> <br />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Editor
                                editorState={invoiceMsgState}
                                toolbar={{
                                    options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "link",
                                        "embedded",
                                        "emoji",
                                        "remove",
                                        "history"
                                    ]
                                }}
                                onEditorStateChange={handleInvoiceMsgChange}
                                wrapperStyle={MoreSettingsStyles.editorWrapper}
                                editorStyle={MoreSettingsStyles.editorStyle}
                            />
                            <br />
                            <strong>Receipt:</strong> <br /> <br />
                            <Editor
                                editorState={receiptMsgState}
                                toolbar={{
                                    options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "link",
                                        "embedded",
                                        "emoji",
                                        "remove",
                                        "history"
                                    ]
                                }}
                                onEditorStateChange={handleReceiptMsgChange}
                                wrapperStyle={MoreSettingsStyles.editorWrapper}
                                editorStyle={MoreSettingsStyles.editorStyle}
                            />
                        </div>
                    )}
                    {tab == "websitePayments" && (
                        <div>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col style={MoreSettingsStyles.paymentMethodsTitle}>Payment Methods</Col>
                                    </Row>
                                    {paymentMethods.length > 0 ? (
                                        <Row>
                                            <Col>
                                                <Card style={MoreSettingsStyles.columnsTitles} className="border-0">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>Name</Col>
                                                            <Col>Banked Option</Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card style={MoreSettingsStyles.paymentMethodsError} className="border-0">
                                                        <Card.Body>
                                                            <Row>
                                                                <Col>You have no payment methods</Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    {paymentMethods.length > 0 && (
                                        <div>
                                            {paymentMethods.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Card
                                                            style={
                                                                selectedMethod == item.uuid
                                                                    ? MoreSettingsStyles.selectedCardStyle
                                                                    : MoreSettingsStyles.cardStyle
                                                            }
                                                            onClick={handleSelectPaymentMethod.bind(this, item.uuid)}
                                                        >
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col>{item.method}</Col>
                                                                    <Col>{item.bankedOp == "true" ? "Yes" : "No"}</Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                        <br />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </Col>
                                <Col>
                                    <Button onClick={handleAddPaymentModalOpen}>Add Method</Button> <br /> <br />
                                    {selectedMethod !== 0 && (
                                        <div>
                                            <Button onClick={handleEditMethod}>Edit Method</Button> <br />
                                            <Button onClick={handleRemoveMethod}>Remove Method</Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={paymentModal.open} onHide={closePaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{paymentModal.title} Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            isInvalid={paymentModal.btnPressed && !paymentModal.isValid}
                            type="text"
                            value={paymentModal.name}
                            onChange={handlePMTextChange}
                        />
                        <Form.Control.Feedback type="invalid">Enter a name!</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Check label="'Banked?' option" checked={paymentModal.bankedOption} onChange={handlePMChangeCheck} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closePaymentModal}>Close</Button>
                    <Button onClick={handlePMSubmit}>{paymentModal.title == "Add" ? "Add" : "Update"}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalYN.open} onHide={handleModalYNClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalYN.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalYN.message}</Modal.Body>
                <Modal.Footer>
                    {modalYN.showAccept ? (
                        <div>
                            <Button variant="primary" onClick={modalYN.acceptFunction}>
                                {modalYN.acceptName}
                            </Button>
                        </div>
                    ) : null}
                    {modalYN.showCancel ? (
                        <div>
                            <Button variant="primary" onClick={handleModalYNClose}>
                                {modalYN.cancelName}
                            </Button>
                        </div>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MoreSettings;
