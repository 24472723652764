import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as ControllerStyles from "../styles/controller";

//RESOURCES
import usersFile from "../resources/users.pdf";
import pagesFile from "../resources/pages.pdf";
import editorialsFile from "../resources/editorials.pdf";
import mediaFile from "../resources/media.pdf";
import contactsFile from "../resources/contacts.pdf";

function Help() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const topics = [
        {
            title: "Users",
            file: usersFile
        },
        {
            title: "Pages",
            file: pagesFile
        },
        {
            title: "Editorials",
            file: editorialsFile
        },
        {
            title: "Media",
            file: mediaFile
        },
        {
            title: "Contacts",
            file: contactsFile
        }
    ];

    const [viewerModal, setViewerModal] = useState({
        open: false,
        title: "",
        file: null
    });

    function handleViewModalClose() {
        setViewerModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Help"));
        dispatch(GS_navSettingsActions.UpdateSelected("Help"));
        dispatch(GS_navSettingsActions.UpdateStatic(false));
        dispatch(GS_navSettingsActions.UpdateStaticText(""));
    }, []);

    function handleTopicClick(topic) {
        setViewerModal((prevState) => {
            return { ...prevState, open: true, title: topic.title, file: topic.file };
        });
    }

    return (
        <div style={ControllerStyles.body}>
            {topics.map((topic, index) => {
                return (
                    <div key={index}>
                        <Card style={ControllerStyles.cardStyle} onClick={handleTopicClick.bind(this, topic)}>
                            <Card.Body>
                                <Row>
                                    <Col>{topic.title}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}

            <Modal size="lg" show={viewerModal.open} onHide={handleViewModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{viewerModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe id="iframepdf" width="100%" height="500" src={viewerModal.file}></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleViewModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Help;
