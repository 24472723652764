import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as StatementsViewerStyles from "../styles/statementsViewer";

function MainMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [menu, setMenu] = useState([
        {
            title: "Statements",
            nav: "statements"
        },
        {
            title: "Items",
            nav: "items"
        },
        {
            title: "Website Packages",
            nav: "websitePackages"
        },
        {
            title: "Website Customers",
            nav: "websiteCustomers"
        }
    ]);

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Online Payments"));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));
    }, []);

    function itemNavigate(nav) {
        navigate(`./${nav}`);
    }

    return (
        <div style={StatementsViewerStyles.body}>
            {menu.map((item, index) => {
                return (
                    <div key={index}>
                        <Card style={StatementsViewerStyles.cardStyle} onClick={itemNavigate.bind(this, item.nav)}>
                            <Card.Body>
                                <Row>
                                    <Col>{item.title}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br />
                    </div>
                );
            })}
        </div>
    );
}

export default MainMenu;
