import * as Colors from "../../../../globalStyles/administratorColors";

export const body = {
    paddingRight: "30px",
    paddingTop: "20px"
};

export const newColourBtn = {
    textAlign: "right"
};

export const cardHeader = {
    backgroundColor: Colors.primary,
    color: "white",
    fontWeight: "bold"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const columnStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

export const buttonColStyle = {
    textAlign: "center"
};
