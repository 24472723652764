import * as Colors from "../../../../globalStyles/environmentColors";

export const body = {
    padding: "30px"
};

export const newEnvironmentBtn = {
    textAlign: "right"
};

export const randomBtn = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

export const footerRightBtns = {
    textAlign: "right"
};

export const footerLeft = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white"
};

export const columnStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};
