import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Dropdown, Button } from "react-bootstrap";
import Axios from "axios";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as WebsiteCustomersStyles from "../../styles/packages/websiteCustomers";

//COMPONENTS
import WebPackageItem from "./webPackageItem";

function CustomerViewer(props) {
    const dispatch = useDispatch();
    const [websitePackages, setWebsitePackages] = useState([]);
    const [packageItems, setPackageItems] = useState([]);
    const [packageName, setPackageName] = useState("Select");

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle(`Website Customers - New Customer`));
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));

        onLoad();
    }, []);

    function onLoad() {
        Axios.post("/pods/onlinePayments/websitePackages/getAllPackages")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setWebsitePackages(data.packages);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleDropdownPackageClick(uuid) {
        const webPackage = websitePackages.find((pack) => pack.uuid == uuid);

        setPackageName(webPackage.name);

        const data = { uuid: uuid };
        Axios.post("/pods/onlinePayments/websitePackages/getOnePackage", data)
            .then((res) => {
                const data = res.data;
                setPackageItems(data.items);
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={WebsiteCustomersStyles.body}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Customer Name:</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                </Col>
                <Col>
                    Package:
                    <Dropdown>
                        <Dropdown.Toggle>{packageName}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {websitePackages.map((pack, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={handleDropdownPackageClick.bind(this, pack.uuid)}>
                                        {pack.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <br />
            <Row>
                <Col style={WebsiteCustomersStyles.scrollableBox}>
                    <Row>
                        <Col>
                            <Row>
                                <Col>Number of websites: 1</Col>
                                <Col>Number of domains: 1</Col>
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md="auto" style={WebsiteCustomersStyles.hideCheck}>
                            <Form.Check label="" />
                        </Col>
                        <Col style={WebsiteCustomersStyles.itemsHeadings}>Item</Col>
                        <Col style={WebsiteCustomersStyles.itemsHeadings}>Price</Col>
                        <Col style={{ ...WebsiteCustomersStyles.itemsHeadings, ...WebsiteCustomersStyles.centering }}>Quantity</Col>
                        <Col style={{ ...WebsiteCustomersStyles.itemsHeadings, ...WebsiteCustomersStyles.centering }}>Additional Information</Col>
                        <Col style={WebsiteCustomersStyles.itemsHeadings}>Total</Col>
                    </Row>
                    <br />
                    {packageItems.map((item, index) => {
                        return <WebPackageItem key={index} item={item} />;
                    })}
                </Col>
            </Row>
            <Row>
                <Col style={WebsiteCustomersStyles.columnRight}>
                    <Button>Save</Button>
                </Col>
            </Row>
        </div>
    );
}

export default CustomerViewer;
