import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Media_Upload from "./upload";
import Media_Gallery from "./gallery";

import * as GS_navSettingsActions from "../../../store/actions/globalSettings/GS_navSettings";

import * as GalleryStyles from "./styles/gallery";
import * as MediaStyles from "./styles/media";

function Admin_Media() {
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const media = useSelector((state) => state.media);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GS_navSettingsActions.UpdateTitle("Media"));
        dispatch(GS_navSettingsActions.UpdateSelected("Media"));
        dispatch(GS_navSettingsActions.UpdateStatic(false));
        dispatch(GS_navSettingsActions.UpdateStaticText(""));
        setIsLoaded(true);
    }, [location]);

    return (
        <div>
            {isLoaded ? (
                <div>
                    {media.podDirectoryExists ? (
                        <div style={{ height: "100%" }}>
                            <Row>
                                <Col>
                                    <h3>Upload File(s): </h3>
                                    <Media_Upload />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={GalleryStyles.urlHeading}>Location: {media.currentURI.replace("content/media/", "")}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Media_Gallery />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div style={GalleryStyles.errorMessage}>
                            <br />
                            <p>The Pod&#39;s resource folder and/or the global temp folder does not exist, please contact High-View Studios</p>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default Admin_Media;
