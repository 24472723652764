export const body = {
    padding: "10px"
};

export const signInBtn = {
    textAlign: "center"
};

export const cart = {
    cursor: "pointer"
};

export const errorTextShow = {
    visibility: "visible",
    color: "red"
};

export const errorTextHide = {
    visibility: "hidden"
};

export const passwordRequestedText = {
    textAlign: "center"
};

export const basket = {
    fontSize: "30px"
};
