import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//STYLES
import * as YourPlansStyles from "../styles/yourPlans";

function YourPlans() {
    const [plans, setPlans] = useState([]);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        loadYourPlans();
    }, []);

    function loadYourPlans() {
        const data = { userID: user.id };
        Axios.post("/pods/plannerTasks/getUserPlans", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    console.log(data);
                    setPlans(data.plans);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleClickOnPlan(uuid) {
        navigate(`./plan/${uuid}/home`);
    }

    return (
        <div style={YourPlansStyles.body}>
            <Row>
                <Col style={YourPlansStyles.heading}>
                    <h1>
                        <strong>Your Plans</strong>
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col sm={2}></Col>
                <Col>
                    {plans.length > 0 ? (
                        <div>
                            {plans.map((plan, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={YourPlansStyles.cardStyle} onClick={handleClickOnPlan.bind(this, plan.details.uuid)}>
                                            <Card.Body>{plan.details.title}</Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div style={YourPlansStyles.noPlansText}>You have no plans</div>
                    )}
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default YourPlans;
