import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

function Controller() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("Calendar"));
    dispatch(GS_navSettingsActions.UpdateSelected("Features"));
    dispatch(GS_navSettingsActions.UpdateSubSelected("Calendar"));
  }, []);

  return <h1>Calendar Admin</h1>;
}

export default Controller;
