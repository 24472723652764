import React, { useState } from "react";
import Axios from "axios";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CenterContainer from "../../../../special/wholeSite/centerContainer";

//STYLES
import * as registerStyles from "../styles/register";
import * as loginFormStyles from "../../../loginPod/styles/loginForm";

function Register() {
    const [formState, setFormState] = useState({
        inputs: {
            fullName: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            town: "",
            postcode: "",
            tel: "",
            type: "public",
            password: "",
            confirmPassword: ""
        },
        inputsValid: {
            fullName: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false,
            password: false,
            confirmPassword: false
        },
        formValid: {
            nameAndEmail: false,
            address: true,
            wholeForm: false
        },
        touched: {
            fullName: false,
            email: false,
            addressLine1: false,
            addressLine2: false,
            town: false,
            postcode: false,
            tel: false,
            password: false,
            confirmPassword: false
        }
    });

    const [formValidate, setFormValidate] = useState(false);
    const [completedRequest, setCompletedRequest] = useState({
        completed: false,
        message: ""
    });
    const navigate = useNavigate();

    //shows the regs form
    const [settings, setSettings] = useState({
        form: false
    });

    const [errorMessage, setErrorMessage] = useState({
        message: "",
        passwordError: false
    });

    const [modal, setModal] = useState({
        title: "",
        message: "",
        show: false
    });

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, show: false };
        });
    }

    function UpdateMessage(message, password) {
        setErrorMessage((prevState) => {
            return { ...prevState, message: message, passwordError: password };
        });
    }

    function updateDetailsValidate(event) {
        const { name, value } = event.target;
        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }
        // dispatchFormState({ type: UPDATE_FORM_VALIDATE, input: name, value: value, isValid: isValid });
    }

    function handleInputChange(event) {
        const { name, value } = event.target;

        const inputs = formState.inputs;
        const valids = formState.inputsValid;
        const form = formState.formValid;

        inputs[name] = value;

        let isValid = true;
        if (value.length === 0) {
            isValid = false;
        }
        valids[name] = isValid;

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            valids[name] = false;
        }

        if (valids.fullName && valids.email) {
            form.nameAndEmail = true;
        } else {
            form.nameAndEmail = false;
        }

        let isFullAddress = false;

        if (!valids.addressLine1 && !valids.addressLine2 && !valids.town && !valids.postcode) {
            isFullAddress = true;
        } else if (valids.addressLine1 && valids.addressLine2 && valids.town && valids.postcode) {
            isFullAddress = true;
        }

        form.address = isFullAddress;

        let telIsValid = true;

        if (inputs.tel !== "" && !/^(\+{1}[0-9]{2}|[0])[0-9\s]{10,}$/.test(inputs.tel)) {
            telIsValid = false;
        }

        valids.tel = telIsValid;

        if (form.nameAndEmail && form.address && valids.tel) {
            form.wholeForm = true;
        } else {
            form.wholeForm = false;
        }

        setFormState((prevState) => {
            return { ...prevState, inputs: inputs, inputsValid: valids, formValid: form };
        });
    }

    function handleUpdateTouch(event) {
        const { name } = event.target;

        const updatedTouched = {
            ...formState.touched,
            [name]: true
        };

        setFormState((prevState) => {
            return { ...prevState, touched: updatedTouched };
        });
    }

    function submitRegistration() {
        if (!formState.formValid.wholeForm) {
            switchAllTouches();
            setModal({ title: "Form is invalid!", message: "Please check your form for errors!", show: true });
        } else {
            UpdateMessage("");
            const data = {
                fullName: formState.inputs.fullName,
                email: formState.inputs.email,
                addressLine1: formState.inputs.addressLine1,
                addressLine2: formState.inputs.addressLine2,
                town: formState.inputs.town,
                postcode: formState.inputs.postcode,
                tel: formState.inputs.tel,
                password: formState.inputs.password,
                confirmPassword: formState.inputs.confirmPassword
            };

            Axios.post("/pods/membership/register", data)
                .then((res) => {
                    const data = res.data;
                    if (data.userError === "Yes") {
                        UpdateMessage(data.message, data.passwordError);
                    } else {
                        let output = "";
                        if (data.alreadyAnAccount) {
                            output =
                                "Your account has been linked with the account that High-View Studios holds for you, as you have made payments to High-View Studios in the past. \n\r Please verify your email to use the ViewHelp helpdesk and to see your previous payments";
                        } else {
                            output =
                                "Thank you for registering with High-View Studios! Please verify your email to use the ViewHelp helpdesk and to see your payments";
                        }

                        setCompletedRequest({ completed: true, message: output });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function handleCheckUser(event) {
        if (!formState.inputsValid.email) {
            switchAllTouches();
        } else {
            const data = { email: formState.inputs.email };
            Axios.post("/pods/membership/checkUser", data)
                .then((res) => {
                    const data = res.data;
                    if (data.userError === "Yes") {
                        setModal({ title: "Register!", message: data.message, show: true });
                        setSettings({ form: false });
                    } else if (data.userError === "Half") {
                        setModal({ title: "Register!", message: data.message, show: true });
                        setSettings({ form: true });

                        const inputs = formState.inputs;

                        const userObjects = Object.keys(data.user);
                        console.log(userObjects);
                        userObjects.forEach((info) => {
                            inputs[info] = data.user[info];
                        });

                        setFormState((prevState) => {
                            return { ...prevState, inputs: inputs };
                        });
                    } else if (data.userError === "No") {
                        setModal({ title: "Register!", message: data.message, show: true });
                        setSettings({ form: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function switchAllTouches() {
        const newTouches = Object.assign(...Object.keys(formState.touched).map((k) => ({ [k]: true })));
        setFormState((prevState) => {
            return { ...prevState, touched: newTouches };
        });
    }

    function goBack() {
        navigate(-1);
    }

    return (
        <div>
            <CenterContainer>
                <h1>Register</h1>
                {completedRequest.completed ? (
                    <div>
                        <br />
                        <p style={registerStyles.top_Text}>{completedRequest.message}</p>
                        <Button style={loginFormStyles.loginBtns} name="email" variant="warning" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                ) : (
                    <div>
                        <p style={registerStyles.top_Text}>
                            If you have purchased a service or a product with High-View Studios before, you have already got an account linked to your
                            email. Please enter your email to check and create your online account.
                        </p>
                        <br />
                        <Form>
                            {!settings.form ? (
                                <div>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                isInvalid={formState.touched.email && !formState.inputsValid.email ? true : false}
                                                type="text"
                                                name="email"
                                                placeholder="email"
                                                onChange={handleInputChange}
                                                onBlur={handleUpdateTouch}
                                            />
                                            <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                Please enter a valid email
                                            </Form.Control.Feedback>
                                            <br />
                                            <Button style={loginFormStyles.loginBtns} name="email" variant="warning" onClick={handleCheckUser}>
                                                Check
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div>
                                    <Row>
                                        <Col style={registerStyles.firstFormEmail}>
                                            <Form.Control
                                                isInvalid={formState.touched.email && !formState.inputsValid.email ? true : false}
                                                type="text"
                                                name="email"
                                                placeholder="email"
                                                onChange={handleInputChange}
                                                onBlur={handleUpdateTouch}
                                            />
                                            <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                Please enter a valid email
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={1} style={registerStyles.firstFormBtn}>
                                            <Button style={loginFormStyles.loginBtns} name="email" variant="warning" onClick={handleCheckUser}>
                                                Check
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form>
                        <br />
                        {settings.form ? (
                            <div>
                                <Form style={registerStyles.formLayout}>
                                    <Form.Group>
                                        <Form.Label>Full Name:</Form.Label>
                                        <Form.Control
                                            isInvalid={formState.touched.fullName && !formState.inputsValid.fullName ? true : false}
                                            type="text"
                                            name="fullName"
                                            value={formState.inputs.fullName}
                                            onChange={handleInputChange}
                                            onBlur={handleUpdateTouch}
                                        />
                                        <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                            Please enter a name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <br />
                                    <Form.Group>
                                        <Form.Label>
                                            Address:
                                            <br />
                                            (This is only used for call-outs purposes only, you do not have to enter it)
                                        </Form.Label>
                                        <br />
                                        <Row>
                                            <Col>
                                                Address Line 1:
                                                <Form.Control
                                                    isInvalid={
                                                        formState.touched.addressLine1 &&
                                                        !formState.formValid.address &&
                                                        !formState.inputsValid.addressLine1
                                                            ? true
                                                            : false
                                                    }
                                                    type="text"
                                                    name="addressLine1"
                                                    value={formState.inputs.addressLine1}
                                                    onChange={handleInputChange}
                                                    onBlur={handleUpdateTouch}
                                                />
                                                <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                    Please enter the full address
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col>
                                                Address Line 2:
                                                <Form.Control
                                                    isInvalid={
                                                        formState.touched.addressLine2 &&
                                                        !formState.formValid.address &&
                                                        !formState.inputsValid.addressLine2
                                                            ? true
                                                            : false
                                                    }
                                                    type="text"
                                                    name="addressLine2"
                                                    value={formState.inputs.addressLine2}
                                                    onChange={handleInputChange}
                                                    onBlur={handleUpdateTouch}
                                                />
                                                <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                    Please enter the full address
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                Town:
                                                <Form.Control
                                                    isInvalid={
                                                        formState.touched.town && !formState.formValid.address && !formState.inputsValid.town
                                                            ? true
                                                            : false
                                                    }
                                                    type="text"
                                                    name="town"
                                                    value={formState.inputs.town}
                                                    onChange={handleInputChange}
                                                    onBlur={handleUpdateTouch}
                                                />
                                                <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                    Please enter the full address
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col>
                                                Postcode:
                                                <Form.Control
                                                    isInvalid={
                                                        formState.touched.postcode && !formState.formValid.address && !formState.inputsValid.postcode
                                                            ? true
                                                            : false
                                                    }
                                                    type="text"
                                                    name="postcode"
                                                    value={formState.inputs.postcode}
                                                    onChange={handleInputChange}
                                                    onBlur={handleUpdateTouch}
                                                />
                                                <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                                    Please enter the full address
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <br />
                                    <Form.Group>
                                        <Form.Label>Telephone:</Form.Label>
                                        <Form.Control
                                            isInvalid={formState.touched.tel && !formState.inputsValid.tel ? true : false}
                                            type="text"
                                            name="tel"
                                            value={formState.inputs.tel}
                                            onChange={handleInputChange}
                                            onBlur={handleUpdateTouch}
                                        />
                                        <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                            Please enter your phone number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <br />
                                    <hr />
                                    <br />
                                    <Form.Group>
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control
                                            isInvalid={formState.touched.password && !formState.inputsValid.password ? true : false}
                                            type="password"
                                            name="password"
                                            value={formState.inputs.password}
                                            onChange={handleInputChange}
                                            onBlur={handleUpdateTouch}
                                        />
                                        <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                            Please enter a password
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <br />
                                    <Form.Group>
                                        <Form.Label>Confirm Password:</Form.Label>
                                        <Form.Control
                                            isInvalid={formState.touched.confirmPassword && !formState.inputsValid.confirmPassword ? true : false}
                                            type="password"
                                            name="confirmPassword"
                                            value={formState.inputs.confirmPassword}
                                            onChange={handleInputChange}
                                            onBlur={handleUpdateTouch}
                                        />
                                        <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                            Please re-enter your password
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <br />
                                    <p style={registerStyles.registerError}>{errorMessage.message}</p>
                                    <p style={registerStyles.registerError}>
                                        {errorMessage.passwordError
                                            ? "Your password must contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character. It also requires to be a minimum 8 characters in length"
                                            : null}
                                    </p>
                                    <div style={registerStyles.registerBtns}>
                                        <Button
                                            variant="warning"
                                            style={loginFormStyles.loginBtns}
                                            onClick={submitRegistration}
                                            onBlur={handleUpdateTouch}
                                        >
                                            Submit
                                        </Button>
                                        <Button variant="warning" style={loginFormStyles.loginBtns}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        ) : null}
                    </div>
                )}
            </CenterContainer>

            <Modal show={modal.show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Register;
