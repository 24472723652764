import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Table, Modal, Card, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Axios from "axios";
import Moment from "moment";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../../store/actions/globalSettings/GS_navSettings";

//STYLES
import * as StatementStyles from "../styles/produceAStatement";

//COMPONENTS
import ItemsViewer from "./itemsViewer";

function ProduceAStatement(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [itemsViewerShow, setItemsViewerShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        updateItem: false,
        selected: 0
    });
    const [customer, setCustomer] = useState({
        values: {
            name: "",
            email: "",
            date: new Date(),
            paymentMethod: { uuid: 0, method: "Select", bankedOp: "false" }
        },
        valids: {
            name: false,
            email: false,
            paymentMethod: false
        },
        wholeForm: false,
        errorOnForm: false
    });
    const newItemDefaults = {
        values: {
            name: "",
            unitPrice: "",
            quantity: ""
        },
        valids: {
            name: false,
            unitPrice: false,
            quantity: false
        },
        wholeForm: false,
        errorOnForm: false
    };
    const [newItem, setNewItem] = useState(newItemDefaults);
    const [statementItems, setStatementItems] = useState({
        items: [],
        total: 0
    });
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        dispatch(
            GS_navSettingsActions.UpdateTitle(
                props.type == "invoice"
                    ? `Online Payments - Produce an ${props.type.charAt(0).toUpperCase() + props.type.slice(1)}`
                    : `Online Payments - Produce a ${props.type.charAt(0).toUpperCase() + props.type.slice(1)}`
            )
        );
        dispatch(GS_navSettingsActions.UpdateSelected("Features"));
        dispatch(GS_navSettingsActions.UpdateSubSelected("Online Payments"));
        getPaymentMethods();
    }, []);

    function getPaymentMethods() {
        Axios.post("/pods/onlinePayments/settings/getPaymentMethods")
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPaymentMethods(data.methods);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleItemsBtnClick() {
        setItemsViewerShow(true);
    }

    function handleCloseItemsViewer() {
        setItemsViewerShow(false);
    }

    function newItemTextChanged(event) {
        const { name, value } = event.target;

        const values = newItem.values;
        const valids = newItem.valids;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        valids[name] = isValid;
        values[name] = value;

        let wholeForm = false;

        if (values.name != "" && values.unitPrice != "" && values.quantity != "") {
            wholeForm = true;
        }

        setNewItem((prevState) => {
            return {
                ...prevState,
                values: values,
                valids: valids,
                wholeForm: wholeForm
            };
        });
    }

    const [previewModal, setPreviewModal] = useState({
        open: false,
        filename: "",
        sendBtn: true,
        status: ""
    });

    function handleClosePreviewModal() {
        setPreviewModal((prevState) => {
            return { ...prevState, open: false, filename: "" };
        });
    }

    const [modal, setModal] = useState({
        header: "",
        open: false,
        message: ""
    });

    function handleGoBack() {
        setModal((prevState) => {
            return { ...prevState, open: false };
        });

        navigate(-1);
    }

    const [customerModal, setCustomerModal] = useState({
        open: false,
        customers: []
    });

    function handleCloseCustomerModal() {
        setCustomerModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handleAddItemFromViewer(name, unitPrice) {
        setItemsViewerShow(false);

        const newItem = {
            name: name,
            unitPrice: unitPrice,
            quantity: "1"
        };

        const valids = {
            name: true,
            unitPrice: true,
            quantity: true
        };

        setNewItem((prevState) => {
            return { ...prevState, values: newItem, valids: valids, wholeForm: true };
        });
    }

    function handleAddNewItem() {
        if (!newItem.wholeForm) {
            setNewItem((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            const item = {
                item: newItem.values.name,
                unitPrice: parseFloat(newItem.values.unitPrice),
                quantity: parseFloat(newItem.values.quantity)
            };

            let total = 0;

            for (const item of statementItems.items) {
                const sub = item.unitPrice * item.quantity;
                total = total + sub;
            }

            const newItemSubTotal = parseFloat(newItem.values.unitPrice) * parseFloat(newItem.values.quantity);

            total = total + newItemSubTotal;

            setStatementItems((prevState) => {
                return {
                    ...prevState,
                    items: [...prevState.items, item],
                    total: total
                };
            });

            setNewItem(newItemDefaults);
        }
    }

    function quantityKeyPress(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function unitPriceKeyPress(event) {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function handleSelectedItem(index) {
        setSelectedItem((prevState) => {
            return { ...prevState, selected: index, updateItem: true };
        });

        const item = {
            name: statementItems.items[index].item,
            unitPrice: statementItems.items[index].unitPrice,
            quantity: statementItems.items[index].quantity
        };

        setNewItem((prevState) => {
            return { ...prevState, values: item };
        });
    }

    function handleUpdateSelectedItem() {
        const items = statementItems.items;

        items[selectedItem.selected].item = newItem.values.name;
        items[selectedItem.selected].unitPrice = newItem.values.unitPrice;
        items[selectedItem.selected].quantity = newItem.values.quantity;

        let total = 0;

        for (const item of items) {
            const sub = item.unitPrice * item.quantity;
            total = total + sub;
        }

        setStatementItems((prevState) => {
            return { ...prevState, items: items, total: total };
        });

        setSelectedItem((prevState) => {
            return { ...prevState, selected: "", updateItem: false };
        });

        setNewItem(newItemDefaults);
    }

    function handleBackToAddItem() {
        setSelectedItem((prevState) => {
            return { ...prevState, selected: "", updateItem: false };
        });

        setNewItem(newItemDefaults);
    }

    function handleCustomerOnChange(event) {
        const { name, value } = event.target;

        const values = customer.values;
        const valids = customer.valids;

        let isValid = false;
        if (value != "") {
            isValid = true;
        }

        if (name === "email" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            isValid = false;
        }

        valids[name] = isValid;
        values[name] = value;

        let isWholeFormValid = false;
        if (props.type == "invoice") {
            if (valids.name && valids.email) {
                isWholeFormValid = true;
            }
        }
        if (props.type == "receipt") {
            if (valids.name && valids.email && valids.paymentMethod) {
                isWholeFormValid = true;
            }
        }

        setCustomer((prevState) => {
            return {
                ...prevState,
                values: values,
                valids: valids,
                wholeForm: isWholeFormValid
            };
        });
    }

    function handleDateChange(date) {
        const values = customer.values;

        values["date"] = date;

        setCustomer((prevState) => {
            return { ...prevState, values };
        });
    }

    function handleShowPreviewBtn(event) {
        if (!customer.wholeForm || statementItems.items.length == 0) {
            setCustomer((prevState) => {
                return { ...prevState, errorOnForm: true };
            });
        } else {
            setPreviewModal((prevState) => {
                return { ...prevState, open: true };
            });

            const data = {
                type: props.type,
                items: statementItems.items,
                details: {
                    ...customer.values,
                    date: Moment(customer.values.date).format("DD/MM/YYYY")
                }
            };
            Axios.post("/pods/onlinePayments/createPreviewPDF", data)
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    console.log(window.location.origin);
                    if (data.error == "null") {
                        setPreviewModal((prevState) => {
                            return { ...prevState, filename: data.filename };
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function pdfLoaded(filename) {
        const data = { filename: filename };

        Axios.post("/pods/onlinePayments/deleteTempPDF", data)
            .then((res) => {
                const data = res.data;
            })
            .catch((err) => console.log(err));
    }

    function handleProduceAndSend() {
        setPreviewModal((prevState) => {
            return { ...prevState, sendBtn: false, status: "Producing..." };
        });

        const data = {
            type: props.type,
            items: statementItems.items,
            details: {
                ...customer.values,
                date: Moment(customer.values.date).format("DD/MM/YYYY")
            }
        };

        Axios.post("/pods/onlinePayments/produceAndSendPDF", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    setPreviewModal((prevState) => {
                        return { ...prevState, status: "Sending..." };
                    });

                    const timer = setTimeout(() => {
                        const secondData = { filename: data.filename };

                        Axios.post("/pods/onlinePayments/deleteTempPDF", secondData)
                            .then((res) => {
                                const data = res.data;
                                setModal((prevState) => {
                                    return {
                                        header: `${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Sent!`,
                                        message: `${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Sent Successfully!`,
                                        open: true
                                    };
                                });
                                setPreviewModal((prevState) => {
                                    return {
                                        ...prevState,
                                        open: false,
                                        status: "",
                                        sendBtn: true
                                    };
                                });
                            })
                            .catch((err) => console.log(err));
                        // console.log("here");
                    }, 2000);
                }
            })
            .catch((err) => console.log(err));
    }

    function handleOpenCustomerModal() {
        Axios.post("/pods/onlinePayments/getExisitingCustomers")
            .then((res) => {
                const data = res.data;

                if (data.error == "null") {
                    setCustomerModal((prevState) => {
                        return { ...prevState, open: true, customers: data.customers };
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    function handleCustomerClicked(customerDetails) {
        const values = customer.values;
        const valids = customer.valids;

        values.name = customerDetails.fullname;
        values.email = customerDetails.email;

        let isNameValid = false;
        if (values.name != "") {
            isNameValid = true;
        }

        let isEmailValid = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(values.email)) {
            isEmailValid = false;
        }

        valids["name"] = isNameValid;
        valids["email"] = isEmailValid;

        let isWholeFormValid = false;
        if (props.type == "invoice") {
            if (valids.name && valids.email) {
                isWholeFormValid = true;
            }
        }
        if (props.type == "receipt") {
            if (valids.name && valids.email && valids.paymentMethod) {
                isWholeFormValid = true;
            }
        }

        setCustomer((prevState) => {
            return {
                ...prevState,
                values: values,
                valids: valids,
                wholeForm: isWholeFormValid
            };
        });

        setCustomerModal((prevState) => {
            return { ...prevState, open: false };
        });
    }

    function handlePaymentItemSelected(item) {
        const values = customer.values;
        const valids = customer.valids;

        values.paymentMethod = item;

        let isValid = false;
        if (item.method != "Select") {
            isValid = true;
        }

        valids.paymentMethod = isValid;

        let isWholeFormValid = false;
        if (props.type == "invoice") {
            if (valids.name && valids.email) {
                isWholeFormValid = true;
            }
        }
        if (props.type == "receipt") {
            if (valids.name && valids.email && valids.paymentMethod) {
                isWholeFormValid = true;
            }
        }

        setCustomer((prevState) => {
            return { ...prevState, values, valids: valids, wholeForm: isWholeFormValid };
        });
    }

    return (
        <div style={StatementStyles.body}>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <strong>Customer:</strong>
                        </Col>
                        <Col style={StatementStyles.exCusBtn}>
                            <Button onClick={handleOpenCustomerModal}>Exsiting Customer</Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    isInvalid={customer.errorOnForm && !customer.valids.name ? true : false}
                                    type="text"
                                    name="name"
                                    value={customer.values.name}
                                    onChange={handleCustomerOnChange}
                                />
                                <Form.Control.Feedback type="invalid">Please enter the customer&#39;s name</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    isInvalid={customer.errorOnForm && !customer.valids.email ? true : false}
                                    type="text"
                                    name="email"
                                    value={customer.values.email}
                                    onChange={handleCustomerOnChange}
                                />
                                <Form.Control.Feedback type="invalid">Please enter the customer&#39;s valid email</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Date:</Form.Label>
                                <br />
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={customer.values.date}
                                    onChange={(date: Date) => handleDateChange(date)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <br />
            <Row>
                <Col>
                    <strong>Add New Item:</strong>
                    <br />
                    {customer.errorOnForm && statementItems.items.length == 0 ? (
                        <p style={StatementStyles.errorSubmitError}>Please add some items!</p>
                    ) : null}
                </Col>
                <Col style={StatementStyles.itemsBtn}>
                    <Button onClick={handleItemsBtnClick}>Items</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Item:</Form.Label>
                        <Form.Control
                            isInvalid={newItem.errorOnForm && !newItem.valids.name ? true : false}
                            type="text"
                            name="name"
                            value={newItem.values.name}
                            onChange={newItemTextChanged}
                        />
                        <Form.Control.Feedback type="invalid">Please enter an item</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Unit Price:</Form.Label>
                        <Form.Control
                            isInvalid={newItem.errorOnForm && !newItem.valids.unitPrice ? true : false}
                            type="text"
                            name="unitPrice"
                            value={newItem.values.unitPrice}
                            onChange={newItemTextChanged}
                            onKeyPress={unitPriceKeyPress}
                        />
                        <Form.Control.Feedback type="invalid">Please enter an unit price</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Quantity:</Form.Label>
                        <Form.Control
                            isInvalid={newItem.errorOnForm && !newItem.valids.quantity ? true : false}
                            type="text"
                            name="quantity"
                            value={newItem.values.quantity}
                            onChange={newItemTextChanged}
                            onKeyPress={quantityKeyPress}
                        />
                        <Form.Control.Feedback type={"invalid"}>Please enter a quantity</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {selectedItem.updateItem ? (
                <div>
                    <Row>
                        <Col style={StatementStyles.itemBackBtn}>
                            <Button onClick={handleBackToAddItem}>Back</Button>
                        </Col>
                        <Col style={StatementStyles.itemAddUpdateBtn}>
                            <Button onClick={handleUpdateSelectedItem}>Update</Button>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <Row>
                        <Col style={StatementStyles.itemAddUpdateBtn}>
                            <Button onClick={handleAddNewItem}>Add</Button>
                        </Col>
                    </Row>
                </div>
            )}
            <hr />
            <Row>
                <Col>
                    <strong>Item(s):</strong>
                </Col>
            </Row>
            <br />
            <Row>
                <Col style={StatementStyles.itemScroll}>
                    <Table hover>
                        <thead>
                            <tr>
                                <th width={"70%"} style={StatementStyles.tableHeading}>
                                    Item
                                </th>
                                <th width={"10%"} style={StatementStyles.tableHeading}>
                                    Unit Price
                                </th>
                                <th width={"10%"} style={StatementStyles.tableHeading}>
                                    Quantity
                                </th>
                                <th width={"10%"} style={StatementStyles.tableHeading}>
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {statementItems.items.map((item, index) => {
                                const total = item.unitPrice * item.quantity;

                                return (
                                    <tr key={index} onClick={handleSelectedItem.bind(this, index)}>
                                        <td style={StatementStyles.tableItemCell}>{item.item}</td>
                                        <td style={StatementStyles.tablePriceCell}>£{item.unitPrice}</td>
                                        <td style={StatementStyles.tablePriceCell}>{item.quantity}</td>
                                        <td style={StatementStyles.tablePriceCell}>£{total}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {props.type == "receipt" && (
                <div>
                    <Row>
                        <Col style={StatementStyles.paymentMethodsTitle}>
                            <div>
                                <strong>Payment Method:</strong>
                                {customer.errorOnForm && !customer.valids.paymentMethod && (
                                    <p style={StatementStyles.paymentMethodError}>Pick a method!</p>
                                )}
                            </div>
                        </Col>
                        <Col sm={2} style={StatementStyles.paymentMethodsDropdown}>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle variant={customer.errorOnForm && !customer.valids.paymentMethod ? "danger" : "primary"}>
                                        {customer.values.paymentMethod.method}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {paymentMethods.map((item, index) => {
                                            return (
                                                <Dropdown.Item key={index} onClick={handlePaymentItemSelected.bind(this, item)}>
                                                    {item.method}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
            <Row>
                <Col style={StatementStyles.statementTotal}>Total: £{statementItems.total.toFixed(2)}</Col>
                <Col style={StatementStyles.statementPreviewButton}>
                    <Button onClick={handleShowPreviewBtn}>Preview</Button> <br />
                    {customer.errorOnForm ? <p style={StatementStyles.errorSubmitError}>Please correct the errors in this Statement!</p> : null}
                </Col>
            </Row>
            <ItemsViewer open={itemsViewerShow} close={handleCloseItemsViewer} addItem={handleAddItemFromViewer} />
            <Modal show={previewModal.open} onHide={handleClosePreviewModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            This {props.type} will send to {customer.values.email}
                            <br /> <br />
                            The Reference will be set when the {props.type} is produced and sent.
                            <br /> <br />
                            {props.type == "receipt" && (
                                <div>
                                    Payment Method: {customer.values.paymentMethod.method} (This will not show on the {props.type})
                                    <br /> <br />
                                </div>
                            )}
                            {previewModal.filename == "" ? (
                                <div style={StatementStyles.pdfLodingSign}>Loading...</div>
                            ) : (
                                <div>
                                    <iframe
                                        id="iframepdf"
                                        width="100%"
                                        height="500"
                                        src={`${window.location.origin}/content/temp/${previewModal.filename}`}
                                        onLoad={pdfLoaded.bind(this, previewModal.filename)}
                                    ></iframe>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            {previewModal.filename != "" && previewModal.sendBtn ? (
                                <Button onClick={handleProduceAndSend}>Produce and Send</Button>
                            ) : (
                                <h2>{previewModal.status}</h2>
                            )}
                        </Col>
                        <Col style={StatementStyles.closeBtn}>
                            <Button onClick={handleClosePreviewModal}>Close</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={modal.open} onHide={handleGoBack}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleGoBack}>
                        Go Back
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={customerModal.open} onHide={handleCloseCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Exsiting Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {customerModal.customers.length > 0 ? (
                        <div>
                            {customerModal.customers.map((customer, index) => {
                                return (
                                    <div key={index}>
                                        <Card style={StatementStyles.itemCard} onClick={handleCustomerClicked.bind(this, customer)}>
                                            <Card.Body>
                                                {customer.fullname} <br /> {customer.email}
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div style={StatementStyles.exCusNoCus}>
                            <strong>You have no customers yet!</strong>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseCustomerModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ProduceAStatement;
