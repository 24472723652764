import React from "react";
import { Col, Image } from "react-bootstrap";

//STYLES
import * as ItemStyles from "../styles/product";

function Product(props) {
    return (
        <Col style={ItemStyles.body} sm={3}>
            <div style={ItemStyles.innerBody} onClick={props.click.bind(this, props.product.productCode)}>
                <p style={ItemStyles.title}>{props.product.title}</p>
                <Image
                    src={`/content/3dPrintingShop/products/${props.product.uuid.toString()}/${props.product.mainImage}`}
                    style={ItemStyles.image}
                />
                <p style={ItemStyles.price}>{`Price: £${(parseInt(props.product.price) / 100).toFixed(2)}`}</p>
                <p style={ItemStyles.price}>{`Product Code: ${props.product.productCode}`}</p>
            </div>
        </Col>
    );
}

export default Product;
