import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";

//STYLES
import * as TaskCardStyles from "../styles/taskCard";

function TaskCard(props) {
    const [dateColor, setDateColor] = useState("green");

    useEffect(() => {
        let color = "";

        if (props.task.dueDate != "") {
            const dueDate = props.task.dueDate.split("/");

            const green = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).subtract(7, "d");
            const yellow = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).subtract(2, "d");
            const red = moment(Date.parse(`${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`)).add(1, "d");

            if (moment().isBefore(green)) {
                color = "black";
            } else {
                if (moment().isSameOrAfter(green) && moment().isBefore(yellow)) {
                    color = "green";
                } else {
                    if (moment().isSameOrAfter(yellow) && moment().isBefore(red)) {
                        color = "orange";
                    } else {
                        if (moment().isSameOrAfter(red)) {
                            color = "red";
                        }
                    }
                }
            }
            setDateColor(color);
        }
    }, [props.task]);

    return (
        <div style={TaskCardStyles.body}>
            <Card onClick={props.onOpen}>
                <Card.Body>
                    <strong>{props.task.title}</strong>
                    <hr />
                    {props.task.state}
                    {props.task.state != "Completed" && (
                        <div>
                            <hr />
                            {props.task.dueDate != "" ? (
                                <div style={{ color: dateColor }}>
                                    {dateColor == "black" ? (
                                        <div>{props.task.dueDate}</div>
                                    ) : (
                                        <div>
                                            <strong>{props.task.dueDate}</strong>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>No Due Date</div>
                            )}
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
}

export default TaskCard;
