import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import CenterContainer from "../../../../special/wholeSite/centerContainer";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

//STYLES
import * as loginFormStyles from "../../../loginPod/styles/loginForm";
import * as registerStyles from "../styles/register";

function ForgottenPassword() {
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        emailValue: "",
        emailValid: false,
        touched: false
    });

    const [modal, setModal] = useState({
        title: "",
        message: "",
        show: false
    });

    const [completedRequest, setCompletedRequest] = useState(false);

    function handleCloseModal() {
        setModal((prevState) => {
            return { ...prevState, show: false };
        });
    }

    function handleInputChange(event) {
        const { value } = event.target;

        let valid = true;

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
            valid = false;
        }

        setFormState((prevState) => {
            return { ...prevState, emailValue: value, emailValid: valid };
        });
    }

    function handleTouched() {
        setFormState((prevState) => {
            return { ...prevState, touched: true };
        });
    }

    function handleSubmit() {
        if (!formState.emailValid) {
            handleTouched();
        } else {
            const data = { email: formState.emailValue };

            Axios.post("/pods/membership/requestPassword", data)
                .then((res) => {
                    const data = res.data;
                    if (data.error == "Yes") {
                        setModal({ Header: "Forgot Password", message: data.message, open: true });
                    } else {
                        setCompletedRequest(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    function handleGoBack() {
        navigate(-1);
    }

    return (
        <div>
            <CenterContainer>
                <h1>Forgotten Your Password?</h1>
                <br />
                {completedRequest ? (
                    <div>
                        <p>New password requested! Please check your email to get your temporary email</p>
                        <p>Click the button below to go back</p>
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={handleGoBack}>
                            Go Back
                        </Button>
                    </div>
                ) : (
                    <div>
                        <p className="body-text-size">Please enter your email below: </p>
                        <br />
                        <Form>
                            <Form.Control
                                isInvalid={formState.touched && !formState.emailValid ? true : false}
                                type="text"
                                onChange={handleInputChange}
                                value={formState.emailValue}
                                onBlur={handleTouched}
                            />
                            <Form.Control.Feedback style={registerStyles.registerError} type="invalid">
                                Please enter a valid email
                            </Form.Control.Feedback>
                        </Form>
                        <br /> <br />
                        <Button variant="warning" style={loginFormStyles.loginBtns} onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                )}
            </CenterContainer>
            <Modal show={modal.show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ForgottenPassword;
