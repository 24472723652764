import * as ReducerActions from "../../actions/globalSettings/GS_navSettings";

const initialState = {
    size: "3",
    title: "",
    static: false,
    staticText: "",
    selectedItem: "",
    selectedSubItem: "",
    prevSelectedItem: "",
    prevSelectedSubItem: "",
    menuItems: {
        overview: {
            id: 0,
            title: "Overview",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["overview"]
            }
        },
        users: {
            id: 1,
            title: "Users",
            subMenu: true,
            subMenuType: "component",
            subMenuItems: [
                {
                    id: 0,
                    title: "General Settings",
                    methodId: "generalsettings",
                    floater: false
                }
            ]
        },
        pages: {
            id: 2,
            title: "Pages",
            subMenu: true,
            subMenuItems: []
        },
        editorials: {
            id: 3,
            title: "Editorials",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["editorials"]
            }
        },
        media: {
            id: 4,
            title: "Media",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["media"]
            }
        },
        contacts: {
            id: 5,
            title: "Contacts",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "changeSection",
                params: ["contacts"]
            }
        },
        features: {
            id: 6,
            title: "Features",
            subMenu: true,
            subMenuItems: []
        },
        style: {
            id: 7,
            title: "Style Configuration",
            subMenu: false,
            methodInfo: {
                method: "changeSection",
                params: ["styleConfiguration"]
            }
        },
        help: {
            id: 9,
            title: "Help",
            subMenu: false,
            methodInfo: {
                method: "changeSection",
                params: ["help"]
            }
        },
        logOut: {
            id: 9,
            title: "Log Out",
            subMenu: false,
            subMenuItems: [],
            methodInfo: {
                method: "logOut",
                params: []
            }
        }
    },
    adminMenu: [],
    adminMenuIsLoaded: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.GS_UPDATE_NAV_SIZING:
            return { ...state, size: action.value };
        case ReducerActions.GS_UPDATE_NAV_TITLE:
            return { ...state, title: action.value };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDITEM:
            return { ...state, selectedItem: action.value, selectedSubItem: "" };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDSUBITEM:
            return { ...state, selectedSubItem: action.value };
        case ReducerActions.GS_UPDATE_NAV_SELECTEDTOPREV:
            return { ...state, prevSelectedItem: state.selectedItem, prevSelectedSubItem: state.selectedSubItem };
        case ReducerActions.GS_UPDATE_NAV_PREVTOSELECTED:
            return { ...state, selectedItem: state.prevSelectedItem, selectedSubItem: state.prevSelectedSubItem };
        case ReducerActions.GS_UPDATE_NAV_RESETPREVSELECTED:
            return { ...state, prevSelectedItem: "", prevSelectedSubItem: "" };
        case ReducerActions.GS_UPDATE_NAV_STATIC:
            return { ...state, static: action.value };
        case ReducerActions.GS_UPDATE_NAV_STATICTEXT:
            return { ...state, staticText: action.value };
        case ReducerActions.GS_UPDATE_MENU_ITEMS:
            return { ...state, adminMenu: action.value, adminMenuIsLoaded: true };
        default:
            return state;
    }
};

export default reducer;
