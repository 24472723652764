import React, { useState } from "react";
import { Row, Col, Image, Form, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

//STYLES
import * as BasketItemStyles from "../../styles/basket/basketItem";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

function BasketItem(props) {
    const dispatch = useDispatch();

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        uuid: 0
    });

    function handleCloseDeleteModal() {
        setDeleteModal({ open: false, uuid: 0 });
    }

    function handleQuantityIncrease(itemUUID) {
        dispatch(PrintingShopActions.IncraseBasketItemQuantity(itemUUID));
    }

    function handleQuantityDecrease(itemUUID) {
        dispatch(PrintingShopActions.DecraseBasketItemQuantity(itemUUID));
    }

    function handleDeleteItem(itemUUID) {
        setDeleteModal({ uuid: itemUUID, open: true });
    }

    function handleAcceptDelete() {
        dispatch(PrintingShopActions.RemoveItemFromBasket(deleteModal.uuid));
        setDeleteModal({ open: false, uuid: 0 });
    }

    return (
        <div>
            <Row>
                <Col sm={2}>
                    <Image
                        src={`/content/3dPrintingShop/products/${props.item.productDetails.uuid.toString()}/${props.item.mainImage}`}
                        style={BasketItemStyles.image}
                    />
                </Col>
                <Col sm={10}>
                    <strong>{props.item.productDetails.title}</strong> <br />
                    <Row>
                        <Col>
                            Price: £{(parseInt(props.item.productDetails.price) / 100).toFixed(2)} <br />
                            Qty: {props.serverQuantity}
                        </Col>
                        <Col>
                            {props.item.customItem == "true" && (
                                <div>
                                    <strong>Custom Fields</strong> <br />
                                    {props.item.customFields.map((field, index) => {
                                        return (
                                            <div key={index}>
                                                {field.fieldName}: {field.fieldValue}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Col>
                        <Col>
                            <Row>
                                <Col style={BasketItemStyles.editRightSection}>
                                    <Button variant="danger" onClick={handleDeleteItem.bind(this, props.item.uuid)}>
                                        <i className="fa-solid fa-trash-can"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={BasketItemStyles.quantitySection}>
                                    <Button onClick={handleQuantityDecrease.bind(this, props.item.uuid)}>-</Button>
                                    <Form.Label style={BasketItemStyles.quantityText}>{props.basketQuantity}</Form.Label>
                                    <Button onClick={handleQuantityIncrease.bind(this, props.item.uuid)}>+</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={deleteModal.open} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove this item from your basket?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>No</Button>
                    <Button onClick={handleAcceptDelete}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BasketItem;
