import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { useDispatch } from "react-redux";

//ACTIONS
import * as PrintingShopActions from "../../../../../envStore/actions/3dPrintingShop";

//STYLES
import * as ProcessOrderStyles from "../../styles/checkout/processOrder";

function ProcessOrder() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const data = { orderUUID: params.id };

        Axios.post("/pods/3dPrintingShop/checkout/processOrder", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    dispatch(PrintingShopActions.FetchBasketDeatils());

                    navigate("../orderConfirmation");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={ProcessOrderStyles.body}>
            <h1>Processing Order...</h1>
        </div>
    );
}

export default ProcessOrder;
