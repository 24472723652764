import * as ReducerActions from "../actions/planner";

const initialState = {
    type: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.PLANNER_TYPE:
            return { ...state, type: action.value };
        default:
            return state;
    }
};

export default reducer;
