import React from "react";
import { Row, Col } from "react-bootstrap";

import * as containerStyles from "./styles/container";

function CenterContainer(props) {
    return (
        <div>
            <Row>
                <Col sm={2}></Col>
                <Col md={8} style={{ ...containerStyles.centerContainer, ...props.style }}>
                    {props.children}
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    );
}

export default CenterContainer;
