import * as ReducerActions from "../actions/accessModal";

const initialState = {
    screen: "",
    logInAction: {
        action: ""
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ReducerActions.ACCESSMODAL_UPDATE_SCREEN:
            return { ...state, screen: action.value };
        case ReducerActions.UPDATE_ACTION_AND_OPEN_SIGNIN:
            return { ...state, loginActions: action.value, screen: "signIn" };
        default:
            return state;
    }
};

export default reducer;
