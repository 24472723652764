import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";

//COMPONENTS
import MainMenu from "./files/mainMenu";
import HelpDesk from "./files/helpDesk";
import NewTicket from "./files/newTicket";
import TicketHolder from "./files/frontEnd_ticketHolder";
import CallOut from "./files/callOut";
import OnlinePayments from "../onlinePayments/frontEnd/files/controller";
import Planner from "../planner/frontEnd/podRouter";

function PodRouter() {
    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user.auth) {
            navigate("/membership/login");
        }

        setIsLoaded(true);
    }, []);

    return (
        <div>
            {isLoaded && (
                <div>
                    <Routes>
                        <Route path="/" element={<MainMenu />} />
                        <Route path="/helpdesk" element={<HelpDesk />} />
                        <Route path="/helpdesk/newticket" element={<NewTicket />} />
                        <Route path="/helpdesk/ticket/:uuid" element={<TicketHolder />} />
                        <Route path="/callOut" element={<CallOut />} />
                        <Route path="/onlinePayments" element={<OnlinePayments />} />
                        <Route path="/planner/*" element={<Planner />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default PodRouter;
