export const picturesContainerOuter = {
    height: "180px",
    backgroundColor: "white"
};

export const picturesContainerInner = {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll"
};

export const tableSection = {
    height: "200px",
    overflow: "auto"
};

export const tableRow = {
    cursor: "pointer",
    color: "red"
};

export const tableCell = {
    fontWeight: "none"
};

export const tableCellSelected = {
    fontWeight: "bold"
};

export const modelInput_textBoxWidth = {
    width: "50px"
};

export const modelInput_weightTextBoxWidth = {
    width: "100px"
};

export const bottomSections = {
    display: "flex",
    flexDirection: "row"
};

export const timeCenter = {
    padding: "5px"
};

export const errorShow = {
    visibility: "visible",
    color: "red"
};

export const errorHide = {
    visibility: "hidden",
    color: "red"
};

export const show = {
    visibility: "visible"
};

export const hidden = {
    visibility: "hidden"
};
