import * as Colors from "../../../../../globalStyles/administratorColors";

export const body = {
    padding: "30px"
};

export const tabText = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    cursor: "pointer"
};

export const tabSelectedText = {
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.primary,
    borderBottom: "4px solid"
};

export const tabBody = {
    paddingTop: "30px"
};

export const editorWrapper = {
    backgroundColor: "white",
    width: "100%"
};

export const editorStyle = {
    minHeight: "250px",
    maxHeight: "250px",
    overflowY: "scroll",
    cursor: "text"
};

export const saveBtn = {
    textAlign: "right"
};

export const dateLabel = {
    margin: "auto"
};

export const newDateLabel = {
    textAlign: "right",
    margin: "auto"
};

export const paymentMethodsTitle = {
    textAlign: "center",
    fontWeight: "bold",
    paddingBottom: "10px"
};

export const columnsTitles = {
    background: "transparent",
    textAlign: "center",
    fontWeight: "bold"
};

export const cardStyle = {
    backgroundColor: Colors.primary,
    color: "white",
    cursor: "pointer",
    textAlign: "center"
};

export const selectedCardStyle = {
    backgroundColor: Colors.accent,
    color: "yellow",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer"
};

export const paymentMethodsError = {
    background: "transparent",
    textAlign: "center"
};
