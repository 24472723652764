export const image = {
    height: "150px",
    width: "150px",
    border: "1px solid black"
};

export const quantitySection = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right"
};

export const quantityText = {
    width: "30px",
    textAlign: "center"
};

export const editRightSection = {
    textAlign: "right"
};
