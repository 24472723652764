import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import CategoriesView from "./categoriesView";

//STYLES
import * as ViewControllerStyles from "../styles/viewController";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

function ViewController() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categoryModal, setCategoryModal] = useState({
    open: false,
  });
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("ViewHelp Helpdesk"));
    dispatch(GS_navSettingsActions.UpdateSelected("Features"));
    dispatch(GS_navSettingsActions.UpdateSubSelected("ViewHelp Helpdesk"));

    onOpen();
  }, []);

  function onOpen() {
    Axios.post("/pods/helpdesk/tickets/getAllTickets")
      .then((res) => {
        const data = res.data;
        if (data.error == "null") {
          setTickets(data.tickets);
        }
      })
      .catch((err) => console.log(err));
  }

  function handleCloseCategoryModal() {
    setCategoryModal((prevState) => {
      return { prevState, open: false };
    });
  }

  function handleCategoriesClick() {
    setCategoryModal((prevState) => {
      return { prevState, open: true };
    });
  }

  function handleTicketOnClick(uuid) {
    navigate(`./ticket/${uuid}`);
  }

  return (
    <div style={ViewControllerStyles.body}>
      <Row>
        <Col style={ViewControllerStyles.catergoriesBtn}>
          <Button onClick={handleCategoriesClick}>Categories</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {tickets.map((ticket, index) => {
            return (
              <div key={index}>
                <Card
                  style={ViewControllerStyles.cardStyle}
                  onClick={handleTicketOnClick.bind(this, ticket.uuid)}
                >
                  <Card.Body>
                    <Row>
                      <Col>Customer: {ticket.user}</Col>
                      <Col>Stubject: {ticket.subject}</Col>
                      <Col>Date: {ticket.date}</Col>
                      <Col>Status: {ticket.status}</Col>
                    </Row>
                  </Card.Body>
                </Card>
                <br />
              </div>
            );
          })}
        </Col>
      </Row>

      <Modal show={categoryModal.open} onHide={handleCloseCategoryModal}>
        <CategoriesView close={handleCloseCategoryModal} />
      </Modal>
    </div>
  );
}

export default ViewController;
