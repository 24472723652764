import React, { useState, useRef } from "react";
import { Row, Col, Form, Dropdown, Button, Table, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Axios from "axios";

//STYLES
import * as BasicDetailsStyles from "../../styles/products/basicDetails";

function NewProduct_BasicDetails(props) {
    const [modal, setModal] = useState({
        open: false,
        header: "",
        message: ""
    });

    function handleCloseModal() {
        setModal({ open: false, header: "", message: "" });
    }

    function handleTextChange(event) {
        const { name, value } = event.target;

        const values = props.data.values;
        const valids = props.data.valids;

        let isValid = false;
        if (value.length > 0) {
            isValid = true;
        }

        values[name] = value;
        valids[name] = isValid;

        const allTrue = Object.keys(props.data.valids).every(function (k) {
            return props.data.valids[k] === true;
        });

        props.setData((prevState) => {
            return { ...prevState, values, valids, sectionValid: allTrue };
        });

        props.validateMainErrorMessage(allTrue, null, null);
    }

    function handleOnTouched(event) {
        const { name } = event.target;

        const touched = props.data.touched;

        touched[name] = true;

        props.setData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function numbersDecKeyPress(event) {
        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();
        }
    }

    function handleAvailableDateChange(date) {
        const values = props.data.values;

        values.availableFrom = date;

        props.setData((prevState) => {
            return { ...prevState, values };
        });
    }

    function handleActiveCheckChange(event) {
        const { checked } = event.target;
        let continu = false;

        if (checked) {
            if (props.personalisation.values.coloursInStock != "") {
                continu = true;
            } else {
                setModal({
                    header: "No Colours in stock",
                    message: "You cannot active an product when none of it's colours are in stock",
                    open: true
                });
            }
        } else {
            continu = true;
        }

        if (continu) {
            const values = props.data.values;

            values.active = checked;

            props.setData((prevState) => {
                return { ...prevState, values };
            });
        }
    }

    function handleDropdownItemClick(name) {
        let data = {};
        if (props.edit) {
            data = { category: name, editMode: true, prevProductCode: props.data.previousProductCode }; //LOOK INTO
        } else {
            data = { category: name, editMode: false, prevProductCode: "" };
        }
        Axios.post("/pods/3dPrintingShop/getProductCode", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const values = props.data.values;
                    values.category = name;
                    values.productCode = data.productCode;

                    //Validation
                    let isValid = false;
                    if (name != "Select Category") {
                        isValid = true;
                    }
                    const valids = props.data.valids;
                    valids.category = isValid;

                    const allTrue = Object.keys(props.data.valids).every(function (k) {
                        return props.data.valids[k] === true;
                    });

                    props.setData((prevState) => {
                        return { ...prevState, values, valids, sectionValid: allTrue };
                    });

                    props.validateMainErrorMessage(allTrue, null, null);
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div style={BasicDetailsStyles.body}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <strong>Title:</strong>
                        </Form.Label>
                        <Form.Control
                            isInvalid={props.data.touched.title && !props.data.valids.title}
                            type="text"
                            name="title"
                            value={props.data.values.title}
                            onChange={handleTextChange}
                            maxLength={props.data.countersMax.title}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a title!</Form.Control.Feedback>
                        <div style={BasicDetailsStyles.counterStyle}>
                            {props.data.countersMax.title - props.data.values.title.length}/{props.data.countersMax.title}
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <strong>Description:</strong>
                        </Form.Label>
                        <Form.Control
                            isInvalid={props.data.touched.description && !props.data.valids.description}
                            as="textarea"
                            rows={3}
                            name="description"
                            value={props.data.values.description}
                            onChange={handleTextChange}
                            maxLength={props.data.countersMax.description}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a description!</Form.Control.Feedback>
                        <div style={BasicDetailsStyles.counterStyle}>
                            {props.data.countersMax.description - props.data.values.description.length}/{props.data.countersMax.description}
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <strong>Price:</strong>
                        </Form.Label>
                        <Form.Control
                            isInvalid={props.data.touched.price && !props.data.valids.price}
                            type="text"
                            name="price"
                            onKeyPress={numbersDecKeyPress}
                            value={props.data.values.price}
                            onChange={handleTextChange}
                            onBlur={handleOnTouched}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a price!</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    <strong>Category:</strong>
                                </Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant={props.triedToSubmit && !props.data.valids.category ? "danger" : "primary"}>
                                        {props.data.values.category}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {props.data.categories.map((category, index) => {
                                            return (
                                                <Dropdown.Item key={index} onClick={handleDropdownItemClick.bind(this, category.name)}>
                                                    {category.name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div
                                    style={
                                        props.triedToSubmit && !props.data.valids.category
                                            ? BasicDetailsStyles.errorShow
                                            : BasicDetailsStyles.errorHide
                                    }
                                >
                                    Please select a category
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    <strong>Product Code:</strong>
                                </Form.Label>
                                <Form.Control type="text" disabled value={props.data.values.productCode} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    <strong>Available From:</strong>
                                </Form.Label>{" "}
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.data.values.availableFrom}
                                    onChange={(date: Date) => handleAvailableDateChange(date)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <br />
                            <Form.Check label="Active" checked={props.data.values.active} onChange={handleActiveCheckChange} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={modal.open} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewProduct_BasicDetails;
