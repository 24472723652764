import * as Colors from "./environmentColors";

export const ends = null;
export const middle = null;

export const centerContainer = {
    // backgroundColor: Colors.primary,
    textAlign: "center",
    // color: "white",
    border: `2px solid ${Colors.primary}`,

    // borderRadius: "25px",

    padding: "30px",
    fontSize: "18px",
    marginTop: "10px"
};

export const hidden = {
    visibility: "hidden"
};

export const unhidden = {
    visibility: "visible"
};

export const detailsHeadings = {
    textAlign: "right"
};

export const m_detailsHeadings = {
    textAlign: "left"
};

export const details = {
    textAlign: "left"
};

export const formLabels = {
    textAlign: "right"
};

export const m_formLabels = {
    textAlign: "left"
};

export const reCAPTCHA = {
    textAlign: "center",
    margin: 0,
    display: "inline-block"
};

export const errorMessage = {
    color: "yellow"
};

export const button = {
    color: Colors.primary,
    fontWeight: "bold",
    border: `2px solid ${Colors.primary}`
};
export const buttonVariant = "";
