export const body = {
    margin: "10px",
    border: "1px solid grey",
    padding: "5px",
    cursor: "pointer"
};

export const main = {
    margin: "10px",
    border: "2px solid red",
    padding: "5px",
    cursor: "pointer"
};

export const selected = {
    margin: "10px",
    border: "2px solid blue",
    padding: "5px",
    cursor: "pointer"
};

export const image = {
    width: "150px",
    height: "150px"
};
