import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Axios from "axios";

//ACTIONS
import * as AccessModalActions from "../../../envStore/actions/accessModal";
import * as UserActions from "../../../../store/actions/user";

//STYLES
import * as FrontInterfaceStyles from "../styles/frontInterface";

function SignIn() {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        valids: {
            email: false,
            password: false
        },
        touched: {
            email: false,
            password: false
        },
        errorOnForm: false
    });
    const [message, setMessage] = useState("text");

    function handleOnChange(event) {
        const { id, value } = event.target;

        const valids = data.valids;

        let isValid = false;
        if (id == "email") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm.test(value)) {
                isValid = true;
            }
        } else {
            if (value != "") {
                isValid = true;
            }
        }

        valids[id] = isValid;

        const allTrue = Object.keys(data.valids).every(function (k) {
            return data.valids[k] === true;
        });

        setData((prevState) => {
            return { ...prevState, valids, errorOnForm: allTrue };
        });
    }

    function handleOnTouch(event) {
        const { id } = event.target;

        const touched = data.touched;
        touched[id] = true;

        setData((prevState) => {
            return { ...prevState, touched };
        });
    }

    function handleOpenForgotPassword() {
        dispatch(AccessModalActions.UpdateScreen("forgotPassword"));
    }

    function handleOpenRegister() {
        dispatch(AccessModalActions.UpdateScreen("register"));
    }

    function switchAllTouches() {
        const touches = Object.assign(...Object.keys(data.touched).map((k) => ({ [k]: true })));

        setData((prevState) => {
            return { ...prevState, touched: touches };
        });
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            handleSignInBtn();
        }
    }

    function handleSignInBtn() {
        if (!data.errorOnForm) {
            switchAllTouches();
        } else {
            const email = document.getElementById("email").value;
            const password = document.getElementById("password").value;

            const data = { email: email, password: password };
            Axios.post("/userLogin/auth/local/user", data)
                .then((res) => {
                    const data = res.data;
                    if (data.message === "Logged in successful") {
                        dispatch(UserActions.UpdateAuth(true));
                        dispatch(UserActions.UpdateID(data.user.id));
                        dispatch(UserActions.UpdateName(data.user.fullName));
                        dispatch(UserActions.UpdateEmail(data.user.email));
                        dispatch(UserActions.UpdateType(data.user.type));
                        dispatch(UserActions.UpdateRequestedPassword(data.user.requestedPassword == "true"));
                        setMessage("");
                        if (data.user.requestedPassword == "true") {
                            dispatch(AccessModalActions.UpdateScreen("changePassword"));
                        } else {
                            dispatch(AccessModalActions.SignInMethod());
                        }
                    } else {
                        setMessage(data.info);
                        if (data.info === "A Super Admin cannot sign into the front end") {
                            document.getElementById("username").value = "";
                            document.getElementById("password").value = "";
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                        isInvalid={data.touched.email && !data.valids.email ? true : false}
                        type="text"
                        id="email"
                        onChange={handleOnChange}
                        onBlur={handleOnTouch}
                    />
                    <Form.Control.Feedback type="invalid">Please enter a valid email!</Form.Control.Feedback>
                </Form.Group>
                <br />
                <Form.Group>
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        isInvalid={data.touched.password && !data.valids.password ? true : false}
                        type="password"
                        id="password"
                        onChange={handleOnChange}
                        onBlur={handleOnTouch}
                        onKeyPress={handleKeyPress}
                    />
                    <Form.Control.Feedback type="invalid">Please enter a password!</Form.Control.Feedback>
                </Form.Group>
                <div style={message != "text" ? FrontInterfaceStyles.errorTextShow : FrontInterfaceStyles.errorTextHide}>{message}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="mr-auto" onClick={handleOpenRegister}>
                    Register
                </Button>
                <Button onClick={handleOpenForgotPassword}>Forgot Password</Button>
                <Button onClick={handleSignInBtn}>Sign In</Button>
            </Modal.Footer>
        </div>
    );
}

export default SignIn;
