export const topProductCode = {
    textAlign: "right",
    paddingTop: "10px"
};

export const title = {
    textAlign: "center"
};

export const price = {
    textAlign: "right"
};

export const photoContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export const showError = {
    visibility: "visible",
    color: "red"
};

export const hideError = {
    visibility: "hidden"
};

export const counter = {
    color: "red",
    textAlign: "right"
};

export const quantityText = {
    width: "50px",
    textAlign: "center"
};

export const quantitySection = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right"
};
