import Axios from "axios";

export const UPDATE_BASKET_ITEM_TOTAL = "UPDATE_BASKET_ITEM_TOTAL";
export const UPDATE_SETUPDATE = "UPDATE_SETUPDATE";
export const UPDATE_BASKET = "UPDATE_BASKET";
export const UPDATE_BASKET_QUANITIES = "UPDATE_BASKET_QUANITIES";
export const TOGGLE_BASKET_SUCCUESS = "TOGGLE_BASKET_SUCCUESS";
export const CLEAR_BASKET_INTERFACE = "CLEAR_BASKET_INTERFACE";
export const CHECKOUT_DATA_UPDATE = "CHECKOUT_DATA_UPDATE";
export const UPDATE_POSTAGE_OPTIONS = "UPDATE_POSTAGE_OPTIONS";
export const UPDATE_SELECTED_POSTAGE = "UPDATE_SELECTED_POSTAGE";

export const FetchBasketDeatils = () => {
    return (dispatch, getState) => {
        const data = { userID: getState().user.id };
        Axios.post("/pods/3dPrintingShop/frontEnd/getBasketItems", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    const quantities = {};
                    let basketTotal = 0;

                    for (const item of data.items) {
                        quantities[item.uuid] = item.quantity;

                        const itemTotal = item.productDetails.price * item.quantity;
                        basketTotal = basketTotal + itemTotal;
                    }

                    dispatch({
                        type: UPDATE_BASKET,
                        items: data.items,
                        serverQuanities: quantities,
                        basketQuantities: quantities,
                        basketTotal: basketTotal,
                        itemTotal: data.itemTotal
                    });
                }
            })
            .catch((err) => console.log(err));
    };
};

export const ToggleBasketSuccess = (val) => {
    return {
        type: TOGGLE_BASKET_SUCCUESS,
        value: val
    };
};

export const UpdateBasketItemTotal = () => {
    return (dispatch, getState) => {
        const data = { userID: getState().user.id };
        Axios.post("/pods/3dPrintingShop/frontEnd/getBasketTotal", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    dispatch({
                        type: UPDATE_BASKET_ITEM_TOTAL,
                        value: data.total
                    });
                }
            })
            .catch((err) => console.log(err));
    };
};

export const IncraseBasketItemQuantity = (itemUUID) => {
    return (dispatch, getState) => {
        const quantities = { ...getState().printingShop.basketQuantities };

        quantities[itemUUID] = quantities[itemUUID] + 1;

        dispatch(runCheckQuantities(quantities));

        dispatch({
            type: UPDATE_BASKET_QUANITIES,
            value: quantities
        });
    };
};

export const DecraseBasketItemQuantity = (itemUUID) => {
    return (dispatch, getState) => {
        if (getState().printingShop.basketQuantities[itemUUID] > 1) {
            const quantities = { ...getState().printingShop.basketQuantities };

            quantities[itemUUID] = quantities[itemUUID] - 1;

            dispatch(runCheckQuantities(quantities));

            dispatch({
                type: UPDATE_BASKET_QUANITIES,
                value: quantities
            });
        }
    };
};

const runCheckQuantities = (basketQ) => {
    return (dispatch, getState) => {
        let setUpdate = false;

        for (const [key, value] of Object.entries(getState().printingShop.serverQuanities)) {
            if (basketQ[key] !== value) {
                setUpdate = true;
            }
        }

        dispatch({
            type: UPDATE_SETUPDATE,
            value: setUpdate
        });
    };
};

export const UpdateBasketOnServer = () => {
    return (dispatch, getState) => {
        const data = { basketQuantities: getState().printingShop.basketQuantities };
        Axios.post("/pods/3dPrintingShop/frontEnd/updateBasket", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    dispatch(FetchBasketDeatils());

                    dispatch({
                        type: UPDATE_SETUPDATE,
                        value: false
                    });
                }
            })
            .catch((err) => console.log(err));
    };
};

export const RemoveItemFromBasket = (itemUUID) => {
    return (dispatch, getState) => {
        const data = { userID: getState().user.id, itemUUID };
        Axios.post("/pods/3dPrintingShop/frontEnd/removeItemFromBasket", data)
            .then((res) => {
                const data = res.data;
                if (data.error == "null") {
                    dispatch(FetchBasketDeatils());
                }
            })
            .catch((err) => console.log(err));
    };
};

export const UpdateCheckoutFormData = (newData) => {
    return {
        type: CHECKOUT_DATA_UPDATE,
        value: newData
    };
};

export const UpdatePostageOptions = (options, selected) => {
    return (dispatch, getState) => {
        const basketTotal = getState().printingShop.basketTotal;

        const postage = parseInt(options.find((post) => post.uuid == selected).price);

        const total = basketTotal + postage;

        dispatch({ type: UPDATE_POSTAGE_OPTIONS, postage: options, selected: selected, checkoutTotal: total });
    };
};

export const UpdatePostageSelected = (uuid) => {
    return (dispatch, getState) => {
        const basketTotal = getState().printingShop.basketTotal;

        const postage = parseInt(getState().printingShop.postageOptions.find((post) => post.uuid == uuid).price);

        const total = basketTotal + postage;

        dispatch({ type: UPDATE_SELECTED_POSTAGE, value: uuid, checkoutTotal: total });
    };
};
