import React from "react";

//PODS
//--ADMIN
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";
import AppServerAdmin from "./appService/admin/podRouter";
import WebsiteQuoteAdmin from "./websiteQuote/admin/podRouter";
import AppRequestQuoteAdmin from "./appRequestQuote/admin/podRouter";
import ViewHelp_HelpDesk from "./viewHelp/admin/podRouter";
import ViewHelp_OnlinePayments from "./viewHelp/onlinePayments/admin/podRouter";
import Calendar from "./calendar/admin/files/controller";
import PlannerAdmin from "./viewHelp/planner/admin/podRouter";
import Printing3DAdmin from "./3dPrintingShop/admin/podRouter";

//--FRONTEND
import WebsiteQuoteFrontEnd from "./websiteQuote/frontEnd/files/quoteSystem";
import MembershipExtraLogin from "./membershipExtra/loginPod/loginForm";
import ViewHelpFrontEnd from "./viewHelp/frontEnd/podRouter";
import AppRequestQuoteFrontEnd from "./appRequestQuote/frontEnd/files/quoteSystem";
import Printing3DFrontEnd from "./3dPrintingShop/frontEnd/podRouter";

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {props.podCode == "CPOD_APP_SERVICE_ADMIN" && <AppServerAdmin />}
            {props.podCode == "CPOD_WEBSITEQUOTE_ADMIN" && <WebsiteQuoteAdmin />}
            {props.podCode == "CPOD_WEBSITEQUOTE_FREND" && <WebsiteQuoteFrontEnd />}
            {props.podCode == "CPOD_MEMBEREXTRA_FREND" && <MembershipExtraLogin />}
            {props.podCode == "CPOD_VIEWHELP_FREND" && <ViewHelpFrontEnd />}
            {props.podCode == "CPOD_VIEWHELP_ADMIN" && <ViewHelp_HelpDesk />}
            {props.podCode == "CPOD_APPREQUESTQUOTE_ADMIN" && <AppRequestQuoteAdmin />}
            {props.podCode == "CPOD_APPREQUESTQUOTE_FREND" && <AppRequestQuoteFrontEnd />}
            {props.podCode == "CPOD_VIEWHELP_PAYMENTS_ADMIN" && <ViewHelp_OnlinePayments />}
            {props.podCode == "CPOD_CALENDAR_ADMIN" && <Calendar />}
            {props.podCode == "CPOD_3DPRINTINGSHOP_ADMIN" && <Printing3DAdmin />}
            {props.podCode == "CPOD_3DPRINTINGSHOP_FREND" && <Printing3DFrontEnd />}
        </div>
    );
}

export default EnvironmentPodsMap;
