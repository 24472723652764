import * as Colors from "../../../../globalStyles/environmentColors";

export const formLabel = {
    textAlign: "left",
    fontSize: "1.2rem"
};

export const topText = {
    fontSize: "1.2rem"
};

export const buttons = {
    color: Colors.accent,
    fontWeight: "bold"
};

export const selectedButtons = {
    color: "white",
    fontWeight: "bold"
};

export const feedbackText = {
    color: "yellow"
};
