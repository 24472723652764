import React, { useState, useEffect } from "react";
import { Row, Col, Form, ListGroup, Button } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

import ReplyViewer from "./replyViewer";
import TicketReply from "./ticketReply";
import FileViewer from "./fileViewer";

//STYLES
import * as TicketViewerStyles from "../styles/ticketViewer";

//ACTIONS
import * as GS_navSettingsActions from "../../../../../store/actions/globalSettings/GS_navSettings";

function TicketViewer(props) {
  const [ticketInfo, setTicketInfo] = useState({
    original: {},
    replies: [],
    viewOne: true,
    reOpen: false,
  });
  const dispatch = useDispatch();

  const [fileViewerShow, setFileViewerShow] = useState({
    show: false,
    files: [],
  });

  function handleFilesViewerClose() {
    setFileViewerShow((prevState) => {
      return { ...prevState, show: false };
    });
  }

  function handleViewFileButton(ticketUUID) {
    let data = { original: true, ticketUUID: ticketUUID, replyUUID: "" };

    Axios.post("/pods/helpdesk/tickets/getTicketFiles", data)
      .then((res) => {
        const data = res.data;
        if (data.error == "null") {
          setFileViewerShow((prevState) => {
            return { ...prevState, show: true, files: data.files };
          });
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    dispatch(GS_navSettingsActions.UpdateTitle("ViewHelp Helpdesk"));
    dispatch(GS_navSettingsActions.UpdateSelected("Features"));
    dispatch(GS_navSettingsActions.UpdateSubSelected("ViewHelp Helpdesk"));
    onOpen();
  }, []);

  function disableReopen() {
    setTicketInfo((prevState) => {
      return { ...prevState, reOpen: false };
    });
  }

  function onOpen() {
    const data = { uuid: props.uuid };

    Axios.post("/pods/helpdesk/tickets/getTicketInfo", data)
      .then((res) => {
        const data = res.data;
        console.log(data);
        if (data.error == "null") {
          setTicketInfo((prevState) => {
            return {
              ...prevState,
              original: data.originalInfo,
              replies: data.replies,
            };
          });
        }
      })
      .catch((err) => console.log(err));
  }

  function handleViewAllClick() {
    setTicketInfo((prevState) => {
      return { ...prevState, viewOne: !prevState.viewOne };
    });
  }

  function handleReOpenClick() {
    setTicketInfo((prevState) => {
      return { ...prevState, reOpen: true };
    });
  }

  return (
    <div style={TicketViewerStyles.body}>
      <Row>
        <Col>
          <h2>Original Ticket Info</h2>
          <div style={TicketViewerStyles.ticketBorder}>
            <Row>
              <Col>
                <Row>
                  <Col sm={3}>
                    <p>UUID:</p>
                  </Col>
                  <Col sm={9}>{ticketInfo.original.uuid}</Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p>User:</p>
                  </Col>
                  <Col sm={9}>{ticketInfo.original.user}</Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p>Subject:</p>
                  </Col>
                  <Col sm={9}>{ticketInfo.original.subject}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col sm={3}>
                    <p>Category:</p>
                  </Col>
                  <Col sm={9}>{ticketInfo.original.category}</Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p>Date:</p>
                  </Col>
                  <Col sm={9}>{ticketInfo.original.date}</Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <p>Files:</p>
                  </Col>
                  <Col>
                    {ticketInfo.original.files == "true" ? (
                      <Button
                        onClick={handleViewFileButton.bind(
                          this,
                          ticketInfo.original.uuid
                        )}
                      >
                        View File(s)
                      </Button>
                    ) : (
                      "No file(s) attached"
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                Description: <br />
                <p>{ticketInfo.original.description}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {ticketInfo.replies.length > 1 ? (
        <div>
          <Row>
            <Col></Col>
            <Col
              style={TicketViewerStyles.viewAllLink}
              onClick={handleViewAllClick}
            >
              {ticketInfo.viewOne
                ? `View all replies (${ticketInfo.replies.length})`
                : "View just one reply"}
            </Col>
            <Col></Col>
          </Row>
        </div>
      ) : null}
      {ticketInfo.replies.length > 0 ? (
        <div>
          {ticketInfo.viewOne ? (
            <div>
              <ReplyViewer
                info={ticketInfo.replies[ticketInfo.replies.length - 1]}
              />
            </div>
          ) : (
            <div>
              {ticketInfo.replies.map((reply, index) => {
                return <ReplyViewer key={index} info={reply} />;
              })}
            </div>
          )}
        </div>
      ) : null}
      {ticketInfo.original.status == "open" || ticketInfo.reOpen ? (
        <div>
          <TicketReply
            type={props.type}
            ticketUUID={props.uuid}
            refresh={onOpen}
            reOpen={ticketInfo.reOpen}
            reOpenDisable={disableReopen}
          />
        </div>
      ) : (
        <div>
          {props.type == "admin" ? (
            <div style={TicketViewerStyles.frontEnd_ClosedNotice}>
              This ticket has been closed! <br />
              <Button onClick={handleReOpenClick}>Re-open Ticket</Button>
            </div>
          ) : (
            <div style={TicketViewerStyles.frontEnd_ClosedNotice}>
              <p>
                High-View Studios has closed this ticket. If you want to re-open
                it, please contact High-View Studios
              </p>
            </div>
          )}
        </div>
      )}
      <FileViewer
        open={fileViewerShow.show}
        close={handleFilesViewerClose}
        original={true}
        files={fileViewerShow.files}
        ticketUUID={ticketInfo.original.uuid}
      />
    </div>
  );
}

export default TicketViewer;
