export const body = {
    border: "1px solid black",
    padding: "10px"
};

export const image = {
    height: "150px",
    width: "150px",
    border: "1px solid black"
};

export const columnRight = {
    textAlign: "right"
};
